import { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { SCROLL_DIRECTION } from '_shared/globalState/atoms';

const StickyScrollContainer = ({ children, ...props }) => {
  const [scrollDirection, setScrollDirection] = useRecoilState(SCROLL_DIRECTION);

  const lastScrollTop = useRef(0);
  const lastScrollBottom = useRef(0);

  const updateScrollDirection = (e) => {
    const { scrollHeight, scrollTop } = e.target;
    const scrollBottom = scrollHeight - scrollTop;

    if (lastScrollTop.current - scrollTop > 10) {
      if (scrollDirection !== 'up') {
        setScrollDirection('up');
      }
      lastScrollTop.current = scrollTop > 0 ? scrollTop : 0;
      lastScrollBottom.current = scrollBottom;
    }
    if (lastScrollBottom.current === 0 || lastScrollBottom.current - scrollBottom > 10) {
      if (scrollDirection !== 'down') {
        setScrollDirection('down');
      }
      lastScrollTop.current = scrollTop > 0 ? scrollTop : 0;
      lastScrollBottom.current = scrollBottom;
    }
  };

  useEffect(() => {
    return () => setScrollDirection(null);
  }, [setScrollDirection]);

  return (
    <Box flexGrow="1" overflowY="scroll" onScroll={updateScrollDirection} pb="100px" {...props}>
      {children}
    </Box>
  );
};

export default StickyScrollContainer;
