import { Box, Flex, Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import useGraphPlotKey from '../hooks/useGraphPlotKey';
import TableHeader from './_shared/TableHeader';
import getColourPalette from './utils/chartColours';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0];
    const datalabel = payload[0].payload.label.replaceAll('_', ' ');
    return (
      <Flex direction="column" bg="header" p="2" rounded="sm">
        <Box fontWeight="bold">{`${name}`}</Box>
        <Box>{`${datalabel[0].toUpperCase()}${datalabel.slice(1)}: ${value}`}</Box>
      </Flex>
    );
  }

  return null;
};

const PanelPieChart = ({ panelData, layoutInfo }) => {
  const graphKey = useGraphPlotKey(panelData, layoutInfo);
  const { data: plottingData, data_columns: dataColumns } = panelData;
  const { x } = dataColumns;
  const { y } = dataColumns;
  const tableColumns = [];
  const rawColumns = [x].concat(y);
  rawColumns.forEach((col) => {
    tableColumns.push({
      content: `${col[0].toUpperCase()}${col.slice(1).replaceAll('_', ' ')}`,
      key: col
    });
  });
  const data = [];
  const radius = [];
  for (let i = 0; i < y.length; i += 1) {
    data.push([]);
    radius.push({ outer: 75 * (i + 1 / y.length) + 25, inner: 75 * (i + 1 / y.length) });
  }
  let max = 0;
  const palette = getColourPalette(plottingData.length);
  for (let i = 0; i < plottingData.length; i += 1) {
    const xval = plottingData[i][x];
    if (xval === null) {
      break;
    }
    for (let j = 0; j < y.length; j += 1) {
      const yval = parseInt(plottingData[i][y[j]]);
      plottingData[i].colourFill = palette[i];
      if (xval && (yval === 0 || yval)) {
        data[j].push({ name: xval, value: yval, fill: palette[i], label: y[j] });
        if (yval > max) {
          max = yval;
        }
      }
    }
  }
  return (
    <>
      <Box flex="0 0 330px">
        <ResponsiveContainer key={graphKey}>
          <PieChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
            {data.map((d, idx) => (
              <Pie
                key={idx}
                data={d}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={radius[idx].outer}
                innerRadius={radius[idx].inner}
              />
            ))}
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </Box>
      <Box flexGrow="1" overflow="auto">
        <Table size="sm" w="min-content" mt="5" whiteSpace="nowrap">
          <TableHeader columns={tableColumns} />
          <Tbody>
            {plottingData.map((row, i) => {
              if (row[x] === null) {
                return null;
              }
              return (
                <Tr key={i}>
                  {tableColumns.map((col) => {
                    return <Td key={col.key}>{row[col.key]}</Td>;
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default PanelPieChart;
