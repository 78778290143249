import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton
} from '@chakra-ui/react';
import { useMatchRoute } from '@tanstack/react-location';
import { MdClose } from 'react-icons/md';
import { useRecoilState } from 'recoil';
import { GLOBAL_NAV_OPEN } from '_shared/globalState/atoms';
import MenuButton from './MenuButton';
import SubMenu from './SubMenu';
import menuDef from './menuDef';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import { handlePermissions } from '_shared/utils/utilFunctions';

const MenuDrawer = () => {
  const matchRoute = useMatchRoute();
  const { data: userData = {} } = useUserDetails();

  const { permissions } = userData;
  const [globalNavOpen, setGlobalNavOpen] = useRecoilState(GLOBAL_NAV_OPEN);
  const VCPerm = handlePermissions(10, permissions);
  let filteredArr = menuDef;
  if (!VCPerm) {
    filteredArr = menuDef.filter((e) => {
      return e.text !== 'Video Centre';
    });
  }
  return (
    <Drawer placement="left" onClose={() => setGlobalNavOpen(false)} isOpen={globalNavOpen}>
      <DrawerOverlay />
      <DrawerContent bg="black.100" p="4">
        <DrawerHeader py="3" px="0" display="flex" justifyContent="end">
          <IconButton
            icon={<MdClose size={20} />}
            variant="ghostDark"
            size="sm"
            onClick={() => setGlobalNavOpen(false)}
          />
        </DrawerHeader>
        <DrawerBody px="0" py="1">
          <Flex direction="column" gap="2">
            {filteredArr.map((item) => {
              const { icon, text, subMenuDef, location } = item;
              if (subMenuDef) {
                let initOpen = false;
                subMenuDef.forEach((el) => {
                  const match = matchRoute({ to: el.location });
                  if (match !== undefined) {
                    initOpen = true;
                  }
                });
                return (
                  <SubMenu
                    icon={icon}
                    text={text}
                    subMenuDef={subMenuDef}
                    initOpen={initOpen}
                    key={`${text}-submenu`}
                  />
                );
              }
              return (
                <MenuButton
                  icon={icon}
                  text={text}
                  location={location}
                  key={`${text}-drawer-menu`}
                />
              );
            })}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default MenuDrawer;
