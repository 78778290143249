import { Flex } from '@chakra-ui/react';
import FilterTag from './FilterTag';

const FilterTags = ({ values, filterDefs, border }) => {
  if (!filterDefs) {
    return null;
  }
  return (
    <Flex columnGap="2" rowGap={border && '2'} flexWrap="wrap">
      {Object.entries(values).map(([name, value]) => {
        const filterDef = filterDefs.filters.find((el) => {
          return name === el.name;
        });
        const groupDef = filterDefs.filter_groups.find((el) => {
          return filterDef?.group === el.name;
        });
        return (
          <FilterTag
            key={name}
            group={groupDef}
            filterDef={filterDef}
            value={value}
            border={border}
          />
        );
      })}
    </Flex>
  );
};

export default FilterTags;
