import { getfaq } from '_shared/dataFetching/apiService';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  Flex,
  AccordionIcon,
  AccordionPanel,
  Center,
  Spinner
} from '@chakra-ui/react';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import { useState } from 'react';

const FAQComponent = ({ heading, text, isOn }) => {
  return (
    <AccordionItem mt={'10px'} mb="10px" border="none" overflow="hidden">
      <AccordionButton
        border="1px"
        borderColor="black.8"
        borderTopRadius="10px"
        borderBottomRadius={!isOn ? '10px' : null}
        overflow="hidden"
        bg={'grey.50'}
      >
        <Box as="span" flex="1" textAlign={'left'} fontSize="md" fontWeight="bold">
          {heading}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel
        fontSize="md"
        border="1px"
        borderColor="black.8"
        overflowY={'auto'}
        borderBottomRadius="10px"
        bg={'grey.50'}
      >
        {text}
      </AccordionPanel>
    </AccordionItem>
  );
};

const FrequentlyAskedQuestions = () => {
  const {
    isLoading: objectIsLoading,
    error: objectError,
    data: objectData,
    isFetching: objectIsFetching
  } = useQueryFetch(['faq'], () => getfaq());
  const [accordianValue, setAccordianValue] = useState(null);
  if (objectIsLoading) {
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    );
  }
  if (objectError && !objectIsFetching) {
    return <Box>{`Unable to retrieve home page data`}</Box>;
  }
  if (objectData) {
    return (
      <Flex direction="column" minH="0" flexGrow="1" borderRadius="md" p="10" overflowY="auto">
        <Box maxW="60%" w="100%" margin="auto" bg="white" p="10" flexGrow="1">
          <Box fontSize="xl" color="black.100" fontWeight={'bold'} marginBottom={'10px'}>
            Help Centre
          </Box>
          <Box marginBottom="20px" fontSize="lg" color="grey.600">
            FAQ
          </Box>
          <Flex flexGrow="1" direction="column">
            <Accordion allowToggle onChange={(e) => setAccordianValue(e)}>
              {objectData.map((el, idx) => {
                return (
                  <FAQComponent
                    key={`faq-${idx}`}
                    heading={el.heading}
                    text={el.text}
                    isOn={idx === accordianValue}
                  />
                );
              })}
            </Accordion>
          </Flex>
        </Box>
      </Flex>
    );
  }
};

export default FrequentlyAskedQuestions;
