import PlayerHomePage from 'pages/player/components/PlayerHomePage';
import FetchComponent from 'pages/_shared/components/FetchComponent';
import BetaSwitch from './BetaSwitch';
import PageContainer from 'pages/_shared/components/PageContainer';

export const playerRoutes = {
  path: 'player',
  children: [
    {
      path: '/',
      element: <PlayerHomePage />
    },
    {
      path: ':objectId',
      element: (
        <BetaSwitch beta={<PageContainer type="player" key="player" />}>
          <FetchComponent type="player" />
        </BetaSwitch>
      )
    }
  ]
};
