import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import ButtonSelector from '_shared/components/ButtonSelector';
import ScoreRange from './ScoreRange';

const fullOptions = [
  { value: '0', label: '1st' },
  { value: '1', label: '2nd' },
  { value: '2', label: '3rd' },
  { value: '3', label: '4th' }
];

const InningsAverage = ({ innData = [], panelWidth }) => {
  const [innings, setInnings] = useState('0');
  const options = fullOptions.slice(0, innData.length);

  return (
    <Box flex="2" w={panelWidth}>
      <Box fontSize="md" mb={3}>
        Totals by innings
      </Box>
      <Box mb={2}>
        <ButtonSelector options={options} handleChange={(v) => setInnings(v)} value={innings} />
      </Box>
      {innData && innData[innings] ? <ScoreRange data={innData[innings]} /> : null}
    </Box>
  );
};

export default InningsAverage;
