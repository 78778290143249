import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@chakra-ui/react';
import { Field } from 'formik';

const FormNumberInput = ({ name, label, min = 0, max, precision = 0, step = 1, isDisabled }) => {
  return (
    <Field name={name}>
      {({ field: { value }, form: { setFieldValue, errors, touched } }) => (
        <FormControl isInvalid={errors[name] && touched[name]}>
          <FormLabel>{label}</FormLabel>
          <NumberInput
            onChange={(v) => setFieldValue(name, v)}
            value={value}
            min={min}
            max={max}
            precision={precision}
            step={step}
            isDisabled={isDisabled}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormErrorMessage>{errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default FormNumberInput;
