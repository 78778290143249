import { useState } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-location';
import RouterLink from '_shared/components/Utils/RouterLink';
import dayjs from 'dayjs';
import ItemDetail from '../_shared/ItemDetail';
import EditPlaylistNameInput from './EditPlaylistNameInput';
import DeletePlaylistModal from '../DeletePlaylistModal';
import { HistoryOutline20, PlayOutline20 } from '_icons/outline/20';
import { MoreMenuHorizontalOutline24 } from '_icons/outline/24';
// import SelectionCheck from './SelectionCheck';

const PlaylistCard = ({ playlistData, refetch }) => {
  const navigate = useNavigate();
  const { data, modified, playlist_name, id } = playlistData;
  const [playlistName, setPlaylistName] = useState(playlist_name);
  const [renameActive, setRenameActive] = useState(false);
  const [hover, setHover] = useState(false);
  const [delModalOpen, setDelModalOpen] = useState(false);
  const modifiedString = dayjs().to(dayjs.utc(modified).local());

  return (
    <Flex
      p="4"
      direction="column"
      bg="white.100"
      borderRadius="md"
      gap="2"
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      position="relative"
      _hover={{ boxShadow: 'small' }}
      onClick={() => navigate({ to: `/video_playlist/${id}` })}
      cursor="pointer"
    >
      {/* <SelectionCheck hover={hover} /> */}
      <Flex bg="black.3" justify="center" align="center" w="100%" sx={{ aspectRatio: '4 / 3' }}>
        <Image w="150px" src="https://cv-qtp-images.s3.eu-west-2.amazonaws.com/logo-default.png" />
      </Flex>
      <Flex gap="2">
        <Flex direction="column" gap="2" flexGrow="1">
          {renameActive ? (
            <EditPlaylistNameInput
              playlistName={playlistName}
              setRenameActive={setRenameActive}
              setPlaylistName={setPlaylistName}
              id={id}
              refetch={refetch}
            />
          ) : (
            <Box fontWeight="700">
              <RouterLink to={`/video_playlist/${id}`}>{playlistName}</RouterLink>
            </Box>
          )}
          <Flex gap="1" flexWrap="wrap">
            <ItemDetail icon={<PlayOutline20 color="black.60" />}>{data.length}</ItemDetail>
            <ItemDetail icon={<HistoryOutline20 color="black.60" />}>{modifiedString}</ItemDetail>
          </Flex>
        </Flex>
        <Box flex="0 0 44px">
          {hover ? (
            <Menu>
              <MenuButton
                as={IconButton}
                h="44px"
                w="44px"
                aria-label="options"
                icon={<MoreMenuHorizontalOutline24 />}
                variant="ghost"
                onClick={(e) => e.stopPropagation()}
              />
              <MenuList>
                <MenuItem
                  key="rename"
                  fontSize="md"
                  px="2"
                  onClick={(e) => {
                    e.stopPropagation();
                    setRenameActive(true);
                  }}
                >
                  Rename
                </MenuItem>
                <MenuItem key="duplicate" fontSize="md" px="2" onClick={(e) => e.stopPropagation()}>
                  Duplicate
                </MenuItem>
                <MenuItem
                  key="delete"
                  fontSize="md"
                  px="2"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDelModalOpen(true);
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          ) : null}
        </Box>
      </Flex>
      <DeletePlaylistModal
        playlistData={playlistData}
        formModalOpen={delModalOpen}
        handleClose={() => setDelModalOpen(false)}
      />
    </Flex>
  );
};

export default PlaylistCard;
