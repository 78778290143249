import { createIcon } from '@chakra-ui/icons';

const StatsLeader = createIcon({
  displayName: 'StatsLeader',
  viewBox: '0 0 24 31',
  path: [
    <path
      d="M11.803 27.5404L5.5 30.2417V20.5H18.5V30.2417L12.197 27.5404L12 27.456L11.803 27.5404Z"
      fill="#E7EFFF"
      stroke="currentcolor"
    />,
    <circle cx="12" cy="12" r="11.5" fill="#F8F8F8" stroke="currentcolor" />,
    <path
      d="M13.2773 17H11.165V11.2168C11.165 11.0573 11.1673 10.8613 11.1719 10.6289C11.1764 10.3919 11.1833 10.1504 11.1924 9.9043C11.2015 9.65365 11.2106 9.42806 11.2197 9.22754C11.1696 9.28678 11.0671 9.38932 10.9121 9.53516C10.7617 9.67643 10.6204 9.80404 10.4883 9.91797L9.33984 10.8408L8.32129 9.56934L11.541 7.00586H13.2773V17Z"
      fill="currentcolor"
    />
  ]
});

export default StatsLeader;
