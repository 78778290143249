import player from './player';
import match from './match';
import comp from './comp';
import team from './team';
import ground from './ground';

const tabOptions = {
  player,
  match,
  comp,
  team,
  ground
};

export default tabOptions;
