import usePageTitle from '_shared/hooks/usePageTitle';
// import PanelPage from '../../_shared/components/PanelPage/PanelPage';
import PanelPage from 'panels/PanelPage/PanelPage';
import HomePageHeader from './HomePageHeader';

import StickyScrollWrapper from 'pages/_shared/components/StickyScroll/StickyScrollWrapper';
import StickyScrollContainer from 'pages/_shared/components/StickyScroll/StickyScrollContainer';

const HomePageContainer = ({ data, pageType }) => {
  const { panels, selected_team, user_details } = data;

  let title_str = 'Home Page';
  if (pageType !== 'home') {
    title_str = user_details.full_name;
  }
  usePageTitle(title_str);

  return (
    <>
      <StickyScrollContainer>
        <StickyScrollWrapper headerSize={138} pageType="match">
          <HomePageHeader teamData={selected_team} userData={user_details} />
        </StickyScrollWrapper>
        <PanelPage panels={panels} pageType={pageType} classId={0} />
      </StickyScrollContainer>
    </>
  );
};

export default HomePageContainer;
