import { Box, Flex, CircularProgress, CircularProgressLabel, Tooltip } from '@chakra-ui/react';

const PercentageComponent = ({ percentage, panelWidth }) => {
  return (
    <Tooltip label="Chance of winning" isDisabled={panelWidth > 450}>
      <Flex gap="2" alignItems="center">
        {panelWidth > 450 ? (
          <Box color="positive.shade.30" textAlign="center">
            Chance of winning
          </Box>
        ) : null}
        <CircularProgress value={parseInt(percentage)} color="positive.main" size="90px">
          <CircularProgressLabel color="positive.shade.30" fontSize="lg" fontWeight="700">
            {`${percentage}%`}
          </CircularProgressLabel>
        </CircularProgress>
      </Flex>
    </Tooltip>
  );
};

export default PercentageComponent;
