import { Box, chakra, Flex, Center, Image, Circle } from '@chakra-ui/react';
const getColor = (ball, type) => {
  if (ball.scoring === 'W') {
    return `ballScore.w.${type}`;
  }
  if (ball.scoring === '0') {
    return `ballscore.nr.${type}`;
  }

  return `ballScore.${ball.scoring}.${type}`;
};
const VideoCard = ({ videoData }) => {
  return (
    <Flex direction="row" alignItems="center" w="100%">
      <Flex direction="row" w="35%" alignItems="center">
        <Flex direction="row">
          <Center w="30%">
            <Image src={videoData.batting_team_logo} w="100%" />
          </Center>
          <Flex w="70%" direction="column" ml={2}>
            <Center color={`#${videoData.batting_colour}`} fontSize="1vw">
              {videoData.batting_team_name}
            </Center>
            <Center direction="row" w="100%">
              <Box mr="1">
                <Image src={`https://img.cricviz.com/icons/role/ba.png`} />
              </Box>
              <chakra.span>{videoData.batter}</chakra.span>
            </Center>
          </Flex>
        </Flex>
      </Flex>
      <Flex direction="row" w="30%">
        <Flex direction="column" w="100%" h="100%" alignContent="center">
          <Circle direction="row" bg={getColor(videoData, 'bg')} color={getColor(videoData, 'color')}>
            {videoData.scoring}
          </Circle>
          <Center direction="row">{videoData.start_date}</Center>
          <Center direction="row">{videoData.comp_name}</Center>
          <Flex direction="row">
            <Circle size="50%" bg={`#${videoData.batting_colour}`} color="white" ml={0} pl={0}>
              {videoData.team_score}
            </Circle>
            <Circle size="50%" bg={`#${videoData.bowling_colour}`} color="white" ml={0} pl={0}>
              {videoData.overs_unique}
            </Circle>
          </Flex>
        </Flex>
      </Flex>
      <Flex direction="row" w="35%" ml={4}>
        <Flex direction="row">
          <Flex w="70%" direction="column" ml={2}>
            <Center color={`#${videoData.bowling_colour}`} fontSize="1vw">
              {videoData.bowling_team_name}
            </Center>
            <Center direction="row">
              <Box mr="1">
                <Image src={`https://img.cricviz.com/icons/role/bo.png`} />
              </Box>
              <chakra.span>{videoData.bowler}</chakra.span>
            </Center>
          </Flex>
          <Center w="30%">
            <Image src={videoData.bowling_team_logo} w="100%" />
          </Center>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default VideoCard;
