import { Flex, Button } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import FormSelect from './FormSelect';
import AddNewCategory from './AddNewCategory';
import { getCategoryOptions } from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';

const FormCategorySelector = ({ name, label, value, pageType, classId }) => {
  const [isShown, setIsShown] = useState(false);
  const {
    isLoading,
    error,
    data: panelCategories
  } = useQueryFetch(
    ['categories', pageType, classId],
    () => getCategoryOptions(pageType, classId),
    {
      enabled: Boolean(pageType)
    }
  );

  const showOptions = () => {
    const options = [];
    if (panelCategories && Array.isArray(panelCategories)) {
      panelCategories.forEach((el) => {
        options.push({
          value: el.category_id,
          label: el.category_name
        });
      });
    }
    return options;
  };

  const handleClick = () => {
    setIsShown((current) => !current);
  };
  return (
    // <Field name={name}>
    //   {({ field, form }) => (
    //     <FormControl isInvalid={form.errors[name] && form.touched[name]}>
    <Flex pb={2}>
      <FormSelect
        name={name}
        label={label}
        options={showOptions()}
        value={value}
        placeholder={isLoading ? 'loading...' : error ? 'categories unavailable' : ' '}
        isHorizontal
      />

      <Button mt={3} onClick={handleClick} w="10%">
        {!isShown ? <AddIcon /> : <MinusIcon />}
      </Button>
      {isShown && <AddNewCategory pageType={pageType} classId={classId} />}
    </Flex>
    //     </FormControl>
    //   )}
    // </Field>
  );
};

export default FormCategorySelector;
