import { Box, Button, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { MdOutlineExpandMore, MdOutlineExpandLess } from 'react-icons/md';
import MenuButton from './MenuButton';

const SubMenu = ({ icon, text, subMenuDef, initOpen }) => {
  const [menuOpen, setMenuOpen] = useState(initOpen || false);
  return (
    <>
      <Button
        leftIcon={icon}
        variant="menu"
        rightIcon={menuOpen ? <MdOutlineExpandLess size="24" /> : <MdOutlineExpandMore size="24" />}
        onClick={() => setMenuOpen((p) => !p)}
      >
        <Box flexGrow="1">{text}</Box>
      </Button>
      {menuOpen ? (
        <Flex direction="column" gap="2" pl="8">
          {subMenuDef.map(({ icon, text, location }) => (
            <MenuButton icon={icon} text={text} location={location} />
          ))}
        </Flex>
      ) : null}
    </>
  );
};

export default SubMenu;
