import VideoSearchHomePage from 'pages/videoSearch/components/VideoSearchHomePage';
import VideoFiltersPage from 'pages/videoSearch/components/VideoFiltersPage';
import VideoResultsPage from 'pages/videoSearch/components/VideoResultsPage';

export const videoSearchRoutes = {
  path: 'video_search',
  children: [
    {
      path: '/',
      element: <VideoSearchHomePage />
    },
    {
      path: '/filters/:classId/:objectType/:objectId',
      element: <VideoFiltersPage />
    },
    {
      path: '/results/:classId/:objectType/:objectId',
      element: <VideoResultsPage />
    }
  ]
};
