const { Flex, Box } = require('@chakra-ui/react');

const OptionSection = ({ header, children }) => {
  return (
    <Flex direction="column" mt="3" border="1px" borderColor="cricket.50" rounded="md">
      <Box textAlign="center" bg={'cricket.header'}>
        {header}
      </Box>
      <Box p="2">{children}</Box>
    </Flex>
  );
};

export default OptionSection;
