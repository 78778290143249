import CompV2SubHeader from 'pages/comp/components/CompV2SubHeader';
import GroundV2SubHeader from 'pages/ground/components/GroundV2SubHeader';
import MatchV2SubHeader from 'pages/match/components/MatchSubHeader/MatchV2SubHeader';
import PlayerV2SubHeader from 'pages/player/components/PlayerSubHeader/PlayerV2SubHeader';
import TeamV2SubHeader from 'pages/team/components/TeamV2SubHeader';

const PageInfoHeader = ({ objectData, type, size }) => {
  if (type === 'player') {
    return <PlayerV2SubHeader data={objectData} size={size} />;
  }
  if (type === 'match') {
    return <MatchV2SubHeader data={objectData} size={size} />;
  }
  if (type === 'comp') {
    return <CompV2SubHeader data={objectData} size={size} />;
  }
  if (type === 'team') {
    return <TeamV2SubHeader data={objectData} size={size} />;
  }
  if (type === 'ground') {
    return <GroundV2SubHeader data={objectData} size={size} />;
  }
};

export default PageInfoHeader;
