import dayjs from 'dayjs';

export const checkNewHideValues = (previous, hideInfo) => {
  const newValue = { ...previous };
  Object.entries(hideInfo).forEach(([hideKey, hideValue]) => {
    if (!Object.prototype.hasOwnProperty.call(newValue, hideKey)) {
      newValue[hideKey] = hideValue;
    }
  });
  return newValue;
};

export const getDateFetchString = (date) => {
  const match = date.match(/^(\d+)(year|month)$/);

  if (!match) {
    throw new Error(
      'Invalid input format. Expected format: "Nyear" or "Nmonth", e.g., "1year", "2month".'
    );
  }

  const value = parseInt(match[1], 10); // Extract the number of years or months
  const unit = match[2]; // Extract the unit (year or month)

  const relativeDate = dayjs().subtract(value, unit);

  return `~${relativeDate.format('YYYY-MM-DD')}`;
};
