import { Box, Flex, Tooltip, IconButton } from '@chakra-ui/react';
import TagComponent from './TagComponent';
import VideoEditingSpeedComponent from './VideoEditingSpeedComponent';
import { CheckmarkFilled24 } from '_icons/filled/24';
import {
  ArrowLeftOutline24,
  ArrowRightOutline24,
  CheckboxUncheckedOutline24,
  LoopOutline24,
  SoundOffOutline24,
  SoundOnOutline24
} from '_icons/outline/24';

const VideoEditorBall = ({
  el,
  ballId,
  videoInd,
  removeItem,
  listLength,
  autoPlay,
  setAutoPlay,
  speedVal,
  sound,
  changeSpeed,
  changeSound,
  changeVideo,
  editItem,
  editAtt,
  setEditAtt,
  isChecked,
  setSelectedVideos
}) => {
  return (
    <>
      <Flex direction="column" bg="white.100">
        <Flex
          color="black.60"
          borderBottom="1px"
          borderBottomColor="black.8"
          align="center"
          h="10"
          px="4"
          justify="flex-end"
          gap="4"
        >
          <Flex align="center" gap="2">
            <Box fontSize="xs">
              Video {videoInd + 1} of {listLength}
            </Box>
            <Box cursor="pointer">
              {isChecked ? (
                <CheckmarkFilled24
                  color="primary.main"
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    setSelectedVideos((prev) => prev.filter((innerEl) => innerEl !== ballId));
                  }}
                />
              ) : (
                <CheckboxUncheckedOutline24
                  color="black.8"
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    setSelectedVideos((prev) => [...prev, ballId]);
                  }}
                />
              )}
            </Box>
          </Flex>
        </Flex>
        <Flex
          px="4"
          pt="2"
          columnGap="4"
          rowGap="2"
          direction={{ base: 'column-reverse', xl: 'row' }}
          pb="80px"
        >
          <Box flex="1">
            <TagComponent ballId={ballId} />
          </Box>
          <Box borderRight="1px" borderColor="black.8" />
          <Flex direction="column" gap="1">
            <Flex justify="space-between">
              <Flex>
                <Tooltip
                  label={`Click to ${sound ? `mute` : `enable sound`} for this clip in playlist`}
                >
                  <IconButton
                    icon={!sound ? <SoundOffOutline24 /> : <SoundOnOutline24 />}
                    variant="ghost"
                    color="black.76"
                    onClick={() => {
                      changeSound(!sound);
                    }}
                  />
                </Tooltip>
                <Tooltip
                  label={`Click to ${autoPlay ? `repeat current video` : `play through playlist`}`}
                >
                  <IconButton
                    icon={<LoopOutline24 />}
                    variant="ghost"
                    color={!autoPlay ? 'primary.main' : 'black.76'}
                    onClick={() => setAutoPlay((current) => !current)}
                  />
                </Tooltip>
              </Flex>
              <Flex>
                <Tooltip label="Previous video">
                  <IconButton
                    icon={<ArrowLeftOutline24 />}
                    variant="ghost"
                    color="black.76"
                    onClick={() => changeVideo(videoInd - 1)}
                    disabled={videoInd === 0}
                  />
                </Tooltip>
                <Tooltip label="Next video">
                  <IconButton
                    icon={<ArrowRightOutline24 />}
                    variant="ghost"
                    color="black.76"
                    onClick={() => changeVideo(videoInd + 1)}
                    disabled={videoInd === listLength - 1}
                  />
                </Tooltip>
              </Flex>
            </Flex>
            <VideoEditingSpeedComponent
              speedVal={speedVal}
              changeSpeed={changeSpeed}
              el={el}
              removeItem={removeItem}
              changeVideo={changeVideo}
              editItem={editItem}
              editAtt={editAtt}
              setEditAtt={setEditAtt}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default VideoEditorBall;
