import { Box, Flex, Select, Button, Spinner, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import dayjs from 'dayjs';
import { HistoryOutline20, PlayOutline20 } from '_icons/outline/20';
import {
  InfoOutline24,
  PlayOutline24,
  CloseOutline24,
  ArrowRightOutline24
} from '_icons/outline/24';
import { CircleCheckmarkFilled24 } from '_icons/filled/24';
import { startVideoConcatenation } from '_shared/dataFetching/apiService';
import { downloadVideoPlaylistData } from '_shared/utils/utilFunctions';
import { getCurrentEnvironment } from '_shared/utils/getCurrentEnvironment';
import { useNavigate } from '@tanstack/react-location';
import ItemDetail from './_shared/ItemDetail';

const videoResolutions = [
  { value: '640:360', label: 'SD (640x360)' },
  { value: '640:480', label: 'SD (640x480)' },
  { value: '1280:720', label: 'HD (1280x720)' }
];

const currentEnv = getCurrentEnvironment();

const PlaylistConcatenationComponent = ({
  vidList,
  vidClipping,
  setVidClipping,
  playlistName,
  userName,
  playlistId,
  same_version,
  modified,
  download_link,
  progressMessage
}) => {
  const [vidRes, setVidRes] = useState(videoResolutions[0].value);
  const toast = useToast();
  const modifiedString = dayjs().to(dayjs.utc(modified).local());
  const navigate = useNavigate();

  return (
    <Flex bg="white.100" borderRadius="md" direction="column" p="4" gap="3" ml="2">
      <Flex justify="space-between" align="center">
        <Flex gap="1" align="center">
          <ItemDetail icon={<PlayOutline20 color="black.60" />}>{vidList.length}</ItemDetail>
          <ItemDetail icon={<HistoryOutline20 color="black.60" />}>{modifiedString}</ItemDetail>
          {currentEnv !== 'prod' && (
            <Button
              ml={2}
              variant="secondary"
              onClick={() =>
                downloadVideoPlaylistData(
                  vidList.map((el) => {
                    return {
                      ball_id: el.ball_id,
                      aws_key: el.cv_aws_key,
                      aws_bucket: el.cv_aws_bucket,
                      aws_etag: el.cv_aws_etag,
                      video_start: el.video_start,
                      video_end: el.video_end,
                      sound: el.video_sound,
                      speed: el.video_speed,
                      video_url: el.video_url
                    };
                  }),
                  { playlistName, user: userName, video_resolution: vidRes }
                )
              }
              mb={1}
            >
              Download JSON
            </Button>
          )}
        </Flex>
        <Flex color="black.76" fontWeight="600" align="center" gap="1">
          {vidClipping ? (
            <>
              {progressMessage.includes('Playlist maker failed') ? (
                <CloseOutline24 />
              ) : (
                <InfoOutline24 />
              )}
              <Box fontSize="sm">
                {progressMessage === '' ? 'Playlist build commencing' : progressMessage}
              </Box>
            </>
          ) : !vidClipping && same_version ? (
            <>
              <CircleCheckmarkFilled24 color="positive.main" />
              <Box fontSize="sm">Build complete</Box>
            </>
          ) : null}
        </Flex>
      </Flex>
      <Flex gap="4">
        <Box flex="1">
          <Select
            onChange={(e) => {
              setVidRes(e.target.value);
            }}
            h="44px"
          >
            {videoResolutions.map((item) => {
              return (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </Select>
        </Box>
        <Box flex="1">
          <Button
            h="44px"
            w="100%"
            isDisabled={vidClipping}
            variant={same_version && download_link && !vidClipping ? 'primary' : 'secondary'}
            fontWeight="700"
            display="flex"
            gap="1"
            onClick={() => {
              if (same_version && download_link) {
                navigate({ to: `/video_playlist_viewer/${encodeURIComponent(download_link)}` });
              } else {
                startVideoConcatenation(
                  {
                    playlist: playlistName,
                    username: userName,
                    resolution: vidRes,
                    videos: vidList.map((el) => {
                      return {
                        ball_id: el.ball_id,
                        aws_key: el.cv_aws_key,
                        aws_bucket: el.cv_aws_bucket,
                        aws_etag: el.cv_aws_etag,
                        video_start: el.video_start,
                        video_end: el.video_end,
                        sound: el?.sound,
                        speed: el?.speed,
                        video_url: el?.video_url
                      };
                    })
                  },
                  playlistId
                );
                toast({
                  title: `Building playlist`,
                  position: 'bottom-left',
                  description: `Playlist creation has begun`,
                  status: 'success',
                  duration: 5000,
                  isClosable: true
                });
                setVidClipping(true);
              }
            }}
          >
            {vidClipping ? (
              <Spinner boxSize="5" />
            ) : same_version && download_link ? (
              <>
                <Box as="span">View video </Box>
                <ArrowRightOutline24 />
              </>
            ) : (
              <>
                <PlayOutline24 />
                <Box as="span">Build Video</Box>
              </>
            )}
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default PlaylistConcatenationComponent;
