import { useState, useCallback } from 'react';
import { Box} from '@chakra-ui/react';

import useVideoModal from 'modals/hooks/useVideoModal';
import Header from '../../_components/header/Header';
import HeaderInfo from '../../_components/header/HeaderInfo';
import Info from './Info';
import ColorKey from './ColorKey';

import NoData from './NoData';
import Selectors from './Selectors';
import BattersRow from './BattersRow';
import BowlerRow from './BowlerRow';

const CaptaincyGridContent = ({ content }) => {
  const [infoOpen, setInfoOpen] = useState(false);
  const { showVideoModal } = useVideoModal();

  const [activeDetailsItem, setActiveDetailsItem] = useState(null);
  const [appliedDetailsItem, setAppliedDetailsItem] = useState(null);
  
  const { key, header, data } = content;
  const { players, simple, standard, teams } = data;

  const teamA = teams?.[0];
  const teamIdA = teamA?.team_id;

  const [tabValue, setTabValue] = useState('simple');
  const [teamValue, setTeamValue] = useState(teamIdA);

  const dataToUse = tabValue === 'simple' ? simple : standard;

  const teamIndexFor = useCallback(
    (teamId) => dataToUse?.findIndex((team) => team.team_id === teamId)
  , [dataToUse]);
  const battingTeamData = teamValue >= 0 ? dataToUse?.[teamIndexFor(teamValue)] : null;

  const batters = battingTeamData?.grid?.[0]?.batting;
  const battersLength = batters?.length || 0;
  const bowlersData = battingTeamData?.grid;
  const bowlersLength = bowlersData?.length || 0;

  const getVideoUrl = useCallback((item) => {
    if (item !== null) {
      const {bowlerIdx, batterIdx} = item;
      const videoUrl = battingTeamData?.grid[bowlerIdx]?.batting?.[batterIdx]?.video_link;
      if (videoUrl) {
        return videoUrl;
      }
    }
    return null;
  }, [battingTeamData?.grid]);

  const launchVideo = useCallback(() => {
    const videoUrl = getVideoUrl(appliedDetailsItem);
    if (videoUrl) {
      showVideoModal(videoUrl);
    }
  }, [getVideoUrl, appliedDetailsItem, showVideoModal]);

  if (!battersLength || !bowlersLength) {
    return <NoData />;
  };

  return (
    <Box
      fontSize="14px"
      fontWeight="400"
      color="black.100"
      userSelect="none"
      sx={{
        'container-type': 'inline-size'
      }}
    >
      <Header
        title={header}
        showInfoButton={true}
        showVideoButtons={appliedDetailsItem !== null}
        infoOpen={infoOpen}
        setInfoOpen={setInfoOpen}
        onVideoPlay={() => launchVideo()}
        onVideoCancel={() => {
          setAppliedDetailsItem(null);
          setActiveDetailsItem(null);
        }}
      />
      <Box position="relative">
        <Selectors 
          teams={teams}
          tabValue={tabValue}
          setTabValue={setTabValue}
          teamValue={teamValue}
          setTeamValue={setTeamValue}
          setAppliedDetailsItem={setAppliedDetailsItem}
          setActiveDetailsItem={setActiveDetailsItem}
        />
        <Box
          overflowX="auto"
          sx={{
            scrollbarWidth: 'none',                       // Hide scrollbar (Firefox)
            '&::-webkit-scrollbar': { display: 'none' },  // Hide scrollbar (WebKit browsers : Chrome, Safari, Edge ...)
            '&-ms-overflow-style':  { display: 'none' }   // Hide scrollbar (IE)
          }}
        >
          <BattersRow
            battingTeamData={battingTeamData}
            players={players}
          />
          {bowlersData?.map((bowlerData, bowlerIdx) => (
            <BowlerRow
              bowlerData={bowlerData}
              bowlerIdx={bowlerIdx}
              battersLength={battersLength}
              tabValue={tabValue}
              players={players}
              appliedDetailsItem={appliedDetailsItem}
              setAppliedDetailsItem={setAppliedDetailsItem}
              activeDetailsItem={activeDetailsItem}
              setActiveDetailsItem={setActiveDetailsItem}
              getVideoUrl={getVideoUrl}
            />
          ))}
        </Box>
        <ColorKey />
        <HeaderInfo infoOpen={infoOpen}>
          <Info content={content} key={`${key}_info`} />
        </HeaderInfo>
      </Box>
    </Box>
  );
};

export default CaptaincyGridContent;
