import { Navigate } from '@tanstack/react-location';
import FetchComponent from 'pages/_shared/components/FetchComponent';
import BetaSwitch from './BetaSwitch';
import PageContainer from 'pages/_shared/components/PageContainer';

export const compRoutes = {
  path: 'comp',
  children: [
    { path: '/', element: <Navigate to="/home" /> },
    {
      path: ':objectId',
      element: (
        <BetaSwitch beta={process.env.REACT_APP_ENV !== 'prod' && <PageContainer type="comp" key="comp" />}>
          <FetchComponent type="comp" />
        </BetaSwitch>
      )
    }
  ]
};
