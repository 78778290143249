import { Box, Flex } from '@chakra-ui/react';

const OversStats = ({ data }) => {
  const { runs, wickets, rpo, label } = data;
  if (runs === null || wickets === null || rpo === null) {
    return null;
  }
  return (
    <Flex justify="space-between">
      <Box fontSize="14px" color="black.60">
        {label}
      </Box>
      <Box fontSize="14px" fontWeight="bold" color="black.76">
        {runs}/{wickets} @ {rpo}rpo
      </Box>
    </Flex>
  );
};

export default OversStats;
