export const getInfo = (pageData, pageType) => {
  if (pageType === 'player') {
    const classList = pageData.player_class_ids;
    return {
      panel_category: pageData.player_type_id,
      classList,
      initialClass: Object.keys(classList)[0],
      objectId: pageData.player_information?.id
    };
  }
  if (pageType === 'match') {
    const {
      match_information: { panel_category, international_class_id, general_class_id, id }
    } = pageData;
    return {
      panel_category,
      classList: [],
      initialClass:
        international_class_id && international_class_id !== 0
          ? international_class_id
          : general_class_id,
      objectId: id
    };
  }
  if (pageType === 'comp') {
    const classList = pageData.class_id_list;
    return {
      panel_category: pageData.comp_type_id,
      classList,
      initialClass: Object.keys(classList)[0],
      objectId: pageData.comp_info?.id
    };
  }
  if (pageType === 'team') {
    const classList = pageData.class_id_list;
    return {
      panel_category: pageData.team_type_id,
      classList,
      initialClass: Object.keys(classList)[0],
      objectId: pageData.team_info?.team_id
    };
  }
  if (pageType === 'ground') {
    const classList = pageData.class_id_list;
    return {
      panel_category: '17',
      classList,
      initialClass: Object.keys(classList)[0],
      objectId: pageData.ground_info?.ground_id
    };
  }
  return {};
};
