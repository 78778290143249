import { Flex, Radio, RadioGroup } from '@chakra-ui/react';
import OptionStyle from './OptionStyle';

const FilterRadio = ({ options, handleFilterChange, name, filterValues }) => {
  const value = filterValues[name] || 'default';

  const handleClick = (value) => {
    handleFilterChange(name, value && value !== 'default' ? value : null);
  };

  return (
    <RadioGroup value={value} onChange={handleClick}>
      <Flex direction="column" gap="2">
        {options.map((option) => {
          return (
            <OptionStyle key={option.value}>
              <Radio value={option.value} fontSize="sm" w="100%" p="2.5">
                {option.label}
              </Radio>
            </OptionStyle>
          );
        })}
      </Flex>
    </RadioGroup>
  );
};

export default FilterRadio;
