import { Flex, Slider, SliderFilledTrack, SliderTrack, Box, IconButton } from '@chakra-ui/react';
import { useState } from 'react';
import { MdOutlineVolumeUp, MdOutlineZoomOutMap, MdOutlineVolumeMute } from 'react-icons/md';
const convertTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes.toString();
  const secondsString =
    remainingSeconds < 10
      ? `0${parseInt(remainingSeconds)}`
      : parseInt(remainingSeconds).toString();
  return `${minutesString}:${secondsString}`;
};

const VideoControlBar = ({ videoRef }) => {
  const { duration, currentTime } = videoRef?.current?.video?.props?.video;
  const [muted, setMuted] = useState(false);
  const changePlaybackPosition = (e) => {
    videoRef.current.video.props.video.currentTime = e;
  };
  const changeSound = () => {
    videoRef.current.muted = !muted;
    setMuted((e) => !e);
  };
  const makeFullScreen = () => {
    videoRef.current.actions.toggleFullscreen();
  };
  return (
    <Box w="100%" zIndex={700} mt={-7} bg={'#000000B2'} pt={-5}>
      <Flex w="100%" mt={1}>
        <Flex w="5%">
          <IconButton
            as={!muted ? MdOutlineVolumeUp : MdOutlineVolumeMute}
            variant="ghostDark"
            color="white"
            size="xs"
            ml={'auto'}
            mr={'auto'}
            onClick={() => changeSound()}
          />
        </Flex>
        <Box w="90%" ml="2" mr="2" pt={1.5} pb={2} pl={4} pr={4} bg={'#000000B2'} borderRadius={10}>
          <Slider
            value={currentTime}
            min={0}
            max={duration}
            step={0.1}
            onChange={(val) => {
              changePlaybackPosition(val);
            }}
            borderRadius={'10px'}
          >
            <SliderTrack bg={'#AFB0B8'}>
              <SliderFilledTrack bg="#FFFFFF" />
            </SliderTrack>
          </Slider>
        </Box>
        <Box w="5%">
          <IconButton
            onClick={() => makeFullScreen()}
            as={MdOutlineZoomOutMap}
            variant="ghostDark"
            color="white"
            size="xs"
            ml={2}
          />
        </Box>
      </Flex>
      <Flex
        fontSize="12px"
        fontWeight="bold"
        color={'white'}
        p={1}
        w="100%"
        mt={1}
        fontFamily="Open Sans"
      >
        <Box ml={'5%'} mr={'auto'}>
          {convertTime(currentTime)}
        </Box>
        <Box ml={'auto'} mr={'5%'}>
          {convertTime(duration - currentTime)}
        </Box>
      </Flex>
    </Box>
  );
};
export default VideoControlBar;
