import { useState } from 'react';
import { Box, Select, Flex } from '@chakra-ui/react';
import ButtonSelector from '_shared/components/ButtonSelector';
import SquadDisplay from '_shared/components/SquadDisplay/SquadDisplay';
import TeamHeader from 'content/_components/TeamHeader';
import Header from 'content/_components/header/Header';

const SquadContent = ({ content }) => {
  const { data = [], filters = {}, header } = content;
  const { primary, secondary } = filters;
  const [filterRole, setFilterRole] = useState('all');
  const [teamId, setTeamId] = useState(
    secondary && secondary.length > 0 ? secondary?.[0]?.value : null
  );

  const filteredData = data
    .filter((el) => {
      if (secondary && el.secondary !== teamId) return false;
      if (primary && !el.primary.includes(filterRole)) return false;
      return true;
    })
    .map((el) => el.data);

  return (
    <Box>
      <Header title={getHeaderComponent(header)} />
      <Flex gap={1} px="4">
        <Box flex="2">
          <ButtonSelector
            handleChange={setFilterRole}
            value={filterRole}
            options={primary}
            buttonMinWidth="80px"
            fullWidth
          />
        </Box>
        {secondary && (
          <Box flex="1">
            <Select onChange={(e) => setTeamId(parseInt(e.target.value))} value={teamId} ml="auto">
              {secondary.map((el, idx) => {
                return (
                  <option value={el.value} key={`${el.value}-${idx}`}>
                    {el.label}
                  </option>
                );
              })}
            </Select>
          </Box>
        )}
      </Flex>
      <Box p="4">
        <SquadDisplay squadData={filteredData} />
      </Box>
    </Box>
  );
};
export default SquadContent;

function getHeaderComponent(header) {
  if (typeof header === 'string') return header;
  const { logo, name, id } = header;
  return <TeamHeader img={logo} teamName={name} id={id} />;
}
