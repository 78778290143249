import ErrorBoundary from './ErrorBoundary';
import FullScreenError from './FullScreenError';

export default function AppErrorBoundary({ children }) {
  return (
    <ErrorBoundary
      renderError={() => {
        return <FullScreenError />;
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
