import { useEffect, useRef } from 'react';
import autoAnimate from '@formkit/auto-animate';
import { Flex, GridItem } from '@chakra-ui/react';
import TeamImage from '_shared/components/TeamImage';
import RouterLink from '_shared/components/Utils/RouterLink';
import Score from './Score';
import BattingIndicator from '_icons/icons/BattingIndicator';

const HeaderTeamScore = ({ xScoreAvailable, team, type, matchStatus, size = 'small' }) => {
  const parent = useRef(null);
  const reversed = type === 'home' && size !== 'small';
  const { innings = [], id, name } = team || {};

  const battingTeam = innings.some((el) => {
    return el.live_status === 'current' && matchStatus === 'live';
  });

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  return (
    <>
      <GridItem area={`image_${type}`} alignSelf="center">
        <TeamImage team={team} type="circle" size={size === 'small' ? 36 : 52} padding={12} />
      </GridItem>
      <GridItem
        area={`name_${type}`}
        textAlign={reversed ? 'right' : 'left'}
        fontSize={size === 'small' ? 'md' : 'lg'}
        fontWeight="400"
        lineHeight="1.2"
        justifySelf={reversed ? 'end' : 'start'}
        display="flex"
        alignItems="center"
        gap="1"
      >
        {battingTeam && <BattingIndicator color="positive.tint.10" boxSize="2" />}
        <RouterLink to={`/team/${id}`}>{name}</RouterLink>
      </GridItem>

      <GridItem area={`score_${type}`} alignSelf="end">
        <Flex gap="3" justifyContent={reversed ? 'end' : 'start'} flexWrap="wrap">
          {innings.map((inningsData, idx) => {
            return (
              <Score
                key={`score_item_${idx}`}
                xScoreAvailable={xScoreAvailable}
                inningsData={inningsData}
                type={type}
                size={size}
              />
            );
          })}
        </Flex>
      </GridItem>
    </>
  );
};

export default HeaderTeamScore;
