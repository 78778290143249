const parseClassOptions = (class_ids) => {
  if (!class_ids) return [];
  return Object.entries(class_ids).map(([classId, label]) => {
    if (typeof label === 'string') {
      return {
        value: classId.toString(),
        label
      };
    }
    return {
      value: classId.toString(),
      label: label.medium_name
    };
  });
};

export default parseClassOptions;
