import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { Field } from 'formik';
import ChakraReactDatepicker from 'pages/_shared/components/InputComponents/ChakraReactDatepicker';

const FormChakraDatePicker = ({ name, label, placeholder }) => {
  return (
    <Field name={name}>
      {({ field, form: { setFieldValue, errors, touched } }) => {
        return (
          <FormControl isInvalid={errors[name] && touched[name]}>
            {label ? <FormLabel>{label}</FormLabel> : null}
            <ChakraReactDatepicker
              selectedDate={field.value || null}
              onChange={(v) => {
                setFieldValue(name, v);
              }}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              popperPlacement="bottom-end"
              placeholder={placeholder || 'all'}
            />
            <FormErrorMessage>{errors[name]}</FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default FormChakraDatePicker;
