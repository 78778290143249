import { Box, Flex, Button } from '@chakra-ui/react';
import FilterGroup from './FilterGroup';
import checkFilterGroupActive from './utils/checkFilterGroupActive';
import Visualisations from './Visualisations';
import ContentLoading from '../ContentLoading';

const AdvancedFilters = ({
  filterValues,
  handleApplyFilters,
  enabledFilters,
  fetchFilterDefs,
  handleFilterChange,
  handleClearFilters,
  filterEmpty,
  filterDirty,
  headersTakenHeight
}) => {
  const { isLoading: filterDefsLoading, error: filterDefsError } = fetchFilterDefs;
  return (
    <>
      <Flex
        direction="column"
        justifyContent="space-between"
        bg="white.100"
        p="4"
        pb="0"
        w="300px"
        top={`${headersTakenHeight}px`}
        bottom="0"
        position="fixed"
        userSelect="none"
        overflowY="auto"
      >
        {filterDefsLoading && <ContentLoading />}
        <Flex direction="column" gap="2" w="100%">
          {filterDefsError && <Box flex="1">{filterDefsError.message}</Box>}
          {enabledFilters &&
            enabledFilters
              .filter((el) => {
                return el.name !== 'visualisations';
              })
              .map((group) => {
                const { elements, label } = group;
                const isActive = checkFilterGroupActive(group?.elements, filterValues);
                return (
                  <FilterGroup
                    isActive={isActive}
                    elements={elements}
                    label={label}
                    handleFilterChange={handleFilterChange}
                    key={group.name}
                    filterValues={filterValues}
                  />
                );
              })}
          {enabledFilters.length !== 0 && <Visualisations />}
        </Flex>
        <Flex
          paddingTop="20px"
          paddingBottom="20px"
          bg="white.100"
          gap={3}
          position="sticky"
          bottom="0"
          left="0"
          zIndex="1"
        >
          <Button
            onClick={handleClearFilters}
            variant="tertiary"
            flex="1"
            h="44px"
            isDisabled={filterEmpty}
          >
            Clear
          </Button>
          <Button
            onClick={handleApplyFilters}
            variant={filterDirty ? 'primary' : 'tertiary'}
            flex="1"
            h="44px"
            isDisabled={!filterDirty}
          >
            Apply
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default AdvancedFilters;
