import { useCallback } from 'react';
import { Box, Grid, GridItem, Flex} from '@chakra-ui/react';
import { CircleCheckmarkFilled24, CircleXFilled24 } from '_icons/filled/24';
import { ErrorOutline24 } from '_icons/outline/24';
import colors from './Colors';

const colorForMatchupValue = (value) => {
  const percValue = parseInt(value) || 0;
  const maxIndex = Math.max(colors.length - 1, 0);
  const colorValuesIndex = Math.floor((percValue * maxIndex) / 100);
  return colors[colorValuesIndex];
};

const hasPositiveIcon = (value) => {
  const percValue = parseInt(value) || 0;
  return percValue > 67;
};

const hasNegativeIcon = (value) => {
  const percValue = parseInt(value) || 0;
  return percValue < 34;
};

const BowlerRow = ({bowlerData, bowlerIdx, battersLength, tabValue, players, appliedDetailsItem, setAppliedDetailsItem, activeDetailsItem, setActiveDetailsItem, getVideoUrl}) => {

  const playerFor = (playerId) => players?.find((player) => player.player_id === playerId);

  const isActive = useCallback((bowlerIdx, batterIdx) => {
    return activeDetailsItem?.bowlerIdx === bowlerIdx && activeDetailsItem?.batterIdx === batterIdx;
  }, [activeDetailsItem?.bowlerIdx, activeDetailsItem?.batterIdx]);

  const isApplied = useCallback((bowlerIdx, batterIdx) => {
    return appliedDetailsItem?.bowlerIdx === bowlerIdx && appliedDetailsItem?.batterIdx === batterIdx;
  }, [appliedDetailsItem?.bowlerIdx, appliedDetailsItem?.batterIdx]);

  const onClickItem = useCallback((bowlerIdx, batterIdx) => {
    if (getVideoUrl(activeDetailsItem)) {
      if (isApplied(bowlerIdx, batterIdx)) {
        setAppliedDetailsItem(null);
        setActiveDetailsItem({bowlerIdx, batterIdx});
      } else if (isActive(bowlerIdx, batterIdx)) {
        setAppliedDetailsItem({bowlerIdx, batterIdx});
      }
    }
  }, [activeDetailsItem, getVideoUrl, isActive, isApplied, setAppliedDetailsItem, setActiveDetailsItem]);

  const itemLabels = ['M', 'Avg', 'SR', 'W'];

  return (
    <Grid
      key={`captaincy_line_bowler_${bowlerIdx}`}
      flexGrow="1"
      gridTemplateColumns={`220px repeat(${battersLength}, 1fr)`}
      sx={{
        '&:not(:last-child)': { marginBottom: '4px' }
      }}
    >
      <GridItem
        backgroundColor="white"
        position="sticky"
        left="0"
        zIndex="1"
        sx={{
          '&:not(:last-child)': { paddingRight: '4px' }
        }}
      >
        <Box
          bg="black.4"
          h="44px"
          minW="148px"
          display="flex"
          alignItems="center"
          paddingLeft="4"
          paddingRight="4"
        >
          { tabValue === 'simple' ? bowlerData.bowling_technique : playerFor(bowlerData.bowler_id).name }
        </Box>
      </GridItem>
      {bowlerData?.batting?.map((value, idx) => {
        const itemValue = [value?.matches ?? null, value?.average ?? null, value?.strikerate ?? null, value?.wickets ?? null];
        return <GridItem
          key={`captaincy_value_${idx}`}
          sx={{
            '&:not(:last-child)': { paddingRight: '4px' }
          }}
        >

          { value?.error
          ? (
            <Box
              bg="#F8F8F8"
              h="44px"
              minW="148px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="relative"
              cursor="pointer"
              onMouseEnter={(e) => {
                const item = e?.target;
                if (item) {
                  item.scrollIntoView({ behavior: 'smooth', block: "nearest", inline: "nearest" });
                }
                if (appliedDetailsItem === null) {
                  setActiveDetailsItem({bowlerIdx, batterIdx: idx});
                }
              }}
              onMouseLeave={() => {
                if (appliedDetailsItem === null) {
                  setActiveDetailsItem(null)
                }
              }}
            >
              <ErrorOutline24 color="#AFB0B8" />
              <Box
                position="absolute"
                bottom="0"
                left="0"
                w="100%"
                bg="black.4"
                color="text.primary"
                fontSize="12px"
                lineHeight="1.5"
                fontWeight="600"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height={isActive(bowlerIdx, idx) ? '100%' : '0'}
                transitionProperty="height"
                transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
                transitionDuration="500ms"
                overflow="hidden"
                textAlign="center"
              >
                <Box>Insufficent data for recommendation</Box>
              </Box>
            </Box>
            )
          : (
            <Box
              bg={colorForMatchupValue(value?.matchup_colour)}
              h="44px"
              minW="148px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="relative"
              cursor="pointer"
              onMouseEnter={(e) => {
                const item = e?.target;
                if (item) {
                  item.scrollIntoView({ behavior: 'smooth', block: "nearest", inline: "nearest" });
                }
                if (appliedDetailsItem === null) {
                  setActiveDetailsItem({bowlerIdx, batterIdx: idx});
                }
              }}
              onMouseLeave={() => {
                if (appliedDetailsItem === null) {
                  setActiveDetailsItem(null)
                }
              }}
              onClick={() => onClickItem(bowlerIdx, idx)}
            >
              {hasPositiveIcon(value?.matchup_colour) && 
                <Box w="24px" h="24px" display="flex" justifyContent="center" alignItems="center" bg="#FFFFFF33" borderRadius="50%">
                  <CircleCheckmarkFilled24 color="positive.main" />
                </Box>
              }
              {hasNegativeIcon(value?.matchup_colour) &&
                <Box w="24px" h="24px" display="flex" justifyContent="center" alignItems="center" bg="#FFFFFF33" borderRadius="50%">
                  <CircleXFilled24 color="negative.main" />
                </Box>
              }
              <Box
                position="absolute"
                bottom="0"
                left="0"
                w="100%"
                px="15%"
                bg={isApplied(bowlerIdx, idx) ? "primary.tint.90" : "black.4"}
                color={isApplied(bowlerIdx, idx) ? "primary.main" : "black.100"}
                fontSize="12px"
                lineHeight="14px"
                fontWeight="400"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height={isActive(bowlerIdx, idx) ? '100%' : '0'}
                transitionProperty="height"
                transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
                transitionDuration="500ms"
                overflow="hidden"
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {itemValue.map((itemValue, ii) => 
                    <Box key={`matchup_item_${ii}`}>
                      <Box fontWeight="700">{itemLabels[ii]}</Box>
                      <Box>{itemValue ?? '-'}</Box>
                    </Box>
                  )}
                </Flex>
              </Box>
            </Box>
            )
          }
        </GridItem>
      })}
    </Grid>
  );
};

export default BowlerRow;