import { getCurrentEnvironment } from '_shared/utils/getCurrentEnvironment';
import { logError } from '_shared/dataFetching/apiService';

const currentEnv = getCurrentEnvironment();

export const log = async (inputAttributes) => {
  if (currentEnv === 'dev') {
    console.dir(`DEVLOG:  ${inputAttributes?.message}`);
    return;
  }
  const attributes = {
    path: window.location.href,
    userAgent: navigator.userAgent,
    level: inputAttributes?.level || 'ERROR',
    message: inputAttributes?.message || 'Could not send error log message, attributes object empty'
  };
  await logError(attributes);
};

export const logBasicMessage = (level, message) => {
  log({
    level,
    message
  });
};
