import FormMultiSelectSortable from 'Forms/_shared/FormElements/FormMultiSelectSortable';
import FormSelect from 'Forms/_shared/FormElements/FormSelect';
import ColumnText from './ColumnText';

const Columns = ({ panel_type_id, columnData }) => {
  if (panel_type_id === '2') {
    return (
      <FormMultiSelectSortable
        name="columnSelection"
        label="Table Columns"
        options={columnData}
        placeholder="Select column..."
      />
    );
  }
  if (panel_type_id === '1') {
    return <ColumnText columnData={columnData} />;
  }
  return (
    <>
      <FormSelect
        name="x_column"
        label="X Column"
        options={columnData}
        placeholder="Select X column"
        isHorizontal
      />
      <FormMultiSelectSortable
        name="columnSelection"
        label="Y Columns"
        options={columnData}
        placeholder="Select column..."
      />
    </>
  );
};

export default Columns;
