import { Icon, IconButton, Tooltip } from '@chakra-ui/react';
import Refresh from '_icons/icons/Refresh';
import './RefreshIcon.css';

const RefreshIcon = ({ handleClick, isLoading }) => {
  return (
    <Tooltip label="Refresh panel" placement="top">
      <IconButton
        onClick={handleClick}
        isRound
        boxSize="6"
        minW="0"
        aria-label="refresh"
        variant="outline"
        icon={<Icon as={Refresh} boxSize={5} className={isLoading ? 'isRefetching' : undefined} />}
      />
    </Tooltip>
  );
};

export default RefreshIcon;
