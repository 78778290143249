import { Box } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { HEADER_EXPANDED, SCROLL_DIRECTION } from '_shared/globalState/atoms';

const StickyScrollWrapper = ({ headerSize = 0, expandedHeaderSize, children, pageType }) => {
  const headerExpanded = useRecoilValue(HEADER_EXPANDED);
  const scrollDirection = useRecoilValue(SCROLL_DIRECTION);
  const expanded = headerExpanded[pageType] || false;

  const offsetHeight = expanded ? expandedHeaderSize : headerSize;

  return (
    <Box
      position="sticky"
      zIndex={6}
      top={scrollDirection === 'down' ? `-${offsetHeight}px` : '0'}
      bg="background"
      transitionProperty="top"
      transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
      transitionDuration="500ms"
    >
      {children}
    </Box>
  );
};

export default StickyScrollWrapper;
