import axios from 'axios';

import { fetchClient } from './clientSetup';
import { getQTURL, createTextLine } from './utils';

// create to switch out testing certain endpoints prior to pushing cvapi to staging

// const localhostcvapi = 'http://127.0.0.1:5002/qtp/v1';

const searchUrl = process.env.REACT_APP_SEARCH_API_URL;
const qtpApi = process.env.REACT_APP_QTP_API_URL || '';
const centurionApi = process.env.REACT_APP_CENTURION_API_URL || '';

export const getObjectInformation = async (type, objectId = '') => {
  const url = `${qtpApi}/cricket/${type}/${objectId}`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const getPanelsDetails = async (type, objectId, classId) => {
  const getParams = () => {
    if (classId) {
      return {
        class_id: classId
      };
    }
  };
  const url = `${qtpApi}/cricket/${type}/${objectId}/panels`;
  const res = await fetchClient.get(url, { params: getParams() });
  const { data } = res;
  return data;
};

// export const getPanelInformation = async (payload) => {
//   const url = `${qtpApi}/individual_panel_data`;
//   const res = await fetchClient.post(url, payload);
//   const { data } = res;
//   return data;
// };
export const getPanelData = async (params, api_reference, panelData) => {
  if (['MS_1000', 'MS_1002', 'MS_1004'].includes(params.panel_id)) {
    const url = `${centurionApi}/centurion_panels/v2/${params.panel_id}`;
    delete params.panel_id;
    const res = await fetchClient.get(url, { params });
    const { data } = res;
    return data;
  } else if (api_reference && api_reference.includes('cdn-query.cricviz.com')) {
    const fetchURL = getQTURL(api_reference, params);
    const res = await fetchClient.get(fetchURL.replace('https://', '/'));
    const { data } = res;
    const { result, meta } = data;
    panelData.all_columns = meta
      ? Object.keys(meta).map((el) => {
          return { label: meta[el].heading, value: el };
        })
      : [];
    panelData.data = result || [];
    panelData.meta = meta || {};
    // code to limit NV play videos for everyone - currently means that no one can see NV play videos on centurion
    if (result) {
      if (params?.panel_type_id === 4 && Object.keys(meta).includes('video_url')) {
        panelData.data = result.filter((el) => !el.video_url.includes('/nv_'));
      } else if (params?.panel_type_id === 1) {
        panelData.data = createTextLine(result, panelData);
      }
    }

    return panelData;
  }
  const url = `${qtpApi}/get_panel_data`;
  const res = await fetchClient.get(url, { params });
  const { data } = res;
  return data;
};

export const getfaq = async () => {
  const url = `${qtpApi}/get_faq`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const postPanelVisibility = async (type, payload) => {
  const url = `${qtpApi}/alter_panel/${type}`;
  const res = await fetchClient.post(url, payload);
  return res;
};

export const deletePanel = async (payload) => {
  const url = `${qtpApi}/delete_panel`;
  const res = await fetchClient.post(url, payload);
  return res;
};

export const getUserInfo = async () => {
  const url = `${qtpApi}/user_details`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const getVideoFavourites = async () => {
  const url = `${qtpApi}/cricket/user_video_options`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const getQTColumns = async (formUrl) => {
  const url = `${qtpApi}/fetch_qt_columns`;
  const paramData = { form_url: `${formUrl}` };
  const res = await fetchClient.post(url, paramData);
  const { data } = res;
  return data;
};

export const addRemovePanelCategory = async (methodType, pageType, classId, categoryName) => {
  const url = `${qtpApi}/panel_category_methods/${methodType}`;
  const paramData = { category_name: `${categoryName}`, page_type: `${pageType}`, class: classId };
  const res = await fetchClient.post(url, paramData);
  return res;
};

export const getCategoryOptions = async (pageType = '', classId = '') => {
  let url = `${qtpApi}/get_categories/${pageType}`;
  if (classId !== '') {
    url += `/${classId}`;
  }
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const getClassOptions = async () => {
  const url = `${qtpApi}/get_classes`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const getAPIFormData = async (formUrl) => {
  const url = `${qtpApi}/fetch_form_api_data`;
  const paramData = { form_url: `${formUrl}` };
  const res = await fetchClient.post(url, paramData);
  const { data } = res;
  return data;
};

export const updateUserConfig = async (payload) => {
  const url = `${qtpApi}/set_user_project_config`;
  const res = await fetchClient.post(url, payload);
  return res;
};

export const insertQTPanel = async (payload) => {
  const url = `${qtpApi}/insert_panel`;
  const res = await fetchClient.post(url, payload);
  return res;
};

export const editPanel = async (payload) => {
  const url = `${qtpApi}/alter_panel/edit`;
  const res = await fetchClient.post(url, payload);
  return res;
};

export const editQTQuery = async (payload) => {
  const url = `${qtpApi}/alter_panel/editQuery`;
  const res = await fetchClient.post(url, payload);
  return res;
};

export const changeGrid = async (payload) => {
  const url = `${qtpApi}/alter_panel/changeGrid`;
  const res = await fetchClient.post(url, payload);
  return res;
};

export const playerSearch = async (searchString, type) => {
  const params = { q: `${searchString}` };
  const url = `${searchUrl}/suggest/${type}`;
  const res = await fetchClient.get(url, { params });
  const { data } = res;
  if (!data) {
    return 'Not found';
  }
  return data;
};

export const searchByClassSuggest = async (classId, searchString, type) => {
  const params = { q: `${searchString}` };
  if (type) {
    params.type = type;
  }
  const url = `${searchUrl}/suggest_class/${classId}`;
  const res = await fetchClient.get(url, { params });
  const { data } = res;
  return data;
};

export const getTeamsClass = async (classId) => {
  const url = `${qtpApi}/get_teams_class/${classId}`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const getQTOptions = async (apiUrl) => {
  const url = `${searchUrl}/qtp_option_form_data`;
  const paramData = { qt_internal_api: `${apiUrl}` };
  const res = await fetchClient.post(url, paramData);
  const { data } = res;
  return data;
};

export const postFavourites = async (favouritesList, favType, operationType) => {
  const url = `${qtpApi}/user_favourites_setting/${operationType}`;
  const paramData = { variable_type: `${favType}`, variable_id: favouritesList };
  const res = await fetchClient.post(url, paramData);
  return res;
};

export const getUserFavourites = async (type) => {
  const url = `${qtpApi}/cricket/user_favourites`;
  const config = {
    params: type ? { type } : {}
  };
  const res = await fetchClient.get(url, config);
  const { data } = res;
  return data;
};

export const setHomeTeam = async (team_id) => {
  const url = `${qtpApi}/set_homepage_team`;
  const paramData = { team_id };
  const res = await fetchClient.post(url, paramData);
  return res;
};

export const getHomeTeam = async () => {
  const url = `${qtpApi}/get_homepage_team`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const getPlayerInfo = async (player_id) => {
  const url = `${qtpApi}/get_player_info/${player_id}`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const postUserComments = async (payLoad, operationType) => {
  const url = `${qtpApi}/comment_operations/${operationType}`;
  const res = await fetchClient.post(url, payLoad);
  return res;
};

export const getUserComments = async (pageType, pageId) => {
  const url = `${qtpApi}/get_comments/${pageType}/${pageId}`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const duplicatePanel = async (payload) => {
  const url = `${qtpApi}/duplicate_panel`;
  const res = await fetchClient.post(url, payload);
  return res;
};

export const getUserFeedbackNotifications = async () => {
  const url = `${qtpApi}/get_user_feedback_settings`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const postUserFeedbackNotifications = async (payload) => {
  const url = `${qtpApi}/insert_user_feedback_settings`;
  const res = await fetchClient.post(url, payload);
  return res;
};

export const getVideoObject = async (objectType, classId, inputValue) => {
  const url = `${searchUrl}/video_search/${classId}/${objectType}`;
  const params = { q: `${inputValue}` };
  const res = await fetchClient.get(url, { params });
  const { data } = res;
  return data;
};

export const getVideoFilters = async (objectType, objectId, classId) => {
  const url = `${qtpApi}/video_search_filters/${objectType}/${objectId}`;
  const params = { class_id: classId };
  const res = await fetchClient.get(url, { params });
  const { data } = res;
  return data;
};

export const getVideoResults = async (objectType, objectId, classId, params) => {
  const urlParams = [];
  Object.keys(params).forEach((key) => {
    const paramItem = params[key];
    if (Array.isArray(paramItem)) {
      paramItem.forEach((val) => {
        urlParams.push(`${key}=${val}`);
      });
    } else {
      urlParams.push(`${key}=${paramItem}`);
    }
  });
  let queryUrl = `?class_id=${classId}`;
  if (urlParams.length > 0) {
    queryUrl += `&${urlParams.join('&')}`;
  }
  const url = `${qtpApi}/video_search_results/${objectType}/${objectId}${queryUrl}`;

  const res = await fetchClient.get(url);

  const { data } = res;

  return data;
};

export const getVideoResultsUsingLink = async (searchLink) => {
  const url = `${qtpApi}/video_search_results${searchLink}`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const getBBBVideoData = async (ballId) => {
  const url = `${qtpApi}/cricket/video_ball/${ballId}`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const getPanelNameVariables = async (pageType, pageId) => {
  const url = `${qtpApi}/get_variable_panel_names/${pageType}/${pageId}`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const getUserVideoPlaylists = async () => {
  const url = `${qtpApi}/get_video_playlists`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const getPlaylistData = async (playlistId) => {
  const url = `${qtpApi}/video_playlist_data/${playlistId}`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const newVideoPlaylist = async (payload) => {
  const url = `${qtpApi}/create_video_playlist`;
  const res = await fetchClient.post(url, payload);
  const { data } = res;
  return data;
};

export const updateVideoPlaylist = async (payload) => {
  const url = `${qtpApi}/update_video_playlist`;
  const res = await fetchClient.post(url, payload);
  const { data } = res;
  return data;
};

export const updatePlaylistName = async (payload) => {
  const url = `${qtpApi}/edit_playlist_name`;
  const res = await fetchClient.post(url, payload);
  const { data } = res;
  return data;
};

export const deleteVideoPlaylist = async (payload) => {
  const url = `${qtpApi}/delete_video_playlist`;
  const res = await fetchClient.post(url, payload);
  const { data } = res;
  return data;
};

export const logError = async (payload) => {
  const url = `${qtpApi}/slack_error`;
  const res = await fetchClient.post(url, payload);
  const { data } = res;
  return data;
};

export const startVideoConcatenation = (payload, playlist_id) => {
  const checkNulls = ['sound', 'speed', 'video_end', 'video_start'];
  const rawVideos = payload.videos;
  const videos = [];
  rawVideos.forEach((el) => {
    checkNulls.forEach((innerEl) => {
      if (el.hasOwnProperty(innerEl) && !el[innerEl] && el[innerEl] !== 0) {
        delete el[innerEl];
      }
    });
    videos.push(el);
  });
  payload.videos = videos;

  const url = `https://vcs.ellipsedata.com/compile_playlist/${playlist_id}`;
  axios.post(url, payload).then(function (response) {
    if (response.status === 202) {
      console.log('video concatenation started');
    } else {
      console.log('failed to start video concatenation');
    }
  });
};
