import { Box, Center, Flex } from '@chakra-ui/react';
import { getColor } from './utils';
import { getBBBVideoData } from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';

const BallDescription = ({ ballData }) => {
  const { overs_unique_id } = ballData;
  const { data } = useQueryFetch(['bbbVideoData', overs_unique_id], () =>
    getBBBVideoData(overs_unique_id)
  );
  return (
    <Flex mx="2" mb="1" fontSize="14px" gap="1" maxH="42px">
      <Box>{ballData.overs_unique}</Box>
      <Center
        w="5"
        h="5"
        fontSize="14px"
        borderRadius={3}
        bg={getColor(ballData, 'bg')}
        color={getColor(ballData, 'color')}
        p={1}
      >
        {ballData.scoring}
      </Center>
      <Box overflow="auto">{data?.commentary}</Box>
    </Flex>
  );
};

export default BallDescription;
