import { Flex, Box, Image, Icon, Select } from '@chakra-ui/react';
import ButtonSelector from '_shared/components/ButtonSelector';
import { useState } from 'react';
import { FaPalette } from 'react-icons/fa';
import { breakpoints } from '../PanelPageDraggable/definitions';
import useWindowDimensions from '_shared/hooks/useWindowDimensions';

const CWVStage = ({ dataPack, idVal, standardColour }) => {
  return (
    <Box>
      {dataPack.map((teamEl) => {
        if (teamEl?.probability === 0) {
          return null;
        }
        return (
          <Flex pb={1} pt={1} key={`${idVal}-${teamEl.team_id}`} gap={1} fontSize="sm">
            <Flex flexGrow={1} w={'10%'}>
              <Image src={teamEl.team_logo} h="40px" pl={1} />
              <Box fontWeight="bold" pt={3} pl={4}>
                {teamEl.team_abbreviation.toUpperCase()}
              </Box>
            </Flex>
            <Box flexGrow={6} pt={3}>
              <Box
                w={`${teamEl.probability}%`}
                bg={
                  !standardColour
                    ? `positive.main`
                    : teamEl.team_colour
                    ? `#${teamEl.team_colour}`
                    : 'positive.main'
                }
                h={'20px'}
                borderTopStartRadius={10}
                borderBottomStartRadius={10}
              ></Box>
            </Box>
            <Box direction="column" justifyContent="flex-end" pt={3} w="10%" fontWeight="bold">
              {teamEl.probability < 1 || teamEl.probability > 99
                ? `${teamEl.probability}%`
                : `${parseInt(Math.round(teamEl.probability, 0))}%`}
            </Box>
          </Flex>
        );
      })}
    </Box>
  );
};

const CompetitionWinViz = ({ panelData }) => {
  const { data } = panelData;
  const rawTourneyLevels = Object.keys(data);
  const [standardColour, setStandardColour] = useState(false);
  const tourneyLevels = {};
  const tourneyData = {};
  const { width } = useWindowDimensions();
  for (let i = 0; i < rawTourneyLevels.length; i += 1) {
    tourneyLevels[data[rawTourneyLevels[i]].market_id] = data[rawTourneyLevels[i]].stage_name;
    tourneyData[data[rawTourneyLevels[i]].market_id] = data[rawTourneyLevels[i]].data;
  }
  const [idVal, setIdVal] = useState(Object.keys(tourneyLevels)[0]);
  const tourneyLabels = [];
  Object.keys(tourneyLevels).forEach((el) => {
    tourneyLabels.push({ label: tourneyLevels[el], value: el });
  });

  return (
    <Box fontSize={'md'}>
      <Flex gap={1} ml={1} pt={1}>
        {width < breakpoints.xs && (
          <Select onChange={(e) => setIdVal(e.target.value)}>
            {tourneyLabels.map((el) => {
              return (
                <option value={el.value} key={`select-${el.value}`}>
                  {el.label}
                </option>
              );
            })}
          </Select>
        )}

        {width >= breakpoints.xs && (
          <ButtonSelector
            options={tourneyLabels}
            value={idVal}
            handleChange={setIdVal}
            ml={2}
            mr={2}
          />
        )}
        <Icon
          as={FaPalette}
          w={30}
          mt={3}
          color="primary.main"
          justifyContent={'flex-end'}
          justifyItems={'flex-end'}
          alignItems="flex-end"
          alignContent={'flex-end'}
          onClick={() => setStandardColour((prevCol) => !prevCol)}
          _hover={{ cursor: 'pointer' }}
        />
      </Flex>
      <CWVStage dataPack={tourneyData[idVal]} idVal={idVal} standardColour={standardColour} />
    </Box>
  );
};

export default CompetitionWinViz;
