export const setLocalStorageAuthTokens = (accessToken, refreshToken, userName) => {
  localStorage.setItem('qtpat', accessToken);
  localStorage.setItem('qtprt', refreshToken);
  if (userName) {
    localStorage.setItem('userName', userName);
  }
};

export const getLocalStorageAuthTokens = () => {
  const accessToken = localStorage.getItem('qtpat') || null;
  const refreshToken = localStorage.getItem('qtprt') || null;
  const userName = localStorage.getItem('userName');
  return {
    accessToken,
    refreshToken,
    userName
  };
};

export const removeLocalStorageAuthTokens = () => {
  localStorage.removeItem('qtpat');
  localStorage.removeItem('qtprt');
  localStorage.removeItem('userName');
};
