import { Flex, Box } from '@chakra-ui/react';
import { useRef } from 'react';
import useElementSize from '_shared/hooks/useElementSize';
import BattingFirst from './BattingFirst';
import InningsAverage from './InningsAverage';
import Header from 'content/_components/header/Header';

const PreMatchConditions = ({ content }) => {
  const ref = useRef();
  // Recommend switching to 3rd party useElementSize package 
  // ('@custom-react-hooks/use-element-size') instead of our
  // own implemention here (or even better replacing with CSS only)
  const { width } = useElementSize(ref);
  const { data, header } = content;
  const { batting_first, innings_split } = data;
  return (
    <Box ref={ref}>
      <Header title={header} showInfoButton={false} />
      <Flex flexDirection={width < 1246 ? 'column' : 'row'} gap="3" px="5" pt="3" pb="6">
        <BattingFirst
          batting_first={batting_first}
          panelWidth={width < 1246 ? width : 0.23 * width}
        />
        <InningsAverage
          innData={innings_split}
          panelWidth={width < 1246 ? 0.95 * width : 0.73 * width}
        />
      </Flex>
    </Box>
  );
};

export default PreMatchConditions;
