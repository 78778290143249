import { useState, useCallback } from 'react';
import { Box, Grid, GridItem, Flex, Text} from '@chakra-ui/react';
import { InfoOutline48 } from '_icons/outline/48';
import { CircleCheckmarkFilled24, CircleXFilled24 } from '_icons/filled/24';
import useVideoModal from 'modals/hooks/useVideoModal';
import Header from '../_components/header/Header';
import HeaderInfo from '../_components/header/HeaderInfo';

import './MatchupContent.css';

const colors = [
  "rgb(242, 124, 124)",  // weakest
  "rgb(246, 162, 162)",
  "rgb(250, 199, 199)",
  "rgb(251, 218, 218)",
  "rgb(255, 239, 211)",
  "rgb(255, 231, 188)",
  "rgb(255, 215, 144)",
  "rgb(217, 245, 228)",
  "rgb(198, 240, 215)",
  "rgb(160, 230, 188)",
  "rgb(121, 220, 161)"   // strongest  
];

const colorForMatchupValue = (value) => {
  const percValue = parseInt(value) || 0;
  const maxIndex = Math.max(colors.length - 1, 0);
  const colorValuesIndex = Math.floor((percValue * maxIndex) / 100);
  return colors[colorValuesIndex];
};

const hasPositiveIcon = (value) => {
  const percValue = parseInt(value) || 0;
  return percValue > 67;
};

const hasNegativeIcon = (value) => {
  const percValue = parseInt(value) || 0;
  return percValue < 34;
};

const MatchupContent = ({ content }) => {

  const [infoOpen, setInfoOpen] = useState(false);
  const { showVideoModal } = useVideoModal();
  const [activeDetailsItemIndex, setActiveDetailsItemIndex] = useState(null);
  const [appliedDetailsItemIndex, setAppliedDetailsItemIndex] = useState(null);

  const { key, header } = content;

  const headers = content?.data?.headers;

  const labels = content?.data?.headers?.map((header) => header.label);
  const values = headers?.map((header) => content?.data?.data?.[header.key]);

  const itemDetails = headers?.map((header) => content?.data?.data?.[header.key]?.details);
  const isBowling = (headers.length === 2) && (headers?.some((header) => header?.key === 'right'));
  const itemValues = itemDetails?.map((item) => ([item?.M ?? null, item?.Avg ?? null, (isBowling ? item?.ER : item?.SR) ?? null, item?.W ?? null]));
  const itemLabels = ['M', 'Avg', (isBowling ? 'ER' : 'SR'), 'W'];

  const getVideoUrl = useCallback((idx) => {
    if (idx !== null) {
      const videoUrl = itemDetails[idx]?.video_url;
      if (videoUrl) {
        return videoUrl;
      }
    }
    return null;
  }, [itemDetails]);

  const onClickItem = useCallback((idx) => {
    if (getVideoUrl(activeDetailsItemIndex)) {
      if (appliedDetailsItemIndex === idx) {
        setAppliedDetailsItemIndex(null);
        setActiveDetailsItemIndex(idx);
      } else if (activeDetailsItemIndex === idx) {
        setAppliedDetailsItemIndex(idx);
      }
    }
  }, [activeDetailsItemIndex, appliedDetailsItemIndex, getVideoUrl]);

  const launchVideo = useCallback(() => {
    const videoUrl = getVideoUrl(appliedDetailsItemIndex);
    if (videoUrl) {
      showVideoModal(videoUrl);
    }
  }, [getVideoUrl, appliedDetailsItemIndex, showVideoModal]);

  if (!Object.keys(content?.data?.data)?.length) {
    return (<>
      <Header
        title={header}
      />
      <Flex
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Box py={10}>
          <InfoOutline48 color="primary.tint.80" mb={1} />
          <Text>No data available for this player</Text>
        </Box>
      </Flex>
    </>);
  }

  return (
    <Box
      fontSize="14px"
      fontWeight="400"
      color="black.100"
      userSelect="none"
      className="matchup-outer-box"
    >
      <Header
        title={header}
        showInfoButton={!!Object.keys(content?.data?.data)?.length}
        showVideoButtons={appliedDetailsItemIndex !== null}
        infoOpen={infoOpen}
        setInfoOpen={setInfoOpen}
        onVideoPlay={() => launchVideo()}
        onVideoCancel={() => {
          setAppliedDetailsItemIndex(null);
          setActiveDetailsItemIndex(null);
        }}
      />
      <Box position="relative">
        <Grid
          flexGrow="1"
          gridTemplateColumns={`repeat(${labels.length}, 1fr)`}
          gap="1"
          overflowX="auto"
          sx={{
            scrollbarWidth: 'none',                       // Hide scrollbar (Firefox)
            '&::-webkit-scrollbar': { display: 'none' },  // Hide scrollbar (WebKit browsers : Chrome, Safari, Edge ...)
            '&-ms-overflow-style:': { display: 'none' }   // Hide scrollbar (IE)
          }}
        >
          {labels.map((label, idx) => 
            <GridItem
              key={`matchup_label_${idx}`}
              bg="black.4"
              h="44px"
              minW="148px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {label}
            </GridItem>
          )}
          {values.map((value, idx) => 
            <GridItem
              key={`matchup_value_${idx}`}
              bg={colorForMatchupValue(value?.matchup)}
              h="44px"
              minW="148px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="relative"
              cursor="pointer"
              onMouseEnter={(e) => {
                const item = e?.target;
                if (item) {
                  item.scrollIntoView({ behavior: 'smooth', block: "nearest", inline: "nearest" });
                }
                if (appliedDetailsItemIndex === null) {
                  setActiveDetailsItemIndex(idx);
                }
              }}
              onMouseLeave={() => {
                if (appliedDetailsItemIndex === null) {
                  setActiveDetailsItemIndex(null)
                }
              }}
              onClick={() => onClickItem(idx)}
            >
              {hasPositiveIcon(value?.matchup) && 
                <Box w="24px" h="24px" display="flex" justifyContent="center" alignItems="center" bg="#FFFFFF33" borderRadius="50%">
                  <CircleCheckmarkFilled24 color="positive.main" />
                </Box>
              }
              {hasNegativeIcon(value?.matchup) &&
                <Box w="24px" h="24px" display="flex" justifyContent="center" alignItems="center" bg="#FFFFFF33" borderRadius="50%">
                  <CircleXFilled24 color="negative.main" />
                </Box>
              }
              <Box
                position="absolute"
                bottom="0"
                left="0"
                w="100%"
                px="15%"
                bg={appliedDetailsItemIndex === idx ? "primary.tint.90" : "black.4"}
                color={appliedDetailsItemIndex === idx ? "primary.main" : "black.100"}
                fontSize="12px"
                lineHeight="14px"
                fontWeight="400"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height={(activeDetailsItemIndex === idx) ? '100%' : '0'}
                transitionProperty="height"
                transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
                transitionDuration="500ms"
                overflow="hidden"
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {itemValues?.[idx].map((itemValue, idx) => 
                    <Box key={`matchup_item_${idx}`}>
                      <Box fontWeight="700">{itemLabels[idx]}</Box>
                      <Box>{itemValue ?? '-'}</Box>
                    </Box>
                  )}
                </Flex>
              </Box>
            </GridItem>
          )}
        </Grid>
        <Box p={2}>
          <Box className="matchup-mid-box">
            <Flex
              flexGrow="1"
              justifyContent="center"
              alignItems="center"
              gap={2}
              minH="44px"
            >
              <Box>Strengths</Box>
              <Flex
                position="relative"
                top="1px"
                borderTopLeftRadius={8}
                borderBottomLeftRadius={8}
                borderTopRightRadius={8}
                borderBottomRightRadius={8}
                overflow="hidden"
              >
                {colors.map((_, idx, cols) => {
                  const colorBoxValue = cols[cols.length - 1 - idx];
                  return (
                  <Box
                    key={`colorbox_${idx}`}
                    className="matchup-color-box"
                    h="12px"
                    bg={colorBoxValue}
                  >
                  </Box>);
                })}
              </Flex>
              <Box>Weaknesses</Box>
            </Flex>
          </Box>
        </Box>
        <HeaderInfo infoOpen={infoOpen}>
          <Info content={content} key={`${key}_info`} />
        </HeaderInfo>
      </Box>
    </Box>);
};

const Info = ({ content }) => {
  const headers = content?.data?.headers;
  const isBowling = (headers.length === 2) && (headers?.some((header) => header?.key === 'right'));
  let message;
  if (isBowling) {
    message = "Use the grid to identify the strengths and weaknesses of a bowler against different batter hands to devise strategies.";
  } else {
    message = "Use the grid to identify the strengths and weaknesses of a batter against different bowling techniques to devise strategies.";
  }

  return (
    <Text>
      {message}
    </Text>
  );
};

export default MatchupContent;
