import { Formik } from 'formik';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { insertQTPanel } from '_shared/dataFetching/apiService';
import FormLayout from 'Forms/_shared/components/FormLayout';
import ExtApiPanelForm from 'Forms/PanelForms/_shared/FormLayouts/ExtApiPanelForm';
import { generateNewPanelPayload } from '../utils';
import { getInfo } from 'panels/utils/panelGetInfo';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';

const initialValues = {
  data_type: 'ext',
  ext_api: '',
  columnSelection: [],
  classId: 1001,
  x_column: '',
  data_value: '',
  panel_name: '',
  nested_dict: [],
  panel_type_id: '2',
  is_public: true,
  is_live: false,
  datasource_id: '0',
  dynamic_variable: null,
  columnData: [],
  raw_url: ''
};

const CreateExtApiPanel = ({ pageData, pageType, onSave, panelNameVariables }) => {
  const queryClient = useQueryClient();
  const { data: userInfo } = useUserDetails();
  const { is_staff } = userInfo || {};
  const [formSubmitError, setFormSubmitError] = useState(null);
  const { panel_category, objectId } = getInfo(pageData, pageType);
  // const initialCategoryTypeId = elCategoryOptions.length > 0 ? elCategoryOptions[0].value : 0;
  return (
    <Formik
      initialValues={{
        ...initialValues,
        page_type: pageType,
        panel_page: pageType,
        panel_category,
        variable_id: objectId,
        category_type_id: 1 // initialCategoryTypeId
      }}
      validate={(values) => {
        setFormSubmitError(null);
        const errors = {};
        if (!values.panel_name) {
          errors.panel_name = 'Please enter a panel name';
        }
        if (values.ext_api) {
          if (!values.ext_api.includes('.cricviz.com/')) {
            errors.ext_api = 'Not a valid CricViz api';
          }

          if (!values.ext_api.includes(objectId) && pageType !== 'match') {
            errors.ext_api = 'URL does not include object ID';
          }
        }
        if (!values.category_type_id) {
          errors.category_type_id = 'Please select a panel category';
        }
        if (!values.ext_api) {
          errors.ext_api = 'Please enter api url';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const payload = generateNewPanelPayload(values);
        const res = await insertQTPanel(payload);
        console.log({ payload, response: res });
        if (res.status === 200) {
          queryClient.refetchQueries([pageType, `${objectId}`, 'panels']);
          onSave(res.data.panel_id);
        } else {
          setFormSubmitError(res.data);
        }
        setSubmitting(false);
      }}
    >
      {(props) => (
        <FormLayout buttonText="Add Panel" {...props}>
          <ExtApiPanelForm
            {...props}
            is_staff={is_staff}
            formSubmitError={formSubmitError}
            objectId={objectId}
            dynamicPanelNames={panelNameVariables}
            pageType={pageType}
          />
        </FormLayout>
      )}
    </Formik>
  );
};

export default CreateExtApiPanel;
