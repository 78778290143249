import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { PANEL_SETTINGS_OPEN } from '_shared/globalState/atoms';
import CreatePanelForm from './CreatePanel/CreatePanel';

function fetchPanelNameVariables(panelData) {
  if (!panelData) return [];
  const panelWithNameVariables = panelData.find((el) => el.panel_name_variables);
  if (panelWithNameVariables) {
    return panelWithNameVariables.panel_name_variables;
  }
  return [];
}

const InsertPanelModal = ({ panelData, pageData, pageType }) => {
  const dynamicVarsList = fetchPanelNameVariables(panelData);
  const [panelSettingsOpen, setPanelSettingsOpen] = useRecoilState(PANEL_SETTINGS_OPEN);

  const handleClose = (callback) => {
    setPanelSettingsOpen(false);
    if (callback) {
      callback();
    }
  };
  return (
    <Modal isOpen={panelSettingsOpen} onClose={handleClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody minH="400px" px="3">
          <CreatePanelForm
            pageData={pageData}
            pageType={pageType}
            handleClose={handleClose}
            panelNameVariables={dynamicVarsList}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InsertPanelModal;
