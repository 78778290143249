import { useRecoilState } from 'recoil';
import { VIDEO_MODAL } from '_shared/globalState/atoms';

function useVideoModal() {
  const [videoModal, setVideoModal] = useRecoilState(VIDEO_MODAL);

  const showVideoModal = (params) => {
    let modalParams;
    if (typeof params === 'string') {
      modalParams = {
        content: params,
        direct: false
      };
    } else {
      modalParams = params;
    }
    setVideoModal(modalParams);
  };

  const hideVideoModal = () => {
    setVideoModal(false);
  };

  return { showVideoModal, hideVideoModal, videoModalShown: !!videoModal, videoModalContent: videoModal?.content, videoModalDirect: videoModal?.direct };
}

export default useVideoModal;
