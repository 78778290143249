import { Flex } from '@chakra-ui/react';
import { useRef } from 'react';
import useElementSize from '_shared/hooks/useElementSize';
import BattingFirst from './BattingFirst';
import InningsAverage from './InningsAverage';

const PreMatchConditions = ({ panelData }) => {
  const ref = useRef();
  // Recommend switching to 3rd party useElementSize package 
  // ('@custom-react-hooks/use-element-size') instead of our
  // own implemention here (or even better replacing with CSS only)
  const { width } = useElementSize(ref);
  const { data } = panelData;
  const { batting_first, innings_split } = data;
  return (
    <Flex ref={ref} flexDirection={width < 1000 ? 'column' : 'row'} gap="3" m="3">
      <BattingFirst batting_first={batting_first} panelWidth={width} />
      <InningsAverage innData={innings_split} />
    </Flex>
  );
};
export default PreMatchConditions;
