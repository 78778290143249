import { Box, Flex, Icon } from '@chakra-ui/react';
import { AiOutlineTrophy } from 'react-icons/ai';
import { MdOutlineDateRange } from 'react-icons/md';
import AddUserFavourites from '_shared/components/AddUserFavourites';
// import CommentThread from 'pages/_shared/components/CommentThread/CommentThread';
import capitalise from '_shared/utils/capitalise';

const CompSubHeader = ({ data }) => {
  const { comp_info } = data;
  return (
    <Flex px="5" py="4" gap="4" h="56px" bg="primary.shade.70">
      <Flex color="white" gap="4" flexWrap="wrap" flexGrow="1">
        <Box fontWeight="600">{comp_info.name.toUpperCase()}</Box>
        <Box fontWeight="600">{comp_info.season}</Box>
        {comp_info.comp_type ? (
          <Flex alignItems="center" gap="1">
            <Icon as={AiOutlineTrophy} />
            <Box>
              {capitalise(comp_info.comp_type)} ({comp_info.comp_status})
            </Box>
          </Flex>
        ) : null}
        <Flex alignItems="center" gap="1">
          <Icon as={MdOutlineDateRange} />
          <Box>
            {comp_info?.start_date &&
              comp_info?.end_date &&
              `${comp_info.start_date.replaceAll('-', '/')}${
                comp_info.start_date !== comp_info.end_date
                  ? ` - ${comp_info.end_date.replaceAll('-', '/')}`
                  : ''
              }`}
          </Box>
        </Flex>
        {comp_info.number_of_teams ? <Box>Teams: {comp_info.number_of_teams}</Box> : null}
      </Flex>
      {/* <Flex gap={3}>
        <CommentThread pageType="comp" pageId={comp_info.id} />
      </Flex> */}
      <Flex gap="3">
        <AddUserFavourites favType="comp" favId={comp_info.id} />
      </Flex>
    </Flex>
  );
};

export default CompSubHeader;
