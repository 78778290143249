import { Box, Flex, Avatar, Image } from '@chakra-ui/react';
import AddUserFavourites from '_shared/components/AddUserFavourites';

const TeamV2SubHeader = ({ data }) => {
  return (
    <Box bg="primary.shade.70">
      <Flex justify="space-between" py={4} px={5} gap="5" alignItems="center">
        <Flex alignItems="center" gap="3">
          <Box boxSize="64px">
            <Avatar size="full" bg="white" src={data.img || null} />
          </Box>
          <Box color="white" fontSize={20} fontWeight="700">
            {data.main_heading}
          </Box>
          {data.flag_url ? (
            <Image ml={2} display="inline-block" w={10} src={data.flag_url || null} />
          ) : null}
        </Flex>
        <Flex>
          <AddUserFavourites favType="team" favId={data.id} />
        </Flex>
      </Flex>
    </Box>
  );
};

export default TeamV2SubHeader;
