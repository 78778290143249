import { useNavigate, useRouter } from '@tanstack/react-location';
import { useQuery } from 'react-query';
import { removeLocalStorageAuthTokens } from '_shared/utils/localStorage';

const useQueryFetch = (queryKey, queryFn, options) => {
  const router = useRouter();
  const navigate = useNavigate();
  const { onError, ...restOptions } = options || {};
  return useQuery(queryKey, queryFn, {
    ...restOptions,
    retry: (failureCount, error) => {
      if (error === 'Token refresh currently in progress' && failureCount < 20) {
        return true;
      }
      if (error?.response?.status && [401, 422].includes(error.response.status)) {
        return false;
      }
      if (failureCount < 3) {
        return true;
      }
      return false;
    },
    onError: (e) => {
      if ([401, 422].includes(e.response.status)) {
        const currentPath = router.state.location.pathname;
        const base64path = btoa(currentPath);
        removeLocalStorageAuthTokens();
        navigate({ to: `/login`, search: { r: base64path } });
      } else if (onError) {
        onError();
      }
    }
  });
};

export default useQueryFetch;
