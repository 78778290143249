import { Box, Flex, Center, Spinner } from '@chakra-ui/react';
import FormCheckboxGroup from 'Forms/_shared/FormElements/FormCheckboxGroup';
import FormNumberInput from 'Forms/_shared/FormElements/FormNumberInput';
import FormMultiSelect from 'Forms/_shared/FormElements/FormMultiSelect';
import FormNumberRange from 'Forms/_shared/FormElements/FormNumberRange';
import FormRadioButtons from 'Forms/_shared/FormElements/FormRadioButtons';
import FormSearchMultiSelect from 'Forms/_shared/FormElements/FormSearchMultiSelect';
import FormSelect from 'Forms/_shared/FormElements/FormSelect';
import { getSearchParamsFromUrl } from '_shared/utils/searchParamsUtils';
import OptionSection from './OptionSection';
import {
  // groupSelectOptions,
  mapOptions
} from 'Forms/_shared/utils/selectOptionsUtils';

const WhereQueryOptions = ({ queryOptions, apiParams }) => {
  const optArray = [];
  if (!queryOptions) {
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    );
  }
  Object.entries(queryOptions).forEach(([key, value]) => {
    const { heading, option, input, form, limit } = value;
    let isDisabled = false;
    if (['batsman', 'bowler', 'player'].includes(key) && apiParams[key]) {
      isDisabled = true;
    }
    if (form === 'checkbox') {
      optArray.push(
        <FormCheckboxGroup
          name={`form_opts.${key}`}
          label={heading}
          options={option}
          key={key}
          isDisabled={isDisabled}
        />
      );
    }
    if (form === 'select') {
      optArray.push(
        <FormMultiSelect
          name={`form_opts.${key}`}
          label={heading}
          options={option.map(mapOptions)}
          placeholder={heading}
          isDisabled={isDisabled}
          key={key}
        />
      );
    }
    if (form === 'search') {
      let searchType;
      if (heading === 'Player') {
        searchType = 'player';
      }
      if (heading === 'Match') {
        searchType = 'match';
      }
      if (!searchType) {
        return;
      }
      optArray.push(
        <FormSearchMultiSelect
          name={`form_opts.${key}`}
          label={heading}
          placeholder={heading}
          isDisabled={isDisabled}
          searchType={searchType}
          key={key}
        />
      );
    }
    if (form === 'select_single') {
      optArray.push(
        <FormSelect
          name={`form_opts.${key}`}
          label={heading}
          options={option}
          placeholder={heading}
          key={key}
          isDisabled={isDisabled}
          isHorizontal
        />
      );
    }
    if (form === 'radio') {
      optArray.push(
        <FormRadioButtons
          name={`form_opts.${key}`}
          label={heading}
          options={option}
          key={key}
          isDisabled={isDisabled}
        />
      );
    }
    if (form === 'range' && input === 'number') {
      optArray.push(
        <FormNumberRange
          name={`form_opts.${key}`}
          label={heading}
          min={parseInt(limit[0])}
          max={parseInt(limit[1])}
          key={key}
          isDisabled={isDisabled}
        />
      );
    }
  });

  return <OptionSection header="Where">{optArray}</OptionSection>;
};

const QueryOptions = ({ queryOptions }) => {
  const optElements = {};
  Object.entries(queryOptions).forEach(([key, value]) => {
    if (
      [
        'diy_operator',
        'diy_select1',
        'diy_select2',
        'having_select',
        'having_cmp',
        'orderby'
      ].includes(key) &&
      value.option &&
      value.option.length !== 0
    ) {
      const { option } = value;
      optElements[key] = (
        <FormSelect
          name={`form_opts.${key}`}
          options={option.map(mapOptions)}
          placeholder="select"
          key={key}
          isHorizontal
        />
      );
    }
    if (key === 'orderbyad' && value.option && value.option.length !== 0) {
      const { option } = value;
      optElements[key] = (
        <FormRadioButtons name={`form_opts.${key}`} options={option.map(mapOptions)} key={key} />
      );
    }
    if (['having_value', 'size'].includes(key)) {
      optElements[key] = <FormNumberInput name={`form_opts.${key}`} key={key} />;
    }
    if (key === 'groupby' && value.option && value.option.length !== 0) {
      const { option } = value;
      optElements[key] = (
        <FormMultiSelect
          name={`group_by`}
          options={option.filter((el) => el.value !== 'default').map(mapOptions)}
          key={key}
        />
      );
    }
    if (key === 'selectopt' && value.option && value.option.length !== 0) {
      const { option } = value;
      optElements[key] = (
        <FormCheckboxGroup
          name={`form_opts.${key}`}
          options={option.map(mapOptions)}
          key={key}
          columnStack
        />
      );
    }
  });
  return (
    <>
      {optElements.menu && <OptionSection header="Select">{optElements.menu}</OptionSection>}
      {optElements.selectopt && (
        <OptionSection header="Select additional columns">{optElements.selectopt}</OptionSection>
      )}
      {optElements.diy_operator && optElements.diy_select1 && optElements.diy_select2 && (
        <OptionSection header="DIY calculation">
          <Flex gap="2">
            {optElements.diy_select1}
            {optElements.diy_operator}
            {optElements.diy_select2}
          </Flex>
        </OptionSection>
      )}
      {optElements.groupby && (
        <OptionSection header="Group by">{optElements.groupby}</OptionSection>
      )}
      {optElements.having_select && optElements.having_cmp && optElements.having_value && (
        <OptionSection header="Having">
          {optElements.having_select}
          <Flex gap="2" alignItems="end">
            {optElements.having_cmp}
            {optElements.having_value}
          </Flex>
        </OptionSection>
      )}
      {optElements.orderby && optElements.orderbyad && (
        <OptionSection header="Order by">
          {optElements.orderby}
          {optElements.orderbyad}
        </OptionSection>
      )}
      {optElements.size && <OptionSection header="Limit">{optElements.size}</OptionSection>}
    </>
  );
};

const EditQTQueryOptions = ({ panelData, queryOptions }) => {
  const apiParams = getSearchParamsFromUrl(panelData.api_reference);
  return (
    <Flex maxH="600px" overflowY="scroll" mb="4">
      <Box flex="1" mx="2">
        <WhereQueryOptions queryOptions={queryOptions} apiParams={apiParams} />
      </Box>
      <Box flex="1" mx="2">
        <QueryOptions queryOptions={queryOptions} />
      </Box>
    </Flex>
  );
};

export default EditQTQueryOptions;
