const parseSelectValue = (value, options) => {
  const newValues = [];
  const findOpt = (opts, val) => {
    const findOption = opts.find((opt) => opt.value === val.toString());
    if (findOption) {
      const { value, label } = findOption;
      newValues.push({
        value,
        label
      });
    }
  };
  if (Array.isArray(value)) {
    value.forEach((el) => {
      findOpt(options, el);
    });
  } else {
    findOpt(options, value);
  }

  return newValues;
};

export default parseSelectValue;
