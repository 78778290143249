import { Box, Button, Center, Flex } from '@chakra-ui/react';
import { InfoOutline48 } from '_icons/outline/48';

const NoDataDisplay = ({ message, handleSetHidden, hideAvailable }) => {
  return (
    <Center h="100%">
      <Flex direction="column" alignItems="center" gap="5" mb="14" w="90%">
        <InfoOutline48 color="primary.tint.80" />
        <Box mb="2" color="black.60" textAlign="center">
          {message}
        </Box>
        {hideAvailable ? (
          <Button onClick={handleSetHidden} variant="outline">
            Hide panel
          </Button>
        ) : null}
      </Flex>
    </Center>
  );
};

export default NoDataDisplay;
