import { createIcon } from '@chakra-ui/icons';

const Play = createIcon({
  displayName: 'Play',
  viewBox: '0 0 20 20',
  defaultProps: { width: '20px', height: '20xp' },
  path: (
    <path
      d="M8.20799 13.125L13.125 9.99999L8.20799 6.87499V13.125ZM9.99999 17.583C8.95866 17.583 7.97599 17.3853 7.05199 16.99C6.12866 16.594 5.32299 16.0523 4.63499 15.365C3.94766 14.677 3.40599 13.8713 3.00999 12.948C2.61466 12.024 2.41699 11.0413 2.41699 9.99999C2.41699 8.94466 2.61466 7.95866 3.00999 7.04199C3.40599 6.12533 3.94766 5.32299 4.63499 4.63499C5.32299 3.94766 6.12866 3.40599 7.05199 3.00999C7.97599 2.61466 8.95866 2.41699 9.99999 2.41699C11.0553 2.41699 12.0413 2.61466 12.958 3.00999C13.8747 3.40599 14.677 3.94766 15.365 4.63499C16.0523 5.32299 16.594 6.12866 16.99 7.05199C17.3853 7.97599 17.583 8.95866 17.583 9.99999C17.583 11.0413 17.3853 12.024 16.99 12.948C16.594 13.8713 16.0523 14.677 15.365 15.365C14.677 16.0523 13.8713 16.594 12.948 16.99C12.024 17.3853 11.0413 17.583 9.99999 17.583ZM9.99999 16.5C11.8053 16.5 13.34 15.868 14.604 14.604C15.868 13.34 16.5 11.8053 16.5 9.99999C16.5 8.19466 15.868 6.65999 14.604 5.39599C13.34 4.13199 11.8053 3.49999 9.99999 3.49999C8.19466 3.49999 6.65999 4.13199 5.39599 5.39599C4.13199 6.65999 3.49999 8.19466 3.49999 9.99999C3.49999 11.8053 4.13199 13.34 5.39599 14.604C6.65999 15.868 8.19466 16.5 9.99999 16.5Z"
      fill="currentColor"
    />
  )
});

export default Play;
