import { Flex, Box, Icon, Button, Tooltip, useToast } from '@chakra-ui/react';
import {
  MdChevronRight,
  MdLoop,
  MdChevronLeft,
  MdOutlineLink,
  MdCheckBoxOutlineBlank
} from 'react-icons/md';
import { useState } from 'react';
import AddToPlaylistModal from './AddToPlaylistModal';

const ListingViewOptions = ({
  videoVal,
  vidDict,
  ballID,
  vidLength,
  setVideoVal,
  setTopScroll,
  setAutoPlay,
  autoPlay
}) => {
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [vidList, setSelectedVideos] = useState([ballID]);
  const toast = useToast();
  return (
    <Box>
      <Flex mr={0} pr={0}>
        <Flex ml="auto" mr="0">
          <Box textAlign="right" fontSize="sm" color="black.60" p={2}>
            Playing {videoVal + 1} of {vidLength}
          </Box>
          <Box>
            <Tooltip label={'Add to Playlist'}>
              <Button variant="ghost" fontSize="sm" onClick={() => setFormModalOpen(true)}>
                <Icon fontSize="lg" as={MdCheckBoxOutlineBlank} />
              </Button>
            </Tooltip>
          </Box>
        </Flex>

        <AddToPlaylistModal
          vidDict={vidDict}
          vidList={vidList}
          vidLength={vidList.length}
          formModalOpen={formModalOpen}
          setFormModalOpen={setFormModalOpen}
          setSelectedVideos={setSelectedVideos}
        />
      </Flex>
      <Flex borderTop={'1px solid #EBEBED'} ml={0} pl={0}>
        <Tooltip label={'Copy link'}>
          <Button
            variant="ghost"
            fontSize="sm"
            onClick={() => {
              let currUrl = window.location.href;
              const params = new URLSearchParams(window.location.search);

              if (params.has('videoNumber')) {
                currUrl = currUrl.replace(
                  `videoNumber=${params.get('videoNumber')}`,
                  `videoNumber=${videoVal}`
                );
              } else {
                currUrl += `&videoNumber=${videoVal}`;
              }

              navigator.clipboard.writeText(currUrl);
              toast({
                title: `Link copied`,
                position: 'bottom-left',
                description: `${currUrl} has been copied to your clipboard`,
                status: 'success',
                duration: 5000,
                isClosable: true
              });
            }}
          >
            <Icon fontSize="lg" as={MdOutlineLink} />
          </Button>
        </Tooltip>
        <Tooltip
          label={autoPlay ? 'Click to disable AutoPlay' : 'Click to enable AutoPlay'}
          fontSize="sm"
        >
          <Button
            onClick={() => {
              setAutoPlay((prevAutoPlay) => !prevAutoPlay);
            }}
            variant="ghost"
            bg={autoPlay ? '#40CD78' : null}
            color={autoPlay ? 'white' : null}
          >
            <Icon as={MdLoop} size="lg" />
          </Button>
        </Tooltip>
        <Flex ml="auto">
          <Button variant="ghost" ml={'auto'} mr={'0'} isDisabled={videoVal === 0}>
            <Icon
              as={MdChevronLeft}
              fontSize="xl"
              onClick={() => {
                setVideoVal((vidVal) => vidVal - 1);
                setTopScroll(videoVal - 1);
              }}
            />
          </Button>

          <Button variant="ghost" ml={'auto'} mr={'0'} isDisabled={videoVal === vidLength - 1}>
            <Icon
              as={MdChevronRight}
              fontSize="xl"
              onClick={() => {
                setVideoVal((vidVal) => vidVal + 1);
                setTopScroll(videoVal + 1);
              }}
            />
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ListingViewOptions;
