/* eslint-disable react/jsx-key */
import { createIcon } from '@chakra-ui/icons';

const LiveIcon = createIcon({
  displayName: 'LiveIcon',
  viewBox: '0 0 24 24',
  defaultProps: { fill: 'none' },
  path: [
    <circle cx="12" cy="12" r="10" stroke="currentcolor" strokeWidth="2" fill="none" />,
    <circle cx="12" cy="12" r="4" stroke="currentcolor" fill="currentColor">
      <animate
        attributeType="XML"
        attributeName="r"
        values="5;1;5"
        dur="1.8s"
        repeatCount="indefinite"
      />
      <animate
        attributeType="XML"
        attributeName="opacity"
        values="0.8;0.4;0.8"
        dur="1.8s"
        repeatCount="indefinite"
      />
    </circle>
  ]
});

export default LiveIcon;
