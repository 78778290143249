import VideoPlaylistHome from 'pages/videoPlaylist/components/VideoPlaylistHome';
import VideoPlaylistViewer from 'pages/videoPlaylist/components/VideoPlaylistViewer';

export const videoPlaylistRoutes = {
  path: 'video_playlist',
  children: [
    {
      path: '/',
      element: <VideoPlaylistHome />
    },
    {
      path: '/:playlistId',
      element: <VideoPlaylistViewer />
    }
  ]
};
