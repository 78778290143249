import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const showDateDisplay = (startDate, endDate, dateTimeUTC) => {
  const startDayJs = dayjs.utc(dateTimeUTC || startDate).local();
  const matchLength = calcMatchLength(startDate, endDate);
  const endDayJs = startDayJs.add(matchLength, 'day');
  let startDay, startMonth, startYear, endDay, endMonth, endYear;
  if (startDayJs.isValid()) {
    startDay = startDayJs.format('D');
    startMonth = startDayJs.format('MMM');
    startYear = startDayJs.format('YYYY');
  } else {
    return '';
  }
  if (endDayJs.isValid()) {
    endDay = endDayJs.format('D');
    endMonth = endDayJs.format('MMM');
    endYear = endDayJs.format('YYYY');
  } else {
    return `${startDay} ${startMonth} ${startYear}`;
  }
  if (startYear !== endYear) {
    return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
  }
  if (startMonth !== endMonth) {
    return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
  }
  if (startDay !== endDay) {
    return `${startDay} - ${endDay} ${startMonth} ${startYear}`;
  }
  return `${startDay} ${startMonth} ${startYear}`;
};

function calcMatchLength(startDate, endDate) {
  if (!dayjs(startDate).isValid() || !dayjs(endDate).isValid()) {
    return 0;
  }
  const startDayJs = dayjs(startDate);
  const endDayJs = dayjs(endDate);

  return endDayJs.diff(startDayJs, 'day');
}
