import { Box } from '@chakra-ui/react';
import ContentGrid from './ContentGrid';
import AdvancedFilters from './AdvancedFilters/AdvancedFilters';
import useAdvancedFilters from './AdvancedFilters/hooks/useAdvancedFilters';

import ContentError from './ContentError';
import ContentLoading from './ContentLoading';
import RecentFilterSelection from './AdvancedFilters/RecentFilterSelection';
import FilterTags from './AdvancedFilters/FilterTags';
import { useEffect } from 'react';
import { useNavigate, useSearch } from '@tanstack/react-location';

const AdvancedContainer = ({ type, headersTakenHeight }) => {
  const search = useSearch();
  const { role } = search;
  const objectVariable = type !== 'player' ? type : role;
  const {
    fetchValues,
    fetchAdvancedData,
    fetchFilterDefs,
    recentFilterOptions,
    handleApplyFilterPreset,
    ...rest
  } = useAdvancedFilters(type);
  const navigate = useNavigate();
  const { isLoading, error, data: advancedData } = fetchAdvancedData;
  const { data: filterDefs } = fetchFilterDefs;

  useEffect(() => {
    return () => {
      navigate({
        search: (old) => ({ ...old, q: undefined }),
        replace: true,
        fromCurrent: true
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" w="100%" h="100%" position="relative">
      <AdvancedFilters fetchFilterDefs={fetchFilterDefs} headersTakenHeight={headersTakenHeight} {...rest} />
      <Box flex="1" ml="300px">
        {error && (
          <ContentError
            title="We’re sorry, an error has occurred"
            subtitle="Please try refreshing the page or clear the filters."
          />
        )}
        {isLoading && <ContentLoading />}
        {advancedData && (
          <Box px="5" pt="5">
            <FilterTags values={fetchValues} filterDefs={filterDefs} border />
          </Box>
        )}
        {advancedData && (
          <ContentGrid
            content={advancedData?.data}
            video={advancedData?.video}
            objectVariable={objectVariable}
          />
        )}
        {!advancedData?.data && !isLoading && !error && recentFilterOptions && filterDefs && (
          <RecentFilterSelection
            filterDefs={filterDefs}
            recentFilterOptions={recentFilterOptions}
            handleApplyFilterPreset={handleApplyFilterPreset}
          />
        )}
      </Box>
    </Box>
  );
};
export default AdvancedContainer;
