import { Box, Flex } from '@chakra-ui/react';
import ReactTable from '_shared/components/ReactTable/ReactTable';
import { getNameString } from './utils';
import TeamImage from '_shared/components/TeamImage';

export default function RankingTable({ tableData, tableHeader }) {
  const columns = [];
  if (tableHeader) {
    tableHeader.forEach((el) => {
      if (el.key) {
        const colValue = {
          Header: el.label,
          accessor: el.key,
          tipText: el.tooltip || '',
          videoKey: el.video_link,
          linkKey: el.link,
          isNumeric: el.key === 'value' || el.is_numeric,
          bold: el.key === 'value' || el.is_numeric
        };
        if (el.key === 'name') {
          colValue.Cell = (props) => {
            const rowData = props.cell?.row?.original || {};
            const { ranking, name } = rowData;
            const nameString = getNameString(name);
            return (
              <Flex gap="2">
                <Box fontWeight="700" minW="4">
                  {ranking}
                </Box>
                <Box>{nameString}</Box>
              </Flex>
            );
          };
        }
        if (el.key === 'team') {
          colValue.Cell = (props) => {
            const rowData = props.cell?.row?.original || {};
            const { team, team_logo, team_flag } = rowData;
            return (
              <Flex gap="2" alignItems="center">
                <TeamImage
                  team={{ logo: team_logo, flag: team_flag, id: team, name: team }}
                  type="circle"
                />
                <Box>{team}</Box>
              </Flex>
            );
          };
        }
        columns.push(colValue);
      }
    });
  }
  return <ReactTable columns={columns} data={tableData} sortable={false} />;
}
