import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { HIDE_SHOW_OPEN } from '_shared/globalState/atoms';
import HideShowPanels from './HideShowPanels';

const HideShowModal = ({ panelData }) => {
  const [hideShowOpen, setHideShowOpen] = useRecoilState(HIDE_SHOW_OPEN);

  return (
    <Modal
      isOpen={hideShowOpen}
      onClose={() => {
        setHideShowOpen(false);
      }}
      size="2xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box fontWeight="700" fontSize="lg">
            Page Panels
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody minH="400px" px="3">
          <HideShowPanels panelData={panelData} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default HideShowModal;
