import { Flex } from '@chakra-ui/react';
import ChakraReactDatepicker from '../../InputComponents/ChakraReactDatepicker';
import dayjs from 'dayjs';

const FilterSelect = ({ range, handleFilterChange, name, filterValues }) => {
  const selectedValues = filterValues[name] || '~';
  const [minValue, maxValue] = selectedValues.split('~');
  const minRange = range?.min ? new Date(range.min) : null;
  const minDate = minValue ? new Date(minValue) : new Date(minRange);
  const maxRange = range?.max ? new Date(range.max) : null;
  const maxDate = maxValue ? new Date(maxValue) : new Date(maxRange);

  const setDate = (v, type) => {
    const formattedDate = v ? dayjs(v).format('YYYY-MM-DD') : '';
    let newValue = '';
    newValue += type === 'min' ? formattedDate : minValue;
    newValue += '~';
    newValue += type === 'max' ? formattedDate : minValue;
    handleFilterChange(name, newValue === '~' ? null : newValue);
  };

  return (
    <Flex direction="column" gap="2">
      <ChakraReactDatepicker
        selectedDate={minDate}
        minDate={minRange}
        maxDate={maxDate || maxRange}
        onChange={(v) => setDate(v, 'min')}
        dateFormat="dd-MM-yyyy"
        fontSize={'sm'}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        popperPlacement="bottom-end"
        placeholder="Start date"
      />
      <ChakraReactDatepicker
        selectedDate={maxDate}
        minDate={minDate || minRange}
        maxDate={maxRange}
        onChange={(v) => setDate(v, 'max')}
        fontSize={'sm'}
        dateFormat="dd-MM-yyyy"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        popperPlacement="bottom-end"
        placeholder="End date"
      />
    </Flex>
  );
};

export default FilterSelect;
