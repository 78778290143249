import { Flex, Grid, List, Select } from '@chakra-ui/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ButtonSelector from '_shared/components/ButtonSelector';
import useRecentlyViewedFilters from './useRecentlyViewedFilters';
import RecentlyViewedCard from './RecentlyViewedCard';
import FixtureComponent from '_shared/components/FixtureComponent/FixtureComponent';
import FlexHeaderScroll from '_shared/components/LayoutComponents/FlexHeaderScroll';

dayjs.extend(relativeTime);

const RecentlyViewedPanel = ({ panelData }) => {
  const { listType, setListType, viewType, setViewType, typeOptions, data } =
    useRecentlyViewedFilters(panelData);

  const getDisplay = () => {
    if (viewType === 'match') {
      return (
        <List m="4" spacing="2">
          {data.map((val) => {
            return (
              <FixtureComponent
                matchData={{ id: val.object_id, match_status: 'recent_view', ...val }}
              />
            );
          })}
        </List>
      );
    }
    return (
      <Grid m="2" templateColumns="repeat( auto-fit, minmax(200px, 1fr) )" gap={2}>
        {data.map((val) => {
          return <RecentlyViewedCard item={val} />;
        })}
      </Grid>
    );
  };

  return (
    <FlexHeaderScroll
      header={
        typeOptions.length !== 0 ? (
          <Flex px="2" py="2" gap="1">
            <ButtonSelector
              handleChange={setViewType}
              value={viewType}
              options={typeOptions}
              // buttonMinWidth="100px"
            />
            <Select
              value={listType}
              onChange={(e) => setListType(e.target.value)}
              maxW="300px"
              bg="white"
              size="md"
              fontSize="14px"
            >
              <option value="recent">Most recent</option>
              <option value="most">Most viewed</option>
            </Select>
          </Flex>
        ) : null
      }
      content={getDisplay()}
    />
  );
};
export default RecentlyViewedPanel;
