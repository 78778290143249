import { Box, Flex, Grid, Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react';
import { useRef } from 'react';
import { Player, ControlBar } from 'video-react';
import VideoBallCard from './VideoBallCard';
import VideoControlBar from './VideoControlBar';
import { setTopScroll } from '_shared/utils/utilFunctions.js';
import VideoFiltersContainer from 'pages/videoSearch/components/VideoFilters/VideoFiltersContainer';
import AddToPlaylist from './AddToPlaylist';
import ResultsHeader from './ResultsHeader';
import VideoGridObject from './VideoGridObject';
import 'video-react/dist/video-react.css';
import './VideoCentre.css';

const VideoResultsVideoView = ({
  setViewType,
  results,
  videoVal,
  setVideoVal,
  autoPlay,
  setAutoPlay,
  selectedVideos,
  setSelectedVideos,
  setFieldValue,
  handleSubmit,
  changeSearch,
  setChangeSearch,
  class_info,
  objectType,
  object_info,
  objectId,
  setFiltersOpen,
  filtersOpen,
  params,
  filters,
  classId,
  multiSelect,
  setMultiSelect,
  reversed,
  setReversed
}) => {
  const videoRef = useRef(null);

  const vidDict = {};
  results.forEach((el) => {
    vidDict[el.ball_id] = el;
  });
  const handleSelect = (el) => {
    if (selectedVideos.includes(el)) {
      setSelectedVideos((oldValues) => {
        return oldValues.filter((val) => val !== el);
      });
      setFieldValue(
        'playlist_selection',
        selectedVideos.filter((val) => val !== el)
      );
    } else {
      setSelectedVideos([...selectedVideos, el]);
      setFieldValue('playlist_selection', [...selectedVideos, el]);
    }
  };
  const selectAllVids = () => {
    const returnArr = [];
    Object.keys(vidDict).forEach((el) => {
      returnArr.push(parseInt(el));
    });
    setSelectedVideos(returnArr);
    setFieldValue('playlist_selection', returnArr);
  };
  const deselectAllVids = () => {
    setSelectedVideos([]);
    setFieldValue('playlist_selection', []);
  };

  return (
    <Box>
      <Box h="95vh">
        <Box>
          <ResultsHeader
            changeSearch={changeSearch}
            setChangeSearch={setChangeSearch}
            class_info={class_info}
            objectType={objectType}
            object_info={object_info}
            results={results}
            objectId={objectId}
            setFiltersOpen={setFiltersOpen}
            selectAllVids={selectAllVids}
            setViewType={setViewType}
            videoPage="standard"
            deselectAllVids={deselectAllVids}
            reversed={reversed}
            setReversed={setReversed}
          />
        </Box>
        <Flex margin="auto">
          <Box minW={{ base: '900px', '2xl': '1000px' }} p={2} overflowY="scroll" h="90vh">
            {videoRef && (
              <Player
                src={results[videoVal].video_url}
                className="video-player-panel"
                playsInline={true}
                autoPlay={autoPlay}
                controls={false}
                ref={videoRef}
                // muted={true}
                onEnded={() => {
                  if (autoPlay) {
                    if (videoVal === results.length - 1) {
                      setTopScroll(0);
                      setVideoVal(0);
                    } else {
                      setVideoVal(videoVal + 1);
                      setTopScroll(videoVal + 1);
                    }
                  }
                }}
              >
                <ControlBar
                  autoHide={2000}
                  disableDefaultControls={true}
                  className="video-control-bar"
                >
                  {videoRef?.current && <VideoControlBar videoRef={videoRef} />}
                </ControlBar>
              </Player>
            )}

            <Box mb={4} ml={1}>
              <VideoBallCard
                ballId={results[videoVal].ball_id}
                videoInd={videoVal}
                vidDict={vidDict}
                setAutoPlay={setAutoPlay}
                autoPlay={autoPlay}
                setVideoVal={setVideoVal}
                vidLength={results.length}
              />
            </Box>
          </Box>
          <Box w="100%" id="scrolling_div" pt={2} mr={2} overflowY="scroll" h="85vh">
            <Grid
              templateColumns={{ base: 'repeat(1, 1fr)', '2xl': 'repeat(2, 1fr)' }}
              gap={2}
              overflowY="scroll"
            >
              {!reversed
                ? results.map((el, idx) => {
                    return (
                      <VideoGridObject
                        el={el}
                        idx={idx}
                        videoVal={videoVal}
                        setVideoVal={setVideoVal}
                        setViewType={setViewType}
                        selectionOn={selectedVideos.length > 0}
                        handleSelect={handleSelect}
                        isChecked={selectedVideos.includes(el.ball_id)}
                      />
                    );
                  })
                : results.toReversed().map((el, idx) => {
                    return (
                      <VideoGridObject
                        el={el}
                        idx={results.length - 1 - idx}
                        videoVal={videoVal}
                        setVideoVal={setVideoVal}
                        setViewType={setViewType}
                        selectionOn={selectedVideos.length > 0}
                        handleSelect={handleSelect}
                        isChecked={selectedVideos.includes(el.ball_id)}
                      />
                    );
                  })}
            </Grid>
          </Box>
        </Flex>

        <Modal isOpen={filtersOpen} onClose={() => setFiltersOpen(false)} size="lg">
          <ModalOverlay />
          <ModalContent maxW="90vw" maxH="80vh" overflowY="auto">
            <ModalBody m="10">
              <VideoFiltersContainer
                presetFilters={params}
                filters={filters}
                classId={classId}
                objectType={objectType}
                objectId={objectId}
                multiSelect={multiSelect}
                setMultiSelect={setMultiSelect}
                setFiltersOpen={setFiltersOpen}
                class_info={class_info}
                object_info={object_info}
              />
            </ModalBody>
          </ModalContent>
        </Modal>

        <AddToPlaylist
          vidDict={vidDict}
          vidList={selectedVideos}
          vidLength={selectedVideos.length}
          setSelectedVideos={setSelectedVideos}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          selectAllVids={selectAllVids}
        />
      </Box>
    </Box>
  );
};

export default VideoResultsVideoView;
