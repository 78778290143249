import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { HIDDEN_PANELS } from '_shared/globalState/atoms';

const useGetFilterOptions = (panelList = []) => {
  const hiddenPanels = useRecoilValue(HIDDEN_PANELS);
  const [categoryOptions, setCategoryOptions] = useState([]);
  useEffect(() => {
    if (panelList.length === 0) {
      return;
    }
    const newCatList = [];
    panelList.forEach((el) => {
      if (hiddenPanels[el.panel_id]) {
        return;
      }
      if (
        !el.is_static &&
        el.category_type_id &&
        el.category_type_name &&
        !newCatList.find((item) => item.value === el.category_type_id.toString())
      ) {
        newCatList.push({
          value: el.category_type_id.toString(),
          label: el.category_type_name
        });
      }
    });
    setCategoryOptions(newCatList.sort((a, b) => a.value - b.value));
  }, [hiddenPanels, panelList]);
  return { categoryOptions };
};

export default useGetFilterOptions;
