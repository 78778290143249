import { Box, Select } from '@chakra-ui/react';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { useEffect } from 'react';

const options = [
  {
    label: 'Date range',
    value: ''
  },
  {
    label: '1 Year',
    value: '1year'
  },
  {
    label: '2 Years',
    value: '2year'
  },
  {
    label: '5 Years',
    value: '5year'
  }
];

const checkDateAvailable = (options, class_id) => {
  if (!class_id) return false;
  if (options.length === 0) return false;
  return options.some((option) => {
    return option.value === class_id;
  });
};

const DateSelect = ({ type }) => {
  const navigate = useNavigate();
  const search = useSearch();
  const { date } = search;
  const dateAvailable = checkDateAvailable(options, date);

  const currentPath = window.location.pathname;
  const applyFilter = currentPath?.includes(`/${type}/`);

  useEffect(() => {
    if (applyFilter && (!date || !dateAvailable)) {
      navigate({
        search: (old) => ({ ...old, date: undefined }),
        replace: true,
        fromCurrent: true
      });
    }
  }, [date, navigate, dateAvailable, applyFilter]);

  const handleChange = (e) => {
    navigate({
      search: (old) => ({ ...old, date: e.target.value || undefined }),
      replace: true,
      fromCurrent: true
    });
  };

  if (options.length === 0) {
    return null;
  }

  if (options.length === 1) {
    const label = options?.[0]?.label || '';
    return <Box>{label}</Box>;
  }

  return (
    <Select value={date} onChange={handleChange} w="240px" bg="white" h="44px">
      {options.map((item) => {
        const { value, label } = item;
        return (
          <option value={value} key={value}>
            {label}
          </option>
        );
      })}
    </Select>
  );
};

export default DateSelect;
