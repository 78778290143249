import { Box, Flex, Icon, Tooltip } from '@chakra-ui/react';
import { MdOutlineLocationOn, MdOutlineDateRange, MdAccessTime } from 'react-icons/md';
import { AiOutlineTrophy } from 'react-icons/ai';
// import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { getHomeAwayInfo } from 'pages/match/_shared/utils';
// import CommentThread from 'pages/_shared/components/CommentThread/CommentThread';
import AddUserFavourites from '_shared/components/AddUserFavourites';
import RouterLink from '_shared/components/Utils/RouterLink';
import TeamImage from '_shared/components/TeamImage';
import { parseDateTimeLocal } from '_shared/utils/dateTimeFunctions';

const MatchSubHeader = ({ data, height }) => {
  const panelInfo = data.match_information;
  const { homeTeam, awayTeam } = getHomeAwayInfo(panelInfo);
  const startDate = parseDateTimeLocal(panelInfo.start_datetime_utc, 'DD/MM/YYYY');
  const startTime = parseDateTimeLocal(panelInfo.start_datetime_utc, 'HH:mm');
  return (
    <Flex px="5" py="4" gap="4" h={`${height}px`} bg="primary.shade.70">
      <Flex
        color="white"
        gap="4"
        flexWrap="wrap"
        flexGrow="1"
        direction={height > 56 ? 'column' : 'row'}
      >
        <Flex alignItems="center" fontSize="16" gap="2" fontWeight="600">
          <TeamImage team={homeTeam} />
          <Box textAlign="center">
            <RouterLink to={`/team/${homeTeam.id}`}>{homeTeam.name}</RouterLink>
          </Box>
          <Box>v</Box>
          <TeamImage team={awayTeam} />
          <Box textAlign="center">
            <RouterLink to={`/team/${awayTeam.id}`}>{awayTeam.name}</RouterLink>
          </Box>
          <Box fontWeight="600">{panelInfo.match_number}</Box>
        </Flex>
        <Flex gap="2" direction={height > 100 ? 'column' : 'row'}>
          <Flex alignItems="center" gap="1">
            <Icon as={AiOutlineTrophy} />
            <RouterLink to={`/comp/${panelInfo.comp_id}`}>{panelInfo.comp_name}</RouterLink>
          </Flex>
          <Flex alignItems="center" gap="1">
            <Icon as={MdOutlineLocationOn} />
            <RouterLink to={`/ground/${panelInfo.ground_id}`}>{panelInfo.ground_name}</RouterLink>
          </Flex>
          <Tooltip
            label={`${panelInfo.start_time_local} Local Start Time`}
            isDisabled={!panelInfo.start_time_local}
            placement="bottom"
            hasArrow
          >
            <Flex alignItems="center" gap="1">
              <Icon as={MdOutlineDateRange} />
              <Box>{startDate}</Box>
              <Icon as={MdAccessTime} />
              <Box>{startTime}</Box>
            </Flex>
          </Tooltip>
        </Flex>
      </Flex>
      {/* <Flex gap="3">
        <CommentThread pageType="match" pageId={panelInfo.id} />
      </Flex> */}
      <Flex gap="3">
        <AddUserFavourites favType={'match'} favId={panelInfo.id} />
        {/* <Icon
          cursor="pointer"
          boxSize={6}
          color="white.100"
          as={isOpen ? FiChevronUp : FiChevronDown}
          onClick={onToggle}
        /> */}
      </Flex>
    </Flex>
  );
};

export default MatchSubHeader;
