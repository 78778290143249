import { Flex, Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { useState } from 'react';
import FilterLabel from './FilterLabel';
import capitalise from '_shared/utils/capitalise';

const RangeFilter = ({
  filterData,
  setFieldValue,
  initialValues,
  isAccordion,
  isExpanded,
  setIsExpanded
}) => {
  const { api_tag, label, options } = filterData;
  const initialMinValue = initialValues[api_tag]?.min ? initialValues[api_tag]?.min : options.min;
  const initialMaxValue = initialValues[api_tag]?.max ? initialValues[api_tag]?.max : options.max;
  const [min, setMin] = useState(initialMinValue);
  const [max, setMax] = useState(initialMaxValue);

  const setRange = (val, rangeType) => {
    if (rangeType === 'min') {
      setMin(val);
      if (val === options.min && max === options.max) {
        setFieldValue(api_tag, null);
      } else if (max === options.max) {
        setFieldValue(api_tag, `${val}~`);
      } else if (val === options.min) {
        setFieldValue(api_tag, `~${max}`);
      } else {
        setFieldValue(api_tag, `${val}~${max}`);
      }
    } else {
      setMax(val);
      if (val === options.max && min === options.min) {
        setFieldValue(api_tag, null);
      } else if (min === options.min) {
        setFieldValue(api_tag, `~${val}`);
      } else if (val === options.max) {
        setFieldValue(api_tag, `${min}~`);
      } else {
        setFieldValue(api_tag, `${min}~${val}`);
      }
    }
  };

  const isActive =
    String(min) !== String(initialMinValue) || String(max) !== String(initialMaxValue);

  return (
    <FilterLabel
      name={api_tag}
      label={capitalise(label)}
      isActive={isActive}
      isAccordion={isAccordion}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <Flex gap="2">
        <InputGroup>
          <InputLeftAddon
            pointerEvents="none"
            color="black.60"
            children="From"
            h="44px"
            bg="black.4"
            fontSize="sm"
          />
          <Input
            onInput={(e) => setRange(e.target.value, 'min')}
            value={min}
            id={`${api_tag}-min`}
            borderRadius="md"
            h="44px"
            type="number"
          />
        </InputGroup>
        <InputGroup>
          <InputLeftAddon
            pointerEvents="none"
            color="black.60"
            children="To"
            h="44px"
            bg="black.4"
            fontSize="sm"
          />
          <Input
            borderRadius="md"
            size="sm"
            h="44px"
            value={max}
            type="number"
            onInput={(e) => setRange(e.target.value, 'max')}
            id={`${api_tag}-max`}
          />
        </InputGroup>
      </Flex>
    </FilterLabel>
  );
};

export default RangeFilter;
