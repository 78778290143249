import { Avatar, Box, Flex, Icon, Image } from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useRecoilState } from 'recoil';
import AddUserFavourites from '_shared/components/AddUserFavourites';
// import CommentThread from 'pages/_shared/components/CommentThread/CommentThread';
import { HEADER_EXPANDED } from '_shared/globalState/atoms';
import PlayerDetails from './PlayerDetails';

const PlayerSubHeader = ({ data }) => {
  const [headerExpanded, setHeaderExpanded] = useRecoilState(HEADER_EXPANDED);
  const expanded = headerExpanded.player || false;
  const { player_information } = data;
  return (
    <Box
      h={expanded ? '271px' : '80px'}
      bg="primary.shade.70"
      transitionProperty="height"
      transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
      transitionDuration="500ms"
    >
      <Flex justify="space-between" py={4} px={5} gap="5">
        <Box
          boxSize={expanded ? '48' : '12'}
          mx={expanded ? '2' : '0'}
          my={expanded ? '5' : '0'}
          transitionProperty="height width"
          transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
          transitionDuration="500ms"
        >
          <Avatar
            size="full"
            src={player_information.panel_picture ? player_information.panel_picture : null}
          />
        </Box>
        <Flex flexGrow={1} direction="column" gap="10px">
          <Flex h="48px" alignItems="center">
            <Flex flexGrow={1} alignItems="center" gap="3">
              <Box color="white" fontSize={20} fontWeight="700">
                {player_information.panel_header}
              </Box>
              <Image
                h="5"
                objectFit="contain"
                src={player_information.nationality_flag}
                alt="Nationality Flag"
              />
            </Flex>
            {/* <Flex gap="3">
              <CommentThread pageType="player" pageId={player_information.id} />
            </Flex> */}
            <Flex gap="3">
              <AddUserFavourites favType={'player'} favId={player_information.id} />
              <Icon
                cursor="pointer"
                boxSize={6}
                color="white.100"
                as={expanded ? FiChevronUp : FiChevronDown}
                onClick={() =>
                  setHeaderExpanded((p) => {
                    const currentValue = p.player || false;
                    return { ...p, player: !currentValue };
                  })
                }
              />
            </Flex>
          </Flex>
          {expanded ? <PlayerDetails playerInfo={player_information} /> : null}
        </Flex>
      </Flex>
    </Box>
  );
};

export default PlayerSubHeader;
