export const colors = [
  "rgb(242, 124, 124)",  // weakest
  "rgb(246, 162, 162)",
  "rgb(250, 199, 199)",
  "rgb(251, 218, 218)",
  "rgb(255, 239, 211)",
  "rgb(255, 231, 188)",
  "rgb(255, 215, 144)",
  "rgb(217, 245, 228)",
  "rgb(198, 240, 215)",
  "rgb(160, 230, 188)",
  "rgb(121, 220, 161)"   // strongest  
];

export default colors;