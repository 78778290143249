import InsertPanelModal from 'Forms/PanelForms/InsertPanelModal';
import PanelPageDraggable from 'panels/PanelPageDraggable/PanelPageDraggable';
import usePanelSettingsAvailable from '_shared/hooks/usePanelSettingsAvailable';
import CompSubHeader from './CompSubHeader';
import StickyScrollContainer from 'pages/_shared/components/StickyScroll/StickyScrollContainer';
import StickyScrollWrapper from 'pages/_shared/components/StickyScroll/StickyScrollWrapper';
import HideShowModal from 'pages/_shared/components/HideShowModal/HideShowModal';
import usePageTitle from '_shared/hooks/usePageTitle';
import useCategoryFilter from 'pages/_shared/hooks/useCategoryFilter';
import FilterLayout from 'pages/_shared/components/FilterComponents/FilterLayout';
import useFetchPagePanels from 'pages/_shared/hooks/useFetchPagePanels';
import PanelsLoadingError from 'pages/_shared/components/PanelsLoadingError';
import PageSelectFilter from 'pages/_shared/components/FilterComponents/PageSelectFilter';
import panelFetchErrorMessage from 'pages/_shared/utils/panelFetchErrorMessage';

const getIdSet = (panelData) => {
  const elIds = [];
  panelData?.forEach((el) => {
    elIds.push(el.panel_id);
  });
  return elIds;
};

const type = 'comp';

const CompContainer = ({ pageData, objectId }) => {
  const { comp_info } = pageData;
  const { comp_status, name } = comp_info;
  usePageTitle(name);

  const { panelsIsLoading, panelError, panelData, panelIsFetching } = useFetchPagePanels(
    type,
    objectId
  );
  const { categoryOptions, categoryId, setCategoryId } = useCategoryFilter(panelData);

  const panelIdSet = getIdSet(panelData);
  const includesPC_100 = panelIdSet.includes('PC_1000');
  const panelSettingsAvailable = usePanelSettingsAvailable(!includesPC_100);

  const noFiltersDisplayed = comp_status === 'forthcoming' || categoryOptions.length === 0;

  return (
    <>
      <StickyScrollContainer>
        <StickyScrollWrapper
          noFiltersDisplayed={noFiltersDisplayed}
          headerSize={56}
          pageType={type}
        >
          <CompSubHeader data={pageData} />
          {!noFiltersDisplayed ? (
            <FilterLayout>
              {comp_status !== 'forthcoming' ? (
                <PageSelectFilter
                  options={categoryOptions}
                  value={categoryId}
                  setValue={setCategoryId}
                  allOption="All categories"
                />
              ) : null}
            </FilterLayout>
          ) : null}
        </StickyScrollWrapper>
        {panelData && (
          <PanelPageDraggable
            pageData={pageData}
            panels={panelData}
            pageType={type}
            categoryId={categoryId}
          />
        )}
      </StickyScrollContainer>
      <PanelsLoadingError
        panelError={panelError}
        panelIsFetching={panelIsFetching}
        panelsIsLoading={panelsIsLoading}
        errorMessage={panelFetchErrorMessage(type, pageData.banner_heading)}
      />
      {panelSettingsAvailable && (
        <>
          <InsertPanelModal panelData={panelData} pageData={pageData} pageType={type} />
          <HideShowModal panelData={panelData} />
        </>
      )}
    </>
  );
};

export default CompContainer;
