import {
  Avatar,
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger
} from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { logout } from '_shared/dataFetching/authService';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import { USER_CONFIG_MODAL } from '_shared/globalState/atoms';

const UserMenu = () => {
  const setUserConfigModal = useSetRecoilState(USER_CONFIG_MODAL);
  const { data: userInfo } = useUserDetails();
  const { full_name, company_name, is_staff, admin_user } = userInfo || {};

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          rounded="full"
          variant="link"
          cursor="pointer"
          minW={0}
          bg="white.20"
          _hover={{ color: 'white.100', bg: 'primary.tint.10' }}
        >
          <Avatar size="user" bg="transparent" color="primary.tint.80" name={full_name} />
        </Button>
      </PopoverTrigger>
      <PopoverContent bg="white.100" w="240px">
        <PopoverBody p="5">
          <Box>
            <Box fontWeight="700" fontSize="lg" mb="3">
              {full_name}
            </Box>
            {company_name && (
              <Box fontWeight="700" color="black.60" mb="2">
                {company_name}
              </Box>
            )}
            <Box color="black.60">
              {admin_user && admin_user === 1
                ? 'Admin Access'
                : is_staff === 1
                ? 'Staff Access'
                : 'Public Access'}
            </Box>
          </Box>
          <Flex direction="column" gap="2" mt="8">
            <Button
              key="profile"
              variant="outline"
              onClick={() => setUserConfigModal(true)}
              bg="primary.tint.90"
              color="primary.main"
            >
              Configuration
            </Button>
            <Button key="logout" variant="tertiary" onClick={logout}>
              Logout
            </Button>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default UserMenu;
