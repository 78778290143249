import { Box, Flex, Grid, GridItem, Center } from '@chakra-ui/react';
import { useState } from 'react';
import ButtonSelector from '_shared/components/ButtonSelector';

const IndividualGrid = ({ gridData, players, gridType }) => {
  if (!gridData || !players) return <Box>No data available</Box>;
  const headerNames = [];
  gridData?.grid[0]?.batting.forEach((el) => {
    headerNames.push(players[Number(el.batsman_id)].known_as);
  });
  return (
    <Grid
      templateColumns={`repeat(${1 + headerNames.length}, ${gridData.grid.length + 1}fr)`}
      gap={2}
      p={2}
    >
      <GridItem w="100%" h="10" id={`blank-${gridType}`} />
      {headerNames.map((el) => {
        return (
          <GridItem
            h="10"
            w="100%"
            id={`${el}-${gridType}`}
            fontSize="xs"
            fontWeight="bold"
            textAlign="center"
          >
            <Center> {el}</Center>
          </GridItem>
        );
      })}
      {gridData.grid.map((el) => {
        return (
          <>
            <GridItem h="10" w="100%" fontSize="xs" fontWeight="bold" textAlign="center">
              {el?.bowling_technique ? el.bowling_technique : players[el.bowler_id].known_as}
            </GridItem>
            {el.batting.map((innerEl) => {
              return (
                <GridItem
                  h="10"
                  w="100%"
                  bg={`captaincy.${innerEl.matchup}`}
                  id={`${el?.bowling_technique ? el.bowling_technique : el.bowler_id}-${
                    innerEl.batsman_id
                  }-${gridType}`}
                ></GridItem>
              );
            })}
          </>
        );
      })}
    </Grid>
  );
};

const GridComponent = ({ gridType, gridData, players, teams }) => {
  const teamKeys = Object.keys(teams);
  const [teamVal, setTeamVal] = useState(teamKeys[0]);
  const playerDict = {};
  const gridDict = {};
  for (let i = 0; i < players.length; i += 1) {
    playerDict[players[i].player_id] = players[i];
  }
  for (let i = 0; i < gridData.length; i += 1) {
    gridDict[gridData[i].team_id] = gridData[i];
  }
  const tabList = [];
  for (let i = 0; i < teamKeys.length; i += 1) {
    tabList.push({ label: teams[teamKeys[i]].abbreviation, value: teamKeys[i] });
  }
  return (
    <Box>
      <Flex justifyContent={'space-between'}>
        <Box w="25%">
          <Center fontSize="md" pt={3}>
            {teams[teamVal].captain} (C)
          </Center>
        </Box>
        <ButtonSelector
          handleChange={setTeamVal}
          value={teamVal}
          options={tabList}
          buttonMinWidth="120px"
        />
        <Box pr={2} w="25%"></Box>
      </Flex>
      <Box>
        <IndividualGrid gridData={gridDict[teamVal]} players={playerDict} gridType={gridType} />
      </Box>
    </Box>
  );
};

const CaptaincyGridPanel = ({ panelData }) => {
  const tabList = [];
  const { data } = panelData;

  const { simple, standard, player, teams } = data;
  if (standard) {
    tabList.push({ value: 'standard', label: 'Standard' });
  }
  if (simple) {
    tabList.push({ value: 'simple', label: 'Simple' });
  }
  const [captaincyGrid, setCaptaincyGrid] = useState(tabList.length > 0 ? tabList[0].value : null);
  if (!panelData.data) {
    return <Box>CaptaincyGrid unavailable</Box>;
  }

  return (
    <Box pl={2} pr={2}>
      <Box pt={2} pb={2}>
        {tabList.length > 1 ? (
          <ButtonSelector
            handleChange={setCaptaincyGrid}
            value={captaincyGrid}
            options={tabList}
            buttonMinWidth="120px"
          />
        ) : (
          tabList[0].label
        )}
      </Box>
      <Box>
        {captaincyGrid ? (
          <GridComponent
            gridType={captaincyGrid}
            gridData={
              captaincyGrid === 'simple' ? simple : captaincyGrid === 'standard' ? standard : []
            }
            players={player}
            teams={teams}
          />
        ) : (
          'No captaincy grid available'
        )}
      </Box>
    </Box>
  );
};

export default CaptaincyGridPanel;
