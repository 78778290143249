import { Box, Flex, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { Field } from 'formik';
import ReactSelect from 'react-select';

const FormMultiSelect = ({ name, label, placeholder, options, isDisabled }) => {
  return (
    <Field name={name}>
      {({ field: { value }, form: { setFieldValue, errors, touched } }) => {
        return (
          <FormControl isInvalid={errors[name] && touched[name]} mt="3">
            <Flex alignItems="end">
              <FormLabel>{label}</FormLabel>
              <Box flex="1">
                <ReactSelect
                  onChange={(v) => setFieldValue(name, v)}
                  value={value}
                  options={options}
                  isMulti
                  placeholder={placeholder}
                  isDisabled={isDisabled}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </Box>
            </Flex>
            <FormErrorMessage>{errors[name]}</FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default FormMultiSelect;
