import { Select } from '@chakra-ui/react';
import { Field } from 'formik';
import FilterLabel from './FilterLabel';
import capitalise from '_shared/utils/capitalise';

const SelectBox = ({ filterData, isAccordion, isExpanded, setIsExpanded }) => {
  const { api_tag, label, options } = filterData;
  return (
    <Field name={api_tag}>
      {({ field }) => {
        const { value } = field;
        return (
          <FilterLabel
            name={api_tag}
            label={capitalise(label)}
            isActive={!!value}
            isAccordion={isAccordion}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          >
            <Select {...field} placeholder={`Select ${label}`}>
              {options.map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </Select>
          </FilterLabel>
        );
      }}
    </Field>
  );
};

export default SelectBox;
