import { Box } from '@chakra-ui/react';
import FormInput from 'Forms/_shared/FormElements/FormInput';
// import FormSelect from 'Forms/_shared/FormElements/FormSelect';
import Columns from 'Forms/PanelForms/_shared/FormComponents/Columns';
import PanelType from 'Forms/PanelForms/_shared/FormComponents/PanelType';
import StaffFields from 'Forms/PanelForms/_shared/FormComponents/StaffFields';
import useExternalColumnData from '../hooks/useExternalColumnData';
import JsonTreeView from '../../_shared/FormComponents/JsonTreeView';
import FormCategorySelector from 'Forms/_shared/FormElements/FormCategorySelector';
import DynamicNameVariables from '../FormComponents/DynamicNameVariables';

const ExtApiPanelForm = ({
  editMode,
  values,
  is_staff,
  videoOptionAvailable,
  setFieldValue,
  pageType,
  objectId
}) => {
  useExternalColumnData(true);
  const { columnData } = values;

  return (
    <>
      {!editMode && (
        <Box mt="3">
          <FormInput name="ext_api" label="API URL" placeholder="Enter API url" />
        </Box>
      )}
      {columnData.length !== 0 && (
        <>
          {is_staff === 1 && <StaffFields />}
          <Box pt={2} pb={2}>
            <FormCategorySelector
              name="category_type_id"
              label="Category"
              pageType={pageType}
              classId={values.classId}
            />
          </Box>
          <Box mt="3">
            <PanelType values={values} videoOptionAvailable={videoOptionAvailable} />
          </Box>
          <DynamicNameVariables
            pageType={pageType}
            objectId={objectId}
            values={values}
            setFieldValue={setFieldValue}
          />
          {values.panel_type_id !== '4' && (
            <Box my="3">
              <Columns panel_type_id={values.panel_type_id} columnData={columnData} />
            </Box>
          )}
        </>
      )}
      <JsonTreeView />
    </>
  );
};

export default ExtApiPanelForm;
