import { useState, useEffect } from 'react';
import useElementSize from '@custom-react-hooks/use-element-size';
import { Box, Flex} from '@chakra-ui/react';
import ButtonSelector from '_shared/components/ButtonSelector';

const Selectors = ({ teams, tabValue, setTabValue, teamValue, setTeamValue, setAppliedDetailsItem, setActiveDetailsItem }) => {
  const teamA = teams?.[0];
  const teamB = teams?.[1];

  const [selectorsRef, selectorsSize] = useElementSize();
  const [useLongTeamNames, setUseLongTeamNames] = useState(false);

  const teamNameA = useLongTeamNames ? (teamA?.short_name || teamA?.name) : teamA?.abbreviation;
  const teamIdA = teamA?.team_id;

  const teamNameB = useLongTeamNames ? (teamB?.short_name || teamB?.name) : teamB?.abbreviation;
  const teamIdB = teamB?.team_id;

  const tabOptions = [{label: 'V Bowler technique', value: 'simple'}, {label: 'V Bowler', value: 'standard'}];
  const teamOptions = [{label: teamNameA, value: teamIdA}, {label: teamNameB, value: teamIdB}];
  const teamNameMinWidth = useLongTeamNames ? 180 : 90;

  useEffect(() => {
    setUseLongTeamNames(selectorsSize.width > 900);
  }, [selectorsSize.width]);

  useEffect(() => {
    setAppliedDetailsItem(null);
    setActiveDetailsItem(null);
  }, [tabValue, teamValue, setAppliedDetailsItem, setActiveDetailsItem]);

  return (
    <Box
      padding="0 1rem 1rem 1rem"
      display="flex"
      justifyContent="space-between"
      gap="1rem"
      flexWrap="wrap"
      ref={selectorsRef}
    >
      <ButtonSelector
        buttonMinWidth="180px"
        options={tabOptions}
        value={tabValue}
        handleChange={setTabValue}
      />

      <Flex flex="1" justifyContent="flex-end">
        <ButtonSelector
          buttonMinWidth={teamNameMinWidth}
          options={teamOptions}
          value={teamValue}
          handleChange={setTeamValue}
        />
      </Flex>
    </Box>
  );
};

export default Selectors;