import { Box, GridItem } from '@chakra-ui/react';
import ContentTypeDefinitions from './ContentTypeDefinitions';
import { useSearch } from '@tanstack/react-location';
import getContentConfig from './contentConfig/getContentConfig';
import isEqual from 'lodash.isequal';
import { memo } from 'react';

const ContentComponent = ({ contentData = {}, objectVariable, objectId, video }) => {
  const search = useSearch();
  const { tab, class_id } = search;
  const { content_type, key } = contentData;

  const Component = ContentTypeDefinitions[content_type];
  const panelColSpan = getContentConfig(key, 'panelColSpan', [
    { type: 'classId', value: class_id },
    { type: 'type', value: objectVariable }
  ]);
  const panelRowSpan = getContentConfig(key, 'panelRowSpan');
  const panelRowMaxHeight = getContentConfig(key, 'panelRowMaxHeight');

  if (Component) {
    return (
      <GridItem
        flexDirection="column"
        h="100%"
        boxShadow="0px 2px 6px rgba(4, 9, 33, 0.1), 0px 2px 0px rgba(4, 9, 33, 0.05), 0px 0px 2px rgba(4, 9, 33, 0.03)"
        rounded="lg"
        overflow="hidden"
        bg="white.100"
        colSpan={panelColSpan}
        rowSpan={panelRowSpan}
      >
        <Box position="relative" height="100%" maxH={panelRowMaxHeight}>
          <Component
            content={contentData}
            key={`${contentData.key}_${tab}_${objectVariable}`}
            objectVariable={objectVariable}
            objectId={objectId}
            video={video}
          />
        </Box>
      </GridItem>
    );
  }

  return null;
};

const memoFunction = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default memo(ContentComponent, memoFunction);
