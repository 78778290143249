import { Box, Flex} from '@chakra-ui/react';
import colors from './Colors';

import './ColorKey.css';

const ColorKey = () => {
  return (
    <Box p={2}>
      <Box
        className="color-key"
        position="static"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        flexWrap="wrap"
        columnGap="2"      
      >
        <Flex
          flexGrow="1"
          justifyContent="center"
          alignItems="center"
          gap={2}
          minH="44px"
        >
          <Box>Good for bowling</Box>
          <Flex
            position="relative"
            top="1px"
            borderTopLeftRadius={8}
            borderBottomLeftRadius={8}
            borderTopRightRadius={8}
            borderBottomRightRadius={8}
            overflow="hidden"
          >
            {colors.map((_, idx, cols) => {
              const colorBoxValue = cols[cols.length - 1 - idx];
              return (
              <Box
                key={`colorbox_${idx}`}
                className="color-box"
                h="12px"
                bg={colorBoxValue}
              >
              </Box>);
            })}
          </Flex>
          <Box>Good for batting</Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default ColorKey;