import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

const checkDateFilter = (filterValue = '~', value = '~') => {
  const [start1Str, end1Str] = filterValue.split('~');
  const [start2Str, end2Str] = value.split('~');

  const start1 = start1Str ? dayjs(start1Str) : dayjs('1900-01-01');
  const end1 = end1Str ? dayjs(end1Str) : dayjs('9999-12-31');
  const start2 = start2Str ? dayjs(start2Str) : dayjs('1900-01-01');
  const end2 = end2Str ? dayjs(end2Str) : dayjs('9999-12-31');

  return start1.isSameOrBefore(end2) && start2.isSameOrBefore(end1);
};

export default checkDateFilter;
