const { Flex, Box } = require('@chakra-ui/react');

const FlexHeaderScroll = ({ header, content, contentBoxProps }) => {
  return (
    <Flex direction="column" h="100%">
      {header}
      <Flex
        flexGrow="1"
        direction="column"
        overflow="auto"
        visibility="hidden"
        _hover={{ visibility: 'visible' }}
        _focusVisible={{ visibility: 'visible' }}
      >
        <Box flexGrow="1" visibility="visible" {...contentBoxProps}>
          {content}
        </Box>
      </Flex>
    </Flex>
  );
};

export default FlexHeaderScroll;
