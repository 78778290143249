import { Flex, Box } from '@chakra-ui/react';
import TeamImage from '_shared/components/TeamImage';
import RouterLink from '_shared/components/Utils/RouterLink';

const TeamHeader = ({ img, teamName, id }) => {
  return (
    <Flex gap={3} alignItems="center">
      <Box>
        <TeamImage team={{ logo: img, id }} type="circle" size={48} />
      </Box>
      <RouterLink fontSize="20px" to={`/team/${id}`}>
        {teamName}
      </RouterLink>
    </Flex>
  );
};
export default TeamHeader;
