import PanelText from './PanelText'; // 1
import PanelTable from './PanelTable'; // 2
import VideoPanel from './VideoPanel/VideoPanel'; // 4, 21
import PanelBarChart from './PanelBarChart'; // 7, 10
import LineChart from './LineChart'; // 8
import PanelPieChart from './PanelPieChart'; // 9
// import LiveStream from './LiveStream'; // 11
import Score from './Score/Score'; // 12
import LiveScorecard from './LiveScorecard/LiveScorecard'; // 13
import BBBOversComms from './BBBOversComms/BBBOversComms'; // 14
// import WinVizPanel from './WinVizPanel'; // 15
import WinVizWidget from '././WidgetPanels/WinVizWidget'; // 15
import ScorecardWidget from './WidgetPanels/ScorecardWidget'; // 16
import FixturesPanel from './FixturesPanel/FixturesPanel'; // 17, 25, 28
import UserFavouritesPanel from './UserFavouritesPanel/UserFavouritesPanel'; // 18
// import SquadPlayers from './SquadPlayers'; // 19
import TeamSquads from './TeamSquads'; // 19
import RecentlyViewedPanel from './RecentlyViewPanel/RecentlyViewedPanel'; // 20
import CompLadderFixturesPanel from './CompLadder/CompLadderFixturesPanel'; // 22
import PlayerStats from './PlayerStats/PlayerStats'; // 23
import CompLadderPanel from './CompLadder/CompLadderPanel'; // 24, 27
import CompetitionWinViz from './CompetitionWinViz'; // 26
import EventHistory from './EventHistory/EventHistory'; // 29
import EventCountDown from './EventCountDown'; // 30
import PreMatchRecentForm from './PreMatchRecentForm'; // 31
import PreMatchConditions from './PreMatchConditions/PreMatchConditions'; // 32
import MatchPitchViz from './MatchPitchViz'; // 33
import TeamHomeGrounds from './TeamHomeGrounds'; // 34
import TeamPageSquadPanel from './TeamPageSquadPanel'; // 35, 36
import CricVizWidget from './WidgetPanels/CricVizWidget'; // 37
import CaptaincyGridPanel from './CaptaincyGridPanel'; // 42
import CricVizCareerWidget from './WidgetPanels/CricVizCareerWidget'; // 43

const PanelTypeDefinitions = {
  1: PanelText,
  2: PanelTable,
  4: VideoPanel,
  7: PanelBarChart,
  8: LineChart,
  9: PanelPieChart,
  10: PanelBarChart,
  // 11: LiveStream,
  12: Score,
  13: LiveScorecard,
  14: BBBOversComms,
  15: WinVizWidget,
  16: ScorecardWidget,
  17: FixturesPanel,
  18: UserFavouritesPanel,
  19: TeamSquads,
  20: RecentlyViewedPanel,
  21: VideoPanel,
  22: CompLadderFixturesPanel,
  23: PlayerStats,
  24: CompLadderPanel,
  25: FixturesPanel,
  26: CompetitionWinViz,
  27: CompLadderPanel,
  28: FixturesPanel,
  29: EventHistory,
  30: EventCountDown,
  31: PreMatchRecentForm,
  32: PreMatchConditions,
  33: MatchPitchViz,
  34: TeamHomeGrounds,
  35: TeamPageSquadPanel,
  36: TeamPageSquadPanel,
  37: CricVizWidget,
  41: FixturesPanel,
  42: CaptaincyGridPanel,
  43: CricVizCareerWidget
};

export const getAvailablePanelTypeIds = () => {
  return Object.keys(PanelTypeDefinitions);
};

export default PanelTypeDefinitions;
