import { Box, Flex, Button, useToast } from '@chakra-ui/react';
import { useMatch } from '@tanstack/react-location';
import { useRef } from 'react';
import { Player, ControlBar } from 'video-react';
import VideoControlBar from 'pages/videoSearch/components/VideoControlBar';
import { handlePermissions } from '_shared/utils/utilFunctions';
import FullScreenAuthError from '_shared/errors/FullScreenAuthError';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import { DownloadOutline24, DuplicateOutline24 } from '_icons/outline/24';

async function downloadFile(url) {
  try {
    // Fetch the video data
    const response = await fetch(url);
    const videoBlob = await response.blob();
    const splitArr = url.split('/');
    const videoName = splitArr.pop();
    // Create a temporary anchor element
    const link = document.createElement('a');

    // Create a URL for the Blob object
    const objectURL = URL.createObjectURL(videoBlob);

    // Set the anchor's href to the URL
    link.href = objectURL;

    // Set the download attribute to suggest a filename
    link.download = videoName;

    // Hide the anchor element
    link.style.display = 'none';

    // Append the anchor to the document body
    document.body.appendChild(link);

    // Programmatically trigger a click event to download the video
    link.click();

    // Clean up
    URL.revokeObjectURL(objectURL);
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading video:', error);
  }
}

const WatchFinishedPlaylist = () => {
  const {
    params: { encodedURL }
  } = useMatch();
  const videoRef = useRef();
  const { data: userData = {} } = useUserDetails();
  const { permissions } = userData;
  const VCPerm = handlePermissions(10, permissions);
  const toast = useToast();
  if (!VCPerm) {
    return <FullScreenAuthError />;
  }
  const downloadURL = decodeURIComponent(encodedURL);

  return (
    <Box
      bg="black"
      width="100%"
      height="100%"
      position="absolute"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box maxW="60%" minW="50%">
        {videoRef && (
          <Player
            src={downloadURL}
            className="video-player-panel"
            playsInline={true}
            autoPlay={true}
            controls={false}
            ref={videoRef}
          >
            <ControlBar autoHide={2000} disableDefaultControls={true} className="video-control-bar">
              {videoRef?.current && <VideoControlBar videoRef={videoRef} />}
            </ControlBar>
          </Player>
        )}
        <Flex mt={4}>
          <Button
            h="44px"
            w="239px"
            variant={'primary'}
            fontWeight="700"
            display="flex"
            gap="1"
            p={2}
            onClick={() => {
              downloadFile(downloadURL);
            }}
          >
            <Flex fontWeight="600" align="center" gap="1">
              <DownloadOutline24 />
              <Box as="span">Download playlist</Box>
            </Flex>
          </Button>
          <Button
            h="44px"
            w="239px"
            variant={'secondaryDark'}
            fontWeight="700"
            display="flex"
            gap="1"
            ml={3}
            p={2}
            onClick={() => {
              navigator.clipboard.writeText(downloadURL);
              toast({
                title: `Copy link`,
                position: 'bottom-left',
                description: `Link copied to clipboard`,
                status: 'success',
                duration: 5000,
                isClosable: true
              });
            }}
          >
            <Flex fontWeight="600" align="center" gap="1">
              <DuplicateOutline24 />
              <Box as="span">Copy link</Box>
            </Flex>
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default WatchFinishedPlaylist;
