import {
  Box,
  Flex,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  Center,
  TabList,
  Text,
  CircularProgress,
  CircularProgressLabel
} from '@chakra-ui/react';

import {
  Radar,
  RadarChart,
  Tooltip,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ReferenceLine,
  Label
} from 'recharts';

const changeElNames = {
  ground_profile: 'Historical Innings',
  history: 'Entire Match',
  session: 'By Session'
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Box>
        {payload.map((el) => {
          return (
            <Text key={`${el.name}-${el.value}`} color={el.color}>{`${el.name.split('_')[0]} : ${
              el.value
            }`}</Text>
          );
        })}
        <p className="label"></p>
      </Box>
    );
  }

  return null;
};
function customPadding({ payload, x, y, textAnchor, stroke, radius }) {
  let cx = x;
  let cy = y;
  if (payload.value === 'Deviation') {
    cx += 10;
  }
  if (payload.value === 'Swing' || payload.value === 'DeviationPace') {
    cy = cy + (cy - radius) * 0.04;
  }
  return (
    <g className="recharts-layer recharts-polar-angle-axis-tick">
      <text
        radius={radius}
        stroke={stroke}
        y={cy}
        x={cx}
        className="recharts-text recharts-polar-angle-axis-tick-value"
        text-anchor={textAnchor}
      >
        <tspan x={cx} dy="0em">
          {payload.value}
        </tspan>
      </text>
    </g>
  );
}
const DataRadarChart = ({ data }) => {
  return (
    <RadarChart height={260} width={450} outerRadius="70%" data={data}>
      <PolarGrid />
      <PolarAngleAxis tick={customPadding} dataKey="name" />
      <PolarRadiusAxis domain={[0, 10]} />
      <Radar dataKey="x" stroke="blue" fill="blue" fillOpacity={0.5} />
    </RadarChart>
  );
};

const HistoricalLineChart = ({ data, inningsBreakPoints, dayBreakPoints }) => {
  return (
    <LineChart
      data={data}
      margin={{ top: 15, left: 0, right: 10, bottom: 15 }}
      width={500}
      height={275}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip content={<CustomTooltip />} />
      <XAxis
        dataKey={
          inningsBreakPoints.length === 0 && dayBreakPoints.length === 0
            ? 'session_day'
            : 'join_key'
        }
        hide={
          (inningsBreakPoints.length !== 0 && dayBreakPoints.length !== 0) ||
          inningsBreakPoints.length === 1
        }
      />
      <YAxis domain={[0, 10]} />
      <Legend wrapperStyle={{ bottom: -10, left: 25 }} />
      {inningsBreakPoints.map((el) => {
        return (
          <ReferenceLine x={el.x} stroke="blue" key={`${el.x}-inn`} strokeDasharray="3 3">
            <Label position={'top'} fontSize="0.75vw" stroke="blue" strokeWidth={0.3}>
              {el.label}
            </Label>
          </ReferenceLine>
        );
      })}
      {dayBreakPoints.map((el) => {
        return (
          <ReferenceLine x={el.x} stroke="black" dot={true} strokeWidth={1} strokeDasharray="3 3">
            <Label position={'bottom'} fontSize="0.75vw" stroke="black" strokeWidth={0.3}>
              {el.label}
            </Label>
          </ReferenceLine>
        );
      })}

      <Line dataKey="bounce" stroke="#332288" strokeWidth={1} dot={false} />
      <Line dataKey="pitchviz_overall" stroke="blue" strokeWidth={1} dot={false} />
      <Line dataKey="deviation" stroke="red" strokeWidth={1} dot={false} />
      <Line dataKey="pace" stroke="#117733" strokeWidth={1} dot={false} />
    </LineChart>
  );
};

function convertToPercentage(val) {
  return parseFloat(val ? val : 0) * 10;
}

function ordinal(el) {
  const j = el % 10,
    k = el % 100;
  if (j === 1 && k !== 11) {
    return `${el}st`;
  }
  if (j === 2 && k !== 12) {
    return `${el}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${el}rd`;
  }
  return `${el}th`;
}

const PitchVizPaceSpinComponent = ({ data }) => {
  const relKeys = ['bounce_consistency', 'deviation', 'swing', 'wicket_pace'];

  return (
    <>
      <Flex direction="row">
        {data.map((el, idx) => {
          const { pitchviz_overall } = el;
          const OverallPVPercentage = convertToPercentage(pitchviz_overall);
          return (
            <Flex key={idx} direction="column" w={`${100 / data.length}%`}>
              <Center>
                <Box pr={2}>{el.bowling_type ? el.bowling_type : 'pitch'} rating</Box>
                <CircularProgress
                  value={OverallPVPercentage}
                  color={el.bowling_type === 'pace' ? 'blue.400' : 'green.400'}
                  size="50px"
                >
                  <CircularProgressLabel fontSize="0.7vw" fontWeight="bold">
                    {pitchviz_overall}
                  </CircularProgressLabel>
                </CircularProgress>
              </Center>
            </Flex>
          );
        })}
      </Flex>
      <Box h="80%" w="100%" pt={0.1} overflowY="scroll">
        {relKeys.map((keyName, idx) => {
          return (
            <Box key={`panel-idx-${idx}`} h={`${100 / relKeys.length}%`} fontSize="0.7vw">
              <Box>
                {keyName === 'bounce_consistency'
                  ? 'bounce inconsistency'
                  : keyName.replaceAll('_', ' ')}
              </Box>
              <Flex>
                <Flex direction="column" w="95%">
                  {data.map((elVal, idx) => {
                    return (
                      <Box
                        key={idx}
                        w={`${convertToPercentage(elVal[keyName])}%`}
                        h="12px"
                        bg={elVal.bowling_type === 'pace' ? 'blue.400' : 'green.400'}
                        mb={0.5}
                        mt={0.5}
                      ></Box>
                    );
                  })}
                </Flex>
                <Flex direction="column" w="5%">
                  {data.map((elVal, idx) => {
                    return <Box key={idx}>{elVal[keyName]}</Box>;
                  })}
                </Flex>
              </Flex>
            </Box>
          );
        })}
        <Box fontSize="xs" mt={2} color="black.100">
          * The higher the value, the more difficult that aspect of the pitch is for the batter
        </Box>
      </Box>
    </>
  );
};

const CurrentPitchViz = ({ current }) => {
  if (current.length === 0) {
    return null;
  }
  const currentSession = current[0];
  const {
    bounce,
    bounce_consistency,
    bounce_threat,
    deviation,
    deviation_pace,
    deviation_spin,
    swing,
    swing_pace,
    swing_spin,
    wicket_pace,
    wicket_pace_threat,
    pitchviz_trend,
    pitchviz_overall
  } = currentSession;
  const data = [
    { name: 'Bounce', x: bounce },
    { name: 'BounceInconsistency', x: bounce_consistency },
    { name: 'BounceThreat', x: bounce_threat },
    { name: 'Deviation', x: deviation },
    { name: 'DeviationSpin', x: deviation_spin },
    { name: 'DeviationPace', x: deviation_pace },
    { name: 'Swing', x: swing },
    { name: 'SwingPace', x: swing_pace },
    { name: 'SwingSpin', x: swing_spin },
    { name: 'WicketPace', x: wicket_pace },
    { name: 'WicketPaceThreat', x: wicket_pace_threat }
  ];
  return (
    <Box>
      <Flex>
        <Flex w="50%" direction="column" fontSize="0.9vw">
          <Text fontWeight="bold">Trend</Text>
          {pitchviz_trend}
        </Flex>
        <Flex w="50%" direction="column" pl={2} textAlign="right" fontSize="0.9vw">
          <Text>
            <Text fontWeight="bold">Pitch Rating </Text>
            {pitchviz_overall}
          </Text>
        </Flex>
      </Flex>
      <Center>
        <DataRadarChart data={data} />
      </Center>
    </Box>
  );
};

const GroundPitchViz = ({ ground }) => {
  const inningsSplit = ground.reduce((inningsSplit, item) => {
    const group = inningsSplit[item.innings_number] || [];
    group.push(item);
    inningsSplit[item.innings_number] = group;
    return inningsSplit;
  }, {});
  return (
    <Tabs orientation="vertical">
      <TabList>
        {Object.keys(inningsSplit).map((key, idx) => {
          return (
            <Tab key={`key-${idx}`} fontSize="0.8vw">
              {ordinal(parseInt(key))}
            </Tab>
          );
        })}
      </TabList>
      <TabPanels>
        {Object.keys(inningsSplit).map((key, idx) => {
          return (
            <TabPanel key={`tab-pv-panel-${idx}`} pt={0} pl={0}>
              <PitchVizPaceSpinComponent data={inningsSplit[key]} />
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
};

const HistoryPitchViz = ({ history }) => {
  const refinedData = [];
  const daySplit = [];
  const inningsSplit = [];
  const dataList = history.sort((a, b) => a.join_key - b.join_key);
  let currentDay = dataList.length > 0 ? dataList[0].day_number : null;
  let currentInnings = dataList.length > 0 ? dataList[0].innings_number : null;

  dataList.forEach((el) => {
    refinedData.push({
      bounce: el.bounce,
      pitchviz_overall: el.pitchviz_overall,
      join_key: el.join_key,
      deviation: el.deviation,
      pace: el.wicket_pace
    });
    if (el.innings_number && parseInt(el.innings_number) !== currentInnings) {
      inningsSplit.push({ x: el.join_key, label: `${ordinal(parseInt(el.innings_number))} inn` });
      currentInnings += 1;
    }
    if (el.day_number && parseInt(el.day_number) !== currentDay) {
      daySplit.push({ x: el.join_key, label: `Day ${el.day_number}` });
      currentDay += 1;
    }
  });

  return (
    <HistoricalLineChart
      data={refinedData}
      inningsBreakPoints={inningsSplit}
      dayBreakPoints={daySplit}
    />
  );
};

const SessionPitchViz = ({ session }) => {
  const refinedData = [];
  const dataList = session.sort(
    (a, b) => a.day_number - b.day_number || a.session_number - b.session_number
  );

  dataList.forEach((el) => {
    refinedData.push({
      bounce: el.bounce,
      pitchviz_overall: el.pitchviz_overall,
      session_day: `D${el.day_number} S${el.session_number}`,
      deviation: el.deviation,
      pace: el.wicket_pace
    });
  });
  return <HistoricalLineChart data={refinedData} inningsBreakPoints={[]} dayBreakPoints={[]} />;
};

const DayPitchViz = ({ day }) => {
  const inningsSplit = day.reduce((inningsSplit, item) => {
    const group = inningsSplit[item.day_number] || [];
    group.push(item);
    inningsSplit[item.day_number] = group;
    return inningsSplit;
  }, {});
  if (Object.keys(inningsSplit).length === 1) {
    return <PitchVizPaceSpinComponent data={inningsSplit[Object.keys(inningsSplit)[0]]} />;
  }
  return (
    <Tabs orientation="vertical">
      <TabList>
        {Object.keys(inningsSplit).map((key, idx) => {
          return (
            <Tab key={`key-${idx}`} fontSize="0.8vw">
              {ordinal(parseInt(key))}
            </Tab>
          );
        })}
      </TabList>
      <TabPanels>
        {Object.keys(inningsSplit).map((key, idx) => {
          return (
            <TabPanel key={`tab-pv-panel-${idx}`}>
              <PitchVizPaceSpinComponent data={inningsSplit[key]} />
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
};

const MatchPitchViz = ({ panelData }) => {
  const PitchVizTypes = Object.keys(panelData.data);
  const { session } = panelData.data;
  if (session && session.length === 1) {
    const idx = PitchVizTypes.indexOf('session');
    if (idx > -1) {
      PitchVizTypes.splice(idx, 1);
    }
  }
  if (PitchVizTypes.length === 1) {
    return (
      <Box>
        <Center fontWeight="bold" mb={2}>
          {Object.keys(changeElNames).includes(PitchVizTypes[0])
            ? changeElNames[PitchVizTypes[0]]
            : `${PitchVizTypes[0].slice(0, 1).toUpperCase()}${PitchVizTypes[0]
                .split('_')[0]
                .slice(1)}`}
        </Center>
        {PitchVizTypes[0] === 'ground_profile' ? (
          <GroundPitchViz ground={panelData.data[PitchVizTypes[0]]} />
        ) : PitchVizTypes[0] === 'current' ? (
          <CurrentPitchViz current={panelData.data[PitchVizTypes[0]]} />
        ) : PitchVizTypes[0] === 'day' ? (
          <DayPitchViz day={panelData.data[PitchVizTypes[0]]} />
        ) : PitchVizTypes[0] === 'history' ? (
          <HistoryPitchViz history={panelData.data[PitchVizTypes[0]]} />
        ) : PitchVizTypes[0] === 'session' ? (
          <SessionPitchViz session={panelData.data[PitchVizTypes[0]]} />
        ) : null}
      </Box>
    );
  } else if (PitchVizTypes.length > 1) {
    return (
      <Box w="100%" h="100%">
        <Tabs>
          <TabList>
            {PitchVizTypes.map((el, idx) => {
              return (
                <Tab key={`pv-tab-${idx}`} fontSize="0.8vw">
                  {Object.keys(changeElNames).includes(el)
                    ? changeElNames[el]
                    : `${el.slice(0, 1).toUpperCase()}${el.split('_')[0].slice(1)}`}
                </Tab>
              );
            })}
          </TabList>
          <TabPanels>
            {PitchVizTypes.map((el, idx) => {
              return (
                <TabPanel key={`pv-panel-${idx}`}>
                  {el === 'ground_profile' ? (
                    <GroundPitchViz ground={panelData.data[el]} />
                  ) : el === 'current' ? (
                    <CurrentPitchViz current={panelData.data[el]} />
                  ) : el === 'day' ? (
                    <DayPitchViz day={panelData.data[el]} />
                  ) : el === 'history' ? (
                    <HistoryPitchViz history={panelData.data[el]} />
                  ) : el === 'session' ? (
                    <SessionPitchViz session={panelData.data[el]} />
                  ) : null}
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Box>
    );
  }
};

export default MatchPitchViz;
