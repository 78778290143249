import { createIcon } from '@chakra-ui/icons';

const CricketBall = createIcon({
  displayName: 'CricketBall',
  viewBox: '0 0 20 20',
  defaultProps: { width: '20px', height: '20xp' },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 17C13.866 17 17 13.866 17 10C17 8.79445 16.6952 7.66008 16.1586 6.66973L15.7451 7.08318L15.038 6.37607L15.6063 5.80776C15.2048 5.27171 14.7283 4.79514 14.1922 4.39366L13.624 4.96186L12.9169 4.25475L13.3303 3.84141C12.3399 3.30475 11.2055 3 10 3C6.13401 3 3 6.13401 3 10C3 11.2055 3.30475 12.3399 3.84141 13.3303L4.25487 12.9168L4.96197 13.6239L4.39366 14.1922C4.79514 14.7283 5.27171 15.2048 5.80776 15.6063L6.37596 15.0381L7.08307 15.7452L6.66973 16.1586C7.66008 16.6952 8.79445 17 10 17ZM10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19ZM12.5631 8.85095L13.8006 7.61351L14.5077 8.32062L13.2703 9.55805L12.5631 8.85095ZM10.0883 11.3258L11.3257 10.0884L12.0328 10.7955L10.7954 12.0329L10.0883 11.3258ZM7.6134 13.8007L8.85083 12.5633L9.55794 13.2704L8.3205 14.5078L7.6134 13.8007ZM11.6795 5.49219L10.4421 6.72962L11.1492 7.43673L12.3866 6.19929L11.6795 5.49219ZM9.20462 7.96706L7.96718 9.2045L8.67428 9.9116L9.91172 8.67417L9.20462 7.96706ZM6.72974 10.4419L5.4923 11.6794L6.19941 12.3865L7.43685 11.149L6.72974 10.4419Z"
      fill="currentColor"
    />
  )
});

export default CricketBall;
