import { Box, Flex, Icon } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { MdOutlineHistory, MdOutlineVisibility } from 'react-icons/md';

const ViewsSinceDisplay = ({ item }) => {
  const { last_accessed, page_count } = item;
  return (
    <Flex
      h="7"
      w="max-content"
      alignItems="center"
      rounded="full"
      color="primary.shade.50"
      bg="primary.tint.90"
      border="1px"
      borderColor="primary.tint.80"
      gap="3"
      px="3"
      fontSize="xs"
    >
      <Flex gap="1" alignItems="center">
        <Icon as={MdOutlineVisibility} />
        <Box>{page_count}</Box>
      </Flex>
      <Flex gap="1" alignItems="center">
        <Icon as={MdOutlineHistory} />
        <Box>{dayjs(last_accessed).fromNow()}</Box>
      </Flex>
    </Flex>
  );
};

export default ViewsSinceDisplay;
