import { Box, Text } from '@chakra-ui/react';
import ReactTable from '_shared/components/ReactTable/ReactTable';
import isEqual from 'lodash.isequal';
import { memo, useState } from 'react';
import Header from '../_components/header/Header';
import HeaderInfo from '../_components/header/HeaderInfo';
import getContentConfig from '../contentConfig/getContentConfig';

const TableContent = ({ content }) => {
  const { data, key, header } = content;
  const { table_header, table_data } = data || {};
  const [infoOpen, setInfoOpen] = useState(false);

  const tableInfoText = getContentConfig(key, 'tableInfoText');
  const tableUseLargeRows = getContentConfig(key, 'tableUseLargeRows');
  const stickyColumn = getContentConfig(key, 'stickyColumn');

  const columnsNumeric = [];
  if (table_header) {
    table_header.forEach((headerEl) => {
      const columnIsNumeric = table_data.every((dataEl) => {
        const dataParam = dataEl?.[headerEl.key];
        return typeof dataParam === 'number' || dataParam == null || !isNaN(parseFloat(dataParam));
      });
      columnsNumeric.push(columnIsNumeric);
    });
  }

  const columns = [];
  if (table_header) {
    table_header.forEach((el, index) => {
      columns.push({
        Header: el.label,
        accessor: el.key,
        tipText: el.tooltip || '',
        videoKey: el.video_key,
        linkKey: el.link_key,
        isNumeric: columnsNumeric?.[index]
      });
    });
  }

  const rowPaddingY = tableUseLargeRows ? '22px' : null;
  return (
    <Box mb="1">
      <Header
        title={header}
        showInfoButton={!!tableInfoText}
        infoOpen={infoOpen}
        setInfoOpen={setInfoOpen}
      />
      <Box position="relative">
        <ReactTable
          columns={columns}
          data={table_data}
          rowPaddingY={rowPaddingY}
          stickyColumn={stickyColumn}
        />
        <HeaderInfo infoOpen={infoOpen}>
          {tableInfoText && <Text key={`${key}_info`}>{tableInfoText}</Text>}
        </HeaderInfo>
      </Box>
    </Box>
  );
};

const memoFunction = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default memo(TableContent, memoFunction);
