import { createIcon } from '@chakra-ui/icons';

const CricketBat = createIcon({
  displayName: 'CricketBat',
  viewBox: '0 0 20 20',
  defaultProps: { width: '20px', height: '20xp' },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.40362 11.5107L13.7676 5.14679L15.1818 6.561L8.81783 12.925L7.40362 11.5107ZM13.0605 3.02547C13.451 2.63494 14.0842 2.63494 14.4747 3.02547L17.3031 5.85389C17.6936 6.24442 17.6936 6.87758 17.3031 7.26811L9.52494 15.0463C9.13441 15.4368 8.50125 15.4368 8.11072 15.0463L5.28229 12.2179C4.89177 11.8273 4.89177 11.1942 5.28229 10.8036L13.0605 3.02547ZM5.99031 15.753L6.69741 15.0459L5.2832 13.6317L4.57609 14.3388L2.45477 16.4601C2.06425 16.8506 2.06425 17.4838 2.45477 17.8743C2.8453 18.2649 3.47846 18.2649 3.86899 17.8743L5.99031 15.753Z"
      fill="currentColor"
    />
  )
});

export default CricketBat;
