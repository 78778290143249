import { Center, Spinner, Box } from '@chakra-ui/react';
import { getVideoFavourites } from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import VideoCentreContainer from './VideoCentreContainer';

const VideoCentre = () => {
  const dataType = 'video';
  const {
    isLoading: objectIsLoading,
    error: objectError,
    data: objectData,
    isFetching: objectIsFetching
  } = useQueryFetch([dataType], () => getVideoFavourites(dataType));

  if (objectIsLoading) {
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    );
  }
  if (objectError && !objectIsFetching) {
    return <Box>{`Unable to retrieve live match data`}</Box>;
  }
  if (objectData) {
    return <VideoCentreContainer rawData={objectData} />;
  }
};

export default VideoCentre;
