import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Image,
  Show,
  Box
} from '@chakra-ui/react';
import { Link } from '@tanstack/react-location';
import './PlayerHomePage.css';

const ignoreTableEls = ['player', 'player_id'];
const keysOrder = {
  batting: [
    'team',
    'mat',
    'Inns',
    'NO',
    'runs',
    'bf',
    'hs',
    'avg',
    'SR',
    'DisR',
    'Bound%',
    'Dot%',
    '100s',
    '50s',
    '0s',
    '4s',
    '6s'
  ],
  bowling: [
    'team',
    'Mat',
    'Inns',
    'Wkts',
    'Runs',
    'Balls',
    'bbi',
    'bbm',
    'avg',
    'eR',
    'sR',
    'Dot%',
    'Bound%',
    '5W',
    '10W'
  ]
};

function organiseArray(raw_data) {
  const data = [];
  raw_data.forEach((el) => {
    if (!ignoreTableEls.includes(el)) {
      data.push(el.replaceAll('_', ' '));
    }
  });
  return data;
}

const PlayerDataTable = ({ data, caption, playerImg, tableType }) => {
  if (data.length === 0) {
    return null;
  }

  const rawHeaders = keysOrder[tableType];
  const headers = organiseArray(rawHeaders);
  return (
    <TableContainer
      maxHeight="400px"
      overflowY="auto"
      overflowX="auto"
      width="80%"
      alignContent="center"
    >
      <Table
        variant="simple"
        className="table-tiny"
        size="sm"
        background="white"
        border="1px"
        borderColor="cricket.50"
        boxShadow="lg"
      >
        <Thead>
          <Tr className="first">
            <Th colSpan={headers.length + 2}>{caption}</Th>
          </Tr>
          <Tr className="second">
            <Th colSpan={2}>Player</Th>
            {headers.map((el) => {
              return <Th key={`${el}-header`}>{el}</Th>;
            })}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((el) => {
            return (
              <Tr key={`${caption}-${el.player_id}`}>
                <Td>
                  <Show breakpoint="(min-width: 1100px)">
                    <Box width="60px">
                      <Image src={playerImg[el.player_id]} boxSize="60px" objectFit="cover" m="1" />
                    </Box>
                  </Show>
                </Td>
                <Th>
                  <Link to={`/player/${el.player_id}`} className="playerName">
                    {el.player}
                  </Link>
                </Th>
                {rawHeaders.map((objKey) => {
                  return <Td key={`${caption}-${el.player_id}-${objKey}`}>{el[objKey]}</Td>;
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default PlayerDataTable;
