import { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useMatch, useSearch } from '@tanstack/react-location';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import PageInfoHeader from './PageInfoHeader';
import PageFilterHeader from './PageFilterHeader';
import ContentContainer from './ContentContainer';
import { getObjectInfo } from '_shared/dataFetching/apiServiceV2';
import AdvancedContainer from './AdvancedContainer';
import useElementSize from '@custom-react-hooks/use-element-size';
import ContentError from './ContentError';
import ContentLoading from './ContentLoading';

const getInfoHeaderSize = (tab, type) => {
  if (type === 'player' && (!tab || tab === 'profile')) return 'large';
  if (type === 'ground' && (!tab || tab === 'summary')) return 'large';
  if (type === 'match' && (!tab || tab === 'summary' || tab === 'squad')) return 'large';
  return 'small';
};

const pageNavBarHeight = 64;

const PageContainer = ({ type }) => {
  const {
    params: { objectId }
  } = useMatch();
  const search = useSearch();
  const { tab, class_id, role } = search;
  const {
    isLoading: objectIsLoading,
    error: objectError,
    data: objectData,
    isFetching: objectIsFetching
  } = useQueryFetch(['info', type, objectId], () => getObjectInfo(type, objectId), {
    staleTime: 30 * 1000
  });

  const [headerRef1, headerSize1] = useElementSize();
  const [headerRef2, headerSize2] = useElementSize();

  const [headersTakenHeight, setHeadersTakenHeight] = useState(
    pageNavBarHeight + headerSize1.height + headerSize2.height
  );

  useEffect(() => {
    setHeadersTakenHeight(pageNavBarHeight + headerSize1.height + headerSize2.height);
  }, [headerSize1.height, headerSize2.height]);

  if (objectIsLoading) {
    return <ContentLoading />;
  }
  if (objectError && !objectIsFetching) {
    return (
      <ContentError
        title="We’re sorry, an error has occurred"
        subtitle={`Unable to retrieve header data for ${type} ${objectId}: ${objectError.message}`}
      />
    );
  }

  const objectVariable = type !== 'player' ? type : role;
  let selectionKey = `${objectVariable}`;
  if (class_id) {
    selectionKey += `_${class_id}`;
  }

  const advancedTab = ['advanced', 'advanced_batting', 'advanced_bowling'].includes(tab);

  const infoHeaderSize = getInfoHeaderSize(tab, type);

  const updateHeadersTakenHeight = (e) => {
    const { scrollTop } = e.target;
    setHeadersTakenHeight(
      pageNavBarHeight +
        Math.max(headerSize2.height, headerSize1.height + headerSize2.height - scrollTop)
    );
  };

  if (objectData) {
    return (
      <Box
        h="100%"
        overflowY="scroll"
        onScroll={updateHeadersTakenHeight}
        display="flex"
        flexDirection="column"
      >
        <Box zIndex="20" ref={headerRef1}>
          <PageInfoHeader objectData={objectData} type={type} size={infoHeaderSize} />
        </Box>
        <Box zIndex="20" position="sticky" top="0" ref={headerRef2}>
          <Box bg="white.100">
            <PageFilterHeader objectData={objectData} type={type} />
          </Box>
        </Box>
        <Box flex="1">
          {advancedTab && (
            <AdvancedContainer
              type={type}
              key={`advanced_${selectionKey}`}
              headersTakenHeight={headersTakenHeight}
            />
          )}
          {!advancedTab && <ContentContainer type={type} key={`content_${selectionKey}`} />}
        </Box>
      </Box>
    );
  }
  return null;
};

export default PageContainer;
