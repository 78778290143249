import { Flex, Box, Center } from '@chakra-ui/react';

export const getColor = (value, type) => {
  if (value?.toLowerCase().includes('w') && !value?.toLowerCase().includes('d')) {
    return `ballScore.w.${type}`;
  }
  if (parseInt(value) > 4) {
    return `ballScore.6.${type}`;
  }
  if (parseInt(value) === 4) {
    return `ballScore.4.${type}`;
  }
  if (parseInt(value) > 0) {
    return `ballScore.r.${type}`;
  }

  return `ballScore.nr.${type}`;
};

export const selectedButton = (label, value, col, boldOff) => {
  return (
    <Flex ml={2} fontWeight={100}>
      <Center color={col === 'dark' ? 'white' : null}>{label}</Center>
      <Box
        bg={col === 'dark' || col === 'white' ? null : 'grey.200'}
        ml={2}
        p={1}
        borderRadius="sm"
        color={col === 'dark' ? 'white' : null}
        fontWeight={!boldOff ? 'bold' : '400'}
      >
        {value}
      </Box>
    </Flex>
  );
};

export const filtersConversion = (valObj, filtersObj, col) => {
  const returnVals = [];

  for (let k = 0; k < Object.keys(valObj).length; k += 1) {
    const key = Object.keys(valObj)[k];

    const val = valObj[key];
    const filterDict = filtersObj.filter((el) => el.api_tag === key)[0];

    if (['multiselect', 'checkbox'].includes(filterDict.type)) {
      const filterLabels = [];
      filterDict.options
        .filter((el) => val.includes(el.value))
        .forEach((el) => filterLabels.push(el.label));

      returnVals.push(
        selectedButton(
          `${filterDict.label[0].toUpperCase()}${filterDict.label.slice(1)}`,
          filterLabels.join(', '),
          col
        )
      );
    } else if (val.includes(`~`)) {
      const min = val.split('~')[0];
      const max = val.split('~')[1];
      if (min && max) {
        returnVals.push(
          selectedButton(
            filterDict.label[0].toUpperCase() + filterDict.label.slice(1),
            `${min} to ${max}`,
            col
          )
        );
      } else if (min) {
        returnVals.push(
          selectedButton(
            filterDict.label[0].toUpperCase() + filterDict.label.slice(1),
            `> ${min}`,
            col
          )
        );
      } else if (max) {
        returnVals.push(
          selectedButton(
            filterDict.label[0].toUpperCase() + filterDict.label.slice(1),
            `< ${max}`,
            col
          )
        );
      }
    }
  }

  return (
    <Flex gap={2} fontWeight={100}>
      {returnVals}
    </Flex>
  );
};
