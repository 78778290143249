import { createIcon } from '@chakra-ui/icons';

const Checkmark = createIcon({
  displayName: 'Checkmark',
  viewBox: '0 0 24 24',
  defaultProps: { width: '24px', height: '24px' },
  path: [
    <rect width="24" height="24" rx="4" fill="currentColor" />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7243 6.31052C21.1051 6.71054 21.0895 7.34352 20.6895 7.72431L9.13393 18.7243C8.93659 18.9122 8.67115 19.0114 8.39898 18.999C8.12681 18.9866 7.87148 18.8637 7.69202 18.6587L3.24758 13.5818C2.8838 13.1662 2.92577 12.5344 3.34132 12.1707C3.75687 11.8069 4.38864 11.8488 4.75242 12.2644L8.51012 16.5568L19.3105 6.2757C19.7105 5.89491 20.3435 5.9105 20.7243 6.31052Z"
      fill="white"
    />
  ]
});

export default Checkmark;
