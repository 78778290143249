import { Grid } from '@chakra-ui/react';
import PlayerCard from './PlayerCard';

const SquadGrid = ({ squadData }) => {
  return (
    <Grid
      gap="2"
      templateColumns={{
        base: 'repeat(auto-fill,minmax(160px, 1fr))',
        md: 'repeat(auto-fill,minmax(200px, 1fr))'
      }}
    >
      {squadData.map((item) => {
        return <PlayerCard item={item} key={item.id} />;
      })}
    </Grid>
  );
};

export default SquadGrid;
