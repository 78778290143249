import { useMatch } from '@tanstack/react-location';
import { useRecoilState } from 'recoil';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import { PAGE_FILTER_DATES } from '_shared/globalState/atoms';
import ChakraReactDatepicker from '../InputComponents/ChakraReactDatepicker';

const DateFilter = ({ type }) => {
  const {
    params: { objectId }
  } = useMatch();
  const [pageFilterDates, setPageFilterDates] = useRecoilState(PAGE_FILTER_DATES);
  useUserDetails({
    onSuccess: (data) => {
      const { user_date_min, user_date_max } = data;
      setPageFilterDates((p) => {
        if (p === null || (p?.type !== type && p?.objectId !== objectId)) {
          return {
            min: user_date_min !== null && user_date_min !== '' ? new Date(user_date_min) : null,
            max: user_date_max !== null && user_date_max !== '' ? new Date(user_date_max) : null,
            type,
            objectId
          };
        }
        return p;
      });
    }
  });

  const setDate = (value, type) => {
    setPageFilterDates((p) => {
      const newValues = { ...p };
      newValues[type] = value;
      return newValues;
    });
  };

  if (!pageFilterDates) {
    return null;
  }

  return (
    <>
      <ChakraReactDatepicker
        selectedDate={pageFilterDates.min}
        onChange={(v) => setDate(v, 'min')}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        popperPlacement="bottom-end"
        placeholder="all"
        maxW="200px"
      />
      <ChakraReactDatepicker
        selectedDate={pageFilterDates.max}
        onChange={(v) => setDate(v, 'max')}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        placeholder="all"
        popperPlacement="bottom-end"
        maxW="200px"
      />
    </>
  );
};

export default DateFilter;
