import { Box, Flex, ListItem, Tooltip, Icon } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-location';
import { MdAccessTime, MdOutlineDateRange } from 'react-icons/md';
import AddUserFavourites from '_shared/components/AddUserFavourites';
import {
  greyCardHover,
  whiteCardHover
} from '_shared/components/cssStylePatterns/cssStylePatterns';
import IconLabel from '_shared/components/IconLabel';
import { parseDateTimeLocal } from '_shared/utils/dateTimeFunctions';
import ViewsSinceDisplay from '../ViewsSinceDisplay';
import TeamScore from './TeamScore';
import { getInningsData } from './utils';
import FixtureStatusHeader from './FixtureStatusHeader';
import { getClassIcon } from 'pages/_shared/components/HideShowModal/utils';
import TeamImage from '../TeamImage';

const MatchFixtureDateTime = ({
  dateTime,
  startTimeLocal,
  sameDay,
  endDay,
  dayNumber,
  matchTitle
}) => {
  const startTime = parseDateTimeLocal(dateTime, 'HH:mm');
  const splitDate = String(new Date(dateTime)).split(' ');
  const splitEndDate = String(new Date(endDay)).split(' ');

  let matchDate;
  if (sameDay) {
    matchDate = `${splitDate[1]} ${splitDate[2]} ${splitDate[3]}`;
  } else {
    if (splitDate[2] === splitEndDate[2]) {
      matchDate = `${splitDate[1]}-${splitEndDate[1]} ${splitEndDate[2]} ${splitEndDate[3]}`;
    } else if (splitDate[3] === splitEndDate[3]) {
      matchDate = `${splitDate[1]} ${splitDate[2]} - ${splitEndDate[1]} ${splitEndDate[2]} ${splitEndDate[3]}`;
    } else {
      matchDate = `${splitDate[1]} ${splitDate[2]} ${splitDate[3]}  - ${splitEndDate[1]} ${splitEndDate[2]} ${splitEndDate[3]}`;
    }
  }
  return (
    <Flex gap="3" alignItems="center" width="100%" color={'black.60'}>
      <Flex gap={3}>
        {(dayNumber && !sameDay) || (matchTitle && matchTitle !== '') ? (
          <Flex gap="1">
            {dayNumber && !sameDay ? <Box fontSize="xs">Day {dayNumber}</Box> : null}
            {matchTitle && matchTitle !== '' && dayNumber && !sameDay ? (
              <Box fontSize="xs"> - </Box>
            ) : null}
            {matchTitle && matchTitle !== '' ? <Box fontSize="xs">{matchTitle}</Box> : null}
          </Flex>
        ) : null}
        <Tooltip
          label={`${startTimeLocal} Local Start Time`}
          isDisabled={!startTimeLocal}
          placement="bottom"
        >
          <Flex gap="2">
            <IconLabel icon={MdOutlineDateRange} label={matchDate} fontSize="xs" />
            <IconLabel icon={MdAccessTime} label={startTime} fontSize="xs" />
          </Flex>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

const BottomLine = ({
  general_class_id,
  international_class_name,
  general_class_name,
  comp_name,
  ground_name
}) => {
  return (
    <Flex pt="2" borderTop="1px" borderColor="black.8" align="center" gap="2">
      <Flex
        rounded="full"
        border="1px"
        borderColor="black.8"
        px="1"
        py="2px"
        fontSize="xs"
        color="black.60"
        alignItems="center"
        gap="1"
        lineHeight="1.3"
      >
        <Icon as={getClassIcon(general_class_id)} />
        <Box fontSize="xs" pr="1px">
          {international_class_name ? international_class_name : general_class_name}
        </Box>
      </Flex>
      <Box fontSize="xs" color={'black.60'}>
        {comp_name || ground_name}
      </Box>
    </Flex>
  );
};

const ForthcomingMatch = ({ matchData, handleClick }) => {
  const {
    id,
    start_datetime_utc,
    start_time_local,
    end_date,
    comp_name,
    ground_name,
    team1_id,
    team1_name,
    team1_logo,
    team2_id,
    team2_name,
    team2_logo,
    last_accessed,
    page_count,
    scheduled_overs,
    day_number,
    general_class_id,
    international_class_name,
    general_class_name,
    title
  } = matchData;
  return (
    <ListItem p="2" {...greyCardHover} onClick={handleClick}>
      <Flex direction="column" flexGrow="1" gap="3">
        <Flex
          direction="row"
          flexGrow="1"
          gap="1"
          borderBottom="1px"
          align="center"
          borderBottomColor="black.8"
          pb="2"
        >
          {start_datetime_utc ? (
            <MatchFixtureDateTime
              dateTime={start_datetime_utc}
              startTimeLocal={start_time_local}
              endDay={end_date}
              sameDay={scheduled_overs !== 0}
              dayNumber={day_number}
              matchTitle={title}
              component="forthcoming"
            />
          ) : null}
          <Box>
            <AddUserFavourites favType="match" favId={id} lightBackground />
          </Box>
        </Flex>

        <Flex fontSize="md" fontWeight="700" gap="2" minH="60px">
          <Flex flex="1" alignItems="center" gap="2">
            {team1_logo ? <TeamImage size={46} team={{ logo: team1_logo, id: team1_id }} /> : null}
            <Box>{`${team1_name || 'TBC'}`}</Box>
          </Flex>
          <Flex flex="1" alignItems="center" justify="flex-end" gap="2">
            <Box textAlign="right">{`${team2_name || 'TBC'}`}</Box>
            {team2_logo ? <TeamImage size={46} team={{ logo: team2_logo, id: team2_id }} /> : null}
          </Flex>
        </Flex>

        <BottomLine
          general_class_id={general_class_id}
          international_class_name={international_class_name}
          general_class_name={general_class_name}
          comp_name={comp_name}
          ground_name={ground_name}
        />

        {last_accessed && page_count ? (
          <ViewsSinceDisplay item={{ last_accessed, page_count }} />
        ) : null}
      </Flex>
    </ListItem>
  );
};

const LiveCompleteMatch = ({ matchData, handleClick }) => {
  const {
    team1_logo,
    team2_logo,
    team1_id,
    team2_id,
    team1_name,
    team2_name,
    team1_abbreviation,
    team2_abbreviation,
    innings,
    comp_name,
    ground_name,
    start_datetime_utc,
    start_time_local,
    scheduled_overs,
    end_date,
    day_number,
    general_class_id,
    international_class_name,
    general_class_name,
    title
  } = matchData;
  const inningsData = getInningsData({
    match: {
      team1_id,
      team2_id,
      team1_name,
      team2_name,
      team1_abbreviation,
      team2_abbreviation,
      team1_logo_url: team1_logo,
      team2_logo_url: team2_logo
    },
    innings
  });
  const batting_first_team_id = innings[0]?.team_id || team1_id;
  const batting_second_team_id = team1_id === batting_first_team_id ? team2_id : team1_id;

  return (
    <ListItem {...whiteCardHover} onClick={handleClick}>
      <FixtureStatusHeader matchData={matchData} favouriteAvailable />
      <Flex px="4" py="2" gap="3" direction="column">
        {start_datetime_utc ? (
          <Box pb="2" borderBottom={'1px'} borderBottomColor={'black.8'}>
            <MatchFixtureDateTime
              dateTime={start_datetime_utc}
              startTimeLocal={start_time_local}
              sameDay={scheduled_overs !== 0}
              endDay={end_date}
              dayNumber={day_number}
              matchTitle={title}
              component="live"
            />
          </Box>
        ) : null}
        {batting_first_team_id && batting_second_team_id ? (
          <Flex gap="3" flexWrap="wrap">
            <TeamScore inningsData={inningsData[batting_first_team_id]} />
            <TeamScore inningsData={inningsData[batting_second_team_id]} />
          </Flex>
        ) : null}
        <BottomLine
          general_class_id={general_class_id}
          international_class_name={international_class_name}
          general_class_name={general_class_name}
          comp_name={comp_name}
          ground_name={ground_name}
        />
      </Flex>
    </ListItem>
  );
};

const FixtureComponent = ({ matchData }) => {
  const navigate = useNavigate();
  const { id, match_status } = matchData;
  if (!match_status) {
    return null;
  }

  const handleClick = () => {
    navigate({ to: `/match/${id}` });
  };

  if (match_status === 'live' || match_status === 'complete') {
    return <LiveCompleteMatch matchData={matchData} handleClick={handleClick} />;
  }

  if (match_status === 'forthcoming' || match_status === 'recent_view') {
    return <ForthcomingMatch matchData={matchData} handleClick={handleClick} />;
  }
  return null;
};

export default FixtureComponent;
