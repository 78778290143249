const parseColumns = (columns, x_column) => {
  const colArray = [];
  columns.forEach((el) => {
    if (el && el.value !== x_column) {
      colArray.push(el.value);
    }
  });
  if (x_column) {
    colArray.unshift(x_column);
  }
  return colArray;
};

export default parseColumns;
