import { Box } from '@chakra-ui/react';
import FilterSelect from './FilterSelect';
import FilterMultiSelect from './FilterMultiSelect';
import FilterRadio from './FilterRadio';
import FilterCheckbox from './FilterCheckbox';
import FilterDateRange from './FilterDateRange';
import FilterRange from './FilterRange';

const FilterElement = (props) => {
  const { label, form_type, name } = props;
  if (name === 'visualisations') {
    return <FilterCheckbox {...props} />;
  }
  if (form_type === 'multiselect_checkbox' || form_type === 'select') {
    return <FilterSelect {...props} />;
  }
  if (form_type === 'multiselect') {
    return <FilterMultiSelect {...props} />;
  }
  if (form_type === 'radio') {
    return <FilterRadio {...props} />;
  }
  if (form_type === 'checkbox') {
    return <FilterCheckbox {...props} />;
  }
  if (form_type === 'date_range') {
    return <FilterDateRange {...props} />;
  }
  if (form_type === 'range') {
    return <FilterRange {...props} />;
  }
  return (
    <Box>
      {label} - {form_type}
    </Box>
  );
};

export default FilterElement;
