import { useState } from 'react';
import { Box } from '@chakra-ui/react';
import ButtonSelector from '_shared/components/ButtonSelector';
import SquadDisplay from '_shared/components/SquadDisplay/SquadDisplay';
import TeamHeader from 'content/_components/TeamHeader';

const MatchSquadContent = ({ content }) => {
  const [filterRole, setFilterRole] = useState('all');

  const { data } = content;
  const { logo, name, squad, id } = data;
  return (
    <Box>
      <Box px="4" pt="5">
        <TeamHeader img={logo} teamName={name} id={id} />
      </Box>
      <Box flex="1" minH="0" ml={5} mr={5} mb={5} mt={2}>
        <ButtonSelector
          handleChange={setFilterRole}
          value={filterRole}
          options={['all', 'batters', 'bowlers']}
          buttonMinWidth="50px"
          fullWidth={true}
          fontSize="14px"
          color="primary.main"
          buttonProps={{ width: '33.3%' }}
        />
      </Box>
      <Box m={4}>
        <SquadDisplay filterRole={filterRole} squadData={squad} />
      </Box>
    </Box>
  );
};
export default MatchSquadContent;
