import { useSetRecoilState } from 'recoil';
import { getPanelsDetails } from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import { HIDDEN_PANELS } from '_shared/globalState/atoms';
import { checkNewHideValues } from '../components/utils';

const useFetchPagePanels = (type, objectId, classFilterId) => {
  const setHiddenPanels = useSetRecoilState(HIDDEN_PANELS);
  const queryKey = [type, objectId, 'panels'];
  if (classFilterId) {
    queryKey.push(classFilterId);
  }
  const {
    isLoading: panelsIsLoading,
    error: panelError,
    data: panelData,
    isFetching: panelIsFetching
  } = useQueryFetch(queryKey, () => getPanelsDetails(type, objectId, classFilterId), {
    staleTime: 120 * 1000,
    onSuccess: (d) => {
      if (d) {
        const hideInfo = {};
        d.forEach((panel) => {
          const { is_hidden, panel_id } = panel;
          if (is_hidden === 1) {
            hideInfo[panel_id] = true;
          }
        });
        setHiddenPanels((previous) => {
          const newValue = checkNewHideValues(previous, hideInfo);
          return newValue;
        });
      }
    }
  });
  return { panelsIsLoading, panelError, panelData, panelIsFetching };
};

export default useFetchPagePanels;
