import { Box, List } from '@chakra-ui/react';

import Header from 'content/_components/header/Header';
import ButtonSelector from '_shared/components/ButtonSelector';
import { useState } from 'react';
import FixtureComponent from '_shared/components/FixtureComponent/FixtureComponent';

const MatchList = ({ content }) => {
  const { data = [], header, filters = {} } = content;
  const { primary = [] } = filters;
  // will only show the button selector if a tournament is live - not if forthcoming / complete
  const hasFilters = primary.length > 0;
  const [matchType, setMatchType] = useState(hasFilters ? primary[0].value : null);
  const filteredData = data
    .filter((el) => {
      if (!hasFilters) return true;
      return el.filters.primary === matchType;
    })
    .map((el) => {
      return el.data;
    });
  return (
    <Box overflowY="auto" maxHeight="1550px">
      <Header title={header} stickyHeader />
      {hasFilters && (
        <Box mx={4} flexGrow={filters.primary.length}>
          <ButtonSelector
            options={primary}
            value={matchType}
            handleChange={setMatchType}
            fullWidth
            color="primary.main"
          />
        </Box>
      )}
      <Box key="match-list-content" mt={2}>
        <List m="3" pb="1" spacing="2">
          {filteredData.map((matchData) => {
            return <FixtureComponent matchData={matchData} key={matchData.id} />;
          })}
        </List>
      </Box>
    </Box>
  );
};
export default MatchList;
