import { createIcon } from '@chakra-ui/icons';

const EyeOpen = createIcon({
  displayName: 'EyeOpen',
  viewBox: '0 0 24 24',
  defaultProps: { width: '24px', height: '24px' },
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9996 4C9.35584 4 7.01305 5.38568 5.25133 6.86265C3.47252 8.35393 2.15636 10.0471 1.54823 10.893C1.06986 11.5584 1.06986 12.4416 1.54823 13.107C2.15636 13.9529 3.47252 15.6461 5.25133 17.1374C7.01305 18.6143 9.35584 20 11.9996 20C14.6433 20 16.9861 18.6143 18.7478 17.1374C20.5266 15.6461 21.8427 13.9529 22.4509 13.107C22.9292 12.4416 22.9292 11.5584 22.4509 10.893C21.8427 10.0471 20.5266 8.35393 18.7478 6.86264C16.9861 5.38568 14.6433 4 11.9996 4ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
      fill="currentColor"
    />,
    <path
      d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
      fill="currentColor"
    />
  ]
});

export default EyeOpen;
