import { Box, Flex, Button, IconButton } from '@chakra-ui/react';
import FormAsyncSelect from 'Forms/_shared/FormElements/FormAsyncSelect';
import FormClassSelector from 'Forms/_shared/FormElements/FormClassSelector';
import FormReactSelect from 'Forms/_shared/FormElements/FormReactSelect';
import { getVideoObject } from '_shared/dataFetching/apiService';
import { Formik } from 'formik';

import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import { useNavigate } from '@tanstack/react-location';
import usePageTitle from '_shared/hooks/usePageTitle';
import { handlePermissions } from '_shared/utils/utilFunctions';
import FullScreenAuthError from '_shared/errors/FullScreenAuthError';
import { ListBulletOutline24, SearchOutline24 } from '_icons/outline/24';

const object_type_labels = [
  { label: 'Batter', value: 'batter' },
  { label: 'Bowler', value: 'bowler' },
  { label: 'Match', value: 'match' },
  { label: 'Competition', value: 'comp' },
  { label: 'Ground', value: 'ground' }
];

const fetchObjectOptions = async (values, inputValue) => {
  if (!inputValue || !values.class_id?.value || !values.object_type?.value) return null;
  const data = await getVideoObject(values.object_type?.value, values.class_id?.value, inputValue);

  if (data?.results) {
    return data.results.map(({ id, text }) => {
      return {
        value: id,
        label: text
      };
    });
  }
  return null;
};

const VideoSearchHomePage = () => {
  const navigate = useNavigate();
  const { data: userData = {} } = useUserDetails();
  usePageTitle('Video Search');

  const { user_class_id, user_class_name_medium, permissions } = userData;
  const VCPerm = handlePermissions(10, permissions);

  const initialValues = {
    class_id: { label: user_class_name_medium, value: user_class_id },
    object_type: null,
    object_id: null
  };
  if (!VCPerm) {
    return <FullScreenAuthError />;
  }
  return (
    <>
      <Flex align="center" h="64px" bg="primary.shade.70" px="5">
        <Flex gap="2">
          <IconButton
            icon={<SearchOutline24 />}
            variant="ghostDark"
            color="primary.tint.80"
            bg="white.20"
          />
          <IconButton
            icon={<ListBulletOutline24 />}
            variant="ghostDark"
            onClick={() => {
              navigate({ to: `/video_playlist/` });
            }}
          />
        </Flex>
      </Flex>
      <Flex align="center" direction="column" minH="0" flexGrow="1" p="10" overflowY="auto">
        <Box bg="white" p="10" color="black.100" borderRadius="10px">
          <Box fontSize="xl" fontWeight="700" marginBottom="1">
            Search the video centre
          </Box>
          <Box marginBottom="20px" fontSize="md">
            Find the videos by player type, match or competition
          </Box>
          <Box mt="20px">
            <Formik
              initialValues={initialValues}
              // innerRef={ref}
              validate={(values) => {
                const errors = {};
                if (!values.object_type) {
                  errors.object_type = 'Please select object';
                }
                if (!values.object_id) {
                  errors.object_id = 'Please select a keyword';
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                if (values.object_type && values.object_id) {
                  navigate({
                    to: `/video_search/filters/${values.class_id.value}/${values.object_type.value}/${values.object_id.value}`
                  });
                }
                setSubmitting(false);
              }}
            >
              {(props) => (
                <Flex direction="column" gap="4">
                  <FormReactSelect
                    name="object_type"
                    label={
                      <Box fontWeight={700} fontSize="sm">
                        Search Criteria
                      </Box>
                    }
                    placeholder="Select search criteria"
                    options={object_type_labels}
                    onChangeCustom={(e) => {
                      props.setFieldValue('object_type', e);
                    }}
                  />
                  <FormClassSelector
                    name="class_id"
                    label={
                      <Box fontWeight="700" fontSize="sm">
                        Select format
                      </Box>
                    }
                    placeholder="Select format"
                    onChangeCustom={(e) => {
                      props.setFieldValue('class_id', e);
                    }}
                  />
                  <FormAsyncSelect
                    name="object_id"
                    label={
                      <Box fontWeight="bold" fontSize="sm">
                        Keyword
                      </Box>
                    }
                    placeholder={
                      <Flex align="center" gap="1">
                        <SearchOutline24 mt="1" />
                        <Box>
                          {!props.values.object_type
                            ? 'e.g. player name, match, competition, ground'
                            : `e.g. ${
                                props?.values?.object_type?.label?.toLowerCase() === 'match'
                                  ? 'England v Australia'
                                  : props?.values?.object_type?.label?.toLowerCase() === 'batter'
                                  ? 'Joe Root'
                                  : props?.values?.object_type?.label?.toLowerCase() === 'bowler'
                                  ? 'Pat Cummins'
                                  : props?.values?.object_type?.label?.toLowerCase() ===
                                    'competition'
                                  ? 'IPL 2023, Australia in England, ICC Cricket World Cup 2019'
                                  : props?.values?.object_type?.label?.toLowerCase() === 'ground'
                                  ? 'Sydney Cricket Ground'
                                  : null
                              }
                      `}
                        </Box>
                      </Flex>
                    }
                    fetchOptionsFunction={(e) => fetchObjectOptions(props.values, e)}
                    isClearable
                    isDisabled={!props.values.object_type}
                  />
                  <Button
                    mt="6"
                    w="100%"
                    h="44px"
                    fontWeight="700"
                    onClick={() => {
                      props.handleSubmit();
                    }}
                    isDisabled={
                      !props.values.object_type && !props.values.object_id && !props.values.class_id
                    }
                  >
                    Search
                  </Button>
                </Flex>
              )}
            </Formik>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default VideoSearchHomePage;
