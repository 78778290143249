import { Formik } from 'formik';
import { returnFilters, stringifyJson } from '_shared/utils/utilFunctions.js';
import { useNavigate } from '@tanstack/react-location';
import { useMemo } from 'react';
import VideoFiltersLayout from './VideoFiltersLayout';
import { Box, Flex } from '@chakra-ui/react';
import capitalise from '_shared/utils/capitalise';
import VideoCentreError from '_shared/errors/VideoCentreError';

const SearchTags = ({ label, value }) => {
  return (
    <Flex gap="1" fontSize="sm">
      <Box color="black.60">{label}</Box>
      <Box fontWeight="700">{value}</Box>
    </Flex>
  );
};

const VideoFiltersContainer = ({
  filters,
  classId,
  objectType,
  objectId,
  presetFilters,
  folding,
  setFiltersOpen,
  object_info,
  class_info
}) => {
  const initialValues = {};
  const navigate = useNavigate();
  for (let keyInd = 0; keyInd < filters.length; keyInd += 1) {
    const key = filters[keyInd];
    initialValues[key.api_tag] = null;
  }

  const calculatedFilters = useMemo(
    () => returnFilters(presetFilters, filters),
    [presetFilters, filters]
  );
  if (!object_info?.header || filters.length === 0) {
    const errorMessage = `${class_info?.short_name} ${objectType} ${objectId} not found `;
    return <VideoCentreError errorMessage={errorMessage} pageType="search" />;
  }
  return (
    <Formik
      initialValues={presetFilters ? calculatedFilters : initialValues}
      validate={() => {
        const errors = {};

        return errors;
      }}
      onSubmit={async (values) => {
        const queryValObj = {};
        for (let keyInd = 0; keyInd < Object.keys(values).length; keyInd += 1) {
          const key = Object.keys(values)[keyInd];
          const val = values[key];
          const responseVal = [];
          if (val) {
            if (Array.isArray(val)) {
              if (val.length === 0) {
                continue;
              }
              if (val[0].hasOwnProperty('value')) {
                val.forEach((el) => responseVal.push(el.value));
                queryValObj[key] = responseVal;
              } else {
                queryValObj[key] = val;
              }
            } else if (typeof val === 'object') {
              if (val.hasOwnProperty('min')) {
                queryValObj[key] = `${val.min ? val.min : ''}~${val.max ? val.max : ''}`;
              }
            } else {
              queryValObj[key] = val;
            }
          }
        }
        const queryUrl = stringifyJson(queryValObj);
        const redirectUrl = `/video_search/results/${classId}/${objectType}/${objectId}?q=${queryUrl}`;

        navigate({ to: redirectUrl });
        if (setFiltersOpen) {
          setFiltersOpen(false);
        }
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <>
          <Flex direction="column" gap="4" mb="10">
            <Box fontSize="lg" fontWeight="700">
              Refine your search
            </Box>
            <Flex fontSize="sm" gap={5} flexWrap="wrap">
              <SearchTags label="Format" value={class_info.short_name} />
              <SearchTags label="Criteria" value={capitalise(objectType)} />
              <SearchTags label="Keyword" value={object_info?.header} />
            </Flex>
          </Flex>
          <VideoFiltersLayout
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            folding={folding}
            filters={filters}
            initialValues={calculatedFilters}
          />
        </>
      )}
    </Formik>
  );
};

export default VideoFiltersContainer;
