import { Box, Flex, CircularProgress, CircularProgressLabel } from '@chakra-ui/react';

const PercentageComponent = ({ percentage }) => {
  return (
    <Flex gap="2" alignItems="center">
      <CircularProgress value={parseInt(percentage)} color="positive.main" size="150px">
        <CircularProgressLabel>
          <Box fontSize="sm">Win chance</Box>
          <Box color="positive.shade.30" fontSize="lg" fontWeight="700">
            {`${percentage}%`}
          </Box>
        </CircularProgressLabel>
      </CircularProgress>
    </Flex>
  );
};

export default PercentageComponent;
