import InsertPanelModal from 'Forms/PanelForms/InsertPanelModal';
import usePanelSettingsAvailable from '_shared/hooks/usePanelSettingsAvailable';
import PanelPageDraggable from 'panels/PanelPageDraggable/PanelPageDraggable';
import MatchSubHeader from './MatchSubHeader';
import useCategoryFilter from 'pages/_shared/hooks/useCategoryFilter';
import StickyScrollWrapper from 'pages/_shared/components/StickyScroll/StickyScrollWrapper';
import StickyScrollContainer from 'pages/_shared/components/StickyScroll/StickyScrollContainer';
import HideShowModal from 'pages/_shared/components/HideShowModal/HideShowModal';
import usePageTitle from '_shared/hooks/usePageTitle';
import FilterLayout from 'pages/_shared/components/FilterComponents/FilterLayout';
import useFetchPagePanels from 'pages/_shared/hooks/useFetchPagePanels';
import PanelsLoadingError from 'pages/_shared/components/PanelsLoadingError';
import PageSelectFilter from 'pages/_shared/components/FilterComponents/PageSelectFilter';
import panelFetchErrorMessage from 'pages/_shared/utils/panelFetchErrorMessage';
import useWindowDimensions from '_shared/hooks/useWindowDimensions';
import MatchError from '_shared/errors/MatchError';
const getPageTitle = (match_information) => {
  if (match_information === 'Match is yet to begin') {
    return null;
  }
  const { match_number, team1_abbreviation, team1_name, team2_abbreviation, team2_name } =
    match_information;
  return `${team1_abbreviation ? team1_abbreviation : team1_name ? team1_name : 'TBC'} v ${
    team2_abbreviation ? team2_abbreviation : team2_name ? team2_name : 'TBC'
  }, ${match_number}`;
};

const type = 'match';

const MatchContainer = ({ data, objectId }) => {
  usePanelSettingsAvailable();
  const { match_information, players } = data;
  const pageData = { match_information, players };
  usePageTitle(getPageTitle(match_information));
  const { width } = useWindowDimensions();

  const { panelsIsLoading, panelError, panelData, panelIsFetching } = useFetchPagePanels(
    type,
    objectId
  );

  const { categoryOptions, categoryId, setCategoryId } = useCategoryFilter(panelData);

  if (data.match_information === 'Match is yet to begin') {
    return <MatchError />;
  }

  const subHeaderHeight = width > 1460 ? 56 : width > 970 ? 100 : 160;
  // '271px' : '80px'
  return (
    <>
      <StickyScrollContainer>
        <StickyScrollWrapper
          noFiltersDisplayed={categoryOptions.length === 0}
          headerSize={subHeaderHeight}
          pageType={type}
        >
          <MatchSubHeader data={pageData} height={subHeaderHeight} />
          {!categoryOptions.length === 0 ? (
            <FilterLayout>
              <PageSelectFilter
                options={categoryOptions}
                value={categoryId}
                setValue={setCategoryId}
                allOption="All categories"
              />
            </FilterLayout>
          ) : null}
        </StickyScrollWrapper>
        <PanelsLoadingError
          panelError={panelError}
          panelIsFetching={panelIsFetching}
          panelsIsLoading={panelsIsLoading}
          errorMessage={panelFetchErrorMessage(type, data.match_information?.comp_name)}
        />
        {panelData && (
          <PanelPageDraggable
            pageData={pageData}
            panels={panelData}
            pageType={type}
            filterType="category"
            categoryId={categoryId}
          />
        )}
      </StickyScrollContainer>
      <InsertPanelModal panelData={panelData} pageData={pageData} pageType={type} />
      <HideShowModal panelData={panelData} />
    </>
  );
};

export default MatchContainer;
