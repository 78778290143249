import { Box, Spinner, Flex } from '@chakra-ui/react';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import { getBBBVideoData } from '_shared/dataFetching/apiService';
import TagComponent from 'pages/videoPlaylist/components/TagComponent';
import ListingViewOptions from './ListingViewOptions';
import { CalendarOutline20, LocationOutline20 } from '_icons/outline/20';

const VideoBallCard = ({
  ballId,
  videoInd,
  vidDict,
  vidLength,
  setAutoPlay,
  autoPlay,
  setVideoVal
}) => {
  const { isLoading, data } = useQueryFetch(['bbbVideoData', ballId], () =>
    getBBBVideoData(ballId)
  );
  if (isLoading) {
    return (
      <Box>
        <Spinner />
      </Box>
    );
  }
  if (data) {
    const { ball_id } = data;
    return (
      <Box>
        <Flex bg="white">
          <Box borderRadius={'sm'} bg="white" p={2} minW="60%">
            <Box>
              <Flex fontSize={'xs'} color="black.60" p={1.5} borderBottom={'1px solid #EBEBED'}>
                <CalendarOutline20 />
                <Box ml={1}>{data.match_date}</Box>
                <LocationOutline20 />
                <Box ml={1}>{data.ground_name}</Box>
              </Flex>
            </Box>
            <Flex gap={2} mb={2} p={2} fontSize="sm" borderRight={'1px solid #EBEBED'}>
              <TagComponent ballId={ballId} />
            </Flex>
          </Box>
          <Box w="40%" ml="auto" mr={1}>
            <ListingViewOptions
              videoVal={videoInd}
              ballID={ball_id}
              vidDict={vidDict}
              vidLength={vidLength}
              setAutoPlay={setAutoPlay}
              autoPlay={autoPlay}
              setVideoVal={setVideoVal}
            />
          </Box>
        </Flex>
      </Box>
    );
  }
};

export default VideoBallCard;
