import { Formik } from 'formik';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { editPanel } from '_shared/dataFetching/apiService';
import { getInfo } from 'panels/utils/panelGetInfo';
import QTPanelForm from '../_shared/FormLayouts/QTPanelForm';
import ExtApiPanelForm from '../_shared/FormLayouts/ExtApiPanelForm';
import FormLayout from 'Forms/_shared/components/FormLayout';
import { editPanelInitialValues, generateEditPanelPayload } from './utils';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';

const EditPanel = ({ panelData, pageData, pageType, handleClose }) => {
  const [formSubmitError, setFormSubmitError] = useState(null);
  const { data: userInfo } = useUserDetails();
  const { is_staff } = userInfo || {};
  const { classList, objectId } = getInfo(pageData, pageType);
  const queryClient = useQueryClient();
  const initialValues = editPanelInitialValues(panelData);
  const { data_type } = panelData;
  const classListOptions = Object.entries(classList).map(([key, value]) => {
    return { label: value.short_name, value: key };
  });

  const videoOptionAvailable =
    panelData?.query_form?.form_opts?.video_url === '1' &&
    panelData?.query_form?.form_opts?.selectopt === 'video_link';

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        setFormSubmitError(null);
        const errors = {};
        if (!values.panel_name) {
          errors.panel_name = 'Please enter a panel name';
        }
        if (!values.category_type_id) {
          errors.category_type_id = 'Please select a panel category';
        }
        if (data_type === 'qt' && !values.classId) {
          errors.class = 'Please select class';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const payload = generateEditPanelPayload(values, initialValues);
        const res = await editPanel(payload);
        console.log({ payload, response: res });
        if (res.status === 200) {
          queryClient.refetchQueries(['panel', payload.panel_id]);
          queryClient.refetchQueries([pageType, `${objectId}`, 'panels']);
          handleClose();
        } else {
          setFormSubmitError(res.data);
        }
        setSubmitting(false);
      }}
    >
      {(props) => (
        <FormLayout buttonText="Update Panel" {...props}>
          {data_type === 'qt' && (
            <QTPanelForm
              {...props}
              is_staff={is_staff}
              classListOptions={classListOptions}
              formSubmitError={formSubmitError}
              objectId={objectId}
              editMode
              videoOptionAvailable={videoOptionAvailable}
              dynamicPanelNames={panelData.panel_name_variables}
              dataRow={panelData?.data[0]}
              minDate={panelData?.min_date}
              maxDate={panelData?.max_date}
              pageType={pageType}
            />
          )}
          {data_type === 'ext' && (
            <ExtApiPanelForm
              {...props}
              is_staff={is_staff}
              formSubmitError={formSubmitError}
              objectId={objectId}
              editMode
              videoOptionAvailable={videoOptionAvailable}
              dynamicPanelNames={panelData.panel_name_variables}
              dataRow={panelData?.data[0]}
              pageType={pageType}
            />
          )}
        </FormLayout>
      )}
    </Formik>
  );
};

export default EditPanel;
