import { Flex, Box } from '@chakra-ui/react';
import Close from '_icons/outline/24/Close';

const RadarKey = ({ data, handleClick, disableClickFirstItem }) => {
  return (
    <Flex p="4" gap="2" flexWrap="wrap">
      {data?.map((el, idx) => {
        return (
          <Flex
            px="2.5"
            py="3"
            border="1px"
            borderColor="black.8"
            borderRadius="md"
            flex="1"
            fontSize="sm"
            alignItems="center"
            gap="1.5"
            h="44px"
            cursor={idx !== 0 || !disableClickFirstItem ? 'pointer' : 'auto'}
            key={el.player_id}
            onClick={() => handleClick(el.player_id)}
            sx={{
              _hover: {
                '& .keyColor': {
                  display: (idx !== 0 || !disableClickFirstItem) && 'none'
                },
                '& .keyClose': {
                  display: (idx !== 0 || !disableClickFirstItem) && 'inline-block'
                }
              },
              '& .keyClose': {
                display: 'none'
              }
            }}
          >
            <Flex w="5" justify="center">
              <Box className="keyColor" rounded="full" bg={`chartColors.${idx + 1}`} w="3" h="3" />
              <Close className="keyClose" height="20px" width="20px" />
            </Flex>
            <Box>{el.player_name}</Box>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default RadarKey;
