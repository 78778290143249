import { useEffect, useRef } from 'react';
import { Flex } from '@chakra-ui/react';
import autoAnimate from '@formkit/auto-animate';
import ResultString from './ResultString';
import MatchScore from './MatchScore/MatchScore';
import MatchInfo from './MatchInfo';
import WinVizHeader from './WinVizHeader';
import RecentPeriods from './RecentPeriods';
import PreMatchDetail from './PreMatchDetail/PreMatchDetail';

const MatchV2SubHeader = ({ data, size }) => {
  const parent = useRef(null);
  const matchStatus = data?.status?.match_status;

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  return (
    <Flex
      direction="column"
      alignItems="center"
      bg="primary.shade.70"
      px="5"
      pt={size === 'small' ? '4' : '7'}
      pb={size === 'small' ? '4' : '5'}
      gap="4"
      ref={parent}
    >
      {size === 'large' && matchStatus !== 'forthcoming' && <ResultString data={data} />}
      {matchStatus === 'forthcoming' ? (
        <PreMatchDetail data={data} size={size} />
      ) : (
        <MatchScore data={data} size={size} />
      )}
      {size === 'large' && <MatchInfo data={data} />}
      {size === 'large' && matchStatus !== 'forthcoming' && (
        <WinVizHeader winvizData={data?.winviz} />
      )}
      {size === 'large' && matchStatus !== 'forthcoming' && (
        <RecentPeriods recentData={data?.recent} />
      )}
    </Flex>
  );
};

export default MatchV2SubHeader;
