import { Box, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { FOCUS_PANEL } from '_shared/globalState/atoms';
import CreateExtApiPanel from './ExternalApiPanel/CreateExtApiPanel';
import CreateQTPanel from './QTPanel/CreateQTPanel';

const CreatePanelForm = ({ pageData, pageType, handleClose, panelNameVariables }) => {
  const [type, setType] = useState('qt');
  const setFocusPanel = useSetRecoilState(FOCUS_PANEL);

  const onSave = (panel_id) => {
    let count = 0;
    const scroll = setInterval(() => {
      const section = document.getElementById(`panel_${panel_id}`);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        clearInterval(scroll);
      }
      count += 1;
      if (count > 5) {
        clearInterval(scroll);
      }
    }, 1000);
    setFocusPanel(panel_id);
    setTimeout(() => {
      setFocusPanel(null);
    }, 10 * 1000);
    handleClose();
  };
  return (
    <Box>
      <RadioGroup onChange={setType} value={type}>
        <Stack direction="row">
          <Radio value="qt">Query Tool</Radio>
          <Radio value="ext">CricViz</Radio>
        </Stack>
      </RadioGroup>
      {type === 'qt' && (
        <CreateQTPanel
          pageData={pageData}
          pageType={pageType}
          onSave={onSave}
          panelNameVariables={panelNameVariables}
        />
      )}
      {type === 'ext' && (
        <CreateExtApiPanel
          pageData={pageData}
          pageType={pageType}
          onSave={onSave}
          panelNameVariables={panelNameVariables}
        />
      )}
    </Box>
  );
};

export default CreatePanelForm;
