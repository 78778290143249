import { Box, Flex, Icon } from '@chakra-ui/react';
import AddUserFavourites from '_shared/components/AddUserFavourites';
import capitalise from '_shared/utils/capitalise';
import { showDateDisplay } from '_shared/utils/showDateDisplay';
import { AiOutlineTrophy } from 'react-icons/ai';

const CompV2SubHeader = ({ data = {} }) => {
  const {
    name,
    season,
    start_date,
    end_date,
    start_datetime_utc,
    id,
    comp_type,
    comp_status,
    number_of_teams
  } = data;
  return (
    <Flex px="5" py="4" gap="4" h="56px" bg="primary.shade.70">
      <Flex color="white" gap="4" flexWrap="wrap" flexGrow="1">
        <Box fontWeight="700">{name.toUpperCase()}</Box>
        <Box fontWeight="700">{season}</Box>
        {comp_type ? (
          <Flex alignItems="center" gap="1">
            <Icon as={AiOutlineTrophy} />
            <Box>
              {capitalise(comp_type)} ({comp_status})
            </Box>
          </Flex>
        ) : null}
        <Flex alignItems="center" gap="1">
          <Box>
            {start_date && end_date && showDateDisplay(start_date, end_date, start_datetime_utc)}
          </Box>
        </Flex>
        {number_of_teams ? <Box>Teams: {number_of_teams}</Box> : null}
      </Flex>
      <Flex gap="3">
        <AddUserFavourites favType="comp" favId={id} />
      </Flex>
    </Flex>
  );
};

export default CompV2SubHeader;
