import { Center, Checkbox, CheckboxGroup } from '@chakra-ui/react';
import { useState } from 'react';
import FilterLabel from './FilterLabel';
import capitalise from '_shared/utils/capitalise';

const InputCheckboxGroup = ({
  filterData,
  setFieldValue,
  isAccordion,
  isExpanded,
  setIsExpanded
}) => {
  const { api_tag, label, options } = filterData;
  const [optionsSelected, setOptionsSelected] = useState([]);

  const handleSelect = (el) => {
    if (optionsSelected.includes(el)) {
      setOptionsSelected((oldValues) => {
        return oldValues.filter((val) => val !== el);
      });
      setFieldValue(
        api_tag,
        optionsSelected.filter((val) => val !== el)
      );
    } else {
      setOptionsSelected([...optionsSelected, el]);
      setFieldValue(api_tag, [...optionsSelected, el]);
    }
  };
  const isActive = optionsSelected.length !== 0;
  return (
    <FilterLabel
      name={api_tag}
      label={capitalise(label)}
      isActive={isActive}
      isAccordion={isAccordion}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <CheckboxGroup>
        {options.map((opt) => {
          return (
            <Checkbox
              onChange={() => handleSelect(opt.value)}
              name={api_tag}
              key={`${api_tag}.${opt.value}`}
            >
              <Center>{opt.label}</Center>
            </Checkbox>
          );
        })}
      </CheckboxGroup>
    </FilterLabel>
  );
};

export default InputCheckboxGroup;
