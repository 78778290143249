const checkMatchType = (objectData) => {
  const { status } = objectData;
  const statusId = status?.id;
  const statusName = status?.name;
  // statusId == 6 is prematch, so even though match is in
  if (!statusId && statusName === 'forthcoming') return 'prematch';
  if (statusId === 6) return 'prematch';
  return 'match';
};

export default checkMatchType;
