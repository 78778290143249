import InsertPanelModal from 'Forms/PanelForms/InsertPanelModal';
import PanelPageDraggable from 'panels/PanelPageDraggable/PanelPageDraggable';
import usePanelSettingsAvailable from '_shared/hooks/usePanelSettingsAvailable';
import StickyScrollContainer from 'pages/_shared/components/StickyScroll/StickyScrollContainer';
import StickyScrollWrapper from 'pages/_shared/components/StickyScroll/StickyScrollWrapper';
import HideShowModal from 'pages/_shared/components/HideShowModal/HideShowModal';
import usePageTitle from '_shared/hooks/usePageTitle';
import FilterLayout from 'pages/_shared/components/FilterComponents/FilterLayout';
import useFetchPagePanels from 'pages/_shared/hooks/useFetchPagePanels';
import PanelsLoadingError from 'pages/_shared/components/PanelsLoadingError';
import PageSelectFilter from 'pages/_shared/components/FilterComponents/PageSelectFilter';
import panelFetchErrorMessage from 'pages/_shared/utils/panelFetchErrorMessage';
import useClassFilter from 'pages/_shared/hooks/useClassFilter';
import GroundSubHeader from './GroundSubHeader';
import DateFilter from 'pages/_shared/components/FilterComponents/DateFilter';

const type = 'ground';

const GroundContainer = ({ pageData, objectId }) => {
  usePanelSettingsAvailable();
  usePageTitle(pageData.banner_heading);
  const { class_id_list } = pageData;
  const { classOptions, classFilterId, handleClassFilterChange } = useClassFilter(class_id_list);
  const { panelsIsLoading, panelError, panelData, panelIsFetching } = useFetchPagePanels(
    type,
    objectId,
    classFilterId
  );

  const filterAvailable = classOptions.length !== 0;

  if (pageData.ground_id === 'Ground not found') {
    return 'Ground not found';
  }

  return (
    <>
      <StickyScrollContainer>
        <StickyScrollWrapper
          noFiltersDisplayed={!filterAvailable}
          headerSize={64}
          expandedHeaderSize={244}
          pageType={type}
        >
          <GroundSubHeader data={pageData} />
          {filterAvailable ? (
            <FilterLayout>
              <PageSelectFilter
                options={classOptions}
                value={classFilterId}
                setValue={handleClassFilterChange}
              />
              <DateFilter type={type} />
            </FilterLayout>
          ) : null}
        </StickyScrollWrapper>
        <PanelsLoadingError
          panelError={panelError}
          panelIsFetching={panelIsFetching}
          panelsIsLoading={panelsIsLoading}
          errorMessage={panelFetchErrorMessage(type, pageData.banner_heading)}
        />
        {panelData && (
          <PanelPageDraggable
            pageData={pageData}
            panels={panelData}
            pageType={type}
            classId={classFilterId}
          />
        )}
      </StickyScrollContainer>
      <InsertPanelModal panelData={panelData} pageData={pageData} pageType={type} />
      <HideShowModal panelData={panelData} />
    </>
  );
};

export default GroundContainer;
