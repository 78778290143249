import { Flex } from '@chakra-ui/react';

import Lottie from 'react-lottie';
import animationData from '_lotties/cricviz-icon-animation.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const ContentLoading = () => {
  return (
    <Flex height="100%" alignItems="center">
      <Lottie 
        options={defaultOptions}
        height="80px"
        width="80px"
      />
    </Flex>
  );
};
export default ContentLoading;
