import { Box, Button } from '@chakra-ui/react';
import { Link } from '@tanstack/react-location';
import { useSetRecoilState } from 'recoil';
import { GLOBAL_NAV_OPEN } from '_shared/globalState/atoms';

const MenuButton = ({ icon, text, location }) => {
  const setGlobalNavOpen = useSetRecoilState(GLOBAL_NAV_OPEN);
  return (
    <Link to={location}>
      {({ isActive }) => {
        return (
          <Button
            as={Link}
            to={location}
            leftIcon={icon}
            variant="menu"
            bg={isActive && 'white.20'}
            onClick={() => setGlobalNavOpen(false)}
          >
            <Box flexGrow="1">{text}</Box>
          </Button>
        );
      }}
    </Link>
  );
};

export default MenuButton;
