import { createIcon } from '@chakra-ui/icons';

const CricketBatBall = createIcon({
  displayName: 'CricketBatBall',
  viewBox: '0 0 20 20',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6ZM13.7676 6.14679L7.40362 12.5107L8.81783 13.925L15.1818 7.561L13.7676 6.14679ZM14.4747 4.02547C14.0842 3.63494 13.451 3.63494 13.0605 4.02547L5.2823 11.8036C4.89177 12.1942 4.89177 12.8273 5.2823 13.2179L8.11072 16.0463C8.50125 16.4368 9.13441 16.4368 9.52494 16.0463L17.3031 8.26811C17.6936 7.87758 17.6936 7.24442 17.3031 6.85389L14.4747 4.02547ZM6.69742 16.0459L5.99031 16.753L3.86899 18.8743C3.47847 19.2649 2.8453 19.2649 2.45478 18.8743C2.06425 18.4838 2.06425 17.8506 2.45478 17.4601L4.5761 15.3388L5.2832 14.6317L6.69742 16.0459Z"
      fill="currentcolor"
    />
  )
});

export default CricketBatBall;
