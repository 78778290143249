import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  CheckboxGroup,
  Checkbox,
  Input,
  useToast,
  Grid,
  GridItem
} from '@chakra-ui/react';
import { useState } from 'react';
import {
  getUserVideoPlaylists,
  newVideoPlaylist,
  updateVideoPlaylist
} from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import { useNavigate } from '@tanstack/react-location';
export const AddToPlaylistModal = ({
  vidList,
  vidDict,
  vidLength,
  formModalOpen,
  setFormModalOpen,
  setSelectedVideos
}) => {
  const newArr = [];
  const toast = useToast();
  const navigate = useNavigate();
  vidList.forEach((ball_id) => {
    newArr.push({
      ball_id,
      cv_aws_bucket: vidDict[ball_id]?.cv_aws_bucket,
      cv_aws_key: vidDict[ball_id]?.cv_aws_key,
      cv_aws_etag: vidDict[ball_id?.cv_aws_etag]
    });
  });
  const [newPlaylist, setNewPlaylist] = useState(false);
  const [playlistName, setPlaylistName] = useState('');
  const { data: userPlaylists, refetch } = useQueryFetch(
    ['user_playlists'],
    () => getUserVideoPlaylists(),
    { manual: true, enabled: true }
  );
  const initialCheckBoxes = {};
  const playlistNames = {};
  const playlistData = {};
  if (userPlaylists) {
    userPlaylists.forEach((el) => {
      initialCheckBoxes[el.id] = false;
      playlistNames[el.id] = el.playlist_name;
      playlistData[el.id] = el.data;
    });
  }

  const [playListAddObj, setPlayListAddObj] = useState(initialCheckBoxes);
  const handleClose = () => {
    setFormModalOpen(false);
    setSelectedVideos([]);
  };

  const clickCheckbox = (e) => {
    setPlayListAddObj({ ...playListAddObj, [e.id]: !playListAddObj[e.id] });

    if (!playListAddObj[e.id]) {
      updateVideoPlaylist({ json_obj: playlistData[e.id].concat(newArr), id: e.id });
      toast({
        title: `Playlist updated`,
        position: 'bottom-left',
        description: (
          <Box>
            <Box>
              {newArr.length} videos added to {playlistNames[e.id]}
            </Box>
            <Box>
              <Button onClick={() => navigate({ to: `/video_playlist/${e.id}` })} variant="ghost">
                Go to {playlistNames[e.id]}
              </Button>
            </Box>
          </Box>
        ),
        status: 'success',
        duration: 5000,
        isClosable: true
      });
    } else {
      updateVideoPlaylist({
        json_obj: playlistData[e.id].filter((innerEl) => {
          return !vidList.includes(innerEl);
        }),
        id: e.id
      });
      toast({
        title: `Playlist updated`,
        position: 'bottom-left',
        description: `${newArr.length} videos removed from ${playlistNames[e.id]}`,
        status: 'warning',
        duration: 5000,
        isClosable: true
      });
      refetch();
    }
  };

  return (
    <Modal isOpen={formModalOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent maxW="750px">
        <ModalCloseButton />
        <ModalBody>
          {newPlaylist ? (
            <Box mt={'10'} mb={10}>
              <Box fontWeight={'bold'} fontSize="lg" mb={4}>
                Create new playlist
              </Box>
              <Input
                type="text"
                name="playlist_name"
                placeholder="Enter playlist name"
                value={playlistName}
                onInput={(e) => setPlaylistName(e.target.value)}
              />
              <Box mt={2}>
                <Button
                  w="100%"
                  onClick={() => {
                    newVideoPlaylist({ playlist_name: playlistName });

                    setNewPlaylist(false);
                    setPlaylistName('');
                    setTimeout(() => refetch(), 1000);
                  }}
                >
                  Save
                </Button>
              </Box>
              <Box mt={2}>
                <Button w="100%" onClick={() => setNewPlaylist(false)} variant="secondary">
                  Cancel
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              <Box fontWeight={'bold'} fontSize="lg">
                Add selection to a playlist
              </Box>
              <Box fontSize="md" mt={2}>
                {vidLength} videos selected
              </Box>

              <Grid
                border={'1px'}
                borderRadius={'sm'}
                borderColor="grey.300"
                mt={2}
                ml={1}
                mr={1}
                mb={4}
                p={2}
                templateColumns="repeat(2, 1fr)"
              >
                <CheckboxGroup>
                  {userPlaylists &&
                    userPlaylists.map((el, idx) => {
                      return (
                        <GridItem
                          key={`${idx}-playlist-choice`}
                          bg={Object.keys(playListAddObj).includes(el.id) ? 'white' : 'black.3'}
                          m={3}
                          borderRadius="md"
                          border={1}
                          borderColor="black.3"
                        >
                          <Checkbox
                            p={4}
                            w="100%"
                            name="id"
                            key={`${el.id}.id`}
                            onChange={() => clickCheckbox(el)}
                          >
                            <Box fontSize="sm" fontWeight={600} color="black.60">
                              {el.playlist_name}
                            </Box>
                          </Checkbox>
                        </GridItem>
                      );
                    })}
                </CheckboxGroup>
              </Grid>
              <Button variant="secondary" w="100%" mb={2} onClick={() => setNewPlaylist(true)}>
                Create new playlist
              </Button>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddToPlaylistModal;
