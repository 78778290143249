import { Box, Center, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useState, useEffect } from 'react';

dayjs.extend(relativeTime);

function timeDifference(datetime_utc) {
  let days = 0;
  let hours = 0;
  let minutes = 0;
  const matchDate = dayjs.utc(datetime_utc).local();
  const dateNow = dayjs();
  if (dayjs.isDayjs(matchDate)) {
    const dayDiff = matchDate.diff(dateNow, 'days');
    days = dayDiff > 0 ? dayDiff : 0;
    const hourDiff = matchDate.diff(dateNow, 'hours') % 24;
    hours = hourDiff > 0 ? hourDiff : 0;
    const minuteDiff = matchDate.diff(dateNow, 'minutes') % 60;
    minutes = minuteDiff > 0 ? minuteDiff : 0;
  }
  return {
    days,
    hours,
    minutes
  };
}

const TimeComponent = ({ timeVal, timeAttr }) => {
  return (
    <Flex direction="column" gap="1">
      <Box bg="#00000033" fontSize="60px" py="2.5" px="5" rounded="xl" lineHeight="82px">
        {timeVal.toString().padStart(2, '0')}
      </Box>
      <Box alignSelf="center" lineHeight="32px" textTransform="uppercase">
        {timeAttr}
      </Box>
    </Flex>
  );
};

const EventCountDown = ({ panelData }) => {
  const { event_start } = panelData.data;
  const [time, setTime] = useState(timeDifference(event_start));
  useEffect(() => {
    const interval = setInterval(() => setTime(timeDifference(event_start)), 10 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [event_start]);
  return (
    <Box
      h="100%"
      w="100%"
      color="white.100"
      fontSize="sm"
      fontWeight="700"
      backgroundSize="cover"
      bgImage="https://cv-qtp-images.s3.eu-west-2.amazonaws.com/event_countdown.jpg"
    >
      <Center h="100%" w="100%">
        <Flex direction="column">
          <Box h="43px" fontWeight="700" alignSelf="center">
            MATCH BEGINS IN
          </Box>
          <Flex pb="4" gap="6">
            <TimeComponent timeVal={time.days} timeAttr="days" />
            <TimeComponent timeVal={time.hours} timeAttr="hours" />
            <TimeComponent timeVal={time.minutes} timeAttr="minutes" />
          </Flex>
        </Flex>
      </Center>
    </Box>
  );
};

export default EventCountDown;
