import { Box, Circle, Flex } from '@chakra-ui/react';
import TeamImage from '_shared/components/TeamImage';

const TeamResult = ({ team, win_count, color }) => {
  return (
    <Flex textAlign="center" justifyContent="center" alignItems="center" direction="column" gap="2">
      {team?.logo && <TeamImage team={team} size={96} type="circle" />}
      <Flex direction="column">
        <Circle bg={color} size="12px" m="auto" />
        <Box>{team?.id ? 'Won' : 'Other'}</Box>
        <Box fontWeight={700} fontSize="lg" lineHeight="1.2">
          {win_count}
        </Box>
      </Flex>
    </Flex>
  );
};

export default TeamResult;
