import { Box } from '@chakra-ui/react';
import LiveBatterTable from './LiveBatterTable';
import LiveBowlerTable from './LiveBowlerTable';
import { getLiveScoreData } from './utils';

const LiveScorecard = ({ panelData }) => {
  const { scorecard, career } = panelData.data;
  const { liveBatters, liveBowler, livePartnership } = getLiveScoreData(scorecard);

  return (
    <Box h="100%" overflowX="scroll">
      <LiveBatterTable
        liveBatters={liveBatters}
        livePartnership={livePartnership}
        scorecardData={scorecard}
        career={career}
      />
      <LiveBowlerTable liveBowler={liveBowler} scorecardData={scorecard} career={career} />
    </Box>
  );
};

export default LiveScorecard;
