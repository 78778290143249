import parseColumns from './parseColumns/parseColumns';
import fetchDataColsRegex from './fetchDataColsRegex/fetchDataColsRegex';

const getDataColumns = (values) => {
  const { panel_type_id, columnSelection, x_column } = values;
  if (panel_type_id === '2') {
    return parseColumns(columnSelection);
  }
  if (values.panel_type_id === '1') {
    const { data_value } = values;
    return fetchDataColsRegex(data_value);
  }
  if (values.panel_type_id === '4') {
    const { columnData } = values;
    return columnData.map((col) => col.value);
  }
  return parseColumns(columnSelection, x_column);
};

export default getDataColumns;
