import VideoHeader from './VideoHeader/VideoHeader';
// import VideoOverBallSelector from './VideoOverBallSelector/VideoOverBallSelector';

const VideoNavigator = ({ vidInd, setVidInd, data, navigate, videoRef, videoSearchUrl }) => {
  return (
    <>
      <VideoHeader
        vidInd={vidInd}
        setVidInd={setVidInd}
        data={data}
        videoRef={videoRef}
        videoSearchUrl={videoSearchUrl}
        navigate={navigate}
      />
      {/* <VideoOverBallSelector
        vidInd={vidInd}
        setVidInd={setVidInd}
        data={data}
      /> */}
    </>
  );
};

export default VideoNavigator;


