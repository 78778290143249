import { forwardRef } from 'react';
import PanelTypeDefinitions from 'panels/PanelTypes/PanelTypeDefinitions';
import { isPanelWidget } from './utils/utils';

const PanelContent = forwardRef(({ panelData, pageData, classId, layoutInfo }, ref) => {
  const { panel_type_id } = panelData;

  const Component = PanelTypeDefinitions[panel_type_id];
  const componentProps = { panelData, layoutInfo };

  if ([14, 15, 16].includes(panel_type_id)) {
    componentProps.pageData = pageData;
  } else if ([19, 23, 34, 43].includes(panel_type_id)) {
    componentProps.classId = classId;
  }

  if (isPanelWidget(panel_type_id)) {
    componentProps.ref = ref;
  }

  if (Component) {
    return <Component {...componentProps} />;
  }
  return null;
});

export default PanelContent;
