import { useState, useCallback, useRef, useEffect } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import useVideoModal from 'modals/hooks/useVideoModal';
import ButtonSelector from '_shared/components/ButtonSelector';
import Header from '../_components/header/Header';

const subViewOptions = [
  { value: 'beehive', label: "Beehive" },
  { value: 'grid', label: "Grid" }
];

const BeehiveViewContent = ({ content, video }) => {

  const { showVideoModal } = useVideoModal();
  const [ subViewValue, setSubViewValue ] = useState("beehive");
  const [infoOpen, setInfoOpen] = useState(false);
  const { data, header } = content;
  const { beehive } = data;
  const ref = useRef(null);

  const [ subView, setSubView ] = useState(null);
  const [ beehiveSelection, setBeehiveSelection ] = useState([]);
  const [ gridSelection, setGridSelection ] = useState([]);

  useEffect(()=> {
    let widget = null;

    const widgetSubViewEventHandler = (event) => {
      const { detail } = event;
      setSubView(detail.subView);
    };

    const widgetSelectionEventHandler = (event) => {
      const { detail } = event;
      setBeehiveSelection(detail?.beehiveSelection ?? []);
      setGridSelection(detail?.gridSelection ?? []);
    };

    if (ref?.current) {
      widget = ref.current;
      widget.addEventListener('ed-internal-beehive-subview-changed', widgetSubViewEventHandler);
      widget.addEventListener('ed-internal-beehive-selection-changed', widgetSelectionEventHandler);
    }

    return () => {
      if (widget) {
        widget.removeEventListener('ed-internal-beehive-subview-changed', widgetSubViewEventHandler);
        widget.removeEventListener('ed-internal-beehive-selection-changed', widgetSelectionEventHandler);
      }
    };
  }, [ref]);

  let beehiveBallsDataRef = beehive.map((beehiveItem) => ({
    runs: beehiveItem.runs,
    x: +beehiveItem.x,
    y: +beehiveItem.y,
    video: beehiveItem.video
  }));
  beehiveBallsDataRef = JSON.stringify(beehiveBallsDataRef);

  const widgetProps = {
    type: "beehive-view",
    "sub-view": subViewValue,
    "beehive-balls-data-ref": beehiveBallsDataRef,
    include: "axis-horizontal,axis-vertical,grid-labels,two-runs",
    ref
  };

  const useWidgetSelection = !!video?.available;
  if (useWidgetSelection) {
    widgetProps["user-selection"] = true;
  }

  const onVideoCancel = useCallback(
    () => {
      ref?.current?.clearSelection(subView);
    },
    [subView]
  );

  const onVideoPlay = useCallback(
    () => {
      if (video?.available && video?.base?.length) {
        let videoUrl = `${video.base}`;
        let videos;

        switch (subViewValue) {
          case 'beehive': {
            videos = beehiveSelection.reduce((acc, curr) => acc.concat(curr.video ?? []), []);
            break;
          }
          case 'grid': {
            videos = gridSelection.reduce((acc, curr) => acc.concat(curr.video ?? []), []);
            break;
          }
          default: {
            break;
          }
        }

        if (videos?.length) {
          videos?.forEach((video) => {
            videoUrl += `&join_key=${video}`;
          });    
          showVideoModal(videoUrl);
        }
      }
    },
    [showVideoModal, beehiveSelection, gridSelection, video, subViewValue]
  );

  const showVideoButtons = (subView === 'beehive' && !!beehiveSelection?.length) || (subView === 'grid' && !!gridSelection?.length);

  return (
    <Flex direction="column" h="100%">
      <Header
        title={header}
        showInfoButton={false}
        infoOpen={infoOpen}
        setInfoOpen={setInfoOpen}
        showVideoButtons={showVideoButtons}
        onVideoPlay={onVideoPlay}
        onVideoCancel={onVideoCancel}
      />
      <Box mx="4" mb="4">
        <ButtonSelector
          fullWidth
          value={subViewValue}
          handleChange={setSubViewValue}
          options={subViewOptions}
        />
      </Box>
      <Box flex="1" minH="0">
        <ellipse-data-cricket {...widgetProps}></ellipse-data-cricket>
      </Box>
    </Flex>
  );
};
export default BeehiveViewContent;

