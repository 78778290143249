import { Flex } from '@chakra-ui/react';
import TabButtons from 'pages/_shared/components/FilterComponents/TabButtons';
import ClassFilter from 'pages/_shared/components/FilterComponents/ClassFilter';
import RoleFilter from 'pages/_shared/components/FilterComponents/RoleFilter';
import DateSelect from 'pages/_shared/components/FilterComponents/DateSelect';

const TeamFilterHeader = ({ objectData }) => {
  const { class_list = [] } = objectData;

  return (
    <Flex justify="space-between" w="100%" alignItems="center" flexWrap="wrap" gap="2">
      <TabButtons objectData={objectData} type="team" />
      <Flex gap="2" alignItems="center">
        <ClassFilter options={class_list} type="team" />
        <RoleFilter type="team" />
        <DateSelect type="team" />
      </Flex>
    </Flex>
  );
};

export default TeamFilterHeader;
