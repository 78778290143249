import isEqual from 'lodash.isequal';
import { useEffect, useRef, useState } from 'react';
import { createUUID } from '_shared/utils/utilFunctions';

const useWidgetId = (widgetParams) => {
  const [widgetId, setWidgetId] = useState(null);

  const prevWidgetParams = useRef(null);

  useEffect(() => {
    if (!isEqual(prevWidgetParams.current, widgetParams)) {
      setWidgetId(createUUID(['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l']));
      prevWidgetParams.current = widgetParams;
    }
  }, [widgetParams]);

  return widgetId;
};

export default useWidgetId;
