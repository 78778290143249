import { Center } from '@chakra-ui/react';
import PlayerDataTable from './PlayerDataTable';

const PlayerHomePageLists = ({ classIds, playerImgs, standard, selectedClass }) => {
  return (
    <>
      <Center marginBottom="10">
        <PlayerDataTable
          data={standard.batting[selectedClass]}
          playerImg={playerImgs}
          caption={`${classIds[selectedClass].short} Top Run Scorers in the past year`}
          tableType="batting"
        />
      </Center>

      <Center marginTop="10">
        <PlayerDataTable
          data={standard.bowling[selectedClass]}
          playerImg={playerImgs}
          caption={`${classIds[selectedClass].short} Top Wicket Takers in the past year`}
          tableType="bowling"
        />
      </Center>
    </>
  );
};

export default PlayerHomePageLists;
