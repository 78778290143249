import { Box, Flex } from '@chakra-ui/react';
import { parseDateTimeLocal } from '_shared/utils/dateTimeFunctions';
import RouterLink from '_shared/components/Utils/RouterLink';

const MatchInfo = ({ data }) => {
  const startDate = parseDateTimeLocal(data?.start_datetime_utc, 'DD/MM/YYYY');
  const startTime = parseDateTimeLocal(data?.start_datetime_utc, 'HH:mm');

  return (
    <Flex direction="column" gap="2" color="white.100" justifyContent="center" w="100%">
      <Box fontSize="sm" textAlign="center" fontWeight="700">
        <RouterLink to={`/comp/${data?.comp_id}`}>{data?.comp_name}</RouterLink>
      </Box>
      <Box fontSize="xs" textAlign="center">
        <RouterLink to={`/ground/${data?.ground_id}`}>{data?.ground_name}</RouterLink> | {`${startDate} ${startTime}`}
      </Box>
    </Flex>
  );
};

export default MatchInfo;
