import get from 'lodash.get';

export const getLiveScoreData = (scorecardData) => {
  const { current_bowlers } = scorecardData;
  const liveBatters = [];
  const liveBowler = [];
  const livePartnership = [];
  const currentInnings = scorecardData.innings.find((inn) => {
    return inn.live_status === 'current';
  });
  if (currentInnings) {
    const { batting: teamBatting, bowling: teamBowling, fow } = currentInnings;
    teamBatting.forEach((batsman) => {
      const { batting_hand } = scorecardData.players[batsman.player_id];
      batsman.batting_hand =
        batting_hand === 'left' ? 'LHB' : batting_hand === 'right' ? 'RHB' : '';
      if (batsman.dismissal_name === 'not out') {
        liveBatters.push(batsman);
      }
    });

    teamBowling.forEach((bowler) => {
      if (scorecardData.players[bowler.player_id].bowling_desc_abbrev) {
        bowler.bowling_type = scorecardData.players[bowler.player_id].bowling_desc_abbrev;
      } else if (scorecardData.players[bowler.player_id].bowling_desc) {
        bowler.bowling_type = scorecardData.players[bowler.player_id].bowling_desc;
      } else {
        bowler.bowling_type = scorecardData.players[bowler.player_id].bowling_type;
      }
      if (
        ['current', 'previous'].includes(bowler.live_status) ||
        (current_bowlers && current_bowlers.includes(parseInt(bowler.player_id)))
      ) {
        liveBowler.push(bowler);
      }
    });
    fow.forEach((currWick) => {
      if (currWick.fow_type === 'complete') {
        currWick.xr_diff = currWick.partnership_runs - currWick.partnership_xR;
        livePartnership.push(currWick);
      }
    });
  }
  return {
    liveBatters,
    liveBowler,
    livePartnership
  };
};

export const getBatterImpactAvailable = (liveBatters, scorecardData) => {
  let impact = false;
  liveBatters.forEach((el) => {
    if (get(scorecardData, `impact[${el.player_id}].batting_impact`)) {
      impact = true;
    }
  });
  return impact;
};

export const getBowlerImpactAvailable = (liveBowlers, scorecardData) => {
  let impact = false;
  liveBowlers.forEach((el) => {
    if (get(scorecardData, `impact[${el.player_id}].bowling_impact`)) {
      impact = true;
    }
  });
  return impact;
};

export const partnershipString = (fow) => {
  const partnershipNumber = parseInt(fow) + 1;
  let string = `${partnershipNumber}`;
  if (partnershipNumber === 1) {
    string += 'st';
  } else if (partnershipNumber === 2) {
    string += 'nd';
  } else if (partnershipNumber === 3) {
    string += 'rd';
  } else if (partnershipNumber > 3) {
    string += 'th';
  }
  string += ' Wkt';
  return string;
};
