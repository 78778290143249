import { createIcon } from '@chakra-ui/icons';

const CircleX = createIcon({
  displayName: 'CircleX',
  viewBox: '0 0 24 24',
  defaultProps: { width: '24px', height: '24px' },
  path: [
    <circle cx="12" cy="12" r="10" fill="currentColor" />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0042 13.058L15.0773 16.1311C15.2158 16.2696 15.3898 16.3404 15.5994 16.3436C15.809 16.3468 15.9863 16.276 16.1311 16.1311C16.276 15.9863 16.3485 15.8106 16.3485 15.6042C16.3485 15.3978 16.276 15.2222 16.1311 15.0773L13.0581 12.0042L16.1311 8.93112C16.2696 8.79267 16.3404 8.61864 16.3436 8.40902C16.3468 8.19942 16.276 8.02219 16.1311 7.87732C15.9863 7.73244 15.8106 7.66 15.6042 7.66C15.3978 7.66 15.2222 7.73244 15.0773 7.87732L12.0042 10.9504L8.93113 7.87732C8.79268 7.73886 8.61865 7.66802 8.40903 7.66482C8.19943 7.66161 8.0222 7.73244 7.87733 7.87732C7.73245 8.02219 7.66 8.19782 7.66 8.40422C7.66 8.61062 7.73245 8.78626 7.87733 8.93112L10.9504 12.0042L7.87733 15.0773C7.73886 15.2158 7.66803 15.3898 7.66483 15.5994C7.66161 15.809 7.73245 15.9863 7.87733 16.1311C8.0222 16.276 8.19783 16.3485 8.40423 16.3485C8.61063 16.3485 8.78626 16.276 8.93113 16.1311L12.0042 13.058Z"
      fill="white"
    />
  ]
});

export default CircleX;
