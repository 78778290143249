import { Box, Button, Flex } from '@chakra-ui/react';
import { MATCH_VIDEO_ENABLED, MATCH_VIDEO_ID } from '_shared/globalState/atoms';
import { useSetRecoilState, useRecoilState } from 'recoil';

const Comms = ({ ball, playerObj, vidAvailable }) => {
  const setVidExpanded = useSetRecoilState(MATCH_VIDEO_ENABLED);
  const [vidId, setVideoId] = useRecoilState(MATCH_VIDEO_ID);
  const {
    batting_player_id,
    dismissal_str,
    out_player_id,
    wides,
    noballs,
    byes,
    legbyes,
    penalties,
    batting_runs,
    batting_balls_faced,
    nonstriker_runs,
    nonstriker_balls_faced,
    nonstriker_player_id,
    team_balls,
    overs_unique,
    commentary
  } = ball;
  const extrasObj = { wides, noballs, byes, legbyes, penalty_runs: penalties };
  const out_runs_str =
    batting_player_id === out_player_id
      ? `${batting_runs} (${batting_balls_faced})`
      : nonstriker_player_id === out_player_id
      ? `${nonstriker_runs} (${nonstriker_balls_faced})`
      : null;
  const handleClick = () => {
    if (vidAvailable) {
      setVidExpanded(true);
      setVideoId(ball.ball_id);
    }
  };
  return (
    <Flex
      pb="2"
      fontSize="sm"
      gap={1}
      bg={vidId === ball?.ball_id?.toString() ? 'blue.100' : null}
      m={1}
      pt={1}
      pl={1}
    >
      <Box pr={2}>
        <Button
          size="sm"
          h="24px"
          px="1"
          onClick={() => handleClick()}
          _hover={
            vidAvailable ? { visibility: 'visible' } : { cursor: 'default', visibility: 'visible' }
          }
          _focusVisible={{ visibility: 'visible' }}
        >
          {overs_unique}, ball {team_balls}
        </Button>
      </Box>
      <Box>
        <Box dangerouslySetInnerHTML={{ __html: commentary }} pt="1px" flexGrow={4} />
        <Flex mt={2}>
          {dismissal_str ? (
            <>
              <Box
                bg={'ballScore.w.bg'}
                color={'ballScore.w.color'}
                border={'ballScore.w.border'}
                fontWeight="bold"
                padding={1}
              >
                W
              </Box>
              <Box
                bg={'ballScore.w.bg'}
                color={'ballScore.w.color'}
                border={'ballScore.w.border'}
                fontWeight="bold"
                padding={1}
              >
                {out_player_id && Object.keys(playerObj).includes(out_player_id)
                  ? playerObj[out_player_id]
                  : null}
              </Box>
              <Box
                bg={'ballScore.w.bg'}
                color={'ballScore.w.color'}
                border={'ballScore.w.border'}
                fontWeight="bold"
                padding={1}
              >
                {dismissal_str}
              </Box>
              <Box
                bg={'ballScore.w.bg'}
                color={'ballScore.w.color'}
                border={'ballScore.w.border'}
                fontWeight="bold"
                padding={1}
              >
                {out_runs_str}
              </Box>
            </>
          ) : null}
          {Object.keys(extrasObj).map((key) => {
            if (extrasObj[key] && extrasObj[key] !== '0') {
              return (
                <Box
                  bg={'ballScore.w.bg'}
                  color={'ballScore.w.color'}
                  border={'ballScore.w.border'}
                  fontWeight="bold"
                  padding={1}
                  ml={2}
                  mr={2}
                >
                  {extrasObj[key]} {key.replaceAll('_', ' ').slice(0, key.length - 1)}
                  {parseInt(extrasObj[key]) > 1 ? 's' : null}
                </Box>
              );
            }
            return null;
          })}
        </Flex>
      </Box>
    </Flex>
  );
};

const BBBComms = ({ balls = [], playerObj, videos, videoAllowed }) => {
  return (
    <Box>
      {balls.map((ball) => {
        return (
          <Comms
            ball={ball}
            playerObj={playerObj}
            key={ball.ball_id}
            vidAvailable={
              !videoAllowed ? false : Object.keys(videos).includes(String(ball.ball_id))
            }
          />
        );
      })}
    </Box>
  );
};

export default BBBComms;
