import {
  Box,
  Button,
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderMark,
  RangeSliderThumb,
  RangeSliderTrack
} from '@chakra-ui/react';

const presetButtonVariant = (value, range) => {
  if (value[0] === range?.min && value[1] === range?.max) return 'primary';
  return 'tertiary';
};

const FilterSelect = ({ range, presets, handleFilterChange, name, filterValues }) => {
  const selectedValues = filterValues[name] || '~';
  const splitValues = selectedValues.split('~');
  const minValue = splitValues[0] || range?.min;
  const maxValue = splitValues[1] || range?.max;
  const value = [parseInt(minValue), parseInt(maxValue)];

  const setRange = (value) => {
    let newValue = '';
    if (value[0] !== range?.min) {
      newValue += value[0];
    }
    newValue += '~';
    if (value[1] !== range?.max) {
      newValue += value[1];
    }
    handleFilterChange(name, newValue === '~' ? null : newValue);
  };

  const handleSetPreset = (preset) => {
    if (!preset?.min || !preset?.max) return;
    const minValue = preset.min < range.min ? range.min : preset.min;
    const maxValue = preset.max > range.max ? range.max : preset.max;
    const newValue = `${minValue}~${maxValue}`;
    handleFilterChange(name, newValue);
  };

  return (
    <Box py="2">
      <RangeSlider
        aria-label="over-range"
        onChange={(val) => setRange(val)}
        min={range?.min}
        max={range?.max}
        value={value}
      >
        <RangeSliderMark
          value={value[0]}
          mt="2"
          minW="5"
          ml="-2.5"
          fontSize="sm"
          textAlign="center"
        >
          {value[0]}
        </RangeSliderMark>
        <RangeSliderMark
          value={value[1]}
          mt="2"
          minW="5"
          ml="-2.5"
          fontSize="sm"
          textAlign="center"
        >
          {value[1]}
        </RangeSliderMark>
        <RangeSliderTrack>
          <RangeSliderFilledTrack bg="primary.main" />
        </RangeSliderTrack>
        <RangeSliderThumb index={0} bg="primary.main" />
        <RangeSliderThumb index={1} bg="primary.main" />
      </RangeSlider>
      {!!presets?.length !== 0 && (
        <Flex gap="2" mt="7" flexWrap="wrap">
          {presets.map((el, idx) => {
            return (
              <Button
                key={`fsb_${idx}`}
                variant={presetButtonVariant(value, el.range)}
                flex="1"
                onClick={() => handleSetPreset(el.range)}
              >
                {el.label}
              </Button>
            );
          })}
        </Flex>
      )}
    </Box>
  );
};

export default FilterSelect;
