import { Flex } from '@chakra-ui/react';
import FilterElement from './FilterElements/FilterElement';
import { useState } from 'react';
import FormAccordian from '_shared/components/FormAccordian';

const FilterGroup = ({ isActive, label, handleFilterChange, filterValues, elements = [] }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <FormAccordian
      isActive={isActive}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      label={label}
    >
      <Flex px="4" pb="3" my="1px" direction="column" gap="2">
        {elements.map((el) => {
          return (
            <FilterElement
              {...el}
              handleFilterChange={handleFilterChange}
              key={el.name}
              filterValues={filterValues}
            />
          );
        })}
      </Flex>
    </FormAccordian>
  );
};

export default FilterGroup;
