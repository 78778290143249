import { configureAbly, useChannel } from '@ably-labs/react-hooks';

export const usePlaylistChannelListen = (
  refetch,
  setVidClipping,
  playlistId,
  userName,
  setMessage
) => {
  configureAbly({
    key: 'AjWlRA.ILsg-Q:EczJWkXDmgEhu0Q5yVbL4j_ElD49uC_Gc4TXbIH9K5Y',
    clientId: userName
  });

  useChannel(`centurion-playlist-${playlistId}`, (message) => {
    const { name } = message;
    if (name === 'playlist building finished') {
      setVidClipping(false);
      refetch();
    } else if (name.includes('Playlist maker failed')) {
      // sets error message
      setMessage(name);
      // error message exists for 10 seconds and then returns to the non-built state due to error on server
      setTimeout(() => {
        setVidClipping(false);
      }, 10000);
    } else {
      setMessage(name);
    }
  });
};
