import { useEffect, useRef } from 'react';
import { Avatar, Box, Flex, Grid, Image } from '@chakra-ui/react';
import autoAnimate from '@formkit/auto-animate';
import AddUserFavourites from '_shared/components/AddUserFavourites';

const PlayerV2SubHeader = ({ size, data = {} }) => {
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  return (
    <Box bg="primary.shade.70" ref={parent}>
      <Flex justify="space-between" py={4} px={5} gap="5">
        <Box boxSize="12">
          <Avatar size="full" src={data.img ? data.img : null} />
        </Box>
        <Flex flexGrow={1} direction="column" gap="10px">
          <Flex h="48px" alignItems="center">
            <Flex flexGrow={1} alignItems="center" gap="3">
              <Box color="white" fontSize={20} fontWeight="700">
                {data.main_heading}
              </Box>
              <Image h="5" objectFit="contain" src={data.favicon} alt="Nationality Flag" />
            </Flex>
            <AddUserFavourites favType={'player'} favId={data.id} />
          </Flex>
        </Flex>
      </Flex>
      {size === 'large' && (
        <Grid
          gridTemplateColumns={{ base: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' }}
          gap="2"
          px="4"
          pb="4"
        >
          {data?.info?.map(({ label, value }) => {
            return (
              <Box bg="white.10" flex="1" borderRadius="4" py="2" px="3" lineHeight="1.3">
                <Box fontSize="xs" color="white.56">
                  {label}
                </Box>
                <Box fontSize="md" color="white.100" fontWeight="700">
                  {value}
                </Box>
              </Box>
            );
          })}
        </Grid>
      )}
    </Box>
  );
};

export default PlayerV2SubHeader;
