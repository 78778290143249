import { Button, Flex } from '@chakra-ui/react';
import { ErrorMessage, Form } from 'formik';

const FormLayout = ({
  isSubmitting,
  formSubmitError,
  isValid,
  dirty,
  buttonText,
  onCancel,
  children
}) => {
  return (
    <Form>
      <Flex direction="column">
        {children}
        {formSubmitError && <ErrorMessage description={formSubmitError} />}
        <Flex gap="2">
          {onCancel ? (
            <Button onClick={onCancel} flex="1" variant="tertiary">
              Cancel
            </Button>
          ) : null}
          <Button
            spinnerPlacement="start"
            type="submit"
            disabled={isSubmitting || !(isValid && dirty)}
            flex="1"
          >
            {buttonText}
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};

export default FormLayout;
