import FormRadioButtons from 'Forms/_shared/FormElements/FormRadioButtons';

const plotOptions = [
  { plot_type: 'Table', id: '2' },
  { plot_type: 'Text', id: '1' },
  { plot_type: 'Video', id: '4' },
  // { plot_type: 'Pitchmap', id: '5' },
  // { plot_type: 'Beehive', id: '6' },
  { plot_type: 'Bar', id: '7' },
  { plot_type: 'Line', id: '8' },
  { plot_type: 'Pie', id: '9' }
];

const checkVideoUrl = (url) => {
  if (url) {
    return (
      url.includes('video_url=1') &&
      url.includes('selectopt=video_link') &&
      (url.includes('/desc_row/') || url.includes('/ball_row/'))
    );
  }
  return false;
};

const PanelType = ({ values, videoOptionAvailable }) => {
  const { qt_api } = values;
  const options = plotOptions
    .filter((el) => {
      return el.id !== '4' || checkVideoUrl(qt_api) || videoOptionAvailable;
    })
    .map((el) => ({ value: el.id, label: el.plot_type }));
  return <FormRadioButtons name="panel_type_id" options={options} label="Panel Type" />;
};

export default PanelType;
