import { Checkbox, CheckboxGroup, Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { Field } from 'formik';

const FormCheckboxGroup = ({ name, options, label, isDisabled, columnStack }) => {
  return (
    <FormControl mt="3">
      <Flex justify="space-between" alignItems="start">
        {label && <FormLabel minW={'100px'}>{label}</FormLabel>}
        <CheckboxGroup isDisabled={isDisabled}>
          <Flex flexWrap="wrap" gap="2" direction={columnStack && 'column'}>
            {options.map((opt) => {
              return (
                <Field name={`${name}.${opt.value}`} key={opt.value}>
                  {({ field }) => {
                    const { onChange, value, name } = field;
                    return (
                      <Checkbox isChecked={value} onChange={onChange} name={name}>
                        {opt.label}
                      </Checkbox>
                    );
                  }}
                </Field>
              );
            })}
          </Flex>
        </CheckboxGroup>
      </Flex>
    </FormControl>
  );
};

export default FormCheckboxGroup;
