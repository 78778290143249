import { createIcon } from '@chakra-ui/icons';

const Pause = createIcon({
  displayName: 'Pause',
  viewBox: '0 0 24 24',
  defaultProps: { width: '24px', height: '24px' },
  path: (
    <path
      d="M16 19C15.45 19 14.9792 18.8042 14.5875 18.4125C14.1958 18.0208 14 17.55 14 17V7C14 6.45 14.1958 5.97917 14.5875 5.5875C14.9792 5.19583 15.45 5 16 5C16.55 5 17.0208 5.19583 17.4125 5.5875C17.8042 5.97917 18 6.45 18 7V17C18 17.55 17.8042 18.0208 17.4125 18.4125C17.0208 18.8042 16.55 19 16 19ZM8 19C7.45 19 6.97917 18.8042 6.5875 18.4125C6.19583 18.0208 6 17.55 6 17V7C6 6.45 6.19583 5.97917 6.5875 5.5875C6.97917 5.19583 7.45 5 8 5C8.55 5 9.02083 5.19583 9.4125 5.5875C9.80417 5.97917 10 6.45 10 7V17C10 17.55 9.80417 18.0208 9.4125 18.4125C9.02083 18.8042 8.55 19 8 19Z"
      fill="currentColor"
    />
  )
});

export default Pause;
