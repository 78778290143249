import { Box, Flex } from '@chakra-ui/react';

const WinVizHeader = ({ winvizData }) => {
  const {
    draw_percent,
    team1_abbreviation,
    team1_percent,
    team2_abbreviation,
    team2_percent,
    tie_percent
  } = winvizData || {};

  const drawTiePercent = (() => {
    const draw = draw_percent ? parseInt(draw_percent) : 0;
    const tie = tie_percent ? parseInt(tie_percent) : 0;
    return draw + tie;
  })();

  if (!winvizData || Object.keys(winvizData).length === 0) {
    return null;
  }

  return (
    <Flex direction="column" w="100%" gap="1">
      <Flex justifyContent="space-between">
        <Flex gap="1" alignItems="center">
          <Box bg="winvizHeader.team1" h="3" w="3" rounded="full"></Box>
          <Box color="white.100">{team1_abbreviation}</Box>
          <Box color="white.100" fontWeight="700">
            {team1_percent}%
          </Box>
        </Flex>
        <Flex gap="1" alignItems="center">
          <Box bg="winvizHeader.team2" h="3" w="3" rounded="full"></Box>
          <Box color="white.100">{team2_abbreviation}</Box>
          <Box color="white.100" fontWeight="700">
            {team2_percent}%
          </Box>
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" overflow="hidden" w="100%" h="2">
        <Box
          w={`${team1_percent}%`}
          borderTopStartRadius={10}
          borderBottomStartRadius={10}
          bg="winvizHeader.team1"
        />
        <Box w={`${drawTiePercent}%`} bg="black.3" />
        <Box
          w={`${team2_percent}%`}
          borderTopEndRadius={10}
          borderBottomEndRadius={10}
          bg="winvizHeader.team2"
        />
      </Flex>
      {drawTiePercent !== 0 && (
        <Flex pt="1">
          <Box w={`${team1_percent}%`} />
          <Flex w={`${drawTiePercent}%`} justifyContent="center" className="drawtext">
            <Flex
              gap="1"
              color="white.100"
              bg="white.10"
              px="1.5"
              py="0.5"
              rounded="md"
              fontSize="sm"
            >
              <Box>DRAW</Box>
              <Box fontWeight="700">{drawTiePercent}%</Box>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default WinVizHeader;
