import { Box, Flex, List } from '@chakra-ui/react';
import { useState } from 'react';
import {
  greyCardHover,
  selectedCardHover
} from '_shared/components/cssStylePatterns/cssStylePatterns';
import TeamImage from '_shared/components/TeamImage';
import FixtureComponent from '_shared/components/FixtureComponent/FixtureComponent';

const initSelect = (data) => {
  const teamOptions = Object.keys(data);
  if (teamOptions.length !== 0) {
    return parseInt(teamOptions[0]);
  }
  return null;
};

const PreMatchRecentForm = ({ panelData }) => {
  const { data } = panelData;
  const [selectedTeamId, setSelectedTeamId] = useState(initSelect(data));
  return (
    <Flex direction="column" h="100%">
      <Flex px="2" py="2" gap="1">
        {Object.values(data).map((value) => {
          const { team_id, logo_url, abbreviation } = value?.team_data || {};
          const styles = team_id === selectedTeamId ? selectedCardHover : greyCardHover;
          return (
            <Flex
              {...styles}
              p="2"
              gap="2"
              alignItems="center"
              flex="1"
              fontWeight="700"
              onClick={() => setSelectedTeamId(team_id)}
            >
              <TeamImage
                team={{
                  id: team_id,
                  logo: logo_url
                }}
                size={36}
              />
              <Box>{abbreviation}</Box>
            </Flex>
          );
        })}
      </Flex>
      <Box flexGrow="1" overflow="auto">
        {data?.[selectedTeamId]?.results ? (
          <List m="3" mb="3" spacing="2">
            {data?.[selectedTeamId]?.results.map((matchData) => {
              return (
                <FixtureComponent
                  matchData={{ ...matchData, id: matchData.match_id }}
                  key={matchData.id}
                />
              );
            })}
          </List>
        ) : null}
      </Box>
    </Flex>
  );
};

export default PreMatchRecentForm;
