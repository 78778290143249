import aggregated_results_table from './aggregated_results_table';
import wagon_wheel_wagon_wheel from './wagon_wheel_wagon_wheel';
import pitchmap_pitchmap from './pitchmap_pitchmap';
import beehive_beehive from './beehive_beehive';

const configDefs = {
  aggregated_results_table,
  wagon_wheel_wagon_wheel,
  pitchmap_pitchmap,
  beehive_beehive
};

export default configDefs;
