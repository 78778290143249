import { useEffect, useState } from 'react';
import useGetFilterOptions from './useGetFilterOptions';

const useCategoryFilter = (panelData) => {
  const { categoryOptions } = useGetFilterOptions(panelData);
  const [categoryId, setCategoryId] = useState('all');

  useEffect(() => {
    if (
      categoryOptions.length > 0 &&
      categoryId !== 'all' &&
      (categoryId === null || !categoryOptions.find((el) => el.value === categoryId))
    ) {
      setCategoryId(categoryOptions[0].value);
    } else if (categoryId !== 'all' && categoryOptions.length === 0) {
      setCategoryId('all');
    }
  }, [categoryId, categoryOptions]);
  return { categoryOptions, categoryId, setCategoryId };
};

export default useCategoryFilter;
