import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';

const EditModal = ({ isOpen, handleClose, children }) => {
  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody minH="400px" px="3">
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
