import { Table, Tbody, Td, Th, Thead, Tr, chakra, TableContainer, Tooltip } from '@chakra-ui/react';
import { useTable, useSortBy } from 'react-table';

import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';

import useVideoModal from 'modals/hooks/useVideoModal';
import TriangleRightIcon from '_icons/outline/24/TriangleRight';
import RouterLink from '_shared/components/Utils/RouterLink';
import stickyStyles from './stickyStyles';

const edgeRowPaddingX = '1rem';
const defaultRowPaddingX = '2';
const defaultRowPaddingY = '12px';

const renderArrow = (rows, column) => {
  return (
    <chakra.span px="1">
      {rows?.length > 1 && column.isSorted ? (
        column.isSortedDesc ? (
          <TriangleDownIcon aria-label="sorted descending" />
        ) : (
          <TriangleUpIcon aria-label="sorted ascending" />
        )
      ) : (
        <TriangleDownIcon visibility="hidden" />
      )}
    </chakra.span>
  );
};

const ReactTable = ({
  columns,
  data,
  size,
  rowPaddingY,
  rowHeight,
  stickyColumn,
  sortable = true
}) => {
  const { showVideoModal } = useVideoModal();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      disableSortBy: !sortable
    },
    useSortBy
  );

  const hasVideo = (rowIndex, columnVideoKey) => {
    return !!data?.[rowIndex]?.[columnVideoKey];
  };

  const hasLink = (rowIndex, columnLinkKey) => {
    return !!data?.[rowIndex]?.[columnLinkKey];
  };

  const launchVideo = (rowIndex, columnVideoKey) => {
    const videoUrl = data?.[rowIndex]?.[columnVideoKey];
    if (!videoUrl) return;
    showVideoModal(videoUrl);
  };
  const getLink = (rowIndex, columnLinkKey) => {
    return data?.[rowIndex]?.[columnLinkKey];
  };
  const sxStyles = {
    '& tr': {
      borderTop: '1px',
      borderColor: 'black.8'
    }
  };
  if (stickyColumn) {
    Object.entries(stickyStyles).forEach(([key, value]) => {
      sxStyles[key] = value;
    });
  }
  return (
    <TableContainer>
      <Table
        {...getTableProps()}
        size={size || 'sm'}
        w="100%"
        minW="220px"
        variant="unstyled"
        colorScheme="table"
        sx={sxStyles}
      >
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()} bg="black.3">
              {headerGroup.headers.map((column, index) => {
                const textAlign = column.isNumeric ? 'right' : 'left';
                const rowPaddingX =
                  index === 0 || index === headerGroup.headers.length - 1
                    ? edgeRowPaddingX
                    : defaultRowPaddingX;
                return (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title={rows?.length > 1 && column.canSort ? 'Toggle SortBy' : ''}
                    isNumeric={column.isNumeric}
                    px={rowPaddingX}
                    py={defaultRowPaddingY}
                    lineHeight={1}
                    className="tooltip"
                    color="black.76"
                    fontWeight="700"
                    fontSize="xs"
                    userSelect="none"
                    textAlign={textAlign}
                  >
                    {textAlign === 'right' && renderArrow(rows, column)}
                    <Tooltip label={column.render('tipText')} aria-label="tooltip" placement="top">
                      <chakra.span>{column.render('Header')}</chakra.span>
                    </Tooltip>
                    {textAlign === 'left' && renderArrow(rows, column)}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} h={rowHeight}>
                {row.cells.map((cell, index) => {
                  const textAlign = cell.column.isNumeric ? 'right' : 'left';
                  const fontWeight = cell.column.bold ? '700' : '400';
                  const fontSize = cell.column.fontSize || 'xs';
                  const rowPaddingX =
                    index === 0 || index === row.cells.length - 1
                      ? edgeRowPaddingX
                      : defaultRowPaddingX;
                  return (
                    <Td
                      {...cell.getCellProps()}
                      isNumeric={cell.column.isNumeric}
                      px={rowPaddingX}
                      py={rowPaddingY != null ? rowPaddingY : defaultRowPaddingY}
                      lineHeight={1}
                      color="black.76"
                      fontSize={fontSize}
                      position="relative"
                      textAlign={textAlign}
                      fontWeight={fontWeight}
                    >
                      {hasLink(row.index, cell.column.linkKey) ? (
                        <RouterLink to={getLink(row.index, cell.column.linkKey)}>
                          {cell.render('Cell')}
                        </RouterLink>
                      ) : (
                        <chakra.span
                          position="relative"
                          display="inline-block"
                          verticalAlign="middle"
                        >
                          {cell.render('Cell')}
                          {hasVideo(row.index, cell.column.videoKey) && (
                            <chakra.span
                              color="transparent"
                              position="absolute"
                              top={-1}
                              right={-1.5}
                              _hover={{
                                cursor: 'pointer',
                                color: 'white.100',
                                bg: '#40cc79',
                                borderRadius: '4px',
                                fontWeight: '700'
                              }}
                              onClick={() => launchVideo(row.index, cell.column.videoKey)}
                              display="flex"
                              alignItems={'center'}
                              gap={1}
                              paddingTop={1}
                              paddingLeft={1.5}
                              paddingBottom={1}
                              paddingRight={1.5}
                            >
                              <TriangleRightIcon width={3} height={3} />
                              {cell.render('Cell')}
                            </chakra.span>
                          )}
                        </chakra.span>
                      )}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ReactTable;
