const { useEffect } = require('react');

const usePageTitle = (title) => {
  useEffect(() => {
    document.title = title || 'Centurion';
    return () => (document.title = 'Centurion');
  }, [title]);
};

export default usePageTitle;
