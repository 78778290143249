import { Flex } from '@chakra-ui/react';
import VideoScoreComponent from './VideoScoreComponent';
import BatterBowlerComponent from './BatterBowlerComponent';
const VideoObject = ({
  el,
  id,
  selectedVid,
  selectedVideos,
  setSelectedVideos,
  isChecked,
  handleClick
}) => {
  return (
    <Flex
      // {...whiteCardHover}
      borderRadius="md"
      bg="white.100"
      border="2px"
      borderColor={selectedVid ? 'positive.main' : 'transparent'}
      _hover={{ boxShadow: 'small' }}
      // overflow="hidden"
      cursor="grab"
      key={id}
      direction="column"
      gap="4"
      pb="4"
      onMouseDown={() => {
        handleClick();
      }}
    >
      <VideoScoreComponent
        el={el}
        isChecked={isChecked}
        checkBox={true}
        selectedVideos={selectedVideos}
        setSelectedVideos={setSelectedVideos}
      />
      <BatterBowlerComponent el={el} />
    </Flex>
  );
};

export default VideoObject;
