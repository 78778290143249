import { Flex, Box, Table, Tbody, Tr, Td, TableContainer } from '@chakra-ui/react';
import { useState } from 'react';
import ButtonSelector from '_shared/components/ButtonSelector';
import TableHeader from '../_shared/TableHeader';
import { battingHeadersOrder, bowlingHeadersOrder } from './columnHeaders';

const statChoices = ['batting', 'bowling'];

const createTableRow = (data, columns) => {
  const cells = [];
  columns.forEach((el, idx) => {
    const { content, isNumeric } = el;
    cells.push(
      <Td isNumeric={isNumeric} key={idx}>
        {data[content] ? data[content] : '-'}
      </Td>
    );
  });
  return cells;
};

const PlayerStats = ({ panelData, classId }) => {
  const [statType, setStatType] = useState('batting');
  const headersList = statType === 'batting' ? battingHeadersOrder : bowlingHeadersOrder;
  const { stats, classes } = panelData.data;
  const battingBowlingStats = stats[0];

  return (
    <Box m="2">
      <Flex alignItems="flex-end" gap="2">
        <ButtonSelector value={statType} options={statChoices} handleChange={setStatType} />
        <Box ml="3" alignSelf="center" fontSize="sm" fontWeight="600">
          {classes[classId]?.medium_name}
        </Box>
      </Flex>
      <TableContainer>
        <Table size="sm" whiteSpace="nowrap" mt="2.5">
          <TableHeader
            columns={headersList.map((el) => {
              return { ...el, content: el.content.replace('_', ' ') };
            })}
            bg="black.3"
          />
          <Tbody>
            {battingBowlingStats[statType] &&
            battingBowlingStats[statType][classId] &&
            battingBowlingStats[statType][classId].length > 0 ? (
              <Tr>{createTableRow(battingBowlingStats[statType][classId][0], headersList)}</Tr>
            ) : null}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PlayerStats;
