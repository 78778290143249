import { Flex, List, Select } from '@chakra-ui/react';
import { useState } from 'react';
import FlexHeaderScroll from '_shared/components/LayoutComponents/FlexHeaderScroll';
import ButtonSelector from '_shared/components/ButtonSelector';
import FixtureComponent from '_shared/components/FixtureComponent/FixtureComponent';
import StandingsTable from './StandingsTable';

function checkRespectiveGroups(data) {
  const group = [];
  data.forEach((el) => {
    if (el.group_name && !group.includes(el.group_name)) {
      group.push(el.group_name);
    }
  });
  return group;
}

function organiseLeagueDivisions(data, groups) {
  const returnData = {};
  groups.forEach((group) => {
    const groupData = [];
    data.forEach((el) => {
      if (el.group_name === group) {
        groupData.push(el);
      }
    });
    returnData[group] = groupData;
  });
  return returnData;
}

const CompLeagueTable = ({ data, matches }) => {
  let groups = [];
  if (data.length > 0) {
    groups = checkRespectiveGroups(data);
  }
  const [groupSelected, setGroupSelected] = useState(groups.length > 1 ? groups[0] : null);
  const [display, setDisplay] = useState('ladder');

  const getHeader = () => {
    return (
      <Flex px="2" py="2" gap="1">
        {!matches ? (
          <ButtonSelector handleChange={setGroupSelected} value={groupSelected} options={groups} />
        ) : null}
        {!!matches ? (
          <ButtonSelector
            handleChange={setDisplay}
            value={display}
            options={['ladder', 'fixtures']}
            buttonMinWidth="120px"
          />
        ) : null}
        {!!matches && groups.length !== 0 && display === 'ladder' ? (
          <Select
            value={groupSelected}
            onChange={(e) => setGroupSelected(e.target.value)}
            maxW="300px"
            bg="white"
            size="md"
            fontSize="14px"
          >
            {groups.map((el) => {
              return <option value={el}>{el}</option>;
            })}
          </Select>
        ) : null}
      </Flex>
    );
  };

  const getDisplay = () => {
    if (display === 'ladder') {
      if (groups.length !== 0) {
        const groupData = organiseLeagueDivisions(data, groups);
        return <StandingsTable data={groupData[groupSelected]} />;
      }
      return <StandingsTable data={data} />;
    }
    if (display === 'fixtures' && (matches.live || matches.forthcoming)) {
      return (
        <List m="3" mb="3" spacing="2">
          {matches?.live
            ? matches.live.map((matchData) => {
                return <FixtureComponent matchData={matchData} key={matchData.id} />;
              })
            : null}
          {matches?.forthcoming
            ? matches.forthcoming.map((matchData) => {
                return <FixtureComponent matchData={matchData} key={matchData.id} />;
              })
            : null}
        </List>
      );
    }
    return null;
  };

  return (
    <FlexHeaderScroll
      header={groups.length !== 0 || matches ? <Flex gap="1">{getHeader()}</Flex> : null}
      content={getDisplay()}
    />
  );
};

export default CompLeagueTable;
