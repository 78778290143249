export const getSearchParamsFromUrl = (urlString) => {
  try {
    const url = new URL(urlString);
    const params = new URLSearchParams(url.search);
    return Object.fromEntries(params);
  } catch {
    return {};
  }
};

export const checkParamsIncludeVariable = (params, id, objectType) => {
  const entries = Object.entries(params);

  if (
    ['team', 'comp'].includes(objectType) &&
    params?.[objectType] &&
    parseInt(params?.[objectType]) === id
  ) {
    return objectType;
  }
  const variable = entries.find((el) => {
    return parseInt(el[1]) === id;
  });

  if (variable) {
    return variable[0];
  }
  return null;
};
