import { Box, Flex } from '@chakra-ui/react';

const RangeDisplay = ({ val, highScore, label }) => {
  const percentage = (parseInt(val) / parseInt(highScore)) * 100;
  return (
    <Flex alignItems="center" fontSize="sm" fontWeight="600">
      <Box w="95px">{label}</Box>
      <Flex
        flexGrow={1}
        h="4"
        border="1px"
        borderColor="black.8"
        bg="black.3"
        rounded="full"
        overflow="hidden"
        p="2px"
      >
        <Box w={`${percentage}%`} bg="positive.main" rounded="full" />
      </Flex>
      <Box w="50px" textAlign="right">
        {val}
      </Box>
    </Flex>
  );
};

const ScoreRange = ({
  data: { lowest_score, highest_score, average_score, average_score_two_years }
}) => {
  const allLabels = [
    { val: lowest_score, label: 'Lowest' },
    { val: highest_score, label: 'Highest' },
    { val: average_score, label: 'Average' },
    { val: average_score_two_years, label: 'Last 2 years' }
  ]
    .filter((label) => {
      return label.val && label.val !== null;
    })
    .sort((a, b) => a.val - b.val);

  return (
    <Flex direction="column" gap="1">
      {allLabels.map((el) => {
        return <RangeDisplay highScore={highest_score} {...el} />;
      })}
    </Flex>
  );
};

export default ScoreRange;
