import { useState, useEffect, useRef } from 'react';
import { Input, useOutsideClick } from '@chakra-ui/react';
import { useQueryClient } from 'react-query';
import { updatePlaylistName } from '_shared/dataFetching/apiService';

const EditPlaylistNameInput = ({ id, playlistName, setRenameActive, setPlaylistName }) => {
  const queryClient = useQueryClient();
  const [editedName, setEditedName] = useState(playlistName);
  const inputRef = useRef();

  const handleSave = () => {
    const payload = { id, playlist_name: editedName };
    updatePlaylistName(payload);
    setPlaylistName(editedName);
    setRenameActive(false);
    setTimeout(() => queryClient.refetchQueries(['user_playlists']), 1000);
  };
  useOutsideClick({
    ref: inputRef,
    handler: handleSave
  });

  useEffect(() => {
    if (inputRef?.current !== document.activeElement) {
      inputRef.current.focus();
    }
  }, []);
  return (
    <Input
      variant="unstyled"
      value={editedName}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSave();
          return;
        }
        if (e.key === 'Escape') {
          setRenameActive(false);
          return;
        }
      }}
      onChange={(e) => {
        setEditedName(e.target.value);
      }}
      fontWeight="700"
      ref={inputRef}
      autofocus
    />
  );
};

export default EditPlaylistNameInput;
