import { Select } from '@chakra-ui/react';

const FilterSelect = ({ options, label, handleFilterChange, name, filterValues }) => {
  return (
    <Select
      value={filterValues[name] || ''}
      placeholder={label}
      onChange={(e) => handleFilterChange(name, e.target.value || null)}
    >
      {options.map((item, idx) => {
        return (
          <option key={`${item.value}_${idx}`} value={item.value}>
            {item.label}
          </option>
        );
      })}
    </Select>
  );
};

export default FilterSelect;
