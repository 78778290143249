const bowlerTypes = {
  right: {
    fast: ['RFM', 'RMF', 'RFM', 'RMS', 'RF', 'RM'],
    spin: ['OB', 'LBG', 'LB', 'RS']
  },
  left: {
    fast: ['LFM', 'LMF', 'LMS', 'LSM', 'LF', 'LM'],
    spin: ['SLU', 'SLA', 'LS']
  }
};

export default bowlerTypes;
