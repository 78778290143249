import { Flex, useDisclosure, Switch, chakra, IconButton, Icon } from '@chakra-ui/react';
import { useState, useRef } from 'react';
import { MdChevronLeft, MdChevronRight, MdOutlineFormatListBulleted } from 'react-icons/md';

import { Player, BigPlayButton, ControlBar } from 'video-react';
import 'video-react/dist/video-react.css';
import './Video.css';
import BallDescription from './BallDescription';
import VideoListModal from './VideoListModal';
import AddUserFavourites from '_shared/components/AddUserFavourites';
import NoDataDisplay from 'panels/Panel/NoDataDisplay';

const noFavouriteVideosError =
  "You don't have any favourite videos yet. Click the 'star' icon next to a video for it to appear here.";

const VideoPanel = ({ panelData }) => {
  const [vidDex, setvidDex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, panel_name, panel_type_id } = panelData;

  const videoRef = useRef();

  if (data.length === 0) {
    const message = panel_type_id === 21 ? noFavouriteVideosError : 'No video data available';
    return <NoDataDisplay message={message} />;
  }

  const vidIndexLogic = (forward) => {
    if (forward) {
      if (vidDex === data.length - 1) {
        setvidDex(0);
      } else {
        setvidDex((prev) => prev + 1);
      }
    } else {
      if (vidDex === 0) {
        setvidDex(data.length - 1);
      } else {
        setvidDex((prev) => prev - 1);
      }
    }
  };

  return (
    <>
      <Player
        ref={videoRef}
        src={data[vidDex].video_url}
        fluid={false}
        className="video-player-panel"
        width={'100%'}
        height={'100%'}
        playsInline={true}
        autoPlay={autoPlay}
        muted={true}
        onEnded={() => vidIndexLogic(true)}
      >
        <BigPlayButton position="center" />
        <ControlBar autoHide={true} className="control-bar-panel" autoHideTime={1000} />
      </Player>
      <Flex my="1" mx="2" alignItems="center">
        <Flex gap="4" flexGrow="1">
          <Flex alignItems="center" gap="2">
            <IconButton
              onClick={() => onOpen()}
              size="xs"
              color="black.76"
              variant="ghost"
              aria-label="all-videos"
              icon={<Icon boxSize="5" as={MdOutlineFormatListBulleted} />}
            />
            <Flex gap="2">
              <chakra.span fontSize="12px">Autoplay</chakra.span>
              <Switch
                id="autoplay"
                onChange={() => {
                  setAutoPlay((prevAutoPlay) => !prevAutoPlay);
                }}
              />
            </Flex>
          </Flex>
          <Flex alignItems="center">
            <IconButton
              icon={<MdChevronLeft size={24} />}
              color="black.76"
              variant="ghost"
              size="xs"
              onClick={() => vidIndexLogic(false)}
            />
            <IconButton
              icon={<MdChevronRight size={24} />}
              color="black.76"
              variant="ghost"
              size="xs"
              onClick={() => vidIndexLogic(true)}
            />
          </Flex>
        </Flex>
        <AddUserFavourites favType="video" favId={data[vidDex].ball_id} lightBackground />
      </Flex>
      <BallDescription ballData={data[vidDex]} />
      <VideoListModal
        isOpen={isOpen}
        onClose={onClose}
        data={data}
        panel_name={panel_name}
        selectedVideo={vidDex}
        handleClick={(idx) => {
          onClose();
          setvidDex(idx);
        }}
      />
    </>
  );
};
export default VideoPanel;
