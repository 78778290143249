import './fullScreenError.css';

export default function FullScreenAuthError() {
  return (
    <div className="container">
      <div className="content">
        <div className="logo-container">
          <div className="logo">
            <img
              alt="logo"
              src="https://cv-qtp-images.s3.eu-west-2.amazonaws.com/cricviz-logo.png"
            />
          </div>
        </div>
        <h2>We are sorry</h2>
        <h1>You do not have access to this page</h1>
        <p>Contact us to find out about updating your access to Centurion</p>
        <a href="/">
          <button className="home-button">Home</button>
        </a>
      </div>
    </div>
  );
}
