import useResizeObserver from '@react-hook/resize-observer';
import { useLayoutEffect, useState } from 'react';

// Recommend retire this implementation of useElementSize and instead
// use the 3rd party package '@custom-react-hooks/use-element-size'
// (imported in the project) in all cases, as better handles 
// resize observing when the target.current changes.
const useElementSize = (target) => {
  const [size, setSize] = useState({});

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

export default useElementSize;
