import { useRef, useEffect } from 'react';
import { useNavigate } from '@tanstack/react-location';
import useVideoModal from 'modals/hooks/useVideoModal';

const ScorecardViewContent = ({ content }) => {

  const navigate = useNavigate();
  const { showVideoModal } = useVideoModal();
  const { data } = content;
  const ref = useRef(null);

  useEffect(()=> {
    let widget = null;

    const actionRequestedHandler = (event) => {
      const { detail } = event;
      const { action, value, context } = detail;
      switch (action) {
        case "player":
          {
            const playerId = value;
            if (playerId) {
              const playerLink = `/player/${playerId}`;
              const classId = data?.class_id;
              const role = context;
              const searchParams = {tab: 'profile'};
              if (classId != null) searchParams.class_id = classId;
              if (role != null) searchParams.role = role;
              navigate({ to: playerLink, search: searchParams});
            }
            break;
          }
        case "video":
          {
            const videoUrl = value;
            if (videoUrl) {
              showVideoModal(videoUrl);
            }
            break;
          }
        default:
          {
            break;
          }
      }
    };

    if (ref?.current) {
      widget = ref.current;
      widget.addEventListener('ed-internal-scorecard-action-requested', actionRequestedHandler);
    }

    return () => {
      if (widget) {
        widget.removeEventListener('ed-internal-scorecard-action-requested', actionRequestedHandler);
      }
    };
  }, [ref, showVideoModal, navigate, data]);

  const widgetProps = {
    type: "scorecard-view",
    'customer-id': process.env.REACT_APP_WIDGET_ID,
    "scorecard-data": JSON.stringify(content.data),
    "player-names": "full",
    include: "header,advanced,partnerships,player-links",
    ref
  };

  return (
    <ellipse-data-cricket {...widgetProps}></ellipse-data-cricket>
  );
};
export default ScorecardViewContent;

