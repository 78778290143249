import { Suspense } from 'react';
import { Box, Center, Spinner } from '@chakra-ui/react';
import { useMatch } from '@tanstack/react-location';
import { getObjectInformation } from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';

import MatchContainer from 'pages/match/components/MatchContainer';
import PlayerContainer from 'pages/player/components/PlayerContainer';
import CompContainer from 'pages/comp/components/CompContainer';
import TeamContainer from 'pages/team/components/TeamContainer';
import GroundContainer from 'pages/ground/components/GroundContainer';

const FetchComponent = ({ type }) => {
  const {
    params: { objectId }
  } = useMatch();
  const {
    isLoading: objectIsLoading,
    error: objectError,
    data: objectData,
    isFetching: objectIsFetching
  } = useQueryFetch([type, objectId], () => getObjectInformation(type, objectId), {
    staleTime: 30 * 1000
  });

  if (objectIsLoading) {
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    );
  }
  if (objectError && !objectIsFetching) {
    return (
      <Box>{`Unable to retrieve header data for ${type} ${objectId}: ${objectError.message}`}</Box>
    );
  }

  if (objectData) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        {type === 'match' ? <MatchContainer data={objectData} objectId={objectId} /> : null}
        {type === 'player' ? <PlayerContainer data={objectData} objectId={objectId} /> : null}
        {type === 'comp' ? <CompContainer pageData={objectData} objectId={objectId} /> : null}
        {type === 'team' ? <TeamContainer pageData={objectData} objectId={objectId} /> : null}
        {type === 'ground' ? <GroundContainer pageData={objectData} objectId={objectId} /> : null}
      </Suspense>
    );
  }
  return null;
};

export default FetchComponent;
