import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import capitalise from '_shared/utils/capitalise';
import useGraphPlotKey from '../hooks/useGraphPlotKey';
import getColourPalette from './utils/chartColours';

const PanelLineChart = ({ panelData, layoutInfo }) => {
  const graphKey = useGraphPlotKey(panelData, layoutInfo);
  const { data: plottingData, data_columns: dataColumns } = panelData;

  const { x } = dataColumns;
  const yList = dataColumns.y;
  const colours = getColourPalette(yList.length);
  const data = [];
  let min = 0;
  let max = 0;
  for (let i = 0; i < plottingData.length; i += 1) {
    const xval = plottingData[i][x];
    const rowData = { name: xval };
    for (let j = 0; j < yList.length; j += 1) {
      const yVal = parseFloat(plottingData[i][yList[j]]);
      rowData[yList[j]] = yVal;
      if (yVal > max) {
        max = yVal;
      }
      if (yVal < min) {
        min = yVal;
      }
    }
    data.push(rowData);
  }
  return (
    <ResponsiveContainer width="90%" height="90%" overflowX="scroll" key={graphKey}>
      <LineChart data={data} margin={{ top: 10, left: 0, right: 10, bottom: 0 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[min, max]} />
        <Tooltip />
        <Legend />
        {yList.map((columnName, idx) => {
          return (
            <Line
              key={idx}
              name={capitalise(columnName)}
              type="monotone"
              dataKey={columnName}
              stroke={colours[idx]}
              activeDot={{ r: 8 }}
              dot={false}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default PanelLineChart;
