import checkCompType from './utils/checkCompType';

const comp = [
  {
    value: 'summary',
    label: 'Summary'
  },
  {
    value: 'playerstats',
    label: 'Player statistics',
    labelSmall: 'Player stats',
    enabled: (objectData) => checkCompType(objectData) === 'comp'
  },
  {
    value: 'teamstats',
    label: 'Team statistics',
    labelSmall: 'Team stats',
    enabled: (objectData) => checkCompType(objectData) === 'comp'
  },
  {
    value: 'advanced',
    label: 'Advanced analysis',
    labelSmall: 'Advanced',
    enabled: (objectData) => checkCompType(objectData) === 'comp'
  }
];

export default comp;
