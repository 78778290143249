import { Box, Flex, Button } from '@chakra-ui/react';

import { HelpOutline24, CloseCircleOutline24, CloseOutline24 } from '_icons/outline/24';
import { HelpFilled24, CloseCircleFilled24, PlayFilled24 } from '_icons/filled/24';

const Header = ({
  title,
  showInfoButton,
  infoOpen,
  setInfoOpen,
  showVideoButtons,
  onVideoPlay,
  onVideoCancel,
  paddingBottom,
  stickyHeader
}) => {
  const InfoIconNormal = infoOpen ? CloseCircleOutline24 : HelpOutline24;
  const InfoIconHover = infoOpen ? CloseCircleFilled24 : HelpFilled24;

  return (
    <Box
      textTransform="uppercase"
      fontSize="sm"
      fontWeight="bold"
      p="4"
      paddingBottom={paddingBottom != null ? paddingBottom : '4'}
      lineHeight={1}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      userSelect="none"
      position={stickyHeader && 'sticky'}
      top={stickyHeader && '0'}
      bg="white.100"
    >
      {title}
      <Flex gap={2} alignItems={'center'}>
        {showVideoButtons && (
          <Box h={1} display="flex" alignItems="center" gap={2}>
            <Button
              variant="tertiary"
              minW="0"
              p="0"
              h="28px"
              w="28px"
              borderRadius="14px"
              onClick={onVideoCancel}
            >
              <CloseOutline24 h="20px" w="20px" />
            </Button>
            <Button
              variant="positive"
              minW="0"
              p="0"
              h="28px"
              w="28px"
              borderRadius="14px"
              onClick={onVideoPlay}
            >
              <PlayFilled24 h="20px" w="20px" />
            </Button>
          </Box>
        )}
        {showInfoButton && (
          <Box h="20px" w="20px" display="flex" position="relative">
            <Box
              h="100%"
              w="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              top="0"
              left="0"
            >
              <InfoIconNormal h="24px" w="24px" cursor="pointer" color="primary.main" />
            </Box>
            <Box
              h="100%"
              w="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              top="0"
              left="0"
              opacity="0"
              backgroundColor="white"
              sx={{
                '&:hover': { opacity: '1' }
              }}
            >
              <InfoIconHover
                h="24px"
                w="24px"
                cursor="pointer"
                onClick={() => setInfoOpen(!infoOpen)}
                color="primary.main"
              />
            </Box>
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default Header;
