import { IconButton, Flex, Tooltip } from '@chakra-ui/react';
import { Select, chakraComponents } from 'chakra-react-select';
import { Field } from 'formik';
import FilterLabel from './FilterLabel';
import capitalise from '_shared/utils/capitalise';
import { ChevronDownOutline24, CheckboxUncheckedOutline24 } from '_icons/outline/24';
import { CheckmarkFilled24 } from '_icons/filled/24';
import { useState } from 'react';

const components = {
  DropdownIndicator: (props) => (
    <chakraComponents.DropdownIndicator {...props}>
      <ChevronDownOutline24 color="black.60" />
    </chakraComponents.DropdownIndicator>
  ),
  IndicatorSeparator: () => null,
  ClearIndicator: () => null
};

const selectAllTags = ['dismissal_id'];

const MultiSelect = ({ filterData, isAccordion, isExpanded, setIsExpanded }) => {
  const { api_tag, label, options } = filterData;
  const [isSelectAll, setSelectAll] = useState(false);

  return (
    <Field name={api_tag}>
      {({ field: { value }, form: { setFieldValue } }) => {
        const selectAll = () => {
          if (isSelectAll) {
            setFieldValue(api_tag, []);
          } else {
            setFieldValue(api_tag, options);
          }
          setSelectAll((el) => !el);
        };

        return (
          <FilterLabel
            name={api_tag}
            label={capitalise(label)}
            isActive={!!value}
            isAccordion={isAccordion}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          >
            <Flex>
              <Select
                value={value}
                placeholder={`Select ${label}`}
                options={options}
                isMulti
                size="sm"
                useBasicStyles
                onChange={(v) => setFieldValue(api_tag, v)}
                chakraStyles={{
                  container: (prev) => ({
                    ...prev,
                    minHeight: '44px',
                    zIndex: '1',
                    width: '100%'
                  }),
                  menu: (prev) => ({
                    ...prev,
                    zIndex: 1400
                  })
                }}
                styles={{
                  menuPortal: (provided) => ({ ...provided, zIndex: 1400 })
                }}
                components={components}
                menuPortalTarget={document.body}
              />
              {selectAllTags.includes(api_tag) && (
                <Tooltip label={isSelectAll ? 'Deselect all' : 'Select all'} placement="top">
                  <IconButton
                    icon={isSelectAll ? <CheckmarkFilled24 /> : <CheckboxUncheckedOutline24 />}
                    color="black.60"
                    variant="ghost"
                    ml={1}
                    mr={-3}
                    onClick={() => {
                      selectAll();
                    }}
                  />
                </Tooltip>
              )}
            </Flex>
          </FilterLabel>
        );
      }}
    </Field>
  );
};

export default MultiSelect;
