import { Box, Center, Spinner } from '@chakra-ui/react';
import { getObjectInformation } from '_shared/dataFetching/apiService';

import HomePageContainer from './HomePageContainer';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';

const Home = () => {
  const {
    isLoading: objectIsLoading,
    error: objectError,
    data: objectData,
    isFetching: objectIsFetching
  } = useQueryFetch(['home'], () => getObjectInformation('home'));
  if (objectIsLoading) {
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    );
  }
  if (objectError && !objectIsFetching) {
    return <Box>{`Unable to retrieve home page data`}</Box>;
  }
  if (objectData) {
    return <HomePageContainer data={objectData} pageType="home" />;
  }
};

export default Home;
