import { FormControl, FormErrorMessage, FormLabel, VStack } from '@chakra-ui/react';
import { Field } from 'formik';
import { AsyncSelect } from 'chakra-react-select';

const components = {
  DropdownIndicator: null
};

const FormAsyncSelect = ({
  name,
  label,
  placeholder,
  isDisabled,
  fetchOptionsFunction,
  isClearable
}) => {
  if (!fetchOptionsFunction) {
    return null;
  }

  return (
    <Field name={name}>
      {({ field: { value }, form: { setFieldValue, errors, touched } }) => {
        return (
          <FormControl isInvalid={errors[name] && touched[name]} mt="3">
            <VStack alignItems="start">
              <FormLabel>{label}</FormLabel>
              <AsyncSelect
                onChange={(v) => setFieldValue(name, v)}
                value={value}
                cacheOptions={false}
                loadOptions={fetchOptionsFunction}
                placeholder={placeholder}
                isDisabled={isDisabled}
                defaultOptions
                isClearable={isClearable}
                components={components}
                chakraStyles={{
                  container: (baseStyles) => ({
                    ...baseStyles,
                    width: '100%'
                  })
                }}
                size="sm"
              />
            </VStack>
            <FormErrorMessage>{errors[name]}</FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default FormAsyncSelect;
