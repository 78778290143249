import {
  checkParamsIncludeVariable,
  getSearchParamsFromUrl
} from '_shared/utils/searchParamsUtils';
import { getDataColumns } from '../_shared/utils';

export const generateNewPanelPayload = (values) => {
  const {
    ext_api,
    qt_api,
    panel_id,
    page_type,
    panel_category,
    panel_page,
    nested_dict,
    classId,
    data_type,
    data_value
  } = values;

  const payload = {
    sport: 'cricket',
    data_columns: [],
    datasource_id: 0,
    is_hidden: 0,
    is_live: values.is_live ? '1' : '0',
    is_public: values.is_public ? '1' : '0',
    data_value: values.data_value.trim(),
    panel_name: values.panel_name,
    panel_type_id: values.panel_type_id,
    variable_id: values.variable_id,
    category_type_id: values.category_type_id
  };
  if (data_value) {
    payload.data_value = data_type.trim();
  }
  payload.data_type = data_type || 'qt';
  if (qt_api) {
    payload.qt_api = qt_api;
  }
  // if (min_date || max_date) {
  //   payload.between_dates = getDateRange(min_date, max_date);
  // }
  if (ext_api) {
    payload.qt_api = ext_api;
  }

  if (data_type === 'qt') {
    payload.nested_dict = 'result';
  } else if (nested_dict) {
    payload.nested_dict = nested_dict;
  }
  if (page_type) {
    payload.page_type = page_type;
  }
  if (panel_category) {
    payload.panel_category = panel_category;
  }
  if (panel_page) {
    payload.panel_page = panel_page;
  }
  if (panel_id) {
    payload.panel_id = panel_id;
  }
  if (classId) {
    payload.class = classId;
  }

  payload.data_columns = getDataColumns(values);
  if (values.dynamic_variable) {
    payload.dynamic_variable = values.dynamic_variable;
  } else {
    const searchParams = getSearchParamsFromUrl(values.qt_api);
    const dynamic_variable = checkParamsIncludeVariable(
      searchParams,
      values.variable_id,
      values.page_type
    );
    if (dynamic_variable) {
      payload.dynamic_variable = dynamic_variable;
    }
  }

  return payload;
};
