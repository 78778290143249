import trackingEnabled from './trackingEnabled';

export const searchSuccess = (searchString, linkUrl) => {
  if (trackingEnabled()) {
    window.pendo.track('search_success', {
      searchString,
      linkUrl
    });
  }
};
