import {
  PanelTypeChart,
  PanelTypeTable,
  PanelTypeText,
  PanelTypeVideo,
  PanelTypeWidget,
  RedBall,
  WhiteBall
} from '_icons/svgs';

export const getClassIcon = (classId) => {
  if ([1, 4, 11, 14].includes(classId)) {
    return RedBall;
  }
  return WhiteBall;
};

export const getPanelTypeIcon = (panelType) => {
  if (panelType === 'video') {
    return PanelTypeVideo;
  }
  if (['bar', 'line', 'pie'].includes(panelType)) {
    return PanelTypeChart;
  }
  if (panelType === 'table') {
    return PanelTypeTable;
  }
  if (panelType === 'text') {
    return PanelTypeText;
  }
  if (panelType === 'widget') {
    return PanelTypeWidget;
  }
  return WhiteBall;
};
