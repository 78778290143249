import { useMatch, useNavigate, useSearch } from '@tanstack/react-location';
import { getAdvancedContent, getAdvancedFilters } from '_shared/dataFetching/apiServiceV2';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import { convert64ToJSON, convertJSONTo64 } from '_shared/utils/utilFunctions';
import { useCallback, useEffect, useState } from 'react';
import equal from 'fast-deep-equal';
import validateFilters from '../utils/validateFilters';
import buildEnabledFilters from '../utils/buildEnabledFilters';
import cloneDeep from 'lodash.clonedeep';
import useRecentFilters from './useRecentFilters';

const checkEnabledFetch = (fetchValues) => {
  if (fetchValues === null) return false;
  if (Object.keys(fetchValues).length === 0) return false;
  return true;
};

const useAdvancedFilters = (type) => {
  const navigate = useNavigate();
  const search = useSearch();
  const { tab, class_id, role, q } = search;
  const initValues = q ? convert64ToJSON(q) : {};
  const [filterValues, setFilterValues] = useState(initValues);
  const [fetchValues, setFetchValues] = useState(null);

  const {
    params: { objectId }
  } = useMatch();
  const objectVariable = type !== 'player' ? type : role;

  const filterDefVariables = {
    class_id
  };
  if (tab === 'advanced_batting') filterDefVariables.role = 'batter';
  if (objectVariable !== 'player' && role === 'batter') filterDefVariables.role = 'batter';
  if (tab === 'advanced_bowling') filterDefVariables.role = 'bowler';
  if (objectVariable !== 'player' && role === 'bowler') filterDefVariables.role = 'bowler';

  const fetchAdvancedData = useQueryFetch(
    ['advanced', objectVariable, objectId, { ...filterDefVariables, fetchValues }],
    () => getAdvancedContent(objectVariable, objectId, { ...filterDefVariables, ...fetchValues }),
    {
      enabled: checkEnabledFetch(fetchValues)
    }
  );

  const fetchFilterDefs = useQueryFetch(
    ['filters', objectVariable, objectId, { ...filterDefVariables }],
    () => getAdvancedFilters(objectVariable, objectId, { ...filterDefVariables }),
    {
      staleTime: 30 * 1000,
      enabled: !!objectVariable
    }
  );

  const { data: filterDefs } = fetchFilterDefs;

  const { recentFilterOptions, handleRecentFiltersChange } = useRecentFilters(
    filterDefs,
    objectVariable,
    class_id
  );

  const applyFilterToUrl = useCallback(
    (fVals) => {
      const q = Object.keys(fVals).length !== 0 ? convertJSONTo64(fVals) : undefined;
      navigate({
        search: (old) => ({ ...old, q }),
        replace: true,
        fromCurrent: true
      });
    },
    [navigate]
  );

  useEffect(() => {
    if (filterDefs) {
      const newFilterValues = validateFilters(filterValues, filterDefs.filters);
      if (!equal(newFilterValues, filterValues)) {
        setFilterValues(newFilterValues);
      }
      if (fetchValues === null) {
        setFetchValues(newFilterValues);
        applyFilterToUrl(newFilterValues);
      }
    }
  }, [filterValues, filterDefs, fetchValues, applyFilterToUrl]);

  const handleFilterChange = (key, value) => {
    setFilterValues((p) => {
      const newValues = { ...p };
      if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
        delete newValues[key];
      } else {
        newValues[key] = value;
      }
      return newValues;
    });
  };

  const filterEmpty = equal(filterValues, {});

  const filterDirty = !equal(fetchValues, filterValues);

  const handleClearFilters = () => {
    setFilterValues({});
    setFetchValues({});
    applyFilterToUrl({});
  };

  const handleApplyFilters = () => {
    const newValues = cloneDeep(filterValues);
    setFetchValues(newValues);
    applyFilterToUrl(newValues);
    handleRecentFiltersChange({ value: convertJSONTo64(filterValues || '') });
  };

  const handleApplyFilterPreset = (preset) => {
    const { id, value } = preset;
    setFilterValues(value);
    setFetchValues(value);
    applyFilterToUrl(value);
    const presetValue = { id, value: convertJSONTo64(value || '') };
    handleRecentFiltersChange(presetValue);
  };

  const enabledFilters = buildEnabledFilters(filterValues, filterDefs);

  return {
    fetchValues,
    filterValues,
    filterEmpty,
    filterDirty,
    handleFilterChange,
    handleClearFilters,
    handleApplyFilters,
    fetchAdvancedData,
    fetchFilterDefs,
    enabledFilters,
    recentFilterOptions,
    handleApplyFilterPreset
  };
};

export default useAdvancedFilters;
