import { Box, Flex } from '@chakra-ui/react';
import FixtureStatusHeader from '_shared/components/FixtureComponent/FixtureStatusHeader';
import SessionStats from './SessionStats';
import TeamScore from '_shared/components/FixtureComponent/TeamScore';
import { getInningsData } from '_shared/components/FixtureComponent/utils';

const CricketScorePanel = ({ panelData }) => {
  const { scorecard } = panelData.data;
  const inningsData = getInningsData(scorecard);
  const { id, batting_first_team_id, team1_id, team2_id, match_status, result_str } =
    scorecard.match;
  const batting_second_team_id = team1_id === batting_first_team_id ? team2_id : team1_id;

  return (
    <Flex direction="column">
      <FixtureStatusHeader
        matchData={{ id, match_status, match_situation_str: result_str, result_str }}
      />
      <Flex direction="column">
        <Flex px="4" py="3" gap="2" direction="column">
          <TeamScore inningsData={inningsData[batting_first_team_id]} />
          <TeamScore inningsData={inningsData[batting_second_team_id]} />
        </Flex>
        <Box bg="black.3" mx="4" rounded="md">
          <SessionStats panelData={panelData} />
        </Box>
      </Flex>
    </Flex>
  );
};

export default CricketScorePanel;
