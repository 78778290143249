import { useRef, useEffect } from 'react';
import useVideoModal from 'modals/hooks/useVideoModal';

const CommentaryViewContent = ({ content }) => {

  const { showVideoModal } = useVideoModal();
  const ref = useRef(null);

  useEffect(()=> {
    let widget = null;

    const actionRequestedHandler = (event) => {
      const { detail } = event;
      const videoUrl = `${detail.video}`;
      showVideoModal({content: videoUrl, direct: true});
    };

    if (ref?.current) {
      widget = ref.current;
      widget.addEventListener('ed-internal-commentary-action-requested', actionRequestedHandler);
    }

    return () => {
      if (widget) {
        widget.removeEventListener('ed-internal-commentary-action-requested', actionRequestedHandler);
      }
    };
  }, [ref, showVideoModal]);

  const widgetProps = {
    type: "commentary-view",
    'customer-id': process.env.REACT_APP_WIDGET_ID,
    "commentary-data": JSON.stringify(content.data),
    "title": "ball by ball",
    include: "header",
    ref
  };

  return (
    <ellipse-data-cricket {...widgetProps}></ellipse-data-cricket>
  );
};
export default CommentaryViewContent;

