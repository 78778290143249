// const panelsNotCached = ['MS_1004', 'MS_1002', 'MS_1000'];

const checkIncludeUUID = (config) => {
  if (config.url.includes('get_panel_data')) {
    return false;
  }
  if (config.url.includes('video_search')) {
    return false;
  }
  if (config.url.includes('user_details')) {
    return true;
  }
  if (config.url.includes('api.cricviz.com/qtp/v1')) {
    return true;
  }
  if (config.url.includes('cdn-query.cricviz.com')) {
    return false;
  }
  return false;
};
export default checkIncludeUUID;
