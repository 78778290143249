import { Box, Flex } from '@chakra-ui/react';
import { getBBBVideoData } from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import capitalise from '_shared/utils/capitalise';

const Tag = ({ children }) => {
  return (
    <Box color="black.60" bg="black.4" p="1" borderRadius="4px" fontSize="xs">
      {children}
    </Box>
  );
};

const TagComponent = ({ ballId }) => {
  const { data } = useQueryFetch(['bbbVideoData', ballId], () => getBBBVideoData(ballId));
  if (!data) return null;
  const {
    batting_feet_id,
    batting_shot_type_id,
    bowling_length_id,
    bowling_line_id,
    bowling_technique,
    dismissal,
    fielder_name,
    release_speed,
    seam_deviation,
    swing_deviation
  } = data;
  const degrees = { seam: seam_deviation, swing: swing_deviation };
  const kph = [release_speed];
  const lvl2_filters = [
    bowling_technique,
    bowling_length_id,
    bowling_line_id,
    batting_feet_id,
    batting_shot_type_id
  ];

  return (
    <Flex direction="column" bg="white.100" borderRadius="md">
      <Flex gap="2" pt="2" flexWrap="wrap">
        {lvl2_filters
          .filter((el) => el)
          .map((innerEl) => {
            return <Tag key={`${innerEl}-field`}>{innerEl}</Tag>;
          })}
        {kph
          .filter((el) => el)
          .map((innerEl) => {
            return <Tag key={`${innerEl}-speed`}>{`Release Speed ${Math.round(innerEl)} kph`}</Tag>;
          })}
        {Object.keys(degrees)
          .filter((el) => degrees[el])
          .map((innerEl) => {
            return (
              <Tag key={`${innerEl}-degrees`}>{`${capitalise(innerEl)} ${
                Math.round(10 * degrees[innerEl]) / 10
              }º`}</Tag>
            );
          })}
        {fielder_name && dismissal ? (
          <Tag key="fielder-field">{`${capitalise(dismissal)} ${fielder_name}`}</Tag>
        ) : fielder_name ? (
          <Tag key="fielder-field-2">{`Fielded ${fielder_name}`}</Tag>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default TagComponent;
