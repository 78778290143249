import manhattan_manhattan from './manhattan_manhattan';
import winviz_over_time_winviz_history from './winviz_over_time_winviz_history';
import head_to_head_team_head_to_head from './head_to_head_team_head_to_head';
import match_conditions_match_conditions from './match_conditions_match_conditions';
import recent_performance_team_recent_performance from './recent_performance_team_recent_performance';

const configDefs = {
  manhattan_manhattan,
  winviz_over_time_winviz_history,
  head_to_head_team_head_to_head,
  match_conditions_match_conditions,
  recent_performance_team_recent_performance
};

export default configDefs;
