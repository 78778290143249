import { Flex, Box, Text } from '@chakra-ui/react';

const percentilesData = [
  {
    color: 'stat.high',
    line1: '68-100 percentile',
    line2: '(above average performance)'
  },
  {
    color: 'stat.medium',
    line1: '34-67 percentile',
    line2: '(average performance)'
  },
  {
    color: 'stat.low',
    line1: '0-33 percentile',
    line2: '(below average performance)'
  }
];

const PercentilesInfo = ({ line1, line2, line3 }) => {

    return (
      <Box>
        {line1 && (<><Box>{line1}</Box><br /></>)}
        {line2 && (<><Box>{line2}</Box><br /></>)}
        <Flex
          justifyContent={'center'}
          gap="4"
          wrap="wrap"
          mx="auto"
        >
          {percentilesData.map(
            (percentilesDataItem, idx) => (
              <Box display="flex" alignItems={'center'} justifyContent={'center'} gap="8px" flex="1 0 auto" key={`percentiles_${idx}`}>
                <Box h="8px" w="30px" bg={percentilesDataItem.color} borderRadius="500" flex="none"></Box>
                <Box alignSelf={'start'} minW="200px" textAlign="left">
                  <Text>{percentilesDataItem.line1}</Text>
                  <Text>{percentilesDataItem.line2}</Text>
                </Box>
              </Box>
            )
          )}
        </Flex>
        {line3 && (<><br /><Box>{line3}</Box></>)}
      </Box>
    );
  };

  export default PercentilesInfo;