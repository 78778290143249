import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { PANEL_SETTINGS_AVAILABLE } from '_shared/globalState/atoms';

function usePanelSettingsAvailable(isEnabled = true) {
  const [panelSettingsAvailable, setPanelSettingsAvailable] =
    useRecoilState(PANEL_SETTINGS_AVAILABLE);
  useEffect(() => {
    if (!isEnabled) {
      setPanelSettingsAvailable(false);
    } else {
      setPanelSettingsAvailable(true);
    }
    return () => {
      setPanelSettingsAvailable(false);
    };
  }, [setPanelSettingsAvailable, isEnabled]);
  return panelSettingsAvailable;
}

export default usePanelSettingsAvailable;
