import { FormControl, FormErrorMessage, FormLabel, HStack, Select, VStack } from '@chakra-ui/react';
import { Field } from 'formik';

const FormSelect = (props) => {
  const { name } = props;
  return (
    <Field name={name}>{(fieldProps) => <SelectComponent {...fieldProps} {...props} />}</Field>
  );
};

const SelectComponent = ({
  field,
  form,
  name,
  label,
  placeholder,
  isDisabled,
  options,
  isHorizontal
}) => {
  const content = (
    <>
      <FormLabel>{label}</FormLabel>
      <Select {...field} placeholder={placeholder} isDisabled={isDisabled}>
        {options.map((item) => {
          if (item.options) {
            return (
              <optgroup label={item.label} key={`group_${item.label}`}>
                {item.options.map((opt) => {
                  return (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  );
                })}
              </optgroup>
            );
          }
          return (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </Select>
    </>
  );
  return (
    <FormControl isInvalid={form.errors[name] && form.touched[name]} mt="3">
      {isHorizontal ? (
        <HStack alignItems="end">{content}</HStack>
      ) : (
        <VStack alignItems="start">{content}</VStack>
      )}
      <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
    </FormControl>
  );
};

export default FormSelect;
