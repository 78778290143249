import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { USER_CONFIG_MODAL } from '_shared/globalState/atoms';
import UserConfigForm from './UserConfig';

const UserConfigModal = () => {
  const [configModalOpen, setConfigModalOpen] = useRecoilState(USER_CONFIG_MODAL);

  const handleClose = () => {
    setConfigModalOpen(false);
  };

  return (
    <Modal isOpen={configModalOpen} onClose={handleClose} size="lg" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent maxW="500px">
        <ModalBody px="10" py="5">
          <UserConfigForm handleClose={handleClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UserConfigModal;
