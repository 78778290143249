import { Formik } from 'formik';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { editQTQuery } from '_shared/dataFetching/apiService';
import EditQueryOptions from './EditQueryOptions';
import useEditFormInitialValues from './hooks/useEditFormInitValues';
import { Box } from '@chakra-ui/react';
import FormLayout from 'Forms/_shared/components/FormLayout';
import { generateEditQTQueryPayload } from './utils';

const EditQTQuery = ({ panelData, handleClose }) => {
  const [formSubmitError, setFormSubmitError] = useState(null);
  const queryClient = useQueryClient();
  const { initialValues, queryOptions } = useEditFormInitialValues(panelData);

  if (initialValues === null || queryOptions === null) {
    return <Box>Loading...</Box>;
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={() => {
        setFormSubmitError(null);
        const errors = {};
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const payload = generateEditQTQueryPayload(values, initialValues, queryOptions, panelData);
        const res = await editQTQuery(payload);
        if (res.status === 200) {
          queryClient.refetchQueries(['panel', payload.panel_id]);
          handleClose();
        } else {
          setFormSubmitError(res.data);
        }
        setSubmitting(false);
      }}
    >
      {(props) => (
        <FormLayout buttonText="Save Query" {...props}>
          <EditQueryOptions panelData={panelData} queryOptions={queryOptions} />
          {formSubmitError && <Box>{formSubmitError}</Box>}
        </FormLayout>
      )}
    </Formik>
  );
};

export default EditQTQuery;
