import { Flex, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { forwardRef, useState, useCallback } from 'react';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { CalendarOutline24, CloseOutline24 } from '_icons/outline/24';
import { createPortal } from 'react-dom';

const customDateInput = ({ value, onClick, onChange, placeholder }, ref) => {
  return (
    <Input
      autoComplete="off"
      background="white"
      placeholder={placeholder}
      value={value}
      ref={ref}
      onClick={onClick}
      onChange={onChange}
    />
  );
};

const CustomInput = forwardRef(customDateInput);

const DatePickerIcon = ({ onChange, onClick, calendarOpen }) => {
  return (
    <Flex
      cursor="pointer"
      onMouseDown={(e) => {
        if (calendarOpen) {
          onChange(null);
        }
        onClick();
        e.stopPropagation();
      }}
      alignItems="center"
    >
      {calendarOpen ? <CloseOutline24 /> : <CalendarOutline24 />}
      {/* <CalendarOutline24 /> */}
    </Flex>
  );
};

const ChakraReactDatepicker = ({ selectedDate, onChange, maxW, placeholder, ...props }) => {
  const [calendarOpen, setCalendarOpen] = useState(false);

  const onClickIcon = useCallback(() => {
    setCalendarOpen(!calendarOpen);
  }, [calendarOpen, setCalendarOpen]);

  const onClickOutside = useCallback(() => {
    setCalendarOpen(false);
  }, [setCalendarOpen]);

  const onDatePickerChange = useCallback((date, event) => {
    setCalendarOpen(false);
    onChange(date, event);
  }, [onChange, setCalendarOpen]);

  return (
    <>
      <InputGroup className="dark-theme" maxW={maxW} zIndex="5">
        <ReactDatePicker
          selected={selectedDate}
          onChange={onDatePickerChange}
          className="react-datapicker__input-text"
          customInput={<CustomInput />}
          dateFormat="dd/MM/yyy"
          placeholderText={placeholder}
          popperContainer={({ children }) => createPortal(children, document.body)}
          open={calendarOpen}
          onClickOutside={onClickOutside}
          closeOnScroll={true}
          {...props}
        />
        <InputRightElement
          color="gray.500"
          children={<DatePickerIcon
          onChange={onDatePickerChange}
          onClick={onClickIcon}
          calendarOpen={calendarOpen} />} 
        />
      </InputGroup>
    </>
  );
};

export default ChakraReactDatepicker;
