import { Box } from '@chakra-ui/react';

const HeaderInfo = ({ children, infoOpen }) => {

  return (
    <Box
      position="absolute"
      bg="#fffc"
      color="black.100"
      fontSize="14px"
      fontWeight="400"
      top="0"
      left="0"
      width="100%"
      height={infoOpen ? '100%' : '0'}
      transitionProperty="height"
      transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
      transitionDuration="500ms"
      overflow="hidden"
      backdropFilter="auto"
      backdropBlur="30px"
      textAlign="center"
      px="20px"
      userSelect="none"
      overflowY="auto"
      sx={{
        scrollbarWidth: 'none',                       // Hide scrollbar (Firefox)
        '&::-webkit-scrollbar': { display: 'none' },  // Hide scrollbar (WebKit browsers : Chrome, Safari, Edge ...)
        '&-ms-overflow-style:': { display: 'none' }   // Hide scrollbar (IE)
      }}
      zIndex="10"
    >
      <Box
        opacity={infoOpen ? 1 : 0}
        transitionProperty="opacity"
        transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
        transitionDuration="500ms"
        w="100%"
        h="100%"
        pb="20px"
        display="table"
      >
        <Box display="table-cell" verticalAlign="middle">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderInfo;