import { createIcon } from '@chakra-ui/icons';

const CricketBall = createIcon({
  displayName: 'CricketBall',
  viewBox: '0 0 20 20',
  defaultProps: { fill: 'none' },
  path: [
    <circle cx="10" cy="10" r="8" stroke="currentcolor" strokeWidth="2" />,
    <line
      x1="16.0098"
      y1="6.11137"
      x2="6.11031"
      y2="16.0109"
      stroke="currentcolor"
      strokeDasharray="2 2"
    />,
    <line
      x1="13.8887"
      y1="3.9893"
      x2="3.98921"
      y2="13.8888"
      stroke="currentcolor"
      strokeDasharray="2 2"
    />
  ]
});

export default CricketBall;
