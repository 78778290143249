import { createIcon } from '@chakra-ui/icons';

const Location = createIcon({
  displayName: 'Location',
  viewBox: '0 0 20 20',
  defaultProps: { width: '20px', height: '20xp' },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 9C16 9.72359 15.7128 10.594 15.1759 11.5459C14.6461 12.4852 13.9166 13.4274 13.139 14.2728C12.3627 15.1168 11.5607 15.8403 10.9053 16.3452C10.5762 16.5988 10.2995 16.7853 10.093 16.9029C10.0583 16.9227 10.0273 16.9395 10 16.9537C9.97271 16.9395 9.94173 16.9227 9.90698 16.9029C9.70049 16.7853 9.42382 16.5988 9.09469 16.3452C8.43929 15.8403 7.63728 15.1168 6.86102 14.2728C6.08344 13.4274 5.3539 12.4852 4.82413 11.5459C4.28721 10.594 4 9.72359 4 9C4 5.68629 6.68629 3 10 3C13.3137 3 16 5.68629 16 9ZM9.85774 17.0175C9.85782 17.0174 9.86073 17.0163 9.86627 17.0149C9.86044 17.0169 9.85766 17.0176 9.85774 17.0175ZM10.1337 17.0149C10.1393 17.0163 10.1422 17.0174 10.1423 17.0175C10.1424 17.0176 10.1396 17.0169 10.1337 17.0149ZM17 9C17 12.866 11 18 10 18C9 18 3 12.866 3 9C3 5.13401 6.13401 2 10 2C13.866 2 17 5.13401 17 9ZM10 11C11.1046 11 12 10.1046 12 9C12 7.89543 11.1046 7 10 7C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11Z"
      fill="currentColor"
    />
  )
});

export default Location;
