import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import useLocalStorage from '_shared/hooks/useLocalStorage';
import { convert64ToJSON } from '_shared/utils/utilFunctions';
import { useMemo } from 'react';
import validateFilters from '../utils/validateFilters';
import cloneDeep from 'lodash.clonedeep';
import updateRecentFilters from '../utils/updateRecentFilters';

const useRecentFilters = (filterDefs, objectVariable) => {
  const { data: userInfo } = useUserDetails();
  const key = `${userInfo?.username}_recent_filters`;
  const [recentFilters, setRecentFilters] = useLocalStorage(key, {});
  const recentFilterOptions = useMemo(() => {
    const newFilterOptions = [];
    if (filterDefs) {
      const recentFiltersByKey = recentFilters[objectVariable];
      if (recentFiltersByKey) {
        recentFiltersByKey.forEach((el) => {
          const convertedValue = convert64ToJSON(el.value);
          const newFilterValues = validateFilters(convertedValue, filterDefs.filters);
          newFilterOptions.push({
            id: el.id,
            value: newFilterValues
          });
        });
      }
    }
    return newFilterOptions;
  }, [filterDefs, objectVariable, recentFilters]);
  const handleRecentFiltersChange = (value) => {
    setRecentFilters((prev) => {
      const newValues = cloneDeep(prev);
      newValues[objectVariable] = updateRecentFilters(value, newValues[objectVariable]);
      return newValues;
    });
  };
  return { recentFilterOptions, handleRecentFiltersChange };
};

export default useRecentFilters;
