const capitaliseWord = (string) => {
  return `${string[0].toUpperCase()}${string.slice(1)}`;
};

const capitalise = (value) => {
  if (typeof value !== 'string') {
    return '';
  }
  return value.replaceAll('_', ' ').split(' ').map(capitaliseWord).join(' ');
};

export default capitalise;
