import { Navigate } from '@tanstack/react-location';
import UserComponent from 'pages/user/components/User';

export const userRoutes = {
  path: 'user',
  children: [
    {
      path: '/',
      element: <Navigate to="/home" />
    },
    {
      path: ':objectId',
      element: <UserComponent />
    }
  ]
};
