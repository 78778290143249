import { createIcon } from '@chakra-ui/icons';

const Duplicate = createIcon({
  displayName: 'Duplicate',
  viewBox: '0 0 24 24',
  defaultProps: { width: '24px', height: '24px' },
  path: (
    <path
      d="M5.5 22C4.95 22 4.47917 21.8042 4.0875 21.4125C3.69583 21.0208 3.5 20.55 3.5 20V7C3.5 6.71667 3.59583 6.47917 3.7875 6.2875C3.97917 6.09583 4.21667 6 4.5 6C4.78333 6 5.02083 6.09583 5.2125 6.2875C5.40417 6.47917 5.5 6.71667 5.5 7V20H15.5C15.7833 20 16.0208 20.0958 16.2125 20.2875C16.4042 20.4792 16.5 20.7167 16.5 21C16.5 21.2833 16.4042 21.5208 16.2125 21.7125C16.0208 21.9042 15.7833 22 15.5 22H5.5ZM9.5 18C8.95 18 8.47917 17.8042 8.0875 17.4125C7.69583 17.0208 7.5 16.55 7.5 16V4C7.5 3.45 7.69583 2.97917 8.0875 2.5875C8.47917 2.19583 8.95 2 9.5 2H18.5C19.05 2 19.5208 2.19583 19.9125 2.5875C20.3042 2.97917 20.5 3.45 20.5 4V16C20.5 16.55 20.3042 17.0208 19.9125 17.4125C19.5208 17.8042 19.05 18 18.5 18H9.5ZM9.5 16H18.5V4H9.5V16Z"
      fill="currentColor"
    />
  )
});

export default Duplicate;
