import { Box, Select } from '@chakra-ui/react';
import { useNavigate, useSearch } from '@tanstack/react-location';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import { useEffect } from 'react';

const checkClassAvailable = (options, class_id) => {
  if (!class_id) return false;
  if (options.length === 0) return false;
  return options.some((option) => {
    return option.value === class_id;
  });
};

const getDefaultOption = (options, user_class_id) => {
  if (user_class_id && options.some((el) => el.value === user_class_id)) {
    return user_class_id;
  }
  return options?.[0]?.value;
};

const ClassFilter = ({ type, options = [] }) => {
  const navigate = useNavigate();
  const search = useSearch();
  const { class_id } = search;
  const classAvailable = checkClassAvailable(options, class_id);
  const { data: userInfo } = useUserDetails();
  const { user_class_id } = userInfo || {};
  const defaultOption = getDefaultOption(options, user_class_id);

  const currentPath = window.location.pathname;
  const applyFilter = currentPath?.includes(`/${type}/`);

  useEffect(() => {
    if (applyFilter && (!class_id || !classAvailable)) {
      navigate({
        search: (old) => ({ ...old, class_id: defaultOption }),
        replace: true,
        fromCurrent: true
      });
    }
  }, [class_id, navigate, classAvailable, defaultOption, applyFilter]);

  const handleChange = (e) => {
    navigate({
      search: (old) => ({ ...old, class_id: e.target.value }),
      replace: true,
      fromCurrent: true
    });
  };

  if (options.length === 0) {
    return null;
  }

  if (options.length === 1) {
    const label = options?.[0]?.label || '';
    return <Box>{label}</Box>;
  }

  return (
    <Select value={class_id} onChange={handleChange} w="240px" bg="white" h="44px">
      {options.map((item) => {
        const { value, label } = item;
        return (
          <option value={value} key={value}>
            {label}
          </option>
        );
      })}
    </Select>
  );
};

export default ClassFilter;
