import { Flex } from '@chakra-ui/react';
import ClassFilter from 'pages/_shared/components/FilterComponents/ClassFilter';
import RoleFilter from 'pages/_shared/components/FilterComponents/RoleFilter';
import TabButtons from 'pages/_shared/components/FilterComponents/TabButtons';

const PlayerFilterHeader = ({ objectData }) => {
  const { class_id_list = [], default_role } = objectData;

  return (
    <Flex justify="space-between" w="100%" alignItems="center" flexWrap="wrap" gap="2">
      <TabButtons type="player" />
      <Flex gap="2">
        <ClassFilter options={class_id_list} type="player" />
        <RoleFilter defaultRole={default_role} type="player" />
      </Flex>
    </Flex>
  );
};

export default PlayerFilterHeader;
