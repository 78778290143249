import { forwardRef } from 'react';

const WidgetComponent = forwardRef(({ widgetParams }, ref) => {

  const newWidgetParams = {
    'customer-id': process.env.REACT_APP_WIDGET_ID,
    ref
  };

  Object.entries(widgetParams).forEach(([key, value]) => {
    const newKey = typeof key === 'string' ? key.split('_').join('-') : key;
    const newValue = typeof value === 'string' ? value.split('_').join('-') : value;
    newWidgetParams[newKey] = newValue;
  });

  return (
    <ellipse-data-cricket {...newWidgetParams}></ellipse-data-cricket>
  );
});

export default WidgetComponent;
