import { Avatar, Center, Flex, GridItem, Icon, Link } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-location';
import { MdRadioButtonChecked } from 'react-icons/md';
import AddUserFavourites from '_shared/components/AddUserFavourites';

const PlayerFavListItem = ({ item }) => {
  const { id, name, player_img, opposition, match_id, match_date, summary, live } = item;
  const navigate = useNavigate();
  const handleClick = (e) => {
    e.preventDefault();
    navigate({ to: `/player/${id}` });
  };
  const handleMatchClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    navigate({ to: `/match/${match_id}` });
  };
  return (
    <GridItem
      rounded="md"
      bg="black.3"
      border="1px"
      borderColor={live ? 'positive.tint.60' : 'transparent'}
      _hover={{ boxShadow: 'small', cursor: 'pointer', bg: 'white.100', borderColor: 'black.24' }}
      display="flex"
      onClick={handleClick}
      overflow="hidden"
    >
      <Flex direction="column" w="100%" justifyContent="center">
        {live ? (
          <Flex
            px="3"
            py="1"
            bg="positive.tint.90"
            color="positive.shade.30"
            fontSize="12px"
            fontWeight="700"
            gap="1"
            alignItems="center"
          >
            <Icon as={MdRadioButtonChecked} />
            <Link onClick={handleMatchClick}>Playing now...</Link>
          </Flex>
        ) : null}
        <Flex p="4" gap="2">
          <Center>
            <Avatar size="lg" src={player_img ? player_img : null} />
          </Center>
          <Flex direction="column" flexGrow="1">
            <Flex fontWeight="700" fontSize="sm">
              {name}
            </Flex>
            <Flex direction="column" fontSize="xs">
              <Link onClick={handleMatchClick}>
                <Flex gap="2">
                  <Flex>v {opposition}</Flex>
                  <Flex>{match_date}</Flex>
                </Flex>
                <Flex>{summary}</Flex>
              </Link>
            </Flex>
          </Flex>
          <AddUserFavourites favType="player" favId={id} lightBackground />
        </Flex>
      </Flex>
    </GridItem>
  );
};

export default PlayerFavListItem;
