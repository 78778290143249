import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@chakra-ui/react';
import { Field } from 'formik';
import { useState } from 'react';

const checkPrecsion = (v) => {
  let precision = 0;
  const valueArray = v.split('~');
  const splitValue = valueArray[0].toString().split('.');
  if (splitValue[1]) {
    precision = splitValue[1].length;
  }
  return precision;
};

const NumberRange = ({ value, onChange, label, min, max, isDisabled }) => {
  const [precision] = useState(checkPrecsion(value));
  const [lower, upper] = value.split('~');
  const step = 1 / 10 ** precision;

  const handleChange = (value, type) => {
    if (type === 'lower') {
      const newValue = [value, upper].join('~');
      onChange(newValue);
    }
    if (type === 'upper') {
      const newValue = [lower, value].join('~');
      onChange(newValue);
    }
  };
  return (
    <FormControl mt="3">
      <Flex justify="space-between">
        <FormLabel>{label}</FormLabel>
        <HStack>
          <NumberInput
            size="sm"
            onChange={(v) => handleChange(v, 'lower')}
            value={lower}
            min={min}
            max={upper}
            precision={precision}
            step={step}
            isDisabled={isDisabled}
            maxW="150px"
          >
            <NumberInputField pattern="(-)?[0-9]*(.[0-9]+)?" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Box>to</Box>
          <NumberInput
            size="sm"
            onChange={(v) => handleChange(v, 'upper')}
            value={upper}
            min={lower}
            max={max}
            precision={precision}
            step={step}
            isDisabled={isDisabled}
            maxW="150px"
          >
            <NumberInputField pattern="(-)?[0-9]*(.[0-9]+)?" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </HStack>
      </Flex>
    </FormControl>
  );
};

const FormNumberRange = ({ name, min, max, label, isDisabled }) => {
  return (
    <Field name={name}>
      {({ field: { value }, form: { setFieldValue } }) => {
        const handleChange = (v) => {
          setFieldValue(name, v);
        };
        return (
          <NumberRange
            value={value}
            onChange={handleChange}
            min={min}
            max={max}
            label={label}
            isDisabled={isDisabled}
          />
        );
      }}
    </Field>
  );
};

export default FormNumberRange;
