import { Box, Button, ButtonGroup, Show, Hide } from '@chakra-ui/react';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { useEffect } from 'react';
import tabOptions from './tabOptions/index';

const getTabOptions = (type, objectData) => {
  const defs = tabOptions[type] || [];
  const tabs = [];
  defs.forEach((tab) => {
    if (!tab.enabled || tab.enabled(objectData)) {
      const { value, label, labelSmall } = tab;
      tabs.push({ value, label, labelSmall });
    }
  });
  return tabs;
};

const checkTabAvailable = (options, tab) => {
  if (!tab) return false;
  if (options.length === 0) return false;
  return options.some((option) => {
    return option.value === String(tab);
  });
};

const TabButtons = ({ type, objectData }) => {
  const navigate = useNavigate();
  const search = useSearch();
  const { tab } = search;
  const options = getTabOptions(type, objectData);
  const tabAvailable = checkTabAvailable(options, tab);
  const defaultOption = options?.[0]?.value;

  useEffect(() => {
    if (!tab || !tabAvailable) {
      navigate({
        search: (old) => ({ ...old, tab: defaultOption }),
        replace: true,
        fromCurrent: true
      });
    }
  }, [tab, navigate, tabAvailable, defaultOption]);

  const handleChange = (e) => {
    navigate({
      search: (old) => ({ ...old, tab: e.target.value }),
      replace: true,
      fromCurrent: true
    });
  };

  if (options.length === 0) {
    return null;
  }
  return (
    <Box>
      <ButtonGroup size="md">
        {options
          .filter((el) => Boolean(el))
          .map((option) => {
            const { label, labelSmall, value } = option;

            return (
              <Button
                key={value}
                onClick={handleChange}
                value={value}
                variant={value === tab ? 'secondary' : 'tertiary'}
                h="44px"
                rounded="md"
                fontSize="sm"
                fontWeight="bold"
              >
                <Show above="sm">{label}</Show>
                <Hide above="sm">{labelSmall || label}</Hide>
              </Button>
            );
          })}
      </ButtonGroup>
    </Box>
  );
};

export default TabButtons;
