import { useEffect, useState } from 'react';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import parseClassOptions from '../utils/parseClassOptions';
import { useNavigate } from '@tanstack/react-location';

const useClassFilter = (class_ids = {}) => {
  const navigate = useNavigate();
  const [classFilterId, setClassFilterId] = useState(null);
  const classOptions = parseClassOptions(class_ids);
  const { data: userInfo } = useUserDetails();
  const { user_class_id } = userInfo || {};

  useEffect(() => {
    if (classFilterId === null && classOptions && classOptions.length !== 0) {
      if (user_class_id && classOptions.some((el) => el.value === user_class_id.toString())) {
        setClassFilterId(user_class_id.toString());
      } else {
        setClassFilterId(classOptions[0].value);
      }
    }
  }, [classFilterId, classOptions, user_class_id]);

  const handleClassFilterChange = (value) => {
    setClassFilterId(value);
    navigate({
      search: (old) => ({ ...old, class_id: value }),
      replace: true,
      fromCurrent: true
    });
  };

  return { classOptions, classFilterId, handleClassFilterChange };
};

export default useClassFilter;
