import { Center, Image, Box, Heading, Text } from '@chakra-ui/react';
import RouterButton from '_shared/components/Utils/RouterButton';

export default function MatchError() {
  const headerString = "We're sorry, there has been an error loading this match";
  const messageString = 'Please try refreshing the page or return home';
  return (
    <Center>
      <Box>
        <Center>
          <Image
            src="https://cv-qtp-images.s3.eu-west-2.amazonaws.com/cricviz-logo.png"
            mt="30vh"
            mb="10"
            h={`50px`}
            objectFit="contain"
          />
        </Center>
        <Heading
          fontSize={{ base: '15px', md: '25px', lg: '30px' }}
          as="h2"
          size="xl"
          mb="5"
          align="center"
        >
          {headerString}
        </Heading>
        <Text fontSize={{ base: '12px', md: '15px', lg: '19px' }} mb="10" align="center">
          {messageString}
        </Text>
        <Center>
          <RouterButton variant="solid" to="/home">
            Home
          </RouterButton>
        </Center>
      </Box>
    </Center>
  );
}
