import { createIcon } from '@chakra-ui/icons';

const SoundOn = createIcon({
  displayName: 'SoundOn',
  viewBox: '0 0 24 24',
  defaultProps: { width: '24px', height: '24px' },
  path: (
    <path
      d="M19 11.975C19 10.5917 18.6333 9.32917 17.9 8.1875C17.1667 7.04583 16.1833 6.19167 14.95 5.625C14.7 5.50833 14.5167 5.32917 14.4 5.0875C14.2833 4.84583 14.2667 4.6 14.35 4.35C14.45 4.08333 14.6292 3.89167 14.8875 3.775C15.1458 3.65833 15.4083 3.65833 15.675 3.775C17.2917 4.49167 18.5833 5.5875 19.55 7.0625C20.5167 8.5375 21 10.175 21 11.975C21 13.775 20.5167 15.4125 19.55 16.8875C18.5833 18.3625 17.2917 19.4583 15.675 20.175C15.4083 20.2917 15.1458 20.2917 14.8875 20.175C14.6292 20.0583 14.45 19.8667 14.35 19.6C14.2667 19.35 14.2833 19.1042 14.4 18.8625C14.5167 18.6208 14.7 18.4417 14.95 18.325C16.1833 17.7583 17.1667 16.9042 17.9 15.7625C18.6333 14.6208 19 13.3583 19 11.975ZM7 15H4C3.71667 15 3.47917 14.9042 3.2875 14.7125C3.09583 14.5208 3 14.2833 3 14V10C3 9.71667 3.09583 9.47917 3.2875 9.2875C3.47917 9.09583 3.71667 9 4 9H7L10.3 5.7C10.6167 5.38333 10.9792 5.3125 11.3875 5.4875C11.7958 5.6625 12 5.975 12 6.425V17.575C12 18.025 11.7958 18.3375 11.3875 18.5125C10.9792 18.6875 10.6167 18.6167 10.3 18.3L7 15ZM16.5 12C16.5 12.7 16.3417 13.3625 16.025 13.9875C15.7083 14.6125 15.2917 15.125 14.775 15.525C14.6083 15.625 14.4375 15.6292 14.2625 15.5375C14.0875 15.4458 14 15.3 14 15.1V8.85C14 8.65 14.0875 8.50417 14.2625 8.4125C14.4375 8.32083 14.6083 8.325 14.775 8.425C15.2917 8.84167 15.7083 9.36667 16.025 10C16.3417 10.6333 16.5 11.3 16.5 12ZM10 8.85L7.85 11H5V13H7.85L10 15.15V8.85Z"
      fill="currentColor"
    />
  )
});

export default SoundOn;
