import { createIcon } from '@chakra-ui/icons';

const ArrowLeft = createIcon({
  displayName: 'ArrowLeft',
  viewBox: '0 0 24 24',
  defaultProps: { width: '24px', height: '24px' },
  path: (
    <path
      d="M9.70711 7.70711C10.0976 7.31658 10.0976 6.68342 9.70711 6.29289C9.31658 5.90237 8.68342 5.90237 8.29289 6.29289L3.29289 11.2929C2.90237 11.6834 2.90237 12.3166 3.29289 12.7071L8.29289 17.7071C8.68342 18.0976 9.31658 18.0976 9.70711 17.7071C10.0976 17.3166 10.0976 16.6834 9.70711 16.2929L6.41421 13L20.0556 13C20.5772 13 21 12.5523 21 12C21 11.4477 20.5772 11 20.0556 11L6.41421 11L9.70711 7.70711Z"
      fill="currentColor"
    />
  )
});

export default ArrowLeft;
