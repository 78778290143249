import {
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverBody,
  Input,
  Box,
  List,
  ListItem,
  InputGroup,
  InputRightElement,
  Spinner
} from '@chakra-ui/react';
import { CircleXFilled24 } from '_icons/filled/24';

const PlayerSearch = ({
  searchValue,
  setSearchValue,
  handleClick,
  searchResults,
  searchResultsIsLoading,
  searchResultsIsError,
  disabled
}) => {
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const onClick = (value) => {
    handleClick(value);
    setSearchValue('');
  };

  const isOpen = searchValue !== '' && searchResults;

  return (
    <Popover
      isOpen={isOpen}
      closeOnBlur={false}
      autoFocus={false}
      isLazy
      matchWidth
      placement="bottom-start"
    >
      <Box px="4">
        <PopoverAnchor>
          <InputGroup>
            <Input
              placeholder="vs player"
              value={searchValue}
              onChange={handleChange}
              disabled={disabled}
            />
            <InputRightElement>
              {searchResultsIsLoading && <Spinner size="sm" color="primary.main" />}
              {searchResultsIsError && <CircleXFilled24 color="negative.main" />}
            </InputRightElement>
          </InputGroup>
        </PopoverAnchor>
      </Box>

      <PopoverContent
        bg="white.100"
        _focusVisible={{ outline: 'none', boxShadow: 'none' }}
        width="inherit"
      >
        <PopoverBody>
          <List>
            {searchResults &&
              searchResults.length !== 0 &&
              searchResults?.map((el) => {
                return (
                  <ListItem
                    borderRadius="md"
                    _hover={{ bg: 'black.3' }}
                    p="2"
                    cursor="pointer"
                    key={el.value}
                    onClick={() => onClick(el.value)}
                  >
                    {el.label}
                  </ListItem>
                );
              })}
            {searchResults && searchResults.length === 0 && (
              <ListItem p="2">No search results</ListItem>
            )}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default PlayerSearch;
