import { useFormikContext } from 'formik';
import get from 'lodash.get';
import { useState, useEffect } from 'react';
import { getAPIFormData } from '_shared/dataFetching/apiService';
import { createColumnValue } from '../utils';

const findNestedObj = (originalObj, nested_list = []) => {
  const returnOpts = [];

  const iterateObject = ([key, value]) => {
    if (typeof value !== 'object' && !Array.isArray(value)) {
      returnOpts.push(createColumnValue(key));
    }
  };
  if (nested_list.length === 0) {
    if (originalObj && !Array.isArray(originalObj)) {
      Object.entries(originalObj).forEach(iterateObject);
    }
  } else {
    const objectByPath = get(originalObj, nested_list);
    if (objectByPath && !Array.isArray(objectByPath)) {
      Object.entries(objectByPath).forEach(iterateObject);
    }
  }
  return returnOpts;
};

const useExternalColumnData = (enabled) => {
  const [fetchingData, setFetchingData] = useState(false);
  const { values, errors, setFieldValue } = useFormikContext();
  const { apiData, nested_dict, ext_api } = values;
  const urlValid = !errors.ext_api;

  useEffect(() => {
    if (enabled) {
      const timeoutId = setTimeout(() => {
        const fetch = async () => {
          setFetchingData(true);
          try {
            const data = await getAPIFormData(ext_api);
            setFieldValue('apiData', data, false);
          } catch (err) {
            setFieldValue('apiData', [], false);
          }
          setFetchingData(false);
        };
        if (urlValid && ext_api !== '') {
          fetch();
        } else {
          setFieldValue('columnData', [], false);
        }
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [enabled, ext_api, urlValid, setFieldValue]);

  useEffect(() => {
    if (enabled && apiData) {
      const nestedData = findNestedObj(apiData, nested_dict || []);
      setFieldValue('columnData', nestedData, false);
    }
  }, [enabled, apiData, nested_dict, setFieldValue]);

  return { fetchingData };
};

export default useExternalColumnData;
