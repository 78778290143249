const { Flex } = require('@chakra-ui/react');

const ItemDetail = ({ icon, children }) => {
  return (
    <Flex
      gap="2"
      color="black.60"
      align="center"
      bg="black.4"
      py="1"
      px="2"
      borderRadius="sm"
      fontSize="xs"
    >
      {icon}
      {children}
    </Flex>
  );
};

export default ItemDetail;
