const transformPanelText = (textLabel, labelObj, upperCase) => {
  const bracketedContents = textLabel.match(/\{{.+?\}}/g);
  let returnText = textLabel;
  if (bracketedContents) {
    bracketedContents.forEach((el) => {
      const variableKey = el.replaceAll('{', '').replaceAll('}', '');
      const variableValue = labelObj[variableKey];
      if (variableValue) {
        returnText = returnText.replaceAll(el, variableValue);
      }
    });
  }
  if (upperCase) {
    return returnText.toUpperCase();
  }
  return returnText;
};

export default transformPanelText;
