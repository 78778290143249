import { Flex } from '@chakra-ui/react';
import { useNavigate, useSearch } from '@tanstack/react-location';
import TabButtons from 'pages/_shared/components/FilterComponents/TabButtons';
import { useEffect } from 'react';

const MatchFilterHeader = ({ objectData }) => {
  const navigate = useNavigate();
  const search = useSearch();
  const { class_id } = search;
  const matchClassId = objectData.class_id;

  const currentPath = window.location.pathname;
  const applyFilter = currentPath?.includes('/match/');

  useEffect(() => {
    if (applyFilter && (!class_id || class_id !== matchClassId)) {
      navigate({
        search: (old) => ({ ...old, class_id: matchClassId }),
        replace: true,
        fromCurrent: true
      });
    }
  }, [class_id, matchClassId, navigate, applyFilter]);

  return (
    <Flex justify="start" w="100%" alignItems="center" flexWrap="wrap" gap="2">
      <TabButtons objectData={objectData} type="match" />
    </Flex>
  );
};

export default MatchFilterHeader;
