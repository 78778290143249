import { getUserInfo } from '../apiService';
import useQueryFetch from './useQueryFetch';
import { logout } from '_shared/dataFetching/authService';

const useUserDetails = (options) => {
  const fetchData = useQueryFetch(['user'], () => getUserInfo(), options);
  if (fetchData?.data?.status === 'disabled') {
    logout();
  }
  return fetchData;
};

export default useUserDetails;
