import { Box, Text } from '@chakra-ui/react';
import CircularSegment from './CircularSegment';

const MultiSegmentCircularProgress = ({ segments, played }) => {
  return (
    <Box position="relative" display="inline-block" margin="auto">
      <CircularSegment size={200} strokeWidth={30} segments={segments} />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        textAlign="center"
      >
        <Text fontSize="md" lineHeight="1.2">
          Played
        </Text>
        <Text fontSize="lg" fontWeight="700">
          {played}
        </Text>
      </Box>
    </Box>
  );
};

export default MultiSegmentCircularProgress;
