const CircularSegment = ({ size, strokeWidth, segments }) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  let offset = 180;

  return (
    <svg width={size} height={size}>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke="gray"
        strokeWidth={strokeWidth}
        fill="none"
      />
      {segments
        .filter((el) => el.value)
        .map((segment, index) => {
          const segmentLength = (segment.value / 100) * circumference;
          const strokeDasharray = `${segmentLength} ${circumference - segmentLength}`;
          const strokeDashoffset = -offset;
          offset += segmentLength;

          return (
            <circle
              key={index}
              cx={size / 2}
              cy={size / 2}
              r={radius}
              stroke={segment.color}
              strokeWidth={strokeWidth}
              fill="none"
              strokeDasharray={strokeDasharray}
              strokeDashoffset={strokeDashoffset}
              strokeLinecap="butt"
            />
          );
        })}
    </svg>
  );
};

export default CircularSegment;
