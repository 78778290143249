import { Flex } from '@chakra-ui/react';

const FilterLayout = ({ children, px = 4 }) => {
  return (
    <Flex py="2" px={px} gap="2" minH="10" boxShadow="md">
      {children}
    </Flex>
  );
};

export default FilterLayout;
