import { Box, Grid, GridItem, Text} from '@chakra-ui/react';

const BattersRow = ({battingTeamData, players}) => {

  const playerFor = (playerId) => players?.find((player) => player.player_id === playerId);

  const batters = battingTeamData?.grid?.[0]?.batting;
  const batterPlayers = batters?.map((batter) => playerFor(Number(batter?.batsman_id)));

  const captainPlayer = playerFor(battingTeamData?.captain_id);
  const battersLength = batterPlayers?.length || 0;

  return (
    <Grid
      flexGrow="1"
      gridTemplateColumns={`220px repeat(${battersLength}, 1fr)`}
      fontWeight="700"
      marginBottom="1"
    >
      <GridItem
        key={`captaincy_label_captain`}
        backgroundColor="white"
        position="sticky"
        left="0"
        zIndex="1"
        sx={{
          '&:not(:last-child)': { paddingRight: '4px' }
        }}
      >
        <Box
          bg="black.4"
          h="44px"
          minW="148px"
          display="flex"
          alignItems="center"
          paddingLeft="4"
          paddingRight="4"
        >
          {captainPlayer.name}
          <Text as="span"
            fontSize="0.75rem"
            color="#fff"
            backgroundColor="#0c5dff"
            lineHeight="1"
            padding="2px 4px 2px 3px"
            marginLeft="1"
            borderRadius="0.25rem"
          >
            C
          </Text>
        </Box>
      </GridItem>
      {batterPlayers.map((batterPlayer, idx) => 
        <GridItem
          key={`captaincy_label_${idx}`}
          sx={{
            '&:not(:last-child)': { paddingRight: '4px' }
          }}
        >
          <Box
            bg="black.4"
            h="44px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Text minW="148px">
              {batterPlayer?.name}
            </Text>
          </Box>
        </GridItem>
      )}
    </Grid>
  )
};

export default BattersRow;