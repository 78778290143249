import teams_scoring_rate_ranking from './teams_scoring_rate_ranking';
import boundary_counter_ranking from './boundary_counter_ranking';
import highest_scores_ranking from './highest_scores_ranking';
import mode_of_dismissal_ranking from './mode_of_dismissal_ranking';
import pace_and_spin_deployment_ranking from './pace_and_spin_deployment_ranking';

const configDefs = {
  teams_scoring_rate_ranking,
  boundary_counter_ranking,
  highest_scores_ranking,
  mode_of_dismissal_ranking,
  pace_and_spin_deployment_ranking
};

export default configDefs;
