import { useState } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import Header from '../_components/header/Header';

const ManhattanContent = ({ content, objectId }) => {

  const [infoOpen, setInfoOpen] = useState(false);
  const { header } = content;

  const widgetProps = {
    type: "manhattan",
    'customer-id': process.env.REACT_APP_WIDGET_ID,
    "match": objectId,
    "team-color": true
  };

  return (
    <Flex direction="column" h="100%">
      <Header
        title={header}
        showInfoButton={false}
        infoOpen={infoOpen}
        setInfoOpen={setInfoOpen}
        paddingBottom={0}
      />
      <Box flex="1" minH="0">
        <ellipse-data-cricket {...widgetProps}></ellipse-data-cricket>
      </Box>
    </Flex>
  );
};
export default ManhattanContent;

