import { forwardRef } from 'react';
import { Box } from '@chakra-ui/react';
import WidgetComponent from './WidgetComponent';

const CricVizWidget = forwardRef(({ panelData }, ref) => {
  const { widget_type, page_type, variable_id } = panelData;

  let include = [];
  if (widget_type.includes('beehive') || widget_type.includes('pitchmap')) {
    include.push('axis_horizontal','axis_vertical','grid_labels');
  }
  if (widget_type.includes('beehive') || widget_type.includes('pitchmap') || widget_type.includes('wagonwheel')) {
    include.push('two_runs');
  }
  include = include.join();

  const widgetId = `${widget_type}_${variable_id}`;

  const widgetProps = {
    id: widgetId,
    type: widget_type,
    include,
    live_update: 'true',
    ref
  };
  if (page_type === 'match') {
    widgetProps.match = variable_id;
  }
  if (page_type === 'comp') {
    widgetProps.competition = variable_id;
  }
  if (widget_type.includes('pitchmap')) {
    widgetProps.pitchmap_height_ratio = '100';
  }

  return (
    <Box h="100%" margin={'auto'}>
      <WidgetComponent widgetParams={widgetProps} />
    </Box>
  );
});
export default CricVizWidget;
