import { GridItem } from '@chakra-ui/react';
import { Link } from '@tanstack/react-location';

const GridCardLink = ({ to, children }) => {
  return (
    <GridItem
      p="3"
      rounded="md"
      bg="black.3"
      border="1px"
      borderColor="transparent"
      _hover={{ boxShadow: 'small', cursor: 'pointer', bg: 'white.100', borderColor: 'black.24' }}
    >
      <Link to={to}>{children}</Link>
    </GridItem>
  );
};

export default GridCardLink;
