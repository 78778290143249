import { Flex, Text } from '@chakra-ui/react';
import { CricVizTextLogo} from '_icons/svgs';

const ContentError = ({ title, subtitle }) => {

  return (
    <Flex direction="column" h="100%" w="100%" alignItems="center" justifyContent="center">
      <CricVizTextLogo />
      <Text fontSize="42px" fontWeight="700" color="black.100" mt="50px">
        {title}
      </Text>
      <Text fontSize="20px" fontWeight="400" color="black.100" mt="20px">
        {subtitle}
      </Text>
    </Flex>
  );
};
export default ContentError;
