import { Box, FormLabel } from '@chakra-ui/react';
import FormCheckBox from 'Forms/_shared/FormElements/FormCheckbox';

const StaffFields = () => {
  return (
    <Box mt="3">
      <FormLabel>Display Settings</FormLabel>
      <FormCheckBox name="is_public" label="Available to all users" />
      <FormCheckBox name="is_live" label="Updates Live" />
    </Box>
  );
};

export default StaffFields;
