import { forwardRef } from 'react';
import { Box } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';
import { PAGE_FILTER_DATES } from '_shared/globalState/atoms';
import useWidgetId from './createWidgetId';
import WidgetComponent from './WidgetComponent';

const CricVizCareerWidget = forwardRef(({ panelData, classId }, ref) => {
  const { widget_type, variable_id, class_id_list } = panelData;
  const pageFilterDates = useRecoilValue(PAGE_FILTER_DATES);
  const { min: page_date_min, max: page_date_max } = pageFilterDates || {};

  let include = [];
  if (widget_type.includes('beehive') || widget_type.includes('pitchmap')) {
    include.push('axis_horizontal','axis_vertical','grid_labels');
  }
  if (widget_type.includes('beehive') || widget_type.includes('pitchmap') || widget_type.includes('wagonwheel')) {
    include.push('two_runs');
  }
  include = include.join();

  const widgetProps = {
    type: widget_type,
    include,
    player: variable_id,
    comp_type: classId ? classId : Object.keys(class_id_list)[0],
    ref
  };
  if (page_date_min) {
    widgetProps.date_start = dayjs(page_date_min).format('YYYY-MM-DD');
  }
  if (page_date_max) {
    widgetProps.date_end = dayjs(page_date_max).format('YYYY-MM-DD');
  }

  if (widget_type.includes('pitchmap')) {
    widgetProps.pitchmap_height_ratio = '100';
  }

  const widgetId = useWidgetId(widgetProps);

  if (!widgetId) {
    return null;
  }

  return (
    <Box h="100%" margin={'auto'}>
      <WidgetComponent widgetParams={{ ...widgetProps, id: widgetId }} key={widgetId} />
    </Box>
  );
});
export default CricVizCareerWidget;
