import { Flex, Box, GridItem, chakra, Circle } from '@chakra-ui/react';
import { useState } from 'react';
import { setTopScroll } from '_shared/utils/utilFunctions.js';
import { Field } from 'formik';
import { getColor } from './VideoSearchUtils';
import {
  CalendarOutline20,
  CricketBallOutline20,
  CricketBatOutline20,
  LocationOutline20
} from '_icons/outline/20';
import { PlayFilled24 } from '_icons/filled/24';

const VideoGridObject = ({
  el,
  idx,
  videoVal,
  setVideoVal,
  setViewType,
  selectionOn,
  handleSelect,
  isChecked
}) => {
  const clickFunction = () => {
    setVideoVal(idx);
    setViewType('video');
    setTopScroll(idx);
  };
  const [showCheckbox, setShowCheckbox] = useState(false);
  return (
    <GridItem
      id={`video-result-${idx}`}
      key={`video-key-${idx}`}
      onClick={(e) => {
        if (e.target.tagName.toLowerCase() === 'input' || selectionOn) {
          handleSelect(el.ball_id);
        } else {
          clickFunction();
        }
      }}
      _hover={{ cursor: 'pointer' }}
      onMouseEnter={() => setShowCheckbox(true)}
      onMouseLeave={() => setShowCheckbox(false)}
      border={videoVal === idx ? '2px solid #40CD78' : null}
      borderRadius="10px"
    >
      <Box borderRadius={'md'} bg="white" p={4}>
        <Box>
          <Flex fontSize={'xs'} gap={0.5} color="black.60" p={1} borderBottom={'1px solid #EBEBED'}>
            <CalendarOutline20 />
            <Box ml={0.5}>{el.match_date}</Box>
            <LocationOutline20 />
            <Box ml={0.5}>{el.ground_name}</Box>
            <Box ml="auto">
              <Field
                name="playlist_selection"
                type="checkbox"
                checked={isChecked}
                onChange={() => {
                  handleSelect(el.ball_id);
                }}
                key={`playlist_selection.${el.ball_id}`}
                style={{
                  width: '19px',
                  height: '19px',
                  border: 'solid #EBEBED'
                }}
              />
            </Box>
          </Flex>

          <Flex mt={2}>
            {showCheckbox ? (
              <Circle
                key={`${el?.ball_id}-play-button`}
                bg={'#40CD78'}
                color={'white'}
                boxSize={'10'}
                fontSize="md"
                fontWeight="600"
                mt="auto"
                mb="auto"
                mr="2"
              >
                <PlayFilled24 />
              </Circle>
            ) : (
              <Circle
                key={el?.ball_id}
                bg={getColor(el.scoring, 'bg')}
                color={getColor(el.scoring, 'color')}
                borderColor={getColor(el.scoring, 'border')}
                border={'1px'}
                boxSize={'10'}
                fontSize="md"
                fontWeight="600"
                mt="auto"
                mb="auto"
                mr="2"
                _hover={{ cursor: 'pointer' }}
              >
                {el.scoring}
              </Circle>
            )}

            <Box fontWeight="bold">
              <Box fontSize="md" mr={2} mt={0}>
                {el.batting_team_abbr} v {el.bowling_team_abbr}
              </Box>
              <Box fontWeight="bold" fontSize="md">
                {el.score}
                <chakra.span color="black.60" fontWeight={100} pl={2} fontSize="sm">
                  ({el.overs})
                </chakra.span>
              </Box>
            </Box>
          </Flex>
        </Box>

        <Flex gap={2} mt={2} fontSize="sm">
          <Box flex="1">
            <Flex bg="black.3" p={2} borderRadius="md" align="flex-start">
              <CricketBatOutline20 pt="1" />
              <Box>
                <Box ml={2} fontWeight="bold">
                  {el.batter_name}
                </Box>
                <Box ml={2} fontWeight="bold">
                  {el.batting_runs}{' '}
                  <chakra.span color="black.60" fontWeight={100}>
                    ({el.batting_balls_faced})
                  </chakra.span>
                </Box>
              </Box>
            </Flex>
          </Box>
          <Box flex="1">
            <Flex bg="black.3" p={2} fontWeight="bold" borderRadius="md" align="flex-start">
              <CricketBallOutline20 pt="1" />
              <Box>
                <Box ml={2}>{el.bowler_name}</Box>
                <Box ml={2}>
                  {el.bowling_wickets}/{el.bowling_conceded}
                  <chakra.span color="black.60" fontWeight={100} ml={1}>
                    ({el.bowling_overs})
                  </chakra.span>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </GridItem>
  );
};

export default VideoGridObject;
