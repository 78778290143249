import {
  Box,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  CheckboxGroup
} from '@chakra-ui/react';

import VideoFiltersContainer from 'pages/videoSearch/components/VideoFilters/VideoFiltersContainer';
import 'video-react/dist/video-react.css';
import './VideoCentre.css';

import VideoGridObject from './VideoGridObject';
import ResultsHeader from './ResultsHeader';
import AddToPlaylist from './AddToPlaylist';

const VideoResultsListings = ({
  results,
  params,
  filters,
  filtersOpen,
  setFiltersOpen,
  changeSearch,
  setChangeSearch,
  class_info,
  classId,
  objectType,
  object_info,
  objectId,
  videoVal,
  setVideoVal,
  setViewType,
  multiSelect,
  setMultiSelect,
  selectedVideos,
  setSelectedVideos,
  setFieldValue,
  handleSubmit,
  reversed,
  setReversed
}) => {
  // const { height, width } = useWindowDimensions();

  const handleSelect = (el) => {
    if (selectedVideos.includes(el)) {
      setSelectedVideos((oldValues) => {
        return oldValues.filter((val) => val !== el);
      });
      setFieldValue(
        'playlist_selection',
        selectedVideos.filter((val) => val !== el)
      );
    } else {
      setSelectedVideos([...selectedVideos, el]);
      setFieldValue('playlist_selection', [...selectedVideos, el]);
    }
  };

  const vidDict = {};
  results.forEach((el) => {
    vidDict[el.ball_id] = el;
  });
  const selectAllVids = () => {
    const returnArr = [];
    Object.keys(vidDict).forEach((el) => {
      returnArr.push(parseInt(el));
    });
    setSelectedVideos(returnArr);
    setFieldValue('playlist_selection', returnArr);
  };
  const deselectAllVids = () => {
    setSelectedVideos([]);
    setFieldValue('playlist_selection', []);
  };
  return (
    <Box w="100%" bg="black.3">
      <ResultsHeader
        changeSearch={changeSearch}
        setChangeSearch={setChangeSearch}
        class_info={class_info}
        objectType={objectType}
        object_info={object_info}
        results={results}
        objectId={objectId}
        setFiltersOpen={setFiltersOpen}
        selectAllVids={selectAllVids}
        deselectAllVids={deselectAllVids}
        setViewType={setViewType}
        videoPage={'video'}
        reversed={reversed}
        setReversed={setReversed}
      />
      <Box overflowY="scroll" h="85vh">
        <Box w="97.5%" m="auto">
          <Grid mt={'20px'} templateColumns="repeat(4, 1fr)" gap={3}>
            <CheckboxGroup isDisabled={false}>
              {!reversed
                ? results.map((el, idx) => {
                    return (
                      <VideoGridObject
                        el={el}
                        idx={idx}
                        videoVal={videoVal}
                        setVideoVal={setVideoVal}
                        setViewType={setViewType}
                        selectionOn={selectedVideos.length > 0}
                        handleSelect={handleSelect}
                        isChecked={selectedVideos.includes(el.ball_id)}
                      />
                    );
                  })
                : results.toReversed().map((el, idx) => {
                    return (
                      <VideoGridObject
                        el={el}
                        idx={results.length - 1 - idx}
                        videoVal={videoVal}
                        setVideoVal={setVideoVal}
                        setViewType={setViewType}
                        selectionOn={selectedVideos.length > 0}
                        handleSelect={handleSelect}
                        isChecked={selectedVideos.includes(el.ball_id)}
                      />
                    );
                  })}
            </CheckboxGroup>
          </Grid>
        </Box>
        <Modal isOpen={filtersOpen} onClose={() => setFiltersOpen(false)} size="lg">
          <ModalOverlay />
          <ModalContent maxW="90vw" maxH="80vh" overflowY="auto">
            <ModalBody m="10">
              <VideoFiltersContainer
                presetFilters={params}
                filters={filters}
                classId={classId}
                objectType={objectType}
                objectId={objectId}
                multiSelect={multiSelect}
                setMultiSelect={setMultiSelect}
                setFiltersOpen={setFiltersOpen}
                class_info={class_info}
                object_info={object_info}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
        <AddToPlaylist
          vidDict={vidDict}
          vidList={selectedVideos}
          vidLength={selectedVideos.length}
          setFieldValue={setFieldValue}
          setSelectedVideos={setSelectedVideos}
          handleSubmit={handleSubmit}
        />
      </Box>
    </Box>
  );
};

export default VideoResultsListings;
