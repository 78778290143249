import { Box, Flex } from '@chakra-ui/react';
import FilterTags from './FilterTags';
import { whiteCardHover } from '_shared/components/cssStylePatterns/cssStylePatterns';

const RecentFilterSelection = ({ filterDefs, recentFilterOptions, handleApplyFilterPreset }) => {
  return (
    <Flex p="5" direction="column" gap="2">
      {recentFilterOptions.map((el) => {
        if (Object.keys(el.value).length === 0) {
          return (
            <Box key={el.id} border="1px" borderColor="black.8" bg="black.3" fontSize="xs" p="2">
              Filters not applicable
            </Box>
          );
        }
        return (
          <Box key={el.id} onClick={() => handleApplyFilterPreset(el)} {...whiteCardHover}>
            <FilterTags values={el.value} filterDefs={filterDefs} />
          </Box>
        );
      })}
    </Flex>
  );
};

export default RecentFilterSelection;
