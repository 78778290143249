import EventCountDown from './EventCountDown';
import PreMatchTeams from './PreMatchTeams';

const PreMatchDetail = ({ data, size }) => {
  return (
    <>
      <PreMatchTeams data={data} size={size} />
      {size === 'large' && <EventCountDown startTime={data?.start_datetime_utc} />}
    </>
  );
};

export default PreMatchDetail;
