import cloneDeep from 'lodash.clonedeep';

const mergeLayoutDef = (currentDefs, newDefs, positionType) => {
  const newDefLayout = cloneDeep(currentDefs);
  newDefs.forEach((payloadDef) => {
    if (!newDefLayout[payloadDef.panel_id]) {
      newDefLayout[payloadDef.panel_id] = { panel_id: payloadDef.panel_id };
    }
    const { lg, md, xs, position } = payloadDef;
    if (lg) newDefLayout[payloadDef.panel_id].lg = lg;
    if (md) newDefLayout[payloadDef.panel_id].md = md;
    if (xs) newDefLayout[payloadDef.panel_id].xs = xs;
    newDefLayout[payloadDef.panel_id][positionType] = position;
  });
  return newDefLayout;
};

export default mergeLayoutDef;
