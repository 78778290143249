import { createIcon } from '@chakra-ui/icons';

const Close = createIcon({
  displayName: 'Close',
  viewBox: '0 0 24 24',
  defaultProps: { width: '24px', height: '24px' },
  path: (
    <path
      d="M18.3632 18.364C18.7537 17.9734 18.7537 17.3403 18.3632 16.9497L13.4141 12.0007L18.3646 7.05025C18.7551 6.65973 18.7551 6.02656 18.3646 5.63604C17.974 5.24551 17.3409 5.24551 16.9503 5.63603L11.9999 10.5865L7.04948 5.63603C6.65896 5.24551 6.02579 5.24551 5.63527 5.63603C5.24475 6.02656 5.24475 6.65972 5.63527 7.05025L10.5857 12.0007L5.63663 16.9497C5.2461 17.3403 5.2461 17.9734 5.63663 18.364C6.02715 18.7545 6.66032 18.7545 7.05084 18.364L11.9999 13.4149L16.949 18.364C17.3395 18.7545 17.9727 18.7545 18.3632 18.364Z"
      fill="currentColor"
    />
  )
});

export default Close;
