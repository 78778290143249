import match from './match';
import player from './player';
import advanced from './advanced';
import comp from './comp';

const configDefs = {
  ...match,
  ...player,
  ...comp,
  ...advanced
};

export default configDefs;
