const stickyStyles = {
  'th:first-child, td:first-child': {
    position: 'sticky',
    left: '0',
    zIndex: 1
  },
  'th:first-child': {
    bg: 'black.3'
  },
  'td:first-child': {
    bg: 'white.100'
  },
  'th:first-child::after, td:first-child::after': {
    content: '""',
    position: 'absolute',
    top: '0',
    right: '0',
    width: '1px',
    height: '100%',
    bg: 'black.8',
    zIndex: '1'
  }
};

export default stickyStyles;
