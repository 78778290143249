import { Box, GridItem } from '@chakra-ui/react';
import { forwardRef } from 'react';
import Panel from './Panel';

const PanelContainer = forwardRef(
  (
    {
      panelData,
      pageData,
      pageType,
      classId,
      filterType,
      layoutInfo,
      isDraggable,
      style,
      className,
      children,
      ...rest
    },
    ref
  ) => {
    if (isDraggable) {
      return (
        <Box ref={ref} style={{ ...style }} h="100%" className={className} {...rest}>
          <Panel
            panelData={panelData}
            pageData={pageData}
            pageType={pageType}
            classId={classId}
            filterType={filterType}
            layoutInfo={layoutInfo}
          />
          {children}
        </Box>
      );
    }

    return (
      <GridItem h="450px" position="relative">
        <Panel
          panelData={panelData}
          pageData={pageData}
          pageType={pageType}
          classId={classId}
          filterType={filterType}
        />
      </GridItem>
    );
  }
);

export default PanelContainer;
