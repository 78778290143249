import { Navigate } from '@tanstack/react-location';
import FrequentlyAskedQuestions from 'pages/faq/components/FrequentlyAskedQuestions';
import { matchRoutes } from './matchRoutes';
import { playerRoutes } from './playerRoutes';
// import { playerV2Routes } from './playerV2Routes';
import { compRoutes } from './compRoutes';
import { teamRoutes } from './teamRoutes';
import { groundRoutes } from './groundRoutes';
import { userRoutes } from './userRoutes';
import { videoSearchRoutes } from './videoSearchRoutes';
import { videoPlaylistRoutes } from './videoPlaylistRoutes';
import MainContainer from '_shared/components/MainContainer';
import VideoCentre from 'VideoCentre/VideoCentre';
import TestRoute from '_shared/components/TestRoute';
import LoginForm from 'authentication/components/LoginForm';
import WinVizTestRoute from '_shared/components/WinVizTestRoute';
import ErrorRouteComponent from './ErrorRouteComponent';
import Home from 'pages/home/components/Home';
import MatchHomePage from 'pages/matches/components/MatchHomePage';
import WatchFinishedPlaylist from 'pages/videoPlaylist/components/WatchFinishedPlaylist';

const children = [
  { path: 'home', element: <Home /> },
  { path: 'videocentre', element: <VideoCentre /> },
  { path: 'faq', element: <FrequentlyAskedQuestions /> },
  { path: 'matches', element: <MatchHomePage /> },
  { path: '/video_playlist_viewer/:encodedURL', element: <WatchFinishedPlaylist /> },
  matchRoutes,
  playerRoutes,
  // playerV2Routes,
  compRoutes,
  teamRoutes,
  groundRoutes,
  userRoutes,
  videoSearchRoutes,
  videoPlaylistRoutes
];
if (process.env.NODE_ENV === 'development') {
  children.push({ path: 'test', element: <TestRoute /> });
}
if (process.env.REACT_APP_ENV === 'staging' || process.env.NODE_ENV === 'development') {
  children.push({ path: 'winviz', element: <WinVizTestRoute /> });
  children.push({ path: 'error', element: <ErrorRouteComponent /> });
}
children.push({ path: '/*', element: <Navigate to="home" /> });

export const authenticatedRoutes = [
  { path: '/login', element: <LoginForm /> },
  { path: '/', element: <Navigate to="home" /> },
  {
    key: 'auth-route',
    element: <MainContainer />,
    children
  }
];
