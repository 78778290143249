import { avatarAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  avatarAnatomy.keys
);

const user = defineStyle({
  width: 10,
  height: 10,
  fontSize: '16px',
  fontWeight: 600
});

const sizes = {
  user: definePartsStyle({ container: user })
};

const avatarStyles = defineMultiStyleConfig({ sizes });

export default avatarStyles;
