import { useEffect, useState } from 'react';
import { Grid, Flex, Box, List, Select, Center } from '@chakra-ui/react';
import useWindowDimensions from '_shared/hooks/useWindowDimensions';
import FixtureComponent from '_shared/components/FixtureComponent/FixtureComponent';
import MatchLiveGridList from './MatchHomeGridList';
import ButtonSelector from '_shared/components/ButtonSelector';

export const MatchesUnavailable = ({ display }) => {
  const text = {
    live: 'live',
    complete: 'recently completed',
    forthcoming: 'forthcoming'
  };
  return (
    <Center h="100px">
      <Box>No {text[display]} matches available</Box>
    </Center>
  );
};

const getDataValues = (data, display, filterType) => {
  if (filterType === 'all') {
    return data?.[display]?.combined || [];
  }
  if (filterType === 'international') {
    return data?.[display]?.international || [];
  }
  if (filterType === 'general') {
    return data?.[display]?.general || [];
  }
  return [];
};

const MatchLivePageContainer = (data) => {
  const { width } = useWindowDimensions();
  const filterOptions = [
    { value: 'live', label: 'Live' },
    { value: 'complete', label: 'Complete' },
    { value: 'forthcoming', label: 'Forthcoming' }
  ];
  let initDisplay = 'live';
  if (width > 1300) {
    filterOptions.unshift({ value: 'all', label: 'All' });
    initDisplay = 'all';
  }
  const [display, setDisplay] = useState(initDisplay);
  const [filterType, setFilterType] = useState('all');
  const { standard } = data.data;
  useEffect(() => {
    if (width < 1300 && display === 'all') {
      setDisplay('live');
    }
  }, [display, width]);

  const displayContent = () => {
    if (display === 'all') {
      return (
        <Grid gap={5} templateColumns="repeat(3, 1fr)" mx="30px">
          <MatchLiveGridList
            data={getDataValues(standard, 'live', filterType)}
            title="LIVE"
            text="live"
          />
          <MatchLiveGridList
            data={getDataValues(standard, 'complete', filterType)}
            title="COMPLETE"
            text="recently completed"
          />
          <MatchLiveGridList
            data={getDataValues(standard, 'forthcoming', filterType)}
            title="FORTHCOMING"
            text="forthcoming"
          />
        </Grid>
      );
    }
    const matches = getDataValues(standard, display, filterType);
    if (matches.length === 0) {
      return <MatchesUnavailable display={display} />;
    }
    const gridWidth = display === 'forthcoming' ? 400 : 550;
    return (
      <Box bg="white.100" mx="3" p="4" rounded="md" boxShadow="small">
        <List
          display="grid"
          gridTemplateColumns={`repeat(auto-fill,minmax(${gridWidth}px, 1fr))`}
          bg="white.100"
          gap="2"
        >
          {matches.map((matchData) => {
            return <FixtureComponent matchData={matchData} key={matchData.id} />;
          })}
        </List>
      </Box>
    );
  };

  return (
    <Flex flexGrow="1" direction="column" minHeight={0}>
      <Flex px="2" py="2" gap="1" alignItems="center">
        <ButtonSelector
          handleChange={setDisplay}
          value={display}
          options={filterOptions}
          isSquare
          fontSize="sm"
        />
        <Select
          onChange={(e) => setFilterType(e.target.value)}
          value={filterType}
          width="300px"
          fontSize="sm"
        >
          <option value="all">International & Domestic</option>
          <option value="international">International</option>
          <option value="general">Domestic</option>
        </Select>
      </Flex>
      <Box flexGrow="1" overflowY="auto">
        {displayContent()}
      </Box>
    </Flex>
  );
};
export default MatchLivePageContainer;
