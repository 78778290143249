import { Flex } from '@chakra-ui/react';
import TabButtons from 'pages/_shared/components/FilterComponents/TabButtons';
import ClassFilter from 'pages/_shared/components/FilterComponents/ClassFilter';
import RoleFilter from 'pages/_shared/components/FilterComponents/RoleFilter';

const CompFilterHeader = ({ objectData }) => {
  const { class_id_list = [] } = objectData;

  return (
    <Flex justify="space-between" w="100%" alignItems="center" flexWrap="wrap" gap="2">
      <TabButtons objectData={objectData} type="comp" />
      <Flex gap="2" alignItems="center">
        <ClassFilter options={class_id_list} type="comp" />
        <RoleFilter type="comp" />
      </Flex>
    </Flex>
  );
};

export default CompFilterHeader;
