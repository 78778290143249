import { memo } from 'react';
import isEqual from 'lodash.isequal';
import ReactTable from '_shared/components/ReactTable/ReactTable';

const Table = ({ panelData }) => {
  const { data_columns, data, meta } = panelData;
  const columns = [];
  let colData = data;
  if (data_columns.length > 0) {
    colData = data.filter((el) => el[data_columns[0]]);
  }
  data_columns.forEach((col) => {
    if (!col || !col[0]) {
      return;
    }
    let header;
    let tipText;
    if (typeof meta !== 'undefined' && typeof meta === 'object' && meta.hasOwnProperty(col)) {
      header = meta[col].heading;
      tipText = meta[col].description;
    } else {
      header = col[0] ? `${col[0].toUpperCase()}${col.slice(1).replaceAll('_', ' ')}` : '';
      tipText = col;
    }
    columns.push({
      Header: header,
      accessor: col,
      tipText
    });
  });
  return <ReactTable columns={columns} data={colData} />;
};

const memoFunction = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default memo(Table, memoFunction);
