import checkEnabled from './checkEnabled/checkEnabled';

const buildEnabledFilters = (filterValues, filterDefs) => {
  if (!filterDefs) return [];
  const filterGroupElements = {};
  filterDefs.filter_groups.forEach(({ name }) => {
    filterGroupElements[name] = [];
  });
  filterDefs.filters.forEach(({ enabled, group, options, ...rest }) => {
    if (checkEnabled(filterValues, enabled)) {
      const filterDef = { ...rest };
      if (Array.isArray(options)) {
        filterDef.options = options
          .filter((option) => {
            const enabled = checkEnabled(filterValues, option.enabled);
            return enabled;
          })
          .map(({ value, label }) => {
            return { value, label };
          });
      }
      if (filterGroupElements[group]) {
        filterGroupElements[group].push(filterDef);
      }
    }
  });
  return filterDefs.filter_groups.map((group) => {
    const elements = filterGroupElements[group.name];
    return { ...group, elements };
  });
};

export default buildEnabledFilters;
