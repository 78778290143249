import { Formik } from 'formik';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { insertQTPanel } from '_shared/dataFetching/apiService';
import FormLayout from 'Forms/_shared/components/FormLayout';

import { organiseSearchParams } from 'Forms/PanelForms/_shared/utils';
import {
  checkParamsIncludeVariable,
  getSearchParamsFromUrl
} from '_shared/utils/searchParamsUtils';
import QTPanelForm from '../../_shared/FormLayouts/QTPanelForm';
import { generateNewPanelPayload } from '../utils';
import { getInfo } from 'panels/utils/panelGetInfo';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';

const initialValues = {
  qt_api: '',
  columnSelection: [],
  x_column: '',
  data_value: '',
  panel_name: '',
  panel_type_id: '2',
  is_public: true,
  is_live: false,
  nested_dict: 'result',
  dynamic_variable: null,
  columnData: []
};

const CreateQTPanel = ({ pageData, pageType, onSave, panelNameVariables }) => {
  const queryClient = useQueryClient();
  const { data: userInfo } = useUserDetails();
  const { is_staff } = userInfo || {};
  const [formSubmitError, setFormSubmitError] = useState(null);
  const { initialClass, panel_category, classList, objectId } = getInfo(pageData, pageType);
  const classListOptions = Object.entries(classList).map(([key, value]) => {
    return { label: value.short_name, value: key };
  });
  return (
    <Formik
      initialValues={{
        ...initialValues,
        page_type: pageType,
        classId: initialClass || '',
        panel_page: pageType,
        panel_category,
        variable_id: objectId,
        category_type_id: null
      }}
      validate={(values) => {
        setFormSubmitError(null);
        const errors = {};
        if (!values.panel_name) {
          errors.panel_name = 'Please enter a panel name';
        }
        if (!values.classId) {
          errors.classId = 'Please select class';
        }
        if (values.qt_api) {
          if (!values.qt_api.includes('.cricviz.com/')) {
            errors.qt_api = 'Not a valid CricViz api';
          }
          const searchParams = getSearchParamsFromUrl(values.qt_api);
          const dynamic_variable = checkParamsIncludeVariable(searchParams, objectId, pageType);

          if (!dynamic_variable && pageType !== 'match') {
            errors.qt_api = 'URL does not include object ID';
          } else if (!dynamic_variable) {
            const dv = organiseSearchParams(searchParams);
            // setting a form value in validation function might cause problems
            // dynamic_variable is not set anywhere else in the form so maybe ok
            // potentially need to review this
            values.dynamic_variable = dv;
          }
        }
        if (!values.category_type_id) {
          errors.category_type_id = 'Please select a panel category';
        }
        if (!values.qt_api) {
          errors.qt_api = 'Please enter api url';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const payload = generateNewPanelPayload(values);
        const res = await insertQTPanel(payload);
        console.log({ payload, response: res });
        if (res.status === 200) {
          queryClient.refetchQueries([pageType, `${objectId}`, 'panels']);
          onSave(res.data.panel_id);
        } else {
          setFormSubmitError(res.data);
        }
        setSubmitting(false);
      }}
    >
      {(props) => (
        <FormLayout buttonText="Add Panel" {...props}>
          <QTPanelForm
            {...props}
            is_staff={is_staff}
            classListOptions={classListOptions}
            formSubmitError={formSubmitError}
            objectId={objectId}
            dynamicPanelNames={panelNameVariables}
            pageType={pageType}
          />
        </FormLayout>
      )}
    </Formik>
  );
};

export default CreateQTPanel;
