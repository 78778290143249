import { Checkbox, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Field } from 'formik';

const FormCheckBox = ({ name, label }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[name] && form.touched[name]}>
          <Checkbox {...field} isChecked={field.value}>
            {label}
          </Checkbox>
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default FormCheckBox;
