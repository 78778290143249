import { Box } from '@chakra-ui/react';
import NoDataDisplay from 'panels/Panel/NoDataDisplay';
import ReactTable from '_shared/components/ReactTable/ReactTable';
// import RouterLink from '_shared/components/Utils/RouterLink';

const GroundsTableComponent = ({ elData }) => {
  const { data } = elData;
  const addDraw = [1, 4, 11, 14];
  const columns = [
    {
      Header: 'Ground',
      tipText: 'Ground Name',
      Cell: (props) => <Box>{props.row.original.ground_name}</Box>
    },
    {
      Header: 'Span',
      accessor: 'span',
      tipText: 'Span'
    },
    { Header: 'M', accessor: 'matches', tipText: 'Matches' },
    { Header: 'W', accessor: 'wins', tipText: 'Wins' },
    addDraw.includes(data[0].class_id)
      ? { Header: 'D', accessor: 'draws', tipText: 'Draws' }
      : null,
    { Header: 'L', accessor: 'losses', tipText: 'Losses' },
    {
      Header: 'Win%',
      accessor: 'win_percentage',
      tipText: 'Win %',
      headerStyle: { textAlign: 'center' }
    },
    { Header: 'Area', accessor: 'ground_area', tipText: 'Area where ground is' },
    { Header: 'Country', accessor: 'country', tipText: 'Country where ground is located' }
  ];

  return <ReactTable columns={columns.filter((n) => n)} data={data} />;
};

const TeamHomeGrounds = ({ panelData, classId }) => {
  const { data } = panelData;

  if (!data[classId] || data[classId].data.length === 0) {
    return <NoDataDisplay message="No ground data available" />;
  }

  return <GroundsTableComponent elData={data[classId]} />;
};

export default TeamHomeGrounds;
