import { useState, useRef, useEffect, useCallback } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import useVideoModal from 'modals/hooks/useVideoModal';
import ButtonSelector from '_shared/components/ButtonSelector';
import Header from '../_components/header/Header';

const subViewOptions = [
  { value: 'map', label: "Map" },
  { value: 'grid', label: "Grid" }
];

const PitchmapViewContent = ({ content, video }) => {

  const { showVideoModal } = useVideoModal();
  const [ subViewValue, setSubViewValue ] = useState("map");
  const [infoOpen, setInfoOpen] = useState(false);
  const { data, header } = content;
  const { pitchmap } = data;
  const ref = useRef(null);

  const [ subView, setSubView ] = useState(null);
  const [ mapSelection, setMapSelection ] = useState([]);
  const [ gridSelection, setGridSelection ] = useState([]);

  useEffect(()=> {
    let widget = null;

    const widgetSubViewEventHandler = (event) => {
      const { detail } = event;
      setSubView(detail.subView);
    };

    const widgetSelectionEventHandler = (event) => {
      const { detail } = event;
      setMapSelection(detail?.mapSelection ?? []);
      setGridSelection(detail?.gridSelection ?? []);
    };

    if (ref?.current) {
      widget = ref.current;
      widget.addEventListener('ed-internal-pitchmap-subview-changed', widgetSubViewEventHandler);
      widget.addEventListener('ed-internal-pitchmap-selection-changed', widgetSelectionEventHandler);
    }

    return () => {
      if (widget) {
        widget.removeEventListener('ed-internal-pitchmap-subview-changed', widgetSubViewEventHandler);
        widget.removeEventListener('ed-internal-pitchmap-selection-changed', widgetSelectionEventHandler);
      }
    };
  }, [ref]);

  let mapBallsDataRef = pitchmap.map((pitchmapItem) => ({
    runs: pitchmapItem.runs,
    x: +pitchmapItem.x,
    y: +pitchmapItem.y,
    video: pitchmapItem.video
  }));
  mapBallsDataRef = JSON.stringify(mapBallsDataRef);

  const widgetProps = {
    type: "pitchmap-view",
    "sub-view": subViewValue,
    "map-balls-data-ref": mapBallsDataRef,
    "pitchmap-height-ratio": 85,
    include: "grid-labels,two-runs",
    ref
  };

  const useWidgetSelection = !!video?.available;
  if (useWidgetSelection) {
    widgetProps["user-selection"] = true;
  }

  const onVideoCancel = useCallback(
    () => {
      ref?.current?.clearSelection(subView);
    },
    [subView]
  );

  const onVideoPlay = useCallback(
    () => {
      if (video?.available && video?.base?.length) {
        let videoUrl = `${video.base}`;
        let videos;

        switch (subViewValue) {
          case 'map': {
            videos = mapSelection.reduce((acc, curr) => acc.concat(curr.video ?? []), []);
            break;
          }
          case 'grid': {
            videos = gridSelection.reduce((acc, curr) => acc.concat(curr.video ?? []), []);
            break;
          }
          default: {
            break;
          }
        }

        if (videos?.length) {
          videos?.forEach((video) => {
            videoUrl += `&join_key=${video}`;
          });    
          showVideoModal(videoUrl);
        }
      }
    },
    [showVideoModal, mapSelection, gridSelection, video, subViewValue]
  );

  const showVideoButtons = (subView === 'map' && !!mapSelection?.length) || (subView === 'grid' && !!gridSelection?.length);

  return (
    <Flex direction="column" h="100%">
      <Header
        title={header}
        showInfoButton={false}
        infoOpen={infoOpen}
        setInfoOpen={setInfoOpen}
        showVideoButtons={showVideoButtons}
        onVideoPlay={onVideoPlay}
        onVideoCancel={onVideoCancel}
      />
      <Box mx="4" mb="4">
        <ButtonSelector
          fullWidth
          value={subViewValue}
          handleChange={setSubViewValue}
          options={subViewOptions}
        />
      </Box>
      <Box flex="1" minH="0">
        <ellipse-data-cricket {...widgetProps}></ellipse-data-cricket>
      </Box>
    </Flex>
  );
};
export default PitchmapViewContent;

