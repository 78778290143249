import { useState, useEffect } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

function timeDifference(datetime_utc) {
  let days = 0;
  let hours = 0;
  let minutes = 0;
  const matchDate = dayjs.utc(datetime_utc).local();
  const dateNow = dayjs();
  if (dayjs.isDayjs(matchDate)) {
    const dayDiff = matchDate.diff(dateNow, 'days');
    days = dayDiff > 0 ? dayDiff : 0;
    const hourDiff = matchDate.diff(dateNow, 'hours') % 24;
    hours = hourDiff > 0 ? hourDiff : 0;
    const minuteDiff = matchDate.diff(dateNow, 'minutes') % 60;
    minutes = minuteDiff > 0 ? minuteDiff : 0;
  }
  return {
    days,
    hours,
    minutes
  };
}

const TimeComponent = ({ timeVal, timeAttr }) => {
  return (
    <Flex direction="column" gap="1">
      <Box
        bg="#00000033"
        fontSize="60px"
        py="2.5"
        px="5"
        rounded="xl"
        lineHeight="82px"
        fontWeight="700"
      >
        {timeVal.toString().padStart(2, '0')}
      </Box>
      <Box alignSelf="center" fontSize="sm" fontWeight="700" textTransform="uppercase">
        {timeAttr}
      </Box>
    </Flex>
  );
};

const EventCountDown = ({ startTime }) => {
  const [time, setTime] = useState(timeDifference(startTime));
  useEffect(() => {
    const interval = setInterval(() => setTime(timeDifference(startTime)), 10 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [startTime]);

  return (
    <Center color="white.100">
      <Flex direction="column" gap="4">
        <Box fontSize="sm" fontWeight="700" alignSelf="center">
          MATCH BEGINS IN
        </Box>
        <Flex pb="4" gap="6">
          <TimeComponent timeVal={time.days} timeAttr="days" />
          <TimeComponent timeVal={time.hours} timeAttr="hours" />
          <TimeComponent timeVal={time.minutes} timeAttr="minutes" />
        </Flex>
      </Flex>
    </Center>
  );
};

export default EventCountDown;
