import PanelContainer from 'panels/Panel/PanelContainer';
import { breakpoints } from '../definitions';
import mergeLayoutDef from './mergeLayoutDefs';

export const getBreakPoint = (width) => {
  if (width - 12 >= breakpoints.lg) {
    return 'lg';
  } else if (width - 12 >= breakpoints.md) {
    return 'md';
  }
  return 'xs';
};

export const createNewArrayRow = (cols) => {
  const newArray = Array(cols).fill(null);
  return newArray;
};

export const findPosition = (currentArray, width, colWidth) => {
  let newX = 0;
  let newY = 0;
  let spaceAvailable = false;
  while (!spaceAvailable) {
    if (newX + width > colWidth) {
      if (!currentArray[newY + 1]) {
        currentArray.push(createNewArrayRow(colWidth));
      }
      newX = 0;
      newY += 1;
    } else {
      for (let i = 0; i < width; i += 1) {
        if (currentArray[newY][newX + i] !== null) {
          newX += 1;
          break;
        } else if (i === width - 1) {
          spaceAvailable = true;
        }
      }
    }
  }
  return { newX, newY };
};

export const orderPanelsFunction = (a, b, categoryId) => {
  let positionA = a.class_position;
  let positionB = b.class_position;
  if (categoryId && categoryId !== 'all') {
    positionA = a.category_position;
    positionB = b.category_position;
  }
  if (positionA === positionB) return 0;
  if (positionA === 0) return 1;
  if (positionB === 0) return -1;
  return positionA - positionB;
};

export const createNewLayout = (allPanelDefs, cols, currentBreakpoint, panelProps) => {
  const newLayout = [];
  const children = [];
  let yPointer = 0;
  const pointerArray = [];
  pointerArray.push(createNewArrayRow(cols));
  allPanelDefs.forEach((panel) => {
    const { panel_id, is_static } = panel;
    let width = 4;
    let height = 10;
    if (panel[currentBreakpoint]) {
      [width, height] = panel[currentBreakpoint];
    }
    if (width > cols) {
      width = cols;
    }
    const { newX, newY } = findPosition(pointerArray, width, cols);
    newLayout.push({
      i: panel_id.toString(),
      x: newX,
      y: newY,
      w: width,
      h: height,
      minW: 2,
      minH: 3,
      static: is_static,
      panelData: panel,
      ...panelProps
    });
    children.push(
      <PanelContainer
        key={panel_id}
        panelData={panel}
        layoutInfo={{
          width,
          height
        }}
        isDraggable
        {...panelProps}
      />
    );
    for (let i = newY; i < newY + height; i += 1) {
      if (!pointerArray[i]) {
        pointerArray.push(createNewArrayRow(cols));
      }
      for (let j = newX; j < newX + width; j += 1) {
        pointerArray[i][j] = panel_id;
      }
    }
    if (newX + width === cols) {
      yPointer += height;
      if (!pointerArray[yPointer]) {
        pointerArray.push(createNewArrayRow(cols));
      }
    }
  });
  return { newLayout, children };
};

export const getPositionType = (categoryId) => {
  if (categoryId === 'all' || !categoryId) {
    return 'class_position';
  }
  return 'category_position';
};

export const createNewPayloadDefs = (layout, currentBreakpoint) => {
  const payloadPanelsDefs = [];
  layout
    .filter((el) => {
      return !el.static;
    })
    .sort((a, b) => {
      // same row, compare X
      if (a.y === b.y) {
        return a.x - b.x;
      }
      // different row, compare Y
      return a.y - b.y;
    })
    .forEach((el, idx) => {
      const newEl = {
        panel_id: el.i,
        [currentBreakpoint]: [el.w, el.h]
      };
      newEl.position = idx + 1;
      payloadPanelsDefs.push(newEl);
    });
  return payloadPanelsDefs;
};

export { mergeLayoutDef };
