import { Box, Flex } from '@chakra-ui/react';
import RouterLink from '_shared/components/Utils/RouterLink';
import SubHeaderInfoItem from 'pages/_shared/components/SubHeaderInfoItem';

const TeamDetails = ({ teamInfo }) => {
  const {
    country_id,
    country_name,
    current_comps,
    formats_played,
    span,
    team_abbreviation,
    team_id
  } = teamInfo;
  const CompBool = country_id === team_id;
  const CurrentCompetitions = current_comps.map((el) => {
    return (
      <RouterLink to={`/comp/${el.id}`} pr={2}>
        {el.name}
      </RouterLink>
    );
  });

  return (
    <>
      <Flex color="white.100" justify="space-between" gap="10px">
        <SubHeaderInfoItem header="SPAN" detail={span} />
        <SubHeaderInfoItem header="FORMATS" detail={formats_played} />
        <SubHeaderInfoItem
          header={CompBool ? 'ABBREVIATION' : 'COUNTRY'}
          detail={
            !CompBool ? (
              <Box>
                <RouterLink to={country_id}>{country_name}</RouterLink>
              </Box>
            ) : (
              team_abbreviation
            )
          }
        />
      </Flex>
      {current_comps.length > 0 ? (
        <Flex color="white.100" justify="space-between" gap="10px">
          <SubHeaderInfoItem header="CURRENT COMPETITIONS" detail={CurrentCompetitions} />
        </Flex>
      ) : null}
    </>
  );
};

export default TeamDetails;
