import { Box } from '@chakra-ui/react';
import CompCard from './CompCard';

const EventHistory = ({ panelData }) => {
  const { data } = panelData;
  return (
    <Box p={1}>
      {data?.length > 0
        ? data.map((compData) => {
            return (
              <CompCard
                compData={compData}
                key={`CP_1002-${compData?.comp_id ? compData?.comp_id : compData?.id}`}
              />
            );
          })
        : null}
    </Box>
  );
};
export default EventHistory;
