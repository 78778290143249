import { Box, Center, Flex, Icon, Spinner } from '@chakra-ui/react';
import { MdInfoOutline } from 'react-icons/md';

const PanelsLoadingError = ({ panelError, errorMessage, panelIsFetching, panelsIsLoading }) => {
  return (
    <>
      {panelError && !panelIsFetching ? (
        <Center h="100%" w="100%">
          <Flex direction="column" alignItems="center" gap="5" mb="14">
            <Icon as={MdInfoOutline} color="primary.tint.80" boxSize="48px" />
            <Box mb="2" color="black.60">
              {errorMessage}
            </Box>
          </Flex>
        </Center>
      ) : null}
      {panelsIsLoading ? (
        <Center h="100%">
          <Spinner />
        </Center>
      ) : null}
    </>
  );
};

export default PanelsLoadingError;
