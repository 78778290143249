import { Box } from '@chakra-ui/react';
import { useMatch, useSearch } from '@tanstack/react-location';
import ContentGrid from './ContentGrid';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import { getTabContent } from '_shared/dataFetching/apiServiceV2';
import ContentError from './ContentError';
import ContentLoading from './ContentLoading';
import { memo } from 'react';
import isEqual from 'lodash.isequal';
import { getDateFetchString } from './utils';

const ContentContainer = ({ type }) => {
  const search = useSearch();
  const { tab, class_id, role, date } = search;
  const {
    params: { objectId }
  } = useMatch();
  const objectVariable = type !== 'player' ? type : role;
  const fetchParams = { class_id, tab };
  if (type !== 'player' && role) {
    fetchParams.role = role;
  }
  if (date) {
    fetchParams.date = getDateFetchString(date);
  }
  const { isLoading, error, data } = useQueryFetch(
    ['content', objectVariable, objectId, fetchParams],
    () => getTabContent(objectVariable, objectId, fetchParams),
    {
      staleTime: 30 * 1000,
      enabled: !!objectVariable
    }
  );

  return (
    <Box h="100%">
      {error && (
        <ContentError title="We’re sorry, an error has occurred" subtitle={error.message} />
      )}
      {isLoading && <ContentLoading />}
      {data && (
        <ContentGrid
          content={data?.content}
          objectVariable={objectVariable}
          objectId={objectId}
          video={data?.video}
        />
      )}
    </Box>
  );
};

const memoFunction = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default memo(ContentContainer, memoFunction);
