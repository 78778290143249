import {
  Box,
  Code,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useToast
} from '@chakra-ui/react';
import { useState } from 'react';
import { MdMoreVert } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { deletePanel, duplicatePanel } from '_shared/dataFetching/apiService';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import { FOCUS_PANEL } from '_shared/globalState/atoms';
import usePanelVisibility from '_shared/hooks/usePanelVisibility';
import { downloadData, handlePermissions, downloadCSV } from '_shared/utils/utilFunctions';

const DebugData = ({ panelData }) => {
  const {
    panel_id,
    panel_name,
    panel_type_id,
    user_id,
    variable_id,
    panel_type,
    api_reference,
    class_id,
    class_position,
    page_type,
    xs,
    md,
    lg,
    debug
  } = panelData;
  const { db_query, external_request_data } = debug || {};

  return (
    <Box as="pre">
      {JSON.stringify(
        {
          panel_id,
          panel_name,
          panel_type_id,
          user_id,
          variable_id,
          panel_type,
          api_reference,
          class_id,
          class_position,
          page_type,
          db_query,
          external_request_data,
          xs,
          md,
          lg
        },
        null,
        2
      )}
    </Box>
  );
};

const PanelMenu = ({ panelId, panelData, pageType, objectId, handleEdit, data }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [debugOpen, setDebugOpen] = useState(false);
  const { data: userInfo } = useUserDetails();
  const { permissions } = userInfo || {};
  const { class_id } = panelData;
  const setPanelVisibility = usePanelVisibility();
  const setFocusPanel = useSetRecoilState(FOCUS_PANEL);
  const handleHide = async () => {
    setPanelVisibility(panelId);
  };

  const handleDelete = async () => {
    const response = await deletePanel({
      panel_id: panelId
    });
    if (response.status === 200) {
      if (response.data.is_deleted === 1) {
        queryClient.setQueryData([pageType, `${objectId}`, 'panels'], (panels) => {
          return panels.filter((el) => {
            return el.panel_id !== panelId;
          });
        });
      } else {
        toast({
          title: 'Panel cannot be deleted',
          description: 'Panel set to hidden',
          status: 'warning',
          duration: 9000,
          isClosable: true
        });
        setPanelVisibility(panelId);
      }
    }
  };

  const handleDuplicate = async () => {
    const response = await duplicatePanel({
      panel_id: panelId,
      class_id,
      variable_id: objectId
    });
    if (response.status === 200) {
      queryClient.refetchQueries([pageType, `${objectId}`, 'panels']);
      let count = 0;
      const scroll = setInterval(() => {
        const section = document.getElementById(`panel_${response.data.panel_id}`);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start' });
          clearInterval(scroll);
        }
        count += 1;
        if (count > 5) {
          clearInterval(scroll);
        }
      }, 1000);
      setFocusPanel(response.data.panel_id);
      setTimeout(() => {
        setFocusPanel(null);
      }, 10 * 1000);
    }
  };

  const menuOptions = [];
  if (!panelData.is_static) {
    menuOptions.push(
      <MenuItem key="hide" fontSize="md" px="2" onClick={handleHide}>
        Hide
      </MenuItem>
    );
    if (handlePermissions(4, permissions)) {
      menuOptions.unshift(
        <MenuItem key="duplicatePanel" fontSize="md" px="2" onClick={handleDuplicate}>
          Duplicate Panel
        </MenuItem>
      );
    }

    if (handlePermissions(3, permissions)) {
      menuOptions.unshift(
        <MenuItem key="editPanel" fontSize="md" px="2" onClick={() => handleEdit('editPanel')}>
          Edit Panel
        </MenuItem>
      );
    }

    if (panelData && handlePermissions(2, permissions)) {
      const { data_type } = panelData;
      if (data_type === 'qt') {
        menuOptions.unshift(
          <MenuItem key="editQuery" fontSize="md" px="2" onClick={() => handleEdit('editQuery')}>
            Edit Query
          </MenuItem>
        );
      }
    }
    if (handlePermissions(5, permissions)) {
      menuOptions.push(
        <MenuItem key="delete" fontSize="md" px="2" onClick={handleDelete}>
          Delete
        </MenuItem>
      );
    }
  }
  if (data && handlePermissions(6, permissions)) {
    if (panelData.panel_type_id === 2) {
      menuOptions.unshift(
        <MenuItem key="downloadCSV" fontSize="md" px="2" onClick={() => downloadCSV(data)}>
          CSV Export
        </MenuItem>
      );
    }
    menuOptions.unshift(
      <MenuItem key="downloadData" fontSize="md" px="2" onClick={() => downloadData(data)}>
        JSON Export
      </MenuItem>
    );
  }

  menuOptions.push(
    <MenuItem key="debug" fontSize="md" px="2" onClick={() => setDebugOpen(true)}>
      Debug
    </MenuItem>
  );

  if (menuOptions.length === 0) {
    return null;
  }

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={IconButton}
          size="sm"
          isRound
          color="black.100"
          icon={<MdMoreVert size={24} />}
          variant="ghost"
        />
        <MenuList py="1">{menuOptions}</MenuList>
      </Menu>
      <Modal isOpen={debugOpen} onClose={() => setDebugOpen(false)} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody overflowX="scroll">
            <Code fontSize="11px">
              <DebugData panelData={data || panelData} />
            </Code>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PanelMenu;
