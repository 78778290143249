const buttonStyles = {
  sizes: {
    xs: {
      px: 3,
      py: 1.5,
      h: 7
    }
  },
  baseStyle: {
    border: 0,
    _focusVisible: {
      boxShadow: null
    }
  },
  variants: {
    outline: {
      color: 'primary.main',
      bg: 'primary.tint.90',
      border: 0,
      _hover: {
        bg: 'primary.tint.80'
      },
      _active: {
        bg: 'primary.tint.60',
        boxShadow: null
      }
    },
    solid: {
      color: 'white.100',
      bg: 'primary.main',
      border: 0,
      _hover: {
        bg: 'primary.tint.10'
      },
      _active: {
        bg: 'primary.shade.10'
      }
    },
    primary: {
      color: 'white.100',
      bg: 'primary.main',
      _hover: {
        bg: 'primary.shade.20',
        _disabled: {
          bg: 'primary.shade.20'
        }
      },
      _active: {
        bg: 'primary.shade.40'
      },
      _disabled: {
        bg: 'primary.shade.20'
      }
    },
    primaryDark: {
      color: 'white.100',
      bg: 'primary.tint.10',
      _hover: {
        bg: 'primary.tint.20'
      },
      _active: {
        bg: 'primary.main'
      }
    },
    secondary: {
      color: 'primary.main',
      bg: 'primary.tint.90',
      _hover: {
        bg: 'primary.tint.80',
        _disabled: {
          bg: 'primary.tint.90'
        }
      },
      _active: {
        bg: 'primary.tint.60'
      },
      _disabled: {
        bg: 'primary.tint.90'
      }
    },
    secondaryDark: {
      color: 'primary.tint.80',
      bg: 'white.20',
      _hover: {
        color: 'white.100',
        bg: 'primary.tint.10'
      },
      _active: {
        color: 'white.100',
        bg: 'primary.main'
      }
    },
    tertiary: {
      color: 'black.76',
      bg: 'black.4',
      _hover: {
        bg: 'black.8'
      },
      _active: {
        bg: 'black.24'
      }
    },
    tertiaryDark: {
      color: 'white.100',
      bg: 'white.10',
      _hover: {
        bg: 'white.20'
      },
      _active: {
        bg: 'white.6'
      }
    },
    ghost: {
      color: 'black.76',
      bg: 'transparent',
      _hover: {
        bg: 'black.8'
      },
      _active: {
        bg: 'black.24'
      }
    },
    ghostDark: {
      color: 'white.100',
      bg: 'transparent',
      _hover: {
        color: 'primary.tint.80',
        bg: 'white.20'
      },
      _active: {
        color: 'primary.tint.80',
        bg: 'white.10'
      }
    },
    positive: {
      color: 'white.100',
      bg: 'positive.main',
      border: 0,
      _hover: {
        bg: 'positive.shade.10'
      },
      _active: {
        bg: 'positive.shade.20'
      }
    },
    positiveDark: {
      color: 'white.100',
      bg: 'positive.tint.10',
      border: 0,
      _hover: {
        bg: 'positive.tint.20'
      },
      _active: {
        bg: 'positive.main'
      }
    },
    menu: {
      display: 'flex',
      justifyContent: 'start',
      color: 'white.100',
      bg: 'transparent',
      textAlign: 'left',
      fontWeight: '700',
      border: 0,
      _hover: {
        bg: 'primary.tint.10'
      },
      _active: {
        bg: 'primary.main'
      }
    }
  }
};

export default buttonStyles;
