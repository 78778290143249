import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { postPanelVisibility } from '_shared/dataFetching/apiService';
import { HIDDEN_PANELS } from '_shared/globalState/atoms';

const usePanelVisibility = () => {
  const setHiddenPanels = useSetRecoilState(HIDDEN_PANELS);

  const setPanelVisibility = useCallback(
    async (panelId, value = 'hide') => {
      setHiddenPanels((p) => {
        const newValue = { ...p, [panelId]: value === 'hide' };
        return newValue;
      });
      await postPanelVisibility(value, {
        panel_id: panelId
      });
    },
    [setHiddenPanels]
  );

  return setPanelVisibility;
};

export default usePanelVisibility;
