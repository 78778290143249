import { Box, useToken } from '@chakra-ui/react';
import 'chart.js/auto';
import { Radar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import parseData from './parseData';
import { memo, useRef } from 'react';
import isEqual from 'lodash.isequal';
import RadarKey from './RadarKey';

const RadarComponent = ({ data, handleKeyClick, disableClickFirstItem }) => {
  const tooltipBoxRef = useRef(null);
  const legendColors = useToken('colors', ['chartColors.1', 'chartColors.2', 'chartColors.3']);

  // Init helper variables to assist custom rendering of pointLabels
  const pointLabelsCount = data?.[0]?.data?.length ?? 0;
  const hoverStatuses = new Array(pointLabelsCount).fill(false);
  let hoverDatasetIndex = -1;

  const parsedData = parseData(data, legendColors);

  return (
    <>
      <Box maxW="400px" margin="auto" display="flex" justifyContent="center" alignItems="flex-start">
        <Box ref={tooltipBoxRef} position="relative"></Box>
        <Radar
          data={parsedData}
          plugins={[ChartDataLabels]}
          options={{
            layout: { // Ensures pointLabels don't get clipped
              padding: {
                left: 10,
                right: 10,
                top: 0,
                bottom: 0
              }
            },
            plugins: {
              legend: { display: false },
              datalabels: {
                color(context) {
                  // (1 of 3) Use the callback hook to set this useful param
                  hoverStatuses[context.dataIndex] = context.active;
                  return context.dataset.backgroundColor;
                }
              },
              tooltip: {
                usePointStyle: true,
                callbacks: {
                  title(context) {
                    // (2 of 3) Use the callback hook to set this useful param
                    // Apply this under a timeout to allow it to effect AFTER hook 1 of 3.
                    setTimeout(() => (hoverStatuses[context?.[0]?.dataIndex] = context?.[0]?.element?.active), 0);
                    // (3 of 3) Use the callback hook to set this useful param
                    hoverDatasetIndex = context?.[0]?.datasetIndex;
                    return context?.[0]?.formattedValue;
                  }
                },
                enabled: false, // Disable the on-canvas tooltip
                external(context) {
                    const tooltipBox = tooltipBoxRef.current;
                    let tooltipEl = tooltipBox.firstChild;
                    if (!tooltipEl) {
                      tooltipEl = document.createElement('div');
                      tooltipBox.appendChild(tooltipEl);
                    }
                    const tooltipModel = context.tooltip;

                    const noTooltip = tooltipModel.opacity === 0;
                    if (noTooltip) {
                      // Hide if no tooltip
                      tooltipEl.style.opacity = 0;
                    } else {
                      tooltipEl.classList.remove('above', 'below', 'no-transform');
                      tooltipEl.classList.add(tooltipModel.yAlign ? tooltipModel.yAlign : 'no-transform');
                      tooltipEl.innerText = tooltipModel.title;
                      tooltipEl.style.opacity = 1;
                      tooltipEl.style.position = 'absolute';

                      const rawValue = tooltipModel.dataPoints?.[0]?.raw;

                      let inset = 25;
                      if (rawValue > 65) inset *= -1; // AC_note => assumes scale 0 -> 100 here.

                      const angle = tooltipModel.dataPoints?.[0]?.element?.angle;
                      const deltaLeft = inset * Math.cos(angle);
                      const deltaTop = inset * Math.sin(angle);

                      tooltipEl.style.left = `${tooltipModel.caretX - (tooltipEl.offsetWidth / 2) + deltaLeft}px`;
                      tooltipEl.style.top = `${tooltipModel.caretY - (tooltipEl.offsetHeight / 2) + deltaTop}px`;

                      tooltipEl.style.lineHeight = `1`;
                      tooltipEl.style.padding = `6px 6px`;
                      tooltipEl.style.pointerEvents = 'none';
                      tooltipEl.style.color = '#fff';
                      tooltipEl.style.backgroundColor = tooltipModel?.labelColors?.[0]?.backgroundColor;
                      tooltipEl.style.fontSize = '12px';
                      tooltipEl.style.fontWeight = 700;
                      tooltipEl.style.textAlign = 'center';
                      tooltipEl.style.borderRadius = '4px';
                    }
                }
              }
            },
            elements: {
              point: {
                radius: 8,
                borderColor: '#FFFFFF',
                borderWidth: 2,
                backgroundColor: '#FFA2EB',
                hoverRadius: 8,
                hoverBorderColor: '#FFFFFF',
                hoverBorderWidth: 2,
              },
              line: { borderColor: '#FFA2EB', borderWidth: 6 }
            },
            scales: {
              r: {
                grid: {
                  lineWidth: 2,
                  color: '#EBEBED'
                },
                angleLines: {
                  lineWidth: 2,
                  color: '#EBEBED'
                },
                pointLabels: {
                  color: (context) => {
                    return hoverStatuses[context.index] ? '#fff' : '#040921';
                  },
                  font: (context) => {
                    const weight = hoverStatuses[context.index] ? 'bold' : 'normal';
                    return {
                      family: `'Open Sans', sans-serif`,
                      size: 12,
                      style: 'normal',
                      weight,
                      lineHeight: 1
                    };
                  },
                  backdropColor: (context) => {
                    const color = (hoverDatasetIndex >= 0) ? parsedData.datasets?.[hoverDatasetIndex]?.pointBackgroundColor : null;
                    return ((hoverStatuses[context.index] && color) ? color : 'transparent');
                  },
                  borderRadius: 4,
                  backdropPadding: {
                    left: 10,
                    right: 10,
                    top: 8,
                    bottom: 8
                }
                },

                ticks: {
                  font: {
                    size: 12
                  },
                  color: '#AFB0B8',
                  stepSize: 20
                },
                max: 100,
                beginAtZero: true
              }
            }
          }}
        />
      </Box>
      <RadarKey
        data={data}
        handleClick={handleKeyClick}
        disableClickFirstItem={disableClickFirstItem}
      />
    </>
  );
};

const memoFunction = (prevProps, nextProps) => {
  if (isEqual(prevProps?.data, nextProps?.data)) {
    return true;
  }
  return false;
};

export default memo(RadarComponent, memoFunction);
