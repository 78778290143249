import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Box } from '@chakra-ui/react';
import GridLayout from 'react-grid-layout';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import { HIDDEN_PANELS, SCORECARD_TYPE } from '_shared/globalState/atoms';
import { changeGrid } from '_shared/dataFetching/apiService';
import useWindowDimensions from '_shared/hooks/useWindowDimensions';
import { breakpointColumns } from './definitions';
import {
  createNewLayout,
  getBreakPoint,
  orderPanelsFunction,
  getPositionType,
  mergeLayoutDef,
  createNewPayloadDefs
} from './gridFunctions/gridFunctions';
import { getAvailablePanelTypeIds } from '../PanelTypes/PanelTypeDefinitions';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './customGridStyles.css';

const PanelPageDraggable = ({ pageData, panels, pageType, classId, categoryId }) => {
  const { width } = useWindowDimensions();
  const currentBreakpoint = getBreakPoint(width);
  const hiddenPanels = useRecoilValue(HIDDEN_PANELS);
  const scorecardType = useRecoilValue(SCORECARD_TYPE);
  // layout for react-grid-layout
  const [layout, setLayout] = useState(null);
  const [children, setChildren] = useState(null);

  // local store for layout defs to reload when class or category is changed
  const layoutDefs = useRef({});
  const previousLayout = useRef(null);

  useEffect(() => {
    // number of columns available in current breakpoint
    const cols = breakpointColumns[currentBreakpoint];
    // array of available panel ids
    const availablePanelTypes = getAvailablePanelTypeIds();
    const staticPanels = [];
    const nonStaticPanels = [];
    // iterate panels array to create new panel definitions
    panels.forEach((panel) => {
      // exclude panel if hidden
      if (hiddenPanels[panel.panel_id]) {
        return;
      }
      // exclude panel if panel_type_id is no available
      if (!availablePanelTypes.includes(panel.panel_type_id.toString())) {
        return;
      }
      // get local panel layout details, saved after each grid change
      const currentLayout = layoutDefs.current[panel.panel_id];
      // panel def uses current panel position/sizes or position 0 if unavailable
      const newPanelDef = {
        ...panel,
        class_position: panel.class_position || 0,
        category_position: panel.category_position || 0
      };
      // set height for scorecard depending on scorcard type
      if (panel.panel_id === 'MS_1004') {
        newPanelDef.lg[1] = scorecardType === 'live' ? 10 : 20;
        newPanelDef.md[1] = scorecardType === 'live' ? 10 : 20;
        newPanelDef.xs[1] = scorecardType === 'live' ? 10 : 20;
      }
      // if there is a locally saved layout def this is used instead
      // This should always be the same as server def, it is saved locally to avoid refetching
      if (currentLayout) {
        const { lg, md, xs, class_position, category_position } = currentLayout;
        if (lg) newPanelDef.lg = [...lg];
        if (md) newPanelDef.md = [...md];
        if (xs) newPanelDef.xs = [...xs];
        if (class_position) newPanelDef.class_position = class_position;
        if (category_position) newPanelDef.category_position = category_position;
      }
      // push to static panels
      if (panel.is_static) {
        staticPanels.push(newPanelDef);
        return;
      }
      // Include panel if 'all', no categoryId or categoryId matches
      if (categoryId === 'all' || !categoryId || panel.category_type_id === parseInt(categoryId)) {
        nonStaticPanels.push(newPanelDef);
        return;
      }
    });
    // order panels so position '0' is last
    const orderedPanelDefs = nonStaticPanels.sort((a, b) => orderPanelsFunction(a, b, categoryId));
    // concat with static panels first
    const allPanelDefs = staticPanels.concat(orderedPanelDefs);
    // create grid layout def and children for react-grid-layout
    const { newLayout, children } = createNewLayout(allPanelDefs, cols, currentBreakpoint, {
      pageData,
      pageType,
      classId
    });
    // check if previous layout is equal, do not update if it is for performance
    if (!isEqual(previousLayout.current, newLayout)) {
      // update previous layout
      previousLayout.current = newLayout;
      setLayout(newLayout);
      setChildren(children);
    }
  }, [
    classId,
    categoryId,
    currentBreakpoint,
    hiddenPanels,
    pageData,
    pageType,
    panels,
    scorecardType
  ]);

  // debounce function to save layoutDef
  const saveLayoutDef = useRef(
    debounce(async (payloadPanelsDefs, positionType) => {
      try {
        // keep exisitng layout defs and update new fields
        const newDefs = mergeLayoutDef(layoutDefs.current, payloadPanelsDefs, positionType);
        layoutDefs.current = newDefs;
      } catch (e) {
        console.log('unable to save layout def');
      }
    }, 200)
  ).current;

  // debounce function to reduce number of layout saves
  const saveLayout = useRef(
    debounce(async (payload) => {
      try {
        await changeGrid(payload);
      } catch (e) {
        console.log('unable to save layout');
      }
    }, 2000)
  ).current;

  const savePanelDef = useCallback(
    (layout) => {
      // create payload to save to layout database
      const payloadPanelsDefs = createNewPayloadDefs(layout, currentBreakpoint);
      // check layout position type
      const positionType = getPositionType(categoryId);
      // save local version of layout def to avoid refetching
      saveLayoutDef(payloadPanelsDefs, positionType);
      // add position type to payload
      const updatePanelsPayload = {
        panel_data: payloadPanelsDefs,
        update_type: positionType
      };
      // only update if number of panels is not 0
      if (updatePanelsPayload?.panel_data.length > 0) {
        // save using debounce function
        saveLayout(updatePanelsPayload);
      }
    },
    [categoryId, currentBreakpoint, saveLayout, saveLayoutDef]
  );

  if (!layout) {
    return null;
  }

  return (
    <Box mx="14px">
      <GridLayout
        className="layout"
        layout={layout}
        cols={breakpointColumns[currentBreakpoint]}
        compactType={'vertical'}
        onLayoutChange={(layout) =>
          !['user', 'home'].includes(pageType) ? savePanelDef(layout) : null
        }
        draggableHandle=".draggable-handle"
        width={width - 40}
        // need to times all panel heights by 5
        rowHeight={23}
        margin={[16, 16]}
      >
        {children}
      </GridLayout>
    </Box>
  );
};

export default PanelPageDraggable;
