import { Box, Collapse, Flex } from '@chakra-ui/react';
import { ChevronDownOutline24, ChevronUpOutline24 } from '_icons/outline/24';

const FormAccordian = ({ children, isExpanded, setIsExpanded, isActive, label }) => {
  return (
    <Box
      bg={isExpanded || isActive ? 'white.100' : 'black.3'}
      borderRadius="md"
      border="1px"
      borderColor={isExpanded || isActive ? 'black.8' : 'transparent'}
      _hover={{ borderColor: 'black.24', bg: 'white.100' }}
    >
      <Flex
        px="4"
        py="3"
        aling="center"
        justify="space-between"
        cursor="pointer"
        onClick={() => setIsExpanded((p) => !p)}
      >
        <Flex align="center" gap="1" fontSize="sm" fontWeight="700">
          <Box>{label}</Box>
          {isActive ? <Box color="primary.main">•</Box> : null}
        </Flex>
        {isExpanded ? <ChevronUpOutline24 /> : <ChevronDownOutline24 />}
      </Flex>
      <Collapse in={isExpanded}>{children}</Collapse>
    </Box>
  );
};

export default FormAccordian;
