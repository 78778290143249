import checkMatchType from './utils/checkMatchType';

const match = [
  {
    value: 'summary',
    label: 'Summary',
    enabled: (objectData) => {
      return checkMatchType(objectData) === 'match';
    }
  },
  {
    value: 'squad',
    label: 'Squads',
    enabled: (objectData) => {
      return checkMatchType(objectData) === 'prematch';
    }
  },
  {
    value: 'insights',
    label: 'Insights'
  },
  {
    value: 'advanced_batting',
    label: 'Batting analysis',
    labelSmall: 'Batting',
    enabled: (objectData) => {
      return checkMatchType(objectData) === 'match';
    }
  },
  {
    value: 'advanced_bowling',
    label: 'Bowling analysis',
    labelSmall: 'Bowling',
    enabled: (objectData) => {
      return checkMatchType(objectData) === 'match';
    }
  }
];

export default match;
