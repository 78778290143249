import { Box, List } from '@chakra-ui/react';
import FixtureComponent from '_shared/components/FixtureComponent/FixtureComponent';
import { MatchesUnavailable } from './MatchHomePageContainer';

const MatchLiveGridList = ({ data = [], title, text }) => {
  return (
    <Box bg="white.100" boxShadow="small" rounded="md" minWidth="0">
      <Box borderBottom="1px" borderColor="black.8" fontWeight="700" fontSize="sm" p="10px">
        {title}
      </Box>
      {data.length === 0 ? (
        <MatchesUnavailable text={text} />
      ) : (
        <List m="3" pb="1" spacing="2">
          {data?.map((matchData) => {
            return <FixtureComponent matchData={matchData} key={matchData.id} />;
          })}
        </List>
      )}
    </Box>
  );
};

export default MatchLiveGridList;
