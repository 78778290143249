import configDefs from './configDefs';
import defaultConfig from './configDefs/defaultConfig';

const getContentConfig = (contentKey, variableKey, overrides = []) => {
  const newKey =
    typeof contentKey === 'string'
      ? contentKey.split('-').join('_').split('&').join('_')
      : contentKey;
  if (!contentKey) return null;
  const overrideValue = overrides.find(({ type, value }) => {
    return configDefs[newKey]?.[type]?.[value]?.[variableKey];
  });
  if (overrideValue) {
    const { type, value } = overrideValue;
    return configDefs[newKey]?.[type]?.[value]?.[variableKey];
  }
  if (configDefs[newKey]?.default?.[variableKey]) {
    return configDefs[newKey]?.default?.[variableKey];
  }
  if (defaultConfig[variableKey]) {
    return defaultConfig[variableKey];
  }
  return null;
};

export default getContentConfig;
