import { Center, Spinner, Box } from '@chakra-ui/react';
import { getObjectInformation } from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import usePageTitle from '_shared/hooks/usePageTitle';
import MatchLivePageContainer from './MatchHomePageContainer';

const MatchLivePage = () => {
  const type = 'match';
  const objectId = '';
  const {
    isLoading: objectIsLoading,
    error: objectError,
    data: objectData,
    isFetching: objectIsFetching
  } = useQueryFetch([type, objectId], () => getObjectInformation(type, objectId));
  usePageTitle('Matches');
  if (objectIsLoading) {
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    );
  }
  if (objectError && !objectIsFetching) {
    return <Box>{`Unable to retrieve match data`}</Box>;
  }
  if (objectData) {
    return <MatchLivePageContainer data={objectData} />;
  }
};

export default MatchLivePage;
