import { Checkbox, Flex } from '@chakra-ui/react';
import OptionStyle from './OptionStyle';

const FilterCheckbox = ({ options, handleFilterChange, name, filterValues }) => {
  const values = filterValues[name] || [];

  return (
    <Flex direction="column" gap="2">
      {options.map((option, idx) => {
        return (
          <OptionStyle key={`${idx}_${option.value}`}>
            <Checkbox
              p="2.5"
              value={option.value}
              fontSize="sm"
              w="100%"
              isChecked={values?.includes(option?.value)}
              onChange={() => handleClickOption(values, option, name, handleFilterChange)}
            >
              {option.label}
            </Checkbox>
          </OptionStyle>
        );
      })}
    </Flex>
  );
};

const handleClickOption = (values, option, name, handleFilterChange) => {
  let newValues;
  if (values?.includes(option.value)) {
    newValues = values.filter((val) => val !== option.value);
    newValues = newValues.length ? newValues : null;
  } else {
    newValues = [...values, option.value];
  }
  handleFilterChange(name, newValues);
};

export default FilterCheckbox;
