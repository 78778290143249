import summary from './summary';
import playerstats from './playerstats';
import teamstats from './teamstats';

const configDefs = {
  ...summary,
  ...playerstats,
  ...teamstats
};

export default configDefs;
