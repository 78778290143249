import { Table, Tbody, Td, Tr } from '@chakra-ui/react';
import RouterLink from '_shared/components/Utils/RouterLink';
import TeamImage from '_shared/components/TeamImage';
import TableHeader from '../_shared/TableHeader';
import NoDataDisplay from 'panels/Panel/NoDataDisplay';

const columns = (tiebreaker_name) => {
  const columns = [
    { content: '' },
    { content: '' },
    { content: '#', isNumeric: true, tooltip: 'Position' },
    { content: 'M', isNumeric: true, tooltip: 'Matches' },
    { content: 'W', isNumeric: true, tooltip: 'Wins' },
    { content: 'D', isNumeric: true, tooltip: 'Draws' },
    { content: 'L', isNumeric: true, tooltip: 'Loses' },
    { content: 'PTS', isNumeric: true, tooltip: 'Points' }
  ];
  if (tiebreaker_name) {
    columns.push({
      content: tiebreaker_name === 'net_run_rate' ? 'NRR' : tiebreaker_name,
      isNumeric: true,
      tooltip: tiebreaker_name === 'net_run_rate' ? 'Net Run Rate' : tiebreaker_name
    });
  }
  return columns;
};

const StandingsRow = ({ rowData }) => {
  const {
    team_id,
    team_abbreviation,
    position,
    matches,
    won,
    lost,
    tiebreaker_name,
    tiebreaker_value,
    drawn,
    logo_url,
    points
  } = rowData;
  return (
    <Tr>
      <Td w="50px">
        <TeamImage
          team={{
            id: team_id,
            logo: logo_url
          }}
          size={36}
        />
      </Td>
      <Td>
        {team_abbreviation ? (
          <RouterLink to={`/team/${team_id}`} variant="ghost" fontWeight="700" color="black.100">
            {team_abbreviation.toUpperCase()}
          </RouterLink>
        ) : null}
      </Td>
      <Td isNumeric>{position}</Td>
      <Td isNumeric>{matches}</Td>
      <Td isNumeric>{won}</Td>
      <Td isNumeric>{drawn}</Td>
      <Td isNumeric>{lost}</Td>
      <Td isNumeric>{points}</Td>
      {tiebreaker_name ? <Td isNumeric>{tiebreaker_value}</Td> : null}
    </Tr>
  );
};

const StandingsTable = ({ data }) => {
  const { tiebreaker_name } = data?.[0] || {};
  if (!data) {
    return <NoDataDisplay message="Competition data is unavailable" />;
  }
  return (
    <Table
      size="sm"
      whiteSpace="nowrap"
      variant="striped"
      mt="2"
      sx={{
        '& td': {
          padding: '4px 12px'
        },
        '& th': {
          padding: '4px 12px'
        }
      }}
    >
      <TableHeader columns={columns(tiebreaker_name)} />
      <Tbody>
        {data.map((el) => {
          return <StandingsRow key={`standings-el-${el.team_id}`} rowData={el} />;
        })}
      </Tbody>
    </Table>
  );
};

export default StandingsTable;
