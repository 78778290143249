import cloneDeep from 'lodash.clonedeep';
import { createUUID } from '_shared/utils/utilFunctions';

const updateRecentFilters = ({ id, value }, prev = []) => {
  const newValues = cloneDeep(prev);

  // if preset id move to front of list
  if (id) {
    const existingIndex = prev.findIndex((el) => {
      return el.id === id;
    });
    if (existingIndex > -1) {
      const [oldValue] = newValues.splice(existingIndex, 1);
      newValues.unshift(oldValue);
    }
    return newValues;
  }

  // if preset value exactly matches, move to front of list
  const existingIndex = prev.findIndex((el) => {
    return el.value === value;
  });
  if (existingIndex > -1) {
    const [oldValue] = newValues.splice(existingIndex, 1);
    newValues.unshift(oldValue);
    return newValues;
  }

  // if not create new preset
  const newPresetId = createUUID();
  const newPresetValue = cloneDeep(value);
  newValues.unshift({
    id: newPresetId,
    value: newPresetValue
  });
  // remove item if more than 10
  if (newValues.length > 10) {
    newValues.pop();
  }
  return newValues;
};

export default updateRecentFilters;
