import { Box, Flex, Grid, GridItem, Avatar, Select } from '@chakra-ui/react';
import { Link } from '@tanstack/react-location';
import { useState } from 'react';
import FlexHeaderScroll from '_shared/components/LayoutComponents/FlexHeaderScroll';
import capitalise from '_shared/utils/capitalise';
import ButtonSelector from '_shared/components/ButtonSelector';
import NoDataDisplay from 'panels/Panel/NoDataDisplay';

const PlayerCard = ({ item }) => {
  const { id, known_as, player_img, player_role_short } = item;

  return (
    <GridItem
      p="3"
      rounded="md"
      bg="black.3"
      border="1px"
      borderColor="transparent"
      _hover={{ boxShadow: 'small', cursor: 'pointer', bg: 'white.100', borderColor: 'black.24' }}
    >
      <Link to={`/player/${id}`}>
        <Flex direction="column" alignItems="center" gap="1">
          <Avatar size="lg" src={player_img ? player_img : null} />
          <Box fontSize="sm" fontWeight="700" textAlign="center" w="100%">
            {known_as}
          </Box>
          <Box fontSize="xs" color="black.60" textAlign="center">
            {capitalise(player_role_short)}
          </Box>
        </Flex>
      </Link>
    </GridItem>
  );
};

const filterPlayer = (filter, playerRole) => {
  if (!playerRole) {
    return false;
  }
  if (playerRole.includes('allrounder')) {
    return true;
  }
  if (filter === 'batters' && (playerRole.includes('batter') || playerRole.includes('batsman'))) {
    return true;
  }
  if (filter === 'bowlers' && playerRole.includes('bowler')) {
    return true;
  }
  return false;
};

const SquadGridDisplay = ({ filterRole, squadData }) => {
  if (!squadData || squadData.length === 0) {
    return <NoDataDisplay message="No squad data available" />;
  }
  return (
    <Grid gap="2" templateColumns="repeat(auto-fill,minmax(140px, 1fr))" m="2">
      {squadData
        .filter((el) => {
          return filterRole === 'all' ? true : filterPlayer(filterRole, el.player_role_short);
        })
        .map((item) => {
          return <PlayerCard item={item} />;
        })}
    </Grid>
  );
};

const SquadPlayers = ({ panelData, isMultiSelect }) => {
  const [filterRole, setFilterRole] = useState('all');
  const { data } = panelData;
  const { teams, squads } = data;

  const [teamId, setTeamId] = useState(teams ? Object.keys(teams)[0] : null);

  return (
    <FlexHeaderScroll
      header={
        <Flex px="2" py="2" gap="1">
          <ButtonSelector
            handleChange={setFilterRole}
            value={filterRole}
            options={['all', 'batters', 'bowlers']}
            buttonMinWidth="80px"
          />
          {isMultiSelect && teams && (
            <Select onChange={(e) => setTeamId(e.target.value)} value={teamId}>
              {Object.keys(teams).map((el, idx) => {
                return (
                  <option value={el} key={`${el}-${idx}`}>
                    {teams[el].name}
                  </option>
                );
              })}
            </Select>
          )}
        </Flex>
      }
      content={
        <SquadGridDisplay
          filterRole={filterRole}
          squadData={isMultiSelect ? squads?.[teamId] : data}
        />
      }
    />
  );
};
export default SquadPlayers;
