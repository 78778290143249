import { Box, GridItem } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-location';
import AddUserFavourites from '_shared/components/AddUserFavourites';

const GroundFavListItem = ({ item }) => {
  const { id, name } = item;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate({ to: `/ground/${id}` });
  };
  return (
    <GridItem
      p="4"
      rounded="md"
      bg="black.3"
      border="1px"
      borderColor="transparent"
      _hover={{ boxShadow: 'small', cursor: 'pointer', bg: 'white.100', borderColor: 'black.24' }}
      display="flex"
      onClick={handleClick}
      alignItems="center"
      gap="2"
    >
      <Box flexGrow="1">{name}</Box>
      <AddUserFavourites favType="ground" favId={id} lightBackground />
    </GridItem>
  );
};

export default GroundFavListItem;
