import { Flex, Box, Button } from '@chakra-ui/react';

import { useState } from 'react';
import AddToPlaylistModal from './AddToPlaylistModal';

const AddToPlaylist = ({ vidDict, vidList, vidLength, setFieldValue, setSelectedVideos }) => {
  const [formModalOpen, setFormModalOpen] = useState(false);

  if (vidLength === 0) {
    return null;
  }

  return (
    <Box position={'fixed'} bottom={0} w="100%" bg={'white.100'} margin="auto">
      <Flex mb={2} p={3}>
        <Box fontWeight="bold" fontSize="lg" ml={'auto'} mt={'auto'}></Box>
        <Flex ml="auto" mt={'auto'}>
          <Box mt={'auto'}>
            <Button
              mr={7}
              fontSize="sm"
              variant="tertiary"
              onClick={(e) => {
                e.preventDefault();
                setFieldValue('playlist_selection', null);
                setSelectedVideos([]);
              }}
            >
              Clear selection
            </Button>
          </Box>
          <Button
            mr={3}
            fontSize="sm"
            type="submit"
            onClick={() => {
              setFormModalOpen(true);
            }}
          >
            Add to playlist
          </Button>
        </Flex>
      </Flex>
      <AddToPlaylistModal
        vidDict={vidDict}
        vidList={vidList}
        vidLength={vidLength}
        formModalOpen={formModalOpen}
        setFormModalOpen={setFormModalOpen}
        setSelectedVideos={setSelectedVideos}
      />
    </Box>
  );
};

export default AddToPlaylist;
