import { Box, Center, Flex, IconButton, List, ListItem } from '@chakra-ui/react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useRecoilValue } from 'recoil';
import { FilterCategory } from '_icons/svgs';
import usePanelVisibility from '_shared/hooks/usePanelVisibility';
import { HIDDEN_PANELS } from '_shared/globalState/atoms';
import { getClassIcon, getPanelTypeIcon } from './utils';

const classShortDescriptions = {
  1: 'Test',
  2: 'ODI',
  3: 'T20I',
  4: 'FC',
  5: 'ODD',
  6: 'LATT20',
  11: 'Test',
  12: 'ODI',
  13: 'T20I',
  14: 'FC',
  15: 'ODD',
  16: 'LATT20'
};

const ShowHideIcon = ({ isVisible }) => {
  return (
    <Box>
      <IconButton
        className="show-hide-icon"
        h="44px"
        w="44px"
        aria-label="refresh"
        transition="background-color 100ms ease-in"
        bg={isVisible ? 'primary.main' : 'black.4'}
        color={isVisible ? 'white.100' : 'black.32'}
        variant="outline"
        icon={isVisible ? <AiOutlineEye size={24} /> : <AiOutlineEyeInvisible size={24} />}
      />
    </Box>
  );
};

const MetaDataItem = ({ Icon, label }) => {
  return (
    <Flex
      rounded="full"
      border="1px"
      borderColor="black.8"
      px="1"
      py="2px"
      fontSize="xs"
      color="black.60"
      alignItems="center"
      gap="1"
    >
      <Icon size={12} />
      {label}
    </Flex>
  );
};

const MetaData = ({ panel }) => {
  const { class_id, panel_type, category_type_name } = panel;
  const items = [];
  if (class_id) {
    items.push(
      <MetaDataItem
        Icon={getClassIcon(class_id)}
        label={`${classShortDescriptions[class_id] || ''}`}
      />
    );
  }
  if (category_type_name) {
    items.push(<MetaDataItem Icon={FilterCategory} label={category_type_name} />);
  }
  // if (creator) {
  // items.push(<MetaDataItem Icon={FilterUser} label={creator} />);
  // }
  if (panel_type) {
    items.push(<MetaDataItem Icon={getPanelTypeIcon(panel_type)} label={panel_type} />);
  }
  return <Flex gap="1.5">{items}</Flex>;
};

const HideShowListItem = ({ panel, handleClick }) => {
  const { panel_name = '', panel_id } = panel;
  const hiddenPanels = useRecoilValue(HIDDEN_PANELS);
  const isVisible = Boolean(!hiddenPanels[panel_id]);

  return (
    <ListItem
      p="4"
      border="1px"
      borderColor="black.8"
      rounded="md"
      w="100%"
      display="flex"
      gap="3"
      alignItems="center"
      bg={isVisible ? 'white.100' : 'black.3'}
      _hover={{
        cursor: 'pointer',
        borderColor: 'black.24',
        bg: 'white.100',
        '.show-hide-icon': {
          bg: isVisible ? 'primary.shade.20' : 'black.8'
        }
      }}
      _active={{
        bg: 'black.3'
      }}
      onClick={() => handleClick(panel_id, isVisible ? 'hide' : 'show')}
    >
      <ShowHideIcon isVisible={isVisible} />
      <Flex direction="column" justifyContent="space-between" gap="6px">
        <Box fontWeight="700" fontSize="sm" lineHeight="14px">
          {panel_name.toUpperCase()}
        </Box>
        <MetaData panel={panel} />
      </Flex>
    </ListItem>
  );
};

const HideShowPanels = ({ panelData }) => {
  const setPanelVisibility = usePanelVisibility();

  const nonStaticPanels = panelData ? panelData.filter((el) => !el.is_static) : [];
  return (
    <List spacing={2}>
      {nonStaticPanels.length !== 0 ? (
        panelData
          .filter((el) => !el.is_static)
          .map((panel) => {
            return (
              <HideShowListItem
                key={panel.panel_id}
                panel={panel}
                handleClick={setPanelVisibility}
              />
            );
          })
      ) : (
        <Center>No panels Available</Center>
      )}
    </List>
  );
};

export default HideShowPanels;
