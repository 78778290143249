import { Box, Flex, GridItem, Avatar, Center, Circle } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-location';
import capitalise from '_shared/utils/capitalise';
import ViewsSinceDisplay from '_shared/components/ViewsSinceDisplay';

const RecentlyViewedCard = ({ item }) => {
  const { object_id, object_img, object_name, object_type, object_role } = item;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate({ to: `/${object_type}/${object_id}` });
  };
  return (
    <GridItem
      p="2"
      rounded="md"
      bg="black.3"
      border="1px"
      borderColor="transparent"
      _hover={{ boxShadow: 'small', cursor: 'pointer', bg: 'white.100', borderColor: 'black.24' }}
      display="flex"
      onClick={handleClick}
    >
      <Flex as="a" direction="column" flexGrow="1" gap="2" justify="space-between">
        <Flex rounded="md" alignItems="center" gap="2">
          {object_img || object_type === 'player' ? (
            <Center>
              <Avatar size={object_type === 'team' ? 'md' : 'lg'} src={object_img} />
            </Center>
          ) : null}
          {!object_img && object_type === 'team' ? <Circle boxSize="12" bg="black.8" /> : null}
          <Box>
            <Box fontSize="sm" fontWeight="700">
              {object_name}
            </Box>
            {object_role ? (
              <Box fontSize="xs" color="black.60">
                {capitalise(object_role)}
              </Box>
            ) : null}
          </Box>
        </Flex>
        <ViewsSinceDisplay item={item} />
      </Flex>
    </GridItem>
  );
};

export default RecentlyViewedCard;
