import { Flex, Box } from '@chakra-ui/react';
import OversStats from './OversStats';

const Divider = () => {
  return <Box borderBottom="1px" borderColor="black.8" my="2" />;
};

const checkValidData = (data) => {
  const { runs, wickets, rpo } = data;
  if (runs === null || wickets === null || rpo === null) {
    return false;
  }
  return true;
};

const SessionStats = ({ panelData }) => {
  const { scorecard, bbb } = panelData.data;
  const { scheduled_innings } = scorecard.match;
  const { current_over } = bbb;
  const {
    current_day,
    since_break_name,
    since_break,
    current_session,
    last_5_overs,
    last_10_overs
  } = bbb;

  const buildStats = () => {
    const statsArray = [];
    if (scheduled_innings === 1 && current_over && checkValidData(last_5_overs)) {
      statsArray.push(
        <OversStats
          key="last_five_overs"
          data={{
            ...last_5_overs,
            label: `Last ${current_over >= 6 ? 5 : current_over} overs`
          }}
        />
      );
      statsArray.push(<Divider key="div_1" />);
    }
    if (scheduled_innings !== 1 && current_day && checkValidData(current_day)) {
      statsArray.push(<OversStats key="current_day" data={{ ...current_day, label: 'Today' }} />);
      statsArray.push(<Divider key="div_2" />);
    }
    if (
      since_break &&
      since_break_name &&
      since_break_name !== 'in play' &&
      checkValidData(since_break)
    ) {
      statsArray.push(
        <OversStats
          key="since_break"
          data={{ ...since_break, label: `Since ${since_break_name}` }}
        />
      );
      statsArray.push(<Divider key="div_3" />);
    }
    if (current_session && checkValidData(current_session)) {
      statsArray.push(
        <OversStats key="current_session" data={{ ...current_session, label: 'This session' }} />
      );
      statsArray.push(<Divider key="div_4" />);
    }
    if (current_over >= 11 && checkValidData(last_10_overs)) {
      statsArray.push(
        <OversStats key="last_10_overs" data={{ ...last_10_overs, label: 'Last 10 overs' }} />
      );
      statsArray.push(<Divider key="div_5" />);
    }
    statsArray.pop();
    return statsArray;
  };

  return (
    <Flex direction="column" px="4" py="2">
      {buildStats()}
    </Flex>
  );
};

export default SessionStats;
