import { createIcon } from '@chakra-ui/icons';

const Play = createIcon({
  displayName: 'Play',
  viewBox: '0 0 24 24',
  defaultProps: { width: '24px', height: '24px' },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2466 12L8.96477 6.81662V17.1834L16.2466 12ZM8.52732 19.8335L18.5625 12.8165C19.1458 12.4281 19.1458 11.5719 18.5625 11.1835L8.52732 4.16646C7.87447 3.7317 7 4.1992 7 4.98298V19.017C7 19.8008 7.87447 20.2683 8.52732 19.8335Z"
      fill="currentColor"
    />
  )
});

export default Play;
