import batting_vs_bowling_technique_player_matchup_grid from './batting_vs_bowling_technique_player_matchup_grid';
import bowling_vs_batting_hand_player_matchup_grid from './bowling_vs_batting_hand_player_matchup_grid';
import vs_spin_general_stats from './vs_spin_general_stats';
import vs_spin_direction_general_stats from './vs_spin_direction_general_stats';
import vs_pace_general_stats from './vs_pace_general_stats';
import innings_progression___balls_faced_general_stats from './innings_progression___balls_faced_general_stats';
import phase_breakdown_general_stats from './phase_breakdown_general_stats';
import speed_usage_general_stats from './speed_usage_general_stats';
import bowler_utilisation_general_stats from './bowler_utilisation_general_stats';
import length__economy___strike_rate_general_stats from './length__economy___strike_rate_general_stats';
import death_bowling_approach_general_stats from './death_bowling_approach_general_stats';
import swing_direction_general_stats from './swing_direction_general_stats';
import seam_direction_general_stats from './seam_direction_general_stats';
import vs_ball_age_general_stats from './vs_ball_age_general_stats';
import ball_age_general_stats from './ball_age_general_stats';
import spin_direction_general_stats from './spin_direction_general_stats';
import vs_right_handers_general_stats from './vs_right_handers_general_stats';
import vs_left_handers_general_stats from './vs_left_handers_general_stats';
import length_economy___strike_rate_general_stats from './length_economy___strike_rate_general_stats';
import speeds_general_stats from './speeds_general_stats';

const configDefs = {
  // batting
  batting_vs_bowling_technique_player_matchup_grid,
  vs_spin_general_stats,
  vs_spin_direction_general_stats,
  vs_pace_general_stats,
  innings_progression___balls_faced_general_stats,
  phase_breakdown_general_stats,
  swing_direction_general_stats,
  seam_direction_general_stats,
  vs_ball_age_general_stats,
  // bowling
  bowling_vs_batting_hand_player_matchup_grid,
  speed_usage_general_stats,
  bowler_utilisation_general_stats,
  length__economy___strike_rate_general_stats,
  death_bowling_approach_general_stats,
  ball_age_general_stats,
  spin_direction_general_stats,
  vs_right_handers_general_stats,
  vs_left_handers_general_stats,
  length_economy___strike_rate_general_stats,
  speeds_general_stats
};

export default configDefs;
