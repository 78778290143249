import { Box, Flex, Grid, useToast } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { updateVideoPlaylist } from '_shared/dataFetching/apiService';
import { usePlaylistChannelListen } from '_shared/dataFetching/ably';

import VideoObject from './VideoObject';
import PlaylistConcatenationComponent from './PlaylistConcatenationComponent';
import VideoEditorContainer from './VideoEditorContainer';
import RemoveOrAddToOtherPlaylist from './RemoveOrAddToOtherPlaylist';

const arrayMove = (array, from, to) => {
  const slicedArray = array.slice();
  slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0]);

  return slicedArray;
};

const SortItem = sortableElement(({ children }) => {
  return children;
});

const SortContainer = sortableContainer(({ children }) => {
  return (
    <Flex direction="column" gap="4" mx="2" pb="56px">
      {children}
    </Flex>
  );
});

const VideoPlaylistContainer = ({
  objectData,
  playlistId,
  refetch,
  selectedVideos,
  setSelectedVideos
}) => {
  const { listings, playlist_data } = objectData;
  const { playlist_name, data, download_link, same_version, modified } = playlist_data;
  const organisedData = [];

  listings.forEach((el) => {
    const playlistData =
      data.find((innerEl) => {
        return innerEl.ball_id === el.ball_id;
      }) || {};
    organisedData.push({ ...el, ...playlistData });
  });

  const [vidInd, setVidInd] = useState(0);
  // const { width, height } = useWindowDimensions();
  const toast = useToast();
  const [autoPlay, setAutoPlay] = useState(true);
  const userName = localStorage.getItem('userName');
  const [vidList, setVidList] = useState(organisedData);
  const [progressMessage, setProgressMessage] = useState('');
  const [vidClipping, setVidClipping] = useState(false);
  const videoRef = useRef(null);
  const [editAtt, setEditAtt] = useState({
    video_start: organisedData[0]?.video_start ? organisedData[0]?.video_start : 0,
    video_end: organisedData[0]?.video_end
      ? organisedData[0]?.video_end
      : videoRef?.current?.video?.video?.duration, // videoRef?.current?.video?.video?.duration, // videoRef?.current?.video?.duration,
    sound: listings[0]?.sound ? listings[0]?.sound : true,
    speed: listings[0]?.speed ? listings[0]?.speed : 1,
    ball_id: listings[0]?.ball_id
  });
  usePlaylistChannelListen(refetch, setVidClipping, playlistId, userName, setProgressMessage);
  const removeItem = () => {
    const newValue = vidList.filter((el) => !selectedVideos.includes(el.ball_id));
    setVidList(newValue);
    updatePlaylist(newValue);
    setSelectedVideos([]);
  };
  const editItem = (newDataPacket) => {
    const updatedList = vidList.map((obj) => {
      return obj.ball_id === newDataPacket.ball_id ? { ...obj, ...newDataPacket } : obj;
    });
    updatePlaylist(updatedList);
    refetch();
  };
  const updatePlaylist = (newValue) => {
    const returnRelCols = [
      'cv_aws_bucket',
      'cv_aws_key',
      'cv_aws_etag',
      'ball_id',
      'video_start',
      'video_end',
      'speed',
      'sound',
      'video_url'
    ];
    const returnJson = [];
    setVidList(newValue);
    newValue.forEach((el) => {
      const individualBall = {};
      returnRelCols.forEach((keyItem) => {
        if (el[keyItem] || el[keyItem] === 0) {
          individualBall[keyItem] = el[keyItem];
        }
      });
      returnJson.push(individualBall);
    });
    // need to send this to the database to update
    updateVideoPlaylist({ json_obj: returnJson, id: playlistId });
    toast({
      title: `Playlist updated`,
      position: 'bottom-left',
      description: `Playlist has been modified`,
      status: 'warning',
      duration: 5000,
      isClosable: true
    });
    refetch();
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(vidList, oldIndex, newIndex);
    // if playlist has changed
    if (oldIndex !== newIndex) {
      updatePlaylist(newValue);
      setVidInd(newIndex);
    }
    document.body.classList.remove('grabbing');
  };
  const changeSound = (e) => {
    videoRef.current.defaultMuted = !e;
    videoRef.current.muted = !e;
    setEditAtt({ ...editAtt, sound: e });
  };
  const changeSpeed = (e) => {
    videoRef.current.video.video.playbackRate = e;
    setEditAtt({ ...editAtt, speed: e });
  };
  const changeVideo = (idx) => {
    const checkVars = ['video_start', 'video_end', 'speed', 'sound'];
    const unChangedVariables = [];
    const el = vidList[idx];

    checkVars.forEach((innerEl) => {
      if (el.hasOwnProperty(innerEl)) {
        setEditAtt({ ...editAtt, innerEl: el[innerEl] });
        if (checkVars[2] === innerEl) {
          changeSpeed(el[innerEl]);
          videoRef.current.video.video.playbackRate = el[innerEl];
        } else if (checkVars === innerEl) {
          changeSound(el[innerEl]);
        }
      } else {
        unChangedVariables.push(innerEl);
        if (checkVars[2] === innerEl) {
          changeSpeed(1);
        } else if (checkVars[3] === innerEl) {
          changeSound(true);
        }
      }
    });

    setVidInd(idx);
    const updateState = { ...el };

    unChangedVariables.forEach((innerEl) => {
      if (innerEl === 'video_start') {
        el[innerEl] = 0;
      } else if (innerEl === 'video_end') {
        el[innerEl] = null;
      } else if (innerEl === 'speed') {
        el[innerEl] = 1;
      } else if (innerEl === 'sound') {
        el[innerEl] = true;
      }
    });

    setEditAtt(updateState);
  };
  const vidDict = {};
  listings.forEach((el) => {
    vidDict[el.ball_id] = el;
  });

  return (
    <>
      <Grid
        flexGrow="1"
        gridTemplateColumns="minmax(300px, 1000px) minmax(300px, 500px)"
        minH="0"
        py="5"
        px="5"
        gap="3"
        margin="auto"
      >
        <VideoEditorContainer
          el={vidList[vidInd]}
          lastVid={vidInd === vidList.length - 1}
          autoPlay={autoPlay}
          setAutoPlay={setAutoPlay}
          vidInd={vidInd}
          editItem={editItem}
          videoRef={videoRef}
          editAtt={editAtt}
          setEditAtt={setEditAtt}
          changeVideo={changeVideo}
          listLength={vidList.length}
          changeSpeed={changeSpeed}
          speedVal={videoRef?.current?.video?.video?.playbackRate || editAtt?.speed || 1}
          changeSound={changeSound}
          vidList={vidList}
          selectedVideos={selectedVideos}
          setSelectedVideos={setSelectedVideos}
        />
        <Flex minH="0" direction="column" gap="4">
          <PlaylistConcatenationComponent
            vidList={vidList}
            vidClipping={vidClipping}
            setVidClipping={setVidClipping}
            playlistName={playlist_name}
            userName={userName}
            playlistId={playlistId}
            same_version={same_version}
            modified={modified}
            download_link={download_link}
            progressMessage={progressMessage}
          />
          <Box
            flexGrow="1"
            overflowY="auto"
            id="sortable-helper-container"
            pb="2"
            sx={{ '& .sortable-helper': { zIndex: 1000 } }}
          >
            <SortContainer
              onSortEnd={onSortEnd}
              onSortStart={(_, event) => {
                if (event instanceof MouseEvent) {
                  document.body.classList.add('grabbing');
                }
              }}
              helperContainer={() => document.getElementById('sortable-helper-container')}
              helperClass="sortable-helper"
            >
              {vidList.map((el, idx) => {
                return (
                  <SortItem index={idx} value={el.ball_id} ball_id={el.ball_id} key={el.ball_id}>
                    <VideoObject
                      idx={idx}
                      id={el.ball_id}
                      el={el}
                      selectedVid={idx === vidInd}
                      isChecked={selectedVideos.includes(el?.ball_id)}
                      selectedVideos={selectedVideos}
                      setSelectedVideos={setSelectedVideos}
                      handleClick={() => changeVideo(idx)}
                    />
                  </SortItem>
                );
              })}
            </SortContainer>
          </Box>
        </Flex>
      </Grid>
      <RemoveOrAddToOtherPlaylist
        vidDict={vidDict}
        vidList={selectedVideos}
        vidLength={selectedVideos.length}
        setSelectedVideos={setSelectedVideos}
        removeItem={removeItem}
      />
    </>
  );
};

export default VideoPlaylistContainer;
