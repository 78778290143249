import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import ButtonSelector from '_shared/components/ButtonSelector';
import Header from 'content/_components/header/Header';
import capitalise from '_shared/utils/capitalise';
import HeadToHeadDisplay from './HeadToHeadDisplay';

const HeadToHead = ({ content }) => {
  const { data, header } = content;
  const labels = data.map((el) => {
    return {
      label: capitalise(el?.filter?.primary),
      value: el?.filter?.primary
    };
  });
  const [tab, setTab] = useState(labels.length > 0 ? labels[0].value : null);

  return (
    <Box>
      <Header title={header} showInfoButton={false} />
      <Box mx="4">
        <ButtonSelector
          options={labels}
          handleChange={setTab}
          value={tab}
          fullWidth={true}
          color="primary.main"
          buttonProps={{ width: '33%' }}
        />
      </Box>
      <HeadToHeadDisplay data={data.find((el) => el?.filter?.primary === tab)} key={tab} />
    </Box>
  );
};

export default HeadToHead;
