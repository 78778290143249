import { forwardRef } from 'react';
import { Box } from '@chakra-ui/react';
import WidgetComponent from './WidgetComponent';

const WinVizWidget = forwardRef(({ panelData }, ref) => {
  const { variable_id } = panelData;

  const widgetId = `winviz_${variable_id}`;

  const widgetProps = {
    id: widgetId,
    type: 'winviz',
    layout: 'bar',
    team_color: 'true',
    match: variable_id,
    live_update: 'true',
    ref
  };

  return (
    <Box h="100%" margin={'auto'}>
      <WidgetComponent widgetParams={widgetProps} />
    </Box>
  );
});
export default WinVizWidget;
