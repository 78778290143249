export const getHomeAwayInfo = (data) => {
  const team1 = {
    id: data.team1_id,
    logo: data.team1_logo,
    name: data.team1_name,
    flag: data.team1_flag
  };
  const team2 = {
    id: data.team2_id,
    logo: data.team2_logo,
    name: data.team2_name,
    flag: data.team2_flag
  };
  if (data.home_team_id === data.team1_id) {
    return {
      homeTeam: team1,
      awayTeam: team2
    };
  }
  if (data.home_team_id === data.team2_id) {
    return {
      homeTeam: team2,
      awayTeam: team1
    };
  }
  return {
    homeTeam: team1,
    awayTeam: team2
  };
};
