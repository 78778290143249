import { SimpleGrid } from '@chakra-ui/react';
import { getAvailablePanelTypeIds } from 'panels/PanelTypes/PanelTypeDefinitions';
import { useRecoilValue } from 'recoil';
import { HIDDEN_PANELS } from '_shared/globalState/atoms';
import PanelContainer from '../Panel/PanelContainer';

const tabPages = ['player', 'comp', 'team'];

const PanelPage = ({ pageData, panels, pageType, classId }) => {
  const hiddenPanels = useRecoilValue(HIDDEN_PANELS);
  const availablePanelTypes = getAvailablePanelTypeIds();
  return (
    <SimpleGrid minChildWidth={['240px', '550px']} spacing="4" p="3" pb="100px" mx="4">
      {panels &&
        panels
          .filter((panel) => {
            const { panel_id, class_id, panel_type_id } = panel;
            if (hiddenPanels[panel_id]) {
              return false;
            }
            if (!availablePanelTypes.includes(panel_type_id.toString())) {
              return false;
            }
            if (panel_id === 'PP_1000') {
              return true;
            }
            if (
              tabPages.includes(pageType) &&
              classId !== 'all' &&
              class_id !== parseInt(classId)
            ) {
              return false;
            }
            return true;
          })
          .map((panel) => {
            return (
              <PanelContainer
                key={panel.panel_id}
                panelData={panel}
                pageData={pageData}
                pageType={pageType}
                classId={classId}
              />
            );
          })}
    </SimpleGrid>
  );
};

export default PanelPage;
