import { Box, Flex, Icon } from '@chakra-ui/react';

const IconLabel = ({ fontSize, icon, label, gap = 1 }) => {
  return (
    <Flex alignItems="center" gap={gap} fontSize={fontSize}>
      <Icon as={icon} />
      <Box>{label}</Box>
    </Flex>
  );
};

export default IconLabel;
