import { Box, Center, Spinner } from '@chakra-ui/react';
import { useMatch } from '@tanstack/react-location';
import { getObjectInformation } from '_shared/dataFetching/apiService';
import HomePageContainer from 'pages/home/components/HomePageContainer';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import usePageTitle from '_shared/hooks/usePageTitle';

const User = () => {
  const type = 'user';
  const {
    params: { objectId }
  } = useMatch();
  const {
    isLoading: objectIsLoading,
    error: objectError,
    data: objectData,
    isFetching: objectIsFetching
  } = useQueryFetch([type, objectId], () => getObjectInformation(type, objectId));
  usePageTitle('User');
  if (objectIsLoading) {
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    );
  }
  if (objectError && !objectIsFetching) {
    return <Box>{`Unable to retrieve home page data`}</Box>;
  }
  if (objectData) {
    return <HomePageContainer data={objectData} pageType={type} />;
  }
};

export default User;
