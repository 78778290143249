import { getStorageValue, setStorageValue } from '_shared/utils/localStorageUtils';
import { useEffect, useState } from 'react';

const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    setStorageValue(key, value);
  }, [key, value]);

  const handleSetValue = (v) => {
    setValue(v);
  };
  return [value, handleSetValue];
};

export default useLocalStorage;
