import { createIcon } from '@chakra-ui/icons';

const Info = createIcon({
  displayName: 'Info',
  viewBox: '0 0 48 48',
  defaultProps: { width: '48px', height: '48px' },
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40ZM24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z"
      fill="currentColor"
    />,
    <path
      d="M22 24C22 22.8954 22.8954 22 24 22C25.1046 22 26 22.8954 26 24V32C26 33.1046 25.1046 34 24 34C22.8954 34 22 33.1046 22 32V24Z"
      fill="currentColor"
    />,
    <path
      d="M26 16C26 17.1046 25.1046 18 24 18C22.8954 18 22 17.1046 22 16C22 14.8954 22.8954 14 24 14C25.1046 14 26 14.8954 26 16Z"
      fill="currentColor"
    />
  ]
});

export default Info;
