import { Box, Center, Spinner } from '@chakra-ui/react';

import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import { getVideoResults } from '_shared/dataFetching/apiService';
import { useMatch, useSearch } from '@tanstack/react-location';
import { Formik } from 'formik';
import { useState } from 'react';

import 'video-react/dist/video-react.css';
import './VideoCentre.css';

import { convert64ToJSON } from '_shared/utils/utilFunctions.js';

import VideoResultsListings from './VideoResultsListings';
import VideoResultsVideoView from './VideoResultsVideoView';
import { handlePermissions } from '_shared/utils/utilFunctions';
import FullScreenAuthError from '_shared/errors/FullScreenAuthError';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';

const VideoResultsPage = () => {
  const {
    params: { classId, objectId, objectType }
  } = useMatch();
  const { data: userData = {} } = useUserDetails();
  const { permissions } = userData;
  const VCPerm = handlePermissions(10, permissions);
  const search = useSearch();
  const [autoPlay, setAutoPlay] = useState(false);
  const { q, videoNumber } = search;

  const params = q ? convert64ToJSON(q) : {};
  const [viewType, setViewType] = useState(
    videoNumber || parseInt(videoNumber) >= 0 ? 'video' : 'standard'
  );

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [multiSelect, setMultiSelect] = useState(false);
  const [videoVal, setVideoVal] = useState(videoNumber || videoNumber === 0 ? videoNumber : null);
  const [changeSearch, setChangeSearch] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [reversed, setReversed] = useState(false);
  const {
    isLoading: objectIsLoading,
    error: objectError,
    data: objectData,
    isFetching: objectIsFetching
  } = useQueryFetch(
    [objectType, objectId, classId, params],
    () => getVideoResults(objectType, objectId, classId, params),
    {
      staleTime: 30 * 1000
    }
  );
  if (!VCPerm) {
    return <FullScreenAuthError />;
  }
  if (objectIsLoading) {
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    );
  }
  if (objectError && !objectIsFetching) {
    return (
      <Box>{`Unable to retrieve video results for ${objectType} ${objectId}: ${objectError.message}`}</Box>
    );
  }

  if (objectData) {
    const { results, page_info } = objectData;

    const { object_info, class_info, filters } = page_info;
    return (
      <Formik
        initialValues={{
          playlist_selection: []
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (values.playlist_selection.length > 0) {
            console.log(values.playlist_selection);
          }
          setSubmitting(false);
        }}
      >
        {(props) => {
          if (viewType === 'standard') {
            return (
              <VideoResultsListings
                results={results}
                params={params}
                filters={filters}
                filtersOpen={filtersOpen}
                setFiltersOpen={setFiltersOpen}
                changeSearch={changeSearch}
                setChangeSearch={setChangeSearch}
                class_info={class_info}
                classId={classId}
                objectType={objectType}
                object_info={object_info}
                objectId={objectId}
                videoVal={videoVal}
                setVideoVal={setVideoVal}
                setViewType={setViewType}
                multiSelect={multiSelect}
                setMultiSelect={setMultiSelect}
                selectedVideos={selectedVideos}
                setSelectedVideos={setSelectedVideos}
                setFieldValue={props.setFieldValue}
                handleSubmit={props.handleSubmit}
                reversed={reversed}
                setReversed={setReversed}
              />
            );
          } else if (viewType === 'video' && videoVal < results.length && videoVal >= 0) {
            return (
              <VideoResultsVideoView
                setViewType={setViewType}
                results={results}
                videoVal={videoVal}
                setVideoVal={setVideoVal}
                autoPlay={autoPlay}
                setAutoPlay={setAutoPlay}
                multiSelect={multiSelect}
                setMultiSelect={setMultiSelect}
                selectedVideos={selectedVideos}
                setSelectedVideos={setSelectedVideos}
                setFieldValue={props.setFieldValue}
                handleSubmit={props.handleSubmit}
                changeSearch={changeSearch}
                setChangeSearch={setChangeSearch}
                class_info={class_info}
                classId={classId}
                objectType={objectType}
                object_info={object_info}
                objectId={objectId}
                setFiltersOpen={setFiltersOpen}
                filtersOpen={filtersOpen}
                filters={filters}
                params={params}
                reversed={reversed}
                setReversed={setReversed}
              />
            );
          }
        }}
      </Formik>
    );
  }
};

export default VideoResultsPage;
