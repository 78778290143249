import { Box, Flex } from '@chakra-ui/react';
// import AddUserFavourites from '_shared/components/AddUserFavourites';
import { Fragment, useState, memo, useRef } from 'react';
import EditQuery from 'Forms/PanelForms/EditQuery/EditQuery';
import EditPanel from 'Forms/PanelForms/EditPanel/EditPanel';
import { getPanelData } from '_shared/dataFetching/apiService';
import FormModal from 'Forms/_shared/components/FormModal';
import PanelContent from './PanelContent';
import PanelMenu from './PanelMenu';
import { getInfo } from 'panels/utils/panelGetInfo';
import usePanelVisibility from '_shared/hooks/usePanelVisibility';
import { useRecoilValue } from 'recoil';
import { FOCUS_PANEL } from '_shared/globalState/atoms';
import isEqual from 'lodash.isequal';
import RefreshIcon from './RefreshIcon/RefreshIcon';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import { checkPanelData, isPanelWidget } from './utils/utils';
import usePageFilterDates from 'panels/hooks/usePageFilterDates';
import NoDataDisplay from './NoDataDisplay';
import getPanelStaleTime from '../utils/getPanelStaleTime';

import './panel.css';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';

const PanelComponent = ({ panelData, pageData, pageType, classId, filterType, layoutInfo }) => {
  const focusPanel = useRecoilValue(FOCUS_PANEL);
  const { data: userData = {} } = useUserDetails();
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [widgetLoading, setWidgetLoading] = useState(false);
  const widgetRef = useRef(null);
  const { panel_id, class_id, variable_id, is_static, api_reference, panel_type_id, panel_name } =
    panelData;
  const betweenDates = usePageFilterDates();
  const panelParams = {
    panel_id,
    class_id: parseInt(class_id),
    variable_id,
    panel_type_id
  };
  if (betweenDates) {
    panelParams.between_dates = betweenDates;
  }
  if (
    !panelData.is_static ||
    ['HP_1003', 'HP_1004', 'HP_1001', 'HP_1002', 'HP_1005', 'HP_1000'].includes(panelData.panel_id)
  ) {
    panelParams.user_id = userData.user_id;
  }
  const staleTime = getPanelStaleTime(panel_type_id, isLive);
  const { objectId } = getInfo(pageData, pageType);
  const panelIsWidget = isPanelWidget(panel_type_id);
  const { isLoading, error, data, meta, refetch, isRefetching } = useQueryFetch(
    ['panel', panel_id, panelParams],
    () => getPanelData(panelParams, api_reference, panelData),
    {
      enabled: !panelIsWidget,
      refetchInterval: isLive && 60 * 1000,
      refetchIntervalInBackground: true,
      staleTime,
      onSuccess: (data) => {
        if (data?.is_live === 1) {
          setIsLive(true);
        }
      }
    }
  );

  const setPanelVisibility = usePanelVisibility();

  const showPanelName = () => {
    return data?.panel_name?.toUpperCase() || panel_name.toUpperCase();
  };

  const showContent = () => {
    if (panelIsWidget) {
      return (
        <PanelContent
          panelData={panelData}
          pageData={pageData}
          layoutInfo={layoutInfo}
          classId={classId}
          ref={widgetRef}
        />
      );
    }
    if (isLoading) {
      return (
        <Box h="100%" w="100%">
          <Box className="ed-load" />
        </Box>
      );
    }
    if (error || data?.POST_ERROR === 'API used POST method, but data was not fetched') {
      return (
        <NoDataDisplay
          message="Unable to fetch data"
          handleSetHidden={() => setPanelVisibility(panel_id)}
          hideAvailable={!is_static}
        />
      );
    }
    if (data) {
      if (!checkPanelData(data)) {
        return (
          <NoDataDisplay
            message="No panel data available"
            handleSetHidden={() => setPanelVisibility(panel_id)}
            hideAvailable
          />
        );
      }
      return (
        <PanelContent
          panelData={data}
          pageData={pageData}
          pageType={pageType}
          objectId={objectId}
          classId={classId}
          filterType={filterType}
          layoutInfo={layoutInfo}
        />
      );
    }
  };

  const refreshIcon = () => {
    if (panelIsWidget) {
      // Widget panel => call 'instantUpdate' on widget (& show loading UX for 1 sec)
      const widgetUpdate = () => {
        setWidgetLoading(true);
        widgetRef?.current?.instantUpdate();
        setTimeout(() => setWidgetLoading(false), 1000);
      };
      return <RefreshIcon handleClick={widgetUpdate} isLoading={widgetLoading} />;
    }

    // Regular (i.e. non-widget) panel => issue refetch on panel data
    return <RefreshIcon handleClick={refetch} isLoading={isLoading || isRefetching} />;
  };

  return (
    <Fragment>
      <Flex
        direction="column"
        h="100%"
        boxShadow={
          focusPanel === panel_id
            ? '0 0 0 3px var(--chakra-colors-primary-tint-60)'
            : '0px 2px 6px rgba(4, 9, 33, 0.1), 0px 2px 0px rgba(4, 9, 33, 0.05), 0px 0px 2px rgba(4, 9, 33, 0.03)'
        }
        rounded="md"
        overflow="hidden"
        bg="white.100"
        id={`panel_${panel_id}`}
      >
        {panel_type_id === 30 ? (
          showContent()
        ) : (
          <>
            <Flex
              color="grey.800"
              px="2"
              py="1"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px"
              borderColor="black.8"
              gap={2}
            >
              {refreshIcon()}
              <Box
                className="draggable-handle"
                flexGrow="1"
                cursor={!panelData.is_static ? 'grab' : 'auto'}
                fontWeight="bold"
              >
                {showPanelName()}
              </Box>
              {/* {!panelData.is_static ? (
                <AddUserFavourites
                  favType={'panel'}
                  favId={`${panel_id}-${class_id}-${variable_id}`}
                  lightBackground={true}
                />
              ) : null} */}
              <Box>
                <PanelMenu
                  panelId={panel_id}
                  panelData={panelData}
                  data={data}
                  meta={meta}
                  pageType={pageType}
                  objectId={objectId}
                  handleEdit={setFormModalOpen}
                />
              </Box>
            </Flex>
            {[14, 37].includes(panel_type_id) ? (
              <Box flexGrow="1" overflow="auto">
                {showContent()}
              </Box>
            ) : (
              <Flex
                flexGrow="1"
                overflow="auto"
                visibility="hidden"
                _hover={{ visibility: 'visible' }}
                _focusVisible={{ visibility: 'visible' }}
              >
                <Box
                  flexGrow="1"
                  // overflow="auto"
                  visibility="visible"
                  display={[4, 9, 21].includes(panel_type_id) && 'flex'}
                  flexDirection={[4, 21].includes(panel_type_id) && 'column'}
                >
                  {showContent()}
                </Box>
              </Flex>
            )}
          </>
        )}
      </Flex>
      {data && (
        <FormModal isOpen={formModalOpen} handleClose={() => setFormModalOpen(false)}>
          {formModalOpen === 'editPanel' && (
            <EditPanel
              panelData={data}
              pageData={pageData}
              handleClose={() => setFormModalOpen(false)}
              pageType={pageType}
            />
          )}
          {formModalOpen === 'editQuery' && (
            <EditQuery
              panelData={data}
              pageData={pageData}
              handleClose={() => setFormModalOpen(false)}
              pageType={pageType}
            />
          )}
        </FormModal>
      )}
    </Fragment>
  );
};

const memoFunction = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default memo(PanelComponent, memoFunction);
