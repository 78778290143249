import { Box, Flex, Icon } from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useRecoilState } from 'recoil';
import AddUserFavourites from '_shared/components/AddUserFavourites';
import { HEADER_EXPANDED } from '_shared/globalState/atoms';
import GroundDetails from './GroundDetails';

const GroundSubHeader = ({ data }) => {
  const [headerExpanded, setHeaderExpanded] = useRecoilState(HEADER_EXPANDED);
  const expanded = headerExpanded.ground || false;

  const { ground_info } = data;
  return (
    <Box
      h={expanded ? '244px' : '64px'}
      bg="primary.shade.70"
      transitionProperty="height"
      transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
      transitionDuration="500ms"
    >
      <Flex flexGrow={1} direction="column" px="5">
        <Flex h="64px" alignItems="center">
          <Flex
            flexGrow={1}
            alignItems="center"
            gap="3"
            color="white"
            fontSize={20}
            fontWeight="700"
          >
            {data.banner_heading}
          </Flex>
          <Flex gap="3">
            <AddUserFavourites favType="ground" favId={ground_info.ground_id} />
            <Icon
              cursor="pointer"
              boxSize={6}
              color="white.100"
              as={expanded ? FiChevronUp : FiChevronDown}
              onClick={() =>
                setHeaderExpanded((p) => {
                  const currentValue = p.ground || false;
                  return { ...p, ground: !currentValue };
                })
              }
            />
          </Flex>
        </Flex>
        {expanded ? <GroundDetails groundInfo={ground_info} /> : null}
      </Flex>
    </Box>
  );
};

export default GroundSubHeader;
