import { Box, Button, ButtonGroup } from '@chakra-ui/react';
import capitalise from '_shared/utils/capitalise';

const ButtonSelector = ({
  options,
  handleChange,
  value,
  buttonMinWidth,
  isSquare,
  fontSize = 'sm',
  fullWidth,
  color,
  px = '5px',
  py = '5px',
  buttonProps
}) => {
  const mapOptions = (el) => {
    let optValue;
    let label;
    if (typeof el === 'string') {
      optValue = el;
      label = capitalise(el);
    } else {
      optValue = el.value;
      ({ label } = el);
    }
    return (
      <Button
        key={optValue}
        onClick={() => handleChange(optValue)}
        value={optValue}
        variant={optValue === value ? 'solid' : 'ghost'}
        color={optValue === value ? 'white.100' : color}
        h={isSquare ? undefined : '8'}
        rounded={isSquare ? undefined : 'full'}
        fontSize={fontSize}
        border={isSquare ? '1px' : undefined}
        borderColor={isSquare ? 'black.8' : 'transparent'}
        minW={buttonMinWidth}
        flexGrow={fullWidth ? 1 : undefined}
        {...buttonProps}
      >
        {label}
      </Button>
    );
  };

  if (!options) return null;

  return (
    <Box>
      <ButtonGroup
        size="md"
        px={px}
        py={py}
        display={fullWidth ? 'flex' : 'inline-flex'}
        rounded="full"
        bg={isSquare ? undefined : 'black.3'}
        spacing={isSquare ? '3' : '1'}
        border={isSquare ? undefined : '1px'}
        borderColor={isSquare ? 'transparent' : 'black.8'}
      >
        {options.filter((el) => Boolean(el)).map(mapOptions)}
      </ButtonGroup>
    </Box>
  );
};

export default ButtonSelector;
