const templateParser = (string) => {
  const re = /\{*([^{}]+)\}*/g;

  const output = [];

  while (true) {
    const match = re.exec(string);

    if (!match) {
      break;
    }

    const [templateItem, templateKey] = match;

    output.push({
      type: templateItem === templateKey ? 'static' : 'dynamic',
      value: templateItem === templateKey ? templateItem : templateKey
    });
  }

  return output.filter((el) => {
    return el.type === 'dynamic';
  });
};

const textLineConversion = (data, rawTextSt) => {
  const dynamicVariables = templateParser(rawTextSt);
  let returnStr = rawTextSt;
  dynamicVariables.forEach((el) => {
    returnStr = returnStr.replace(`{{${el.value}}}`, data[el.value]);
  });

  return returnStr;
};

export const createTextLine = (result, panelData) => {
  const { data_value } = panelData;
  result.forEach((el) => {
    el.text_line = textLineConversion(el, data_value);
  });
  return result;
};

export const getQTURL = (api_reference, params) => {
  if (params.hasOwnProperty('between_dates')) {
    const newURL = new URL(api_reference);
    const { searchParams } = newURL;
    if (params.between_dates === 'all') {
      searchParams.delete('start_date');
    } else {
      searchParams.set('start_date', params.between_dates);
    }

    newURL.search = searchParams.toString();
    return newURL.toString();
  }
  return api_reference;
};
