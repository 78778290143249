const inputStyles = {
  defaultProps: {
    focusBorderColor: 'primary.main',
    errorBorderColor: 'negative.main'
  },
  variants: {
    outline: {
      field: {
        h: '44px',
        color: 'black.100',
        fontSize: 'sm',
        border: '1px',
        borderRadius: '6px',
        bg: 'white.100',
        borderColor: 'black.8',
        _hover: {
          borderColor: 'black.24'
        },
        _placeholder: {
          color: 'black.60'
        }
      }
    }
  }
};

export default inputStyles;
