import { useEffect, useRef } from 'react';
import { Grid } from '@chakra-ui/react';
import autoAnimate from '@formkit/auto-animate';
import HeaderTeamScore from './HeaderTeamScore';

const gridTemplateAreas = {
  small: `
      'image_home name_home spacer image_away name_away'
      'image_home score_home spacer image_away score_away'
    `,
  large: `
      'name_home image_home spacer image_away name_away'
      'score_home image_home spacer image_away score_away'
    `
};

const gridTemplateColumns = {
  small: 'auto auto 8px auto 1fr',
  large: '1fr auto 8px auto 1fr'
};

const MatchScore = ({ data, size }) => {
  const parent = useRef(null);
  const teams = data?.teams || [];
  const [homeTeam, awayTeam] = teams;

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const xScoreAvailable = checkXScore(teams);
  const matchStatus = data?.status?.match_status;

  return (
    <Grid
      color="white.100"
      gridTemplateColumns={gridTemplateColumns[size]}
      gridTemplateAreas={gridTemplateAreas[size]}
      w="100%"
      columnGap="3"
      ref={parent}
      py={size === 'small' ? '0' : '2'}
    >
      <HeaderTeamScore
        xScoreAvailable={xScoreAvailable}
        team={homeTeam}
        size={size}
        type="home"
        matchStatus={matchStatus}
      />
      <HeaderTeamScore
        xScoreAvailable={xScoreAvailable}
        team={awayTeam}
        size={size}
        type="away"
        matchStatus={matchStatus}
      />
    </Grid>
  );
};

function checkXScore(teams) {
  return teams.some((el) => {
    return el?.innings?.some((el) => {
      return !!el.xR || !!el.xW;
    });
  });
}

export default MatchScore;
