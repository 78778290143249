import { useState } from 'react';
import { useSearch } from '@tanstack/react-location';
import { searchByClassSuggest } from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import useDebounce from '_shared/hooks/useDebounce';
import { getPlayerSkillsData } from '_shared/dataFetching/apiServiceV2';

const usePlayerSkillsSearch = (objectVariable, objectId) => {
  const search = useSearch();
  const { class_id: classId } = search;
  const [selectedValues, setSelectedValues] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = useDebounce(searchValue, 500);

  const {
    data: searchResults,
    isLoading: searchResultsIsLoading,
    error: searchResultsIsError
  } = useQueryFetch(
    ['player_skills_search', classId, debouncedValue],
    () => searchByClassSuggest(classId, debouncedValue, 'player'),
    {
      enabled: searchValue !== '',
      select: (data) => {
        return (
          data?.results
            ?.map((el) => ({ value: el.id, label: el.text }))
            .filter((el) => {
              return el.value !== parseInt(objectId);
            }) || []
        );
      }
    }
  );

  const {
    data: playerData,
    isLoading: playerDataIsLoading,
    error: playerDataIsError
  } = useQueryFetch(
    ['player_skills_fetch', objectVariable, classId, selectedValues],
    () => getPlayerSkillsData(objectVariable, classId, selectedValues),
    {
      enabled: selectedValues.length !== 0
    }
  );

  return {
    playerData,
    playerDataIsLoading,
    playerDataIsError,
    setSelectedValues,
    searchValue,
    setSearchValue,
    searchResults,
    searchResultsIsLoading,
    searchResultsIsError
  };
};

export default usePlayerSkillsSearch;
