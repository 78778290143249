import './fullScreenError.css';

export default function VideoCentreError({ pageType }) {
  const headerString = `There has been an error loading this video ${pageType} page`;
  return (
    <div className="container">
      <div className="content">
        <div className="logo-container">
          <div className="logo">
            <img
              alt="logo"
              src="https://cv-qtp-images.s3.eu-west-2.amazonaws.com/cricviz-logo.png"
            />
          </div>
        </div>
        <h2>We are sorry</h2>
        <p>{headerString}</p>
        <a href={`/video_${pageType}`}>
          <button className="video-home-button">Back to video {pageType} home</button>
        </a>
      </div>
    </div>
  );
}
