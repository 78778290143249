import {
  Box,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb
} from '@chakra-ui/react';

// const convertTime = (seconds) => {
//   const minutes = Math.floor(seconds / 60);
//   const remainingSeconds = seconds % 60;
//   const minutesString = minutes < 10 ? `0${minutes}` : minutes.toString();
//   const secondsString =
//     remainingSeconds < 10
//       ? `0${parseInt(remainingSeconds)}`
//       : parseInt(remainingSeconds).toString();
//   return `${minutesString}:${secondsString}`;
// };
const EditScrubComponent = ({
  videoRef,
  editAtt,
  changeInput,
  handleInputChangeEnd,
  el,
  currentTime
}) => {
  const { ball_id, video_start, video_end } = el; // , sound, speed }

  return (
    <Box p="1" px="16px" borderRadius="full">
      <RangeSlider
        defaultValue={[
          video_start ? video_start : editAtt.video_start,
          currentTime,
          video_end
            ? video_end
            : editAtt.video_end
            ? videoRef?.current?.video?.video?.duration
            : null
        ]}
        key={`${ball_id}-slider`}
        value={[
          editAtt?.video_start ? editAtt?.video_start : 0,
          videoRef?.current?.video?.video?.currentTime,
          editAtt?.video_end ? editAtt?.video_end : videoRef?.current?.video?.video?.duration
        ]}
        min={0}
        max={videoRef?.current?.video?.video?.duration}
        step={0.1}
        onChange={(e) => changeInput(e)}
        onChangeEnd={(e) => handleInputChangeEnd(e)}
      >
        <RangeSliderTrack bg="positive.tint.80">
          <RangeSliderFilledTrack bg="positive.main" />
        </RangeSliderTrack>

        <RangeSliderThumb index={0} key="edit-start-time" boxSize={5} bg="positive.main" />
        <RangeSliderThumb
          index={1}
          key="edit-current-time"
          h="5"
          bg="positive.main"
          borderRadius="0"
          w="1.5"
        />
        <RangeSliderThumb index={2} key="edit-end-time" boxSize={5} bg="positive.main" />
      </RangeSlider>
      {/* <Box>
        <Flex
          fontSize="xs"
          fontWeight="700"
          color="white.100"
          p={1}
          w="100%"
          mt={1}
          fontFamily="Open Sans"
        >
          <Box mr={'auto'}>{convertTime(currentTime)}</Box>
          <Box ml={'auto'}>
            {convertTime(
              video_end && video_end > currentTime
                ? video_end - currentTime
                : videoRef?.current?.video?.video?.duration - currentTime
            )}
          </Box>
        </Flex>
      </Box> */}
    </Box>
  );
};

export default EditScrubComponent;
