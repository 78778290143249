import { Box } from '@chakra-ui/react';

const SubHeaderInfoItem = ({ header, detail }) => {
  return (
    <Box h="20x" bg="white.10" flex="1" borderRadius="4" p="4">
      <Box fontSize="xs" color="white.56" fontWeight="700" letterSpacing="0px">
        {header}
      </Box>
      <Box fontSize="md" color="white.100" fontWeight="700" letterSpacing="0px">
        {detail}
      </Box>
    </Box>
  );
};

export default SubHeaderInfoItem;
