import NoDataDisplay from 'panels/Panel/NoDataDisplay';
import SquadGrid from './SquadGrid';

const filterPlayer = (filter, playerRole) => {
  if (!playerRole) {
    return false;
  }
  if (playerRole.includes('allrounder')) {
    return true;
  }
  if (filter === 'batters' && (playerRole.includes('batter') || playerRole.includes('batsman'))) {
    return true;
  }
  if (filter === 'bowlers' && playerRole.includes('bowler')) {
    return true;
  }
  return false;
};

const SquadDisplay = ({ filterRole, squadData }) => {
  if (!squadData || squadData.length === 0) {
    return <NoDataDisplay message="No squad data available" />;
  }
  const filterData = squadData.filter((el) => {
    return !filterRole || filterRole === 'all'
      ? true
      : filterPlayer(filterRole, el.player_role_short);
  });
  return <SquadGrid squadData={filterData} />;
};

export default SquadDisplay;
