import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Select
} from '@chakra-ui/react';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { Field } from 'formik';
import cloneDeep from 'lodash.clonedeep';
import { greyCardHover } from '_shared/components/cssStylePatterns/cssStylePatterns';
import { MdClear } from 'react-icons/md';

const arrayMove = (array, from, to) => {
  const slicedArray = array.slice();
  slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0]);
  return slicedArray;
};

const SortableLabel = sortableHandle(({ children }) => {
  return <Box _hover={{ cursor: 'pointer' }}>{children}</Box>;
});

const SortableItem = sortableElement(({ value, handleRemove }) => {
  const itemStyle = greyCardHover;
  delete itemStyle._hover.cursor;
  return (
    <Flex minW="fit-content" gap="2" alignItems="center" {...itemStyle} px="2" py="1">
      <SortableLabel>{value.label}</SortableLabel>
      <IconButton
        onClick={handleRemove}
        size="xs"
        color="black.76"
        variant="ghost"
        aria-label="remove-item"
        icon={<Icon boxSize="4" as={MdClear} />}
      />
    </Flex>
  );
});

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <Flex gap="1" wrap="wrap" px="3">
      {children}
    </Flex>
  );
});

const MultiSelectSort = ({ value, placeholder, options, onChange }) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(value, oldIndex, newIndex);
    onChange(newValue);
    console.log(
      'Values sorted:',
      newValue.map((i) => i.value)
    );
  };

  const onItemSelect = (e) => {
    const newValue = cloneDeep(value);
    const optionSelected = options.find((el) => el.value === e.target.value);
    newValue.push(optionSelected);
    onChange(newValue);
  };

  const handleRemove = (index) => {
    const newValue = cloneDeep(value);
    newValue.splice(index, 1);
    onChange(newValue);
  };

  return (
    <Flex id="sortable-helper-container">
      <Select placeholder={placeholder} onChange={onItemSelect} w="300px">
        {options
          .filter((el) => {
            return !value.some((v) => v.value === el.value);
          })
          .map((item) => {
            return (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
      </Select>
      <SortableContainer
        useDragHandle
        onSortEnd={onSortEnd}
        axis="xy"
        helperContainer={() => document.getElementById('sortable-helper-container')}
      >
        {value &&
          value.map((value, index) => (
            <SortableItem
              key={`item-${value.value}`}
              index={index}
              value={value}
              handleRemove={() => handleRemove(index)}
            />
          ))}
      </SortableContainer>
    </Flex>
  );
};

const FormMultiSelectSortable = ({ name, label, placeholder, options }) => {
  return (
    <Field name={name}>
      {({ field: { value }, form: { setFieldValue, errors, touched } }) => {
        return (
          <FormControl isInvalid={errors[name] && touched[name]} mt="3">
            <FormLabel>{label}</FormLabel>
            <MultiSelectSort
              value={value}
              options={options}
              onChange={(v) => setFieldValue(name, v)}
              label={label}
              placeholder={placeholder}
            />
            <FormErrorMessage>{errors[name]}</FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default FormMultiSelectSortable;
