import { Circle, Image, Square } from '@chakra-ui/react';

const TeamImage = ({ team, size = 20, padding = 4, type = 'square' }) => {
  const { flag, id, name } = team;
  let { logo } = team;

  if (flag) {
    return <Image h={`${size}px`} objectFit="contain" src={flag} alt={`Team Flag ${name || id}`} />;
  }

  if (!logo) {
    logo = '/cricviz_icon_v2.png';
  }

  if (type === 'square') {
    return (
      <Square
        bg="white"
        boxSize={`${size + padding}px`}
        border="1px"
        borderColor="black.8"
        rounded="sm"
      >
        <Image h={`${size}px`} objectFit="contain" src={logo} alt={`Team Flag ${name || id}`} />
      </Square>
    );
  }
  if (type === 'circle') {
    return (
      <Circle
        bg="white"
        boxSize={`${size + padding}px`}
        border="1px"
        borderColor="black.8"
        overflow="hidden"
      >
        <Image
          h={`${size}px`}
          w={`${size}px`}
          objectFit="contain"
          src={logo}
          alt={`Team Flag ${name || id}`}
        />
      </Circle>
    );
  }
  return null;
};

export default TeamImage;
