import { Box, Flex, Grid } from '@chakra-ui/react';
import { CricketBallOutline20, CricketBatOutline20 } from '_icons/outline/20';

const PlayerGrid = ({ children }) => {
  return (
    <Grid
      gridTemplateAreas={`"icon name" "empty score"`}
      gridTemplateColumns="auto 1fr"
      flex="1"
      alignItems="center"
      fontSize="sm"
      columnGap="2"
      bg="black.3"
      borderRadius="sm"
      p="2.5"
    >
      {children}
    </Grid>
  );
};

const BatterBowlerComponent = ({ el }) => {
  return (
    <Flex gap={2} px="4">
      <PlayerGrid>
        <Box gridArea="icon">
          <CricketBatOutline20 />
        </Box>
        <Box gridArea="name" fontWeight="700">
          {el.batter_name || el.batter}
        </Box>
        <Flex gridArea="score" gap="1">
          <Box fontWeight="700">{el.batting_runs}</Box>
          <Box color="black.60">({el.bowling_overs})</Box>
        </Flex>
      </PlayerGrid>
      <PlayerGrid>
        <Box gridArea="icon">
          <CricketBallOutline20 />
        </Box>
        <Box gridArea="name" fontWeight="700">
          {el.bowler_name || el.bowler}
        </Box>
        <Flex gridArea="score" gap="1">
          <Box fontWeight="700">
            {el.bowling_wickets}/{el.bowling_conceded}
          </Box>
          <Box color="black.60">({el.batting_balls_faced})</Box>
        </Flex>
      </PlayerGrid>
    </Flex>
  );
};

export default BatterBowlerComponent;
