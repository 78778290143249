import { Center, Flex, Box } from '@chakra-ui/react';
import { Link } from '@tanstack/react-location';
import RouterLink from '_shared/components/Utils/RouterLink';
import { greyCardHover } from '_shared/components/cssStylePatterns/cssStylePatterns';
import TeamImage from '_shared/components/TeamImage';

const compileClassListStr = (classList) => {
  const returnStr = [];
  classList.forEach((el) => {
    returnStr.push(`${el.matches} ${el.class_name}`);
  });
  return returnStr;
};

const CompCard = ({ compData }) => {
  const { class_list } = compData;

  if (class_list) {
    const { id, name, season } = compData;
    const classListString = compileClassListStr(class_list);
    return (
      <Link to={`/comp/${id}`}>
        <Box {...greyCardHover} m="2" p="3">
          <Flex fontSize="md" fontWeight="700" justify="space-between" mb="1">
            <Box>{name}</Box>
            <Box color="black.60">{season}</Box>
          </Flex>
          <Flex gap="2" fontSize="sm" color="black.60">
            {classListString.map((listItem) => {
              return <Center>{listItem}</Center>;
            })}
          </Flex>
        </Box>
      </Link>
    );
  }

  const {
    comp_id,
    comp_name,
    last_match_id,
    result_string,
    season,
    winner_logo,
    winner_name,
    winner_team_id
  } = compData;
  const cardStyle = greyCardHover;
  delete cardStyle._hover.cursor;
  return (
    <Box {...cardStyle} m="2">
      <RouterLink to={`/comp/${comp_id}`}>
        <Flex p={2} fontWeight="700" justify="space-between">
          <Box>{comp_name}</Box>
          <Box direction="column" w="10%" fontWeight="bold">
            {season}
          </Box>
        </Flex>
      </RouterLink>
      <Flex pl={2} pb={2} alignItems="center" fontSize="md" gap="3">
        <RouterLink to={`/team/${winner_team_id}`}>
          <TeamImage team={{ logo: winner_logo, id: winner_team_id }} size={56} />
        </RouterLink>
        <RouterLink to={`/match/${last_match_id}`} fontSize="sm" color="black.60">
          <Box>Won by {winner_name}</Box>
          <Box>Final - {result_string}</Box>
        </RouterLink>
      </Flex>
    </Box>
  );
};

export default CompCard;
