import { useNavigate, useSearch } from '@tanstack/react-location';
import { CricketBallOutline20, CricketBatOutline20 } from '_icons/outline/20';
import ButtonSelector from '_shared/components/ButtonSelector';
import { useEffect } from 'react';

const options = [
  { value: 'batter', label: <CricketBatOutline20 /> },
  { value: 'bowler', label: <CricketBallOutline20 /> }
];

const checkRoleAvailable = (role) => {
  if (!role) return false;
  return options.some((option) => {
    return option.value === role;
  });
};

const RoleFilter = ({ defaultRole, type }) => {
  const navigate = useNavigate();
  const search = useSearch();
  const { role } = search;
  const roleAvailable = checkRoleAvailable(role);

  const currentPath = window.location.pathname;
  const applyFilter = currentPath?.includes(`/${type}/`);

  useEffect(() => {
    if (applyFilter && (!role || !roleAvailable)) {
      navigate({
        search: (old) => ({ ...old, role: defaultRole || 'batter' }),
        replace: true,
        fromCurrent: true
      });
    }
  }, [role, navigate, roleAvailable, defaultRole, applyFilter]);

  const handleChange = (value) => {
    navigate({
      search: (old) => ({ ...old, role: value }),
      replace: true,
      fromCurrent: true
    });
  };

  return (
    <ButtonSelector
      value={role}
      handleChange={handleChange}
      options={options}
      buttonMinWidth={8}
      buttonProps={{ px: 0 }}
      px="5px"
      py="5px"
    />
  );
};

export default RoleFilter;
