import { fetchClient } from './clientSetup';

const centurionApi = process.env.REACT_APP_CENTURION_API_URL || '';
const baseURL = `${centurionApi}/centurion/v3`;

const teamInfo = {
  class_list: [
    {
      label: 'Test',
      value: 1
    },
    {
      label: 'Twenty20 international',
      value: 3
    }
  ],
  id: 1,
  img: 'https://cv-general-images.s3.amazonaws.com/teams/england_cricket.png',
  flag_url: 'https://img.cricviz.com/flags/england.png',
  main_heading: 'Test Data',
  page_type: 'team'
};

const groundInfo = {
  class_list: [
    {
      label: 'Test',
      value: 1
    },
    {
      label: 'Twenty20 international',
      value: 3
    }
  ],
  id: 1,
  info: [
    {
      label: 'YEAR FOUNDED',
      value: '1871'
    },
    {
      label: 'CAPACITY',
      value: '28000'
    },
    {
      label: 'NORTH END',
      value: 'Malcolm Marshall End'
    },
    {
      label: 'SOUTH END',
      value: 'Joel Garner End'
    },
    {
      label: 'SURFACE AREA',
      value: '13,861m2'
    },
    {
      label: 'CIRCUMFERENCE',
      value: '417m'
    }
    // {
    //   label: 'WICKET ANGLE',
    //   value: 'NS'
    // }
  ],
  main_heading: 'TEST DATA - Kensington Oval, Bridgetown',
  page_type: 'ground'
};

export const getObjectInfo = async (type, objectId) => {
  if (type === 'team') return teamInfo;
  if (type === 'ground') return groundInfo;
  const url = `${baseURL}/info/${type}/${objectId}`;
  const res = await fetchClient.get(url);
  return res?.data || null;
};

export const getTabContent = async (objectVariable, objectId, params) => {
  const url = `${baseURL}/content/${objectVariable}/${objectId}`;
  const res = await fetchClient.get(url, { params });
  return res?.data || null;
};

export const getAdvancedContent = async (objectVariable, objectId, params) => {
  params.visualisations = ['wagon_wheel', 'beehive', 'pitchmap'];
  console.log('Advanced params: ', params);
  const url = `${baseURL}/advanced_results/${objectVariable}/${objectId}`;
  const res = await fetchClient.get(url, { params });
  return res?.data || null;
};

export const getAdvancedFilters = async (objectVariable, objectId, params) => {
  const url = `${baseURL}/advanced_filters/${objectVariable}/${objectId}`;
  const res = await fetchClient.get(url, { params });
  const data = res?.data;
  if (!data) return null;
  data?.filters?.forEach(({ options }) => {
    if (options) {
      options.forEach((option) => {
        option.value = option.name ? String(option.name) : String(option.value);
      });
    }
  });
  return res?.data || null;
};

export const getPlayerSkillsData = async (type, classId, player) => {
  const url = `${baseURL}/player_skills/${type}/${classId}`;
  const res = await fetchClient.get(url, { params: { player } });
  const { data } = res;
  return data?.data || [];
};
