import InsertPanelModal from 'Forms/PanelForms/InsertPanelModal';
import usePanelSettingsAvailable from '_shared/hooks/usePanelSettingsAvailable';
import PanelPageDraggable from 'panels/PanelPageDraggable/PanelPageDraggable';
import PlayerSubHeader from './PlayerSubHeader/PlayerSubHeader';
import StickyScrollContainer from 'pages/_shared/components/StickyScroll/StickyScrollContainer';
import StickyScrollWrapper from 'pages/_shared/components/StickyScroll/StickyScrollWrapper';
import HideShowModal from 'pages/_shared/components/HideShowModal/HideShowModal';
import usePageTitle from '_shared/hooks/usePageTitle';
import useCategoryFilter from 'pages/_shared/hooks/useCategoryFilter';
import DateFilter from 'pages/_shared/components/FilterComponents/DateFilter';
import FilterLayout from 'pages/_shared/components/FilterComponents/FilterLayout';
import PageSelectFilter from 'pages/_shared/components/FilterComponents/PageSelectFilter';
import useClassFilter from 'pages/_shared/hooks/useClassFilter';
import useFetchPagePanels from 'pages/_shared/hooks/useFetchPagePanels';
import PanelsLoadingError from 'pages/_shared/components/PanelsLoadingError';
import panelFetchErrorMessage from 'pages/_shared/utils/panelFetchErrorMessage';

const type = 'player';

const PlayerContainer = ({ data, objectId }) => {
  usePanelSettingsAvailable();
  const { panel_information, player_class_ids, player_type, player_type_id } = data;
  const pageData = {
    player_information: panel_information,
    player_class_ids,
    player_type,
    player_type_id
  };
  usePageTitle(panel_information?.panel_header);

  const { classOptions, classFilterId, handleClassFilterChange } = useClassFilter(player_class_ids);
  const { panelsIsLoading, panelError, panelData, panelIsFetching } = useFetchPagePanels(
    type,
    objectId,
    classFilterId
  );
  const { categoryOptions, categoryId, setCategoryId } = useCategoryFilter(panelData);

  return (
    <>
      <StickyScrollContainer>
        <StickyScrollWrapper headerSize={80} expandedHeaderSize={271} pageType={type}>
          <PlayerSubHeader data={pageData} />
          <FilterLayout>
            <PageSelectFilter
              options={classOptions}
              value={classFilterId}
              setValue={handleClassFilterChange}
            />
            <PageSelectFilter
              options={categoryOptions}
              value={categoryId}
              setValue={setCategoryId}
              allOption="All categories"
            />
            <DateFilter type={type} />
          </FilterLayout>
        </StickyScrollWrapper>
        <PanelsLoadingError
          panelError={panelError}
          panelIsFetching={panelIsFetching}
          panelsIsLoading={panelsIsLoading}
          errorMessage={panelFetchErrorMessage(type, data.panel_information?.panel_header)}
        />
        {panelData && (
          <PanelPageDraggable
            pageData={pageData}
            panels={panelData}
            pageType={type}
            categoryId={categoryId}
            classId={classFilterId}
          />
        )}
      </StickyScrollContainer>
      <InsertPanelModal panelData={panelData} pageData={pageData} pageType={type} />
      <HideShowModal panelData={panelData} />
    </>
  );
};

export default PlayerContainer;
