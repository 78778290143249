import { createIcon } from '@chakra-ui/icons';

const BattingIndicator = createIcon({
  displayName: 'BattingIndicator',
  viewBox: '0 0 10 10',
  path: [
    <circle cx="5" cy="5" r="5" fill="currentcolor">
      <animate
        attributeType="XML"
        attributeName="r"
        values="5;4;5"
        dur="1.8s"
        repeatCount="indefinite"
      />
      <animate
        attributeType="XML"
        attributeName="opacity"
        values="1;0.8;1"
        dur="1.8s"
        repeatCount="indefinite"
      />
    </circle>
  ]
});

export default BattingIndicator;
