import most_runs_ranking from './most_runs_ranking';
import batting_strike_rate_ranking from './batting_strike_rate_ranking';
import batting_average_ranking from './batting_average_ranking';
import highest_individual_score_ranking from './highest_individual_score_ranking';
import most_wickets_ranking from './most_wickets_ranking';
import bowling_strike_rate_ranking from './bowling_strike_rate_ranking';
import bowling_average_ranking from './bowling_average_ranking';
import best_bowling_performances_ranking from './best_bowling_performances_ranking';

const configDefs = {
  most_runs_ranking,
  batting_strike_rate_ranking,
  batting_average_ranking,
  highest_individual_score_ranking,
  most_wickets_ranking,
  bowling_strike_rate_ranking,
  bowling_average_ranking,
  best_bowling_performances_ranking
};

export default configDefs;
