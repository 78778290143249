import { Box, Flex, List } from '@chakra-ui/react';
import { useState } from 'react';
import FlexHeaderScroll from '_shared/components/LayoutComponents/FlexHeaderScroll';
import ButtonSelector from '_shared/components/ButtonSelector';
import FixtureComponent from '_shared/components/FixtureComponent/FixtureComponent';
import NoDataDisplay from 'panels/Panel/NoDataDisplay';

const FixturesPanel = ({ panelData }) => {
  const { data } = panelData;
  const liveDataAvailable = Boolean(data?.live?.length > 0);
  const completeDataAvailable = Boolean(data?.complete?.length > 0);
  const forthcomingDataAvailable = Boolean(data?.forthcoming?.length > 0);
  const initDisplay = liveDataAvailable || forthcomingDataAvailable ? 'fixtures' : 'results';
  const [display, setDisplay] = useState(initDisplay);

  if (!liveDataAvailable && !completeDataAvailable && !forthcomingDataAvailable) {
    return <NoDataDisplay message="No fixtures available for this format and date range" />;
  }

  return (
    <FlexHeaderScroll
      header={
        <Flex px="2" py="2" gap="1">
          {(liveDataAvailable || forthcomingDataAvailable) && completeDataAvailable ? (
            <ButtonSelector
              handleChange={setDisplay}
              value={display}
              options={[
                { value: 'fixtures', label: 'Fixtures' },
                { value: 'results', label: 'Results' }
              ]}
              buttonMinWidth="120px"
            />
          ) : null}
        </Flex>
      }
      content={
        <Box m="4" mb="4">
          {display === 'results' && completeDataAvailable ? (
            <List spacing="2">
              {data.complete.map((matchData) => {
                return <FixtureComponent matchData={matchData} key={matchData.id} />;
              })}
            </List>
          ) : null}
          {display === 'fixtures' && liveDataAvailable ? (
            <List spacing="2">
              {data.live.map((matchData) => {
                return <FixtureComponent matchData={matchData} key={matchData.id} />;
              })}
            </List>
          ) : null}
          {display === 'fixtures' && forthcomingDataAvailable > 0 ? (
            <>
              <Box my="3" fontSize="sm" fontWeight="600">
                Upcoming fixtures
              </Box>
              <List spacing="2">
                {data.forthcoming.map((matchData) => {
                  return <FixtureComponent matchData={matchData} key={matchData.id} />;
                })}
              </List>
            </>
          ) : null}
        </Box>
      }
    />
  );
};

export default FixturesPanel;
