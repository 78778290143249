import { Box, Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { Link } from '@tanstack/react-location';
import get from 'lodash.get';
import { checkBowlerHandedness } from '_shared/utils/utilFunctions';
import TableHeader from '../_shared/TableHeader';
import { getBowlerImpactAvailable } from './utils';

const getColumns = (showImpact) => {
  return [
    { content: 'Bowler' },
    { content: '' },
    { content: 'O', isNumeric: true, tooltip: 'Overs' },
    { content: 'R', isNumeric: true, tooltip: 'Runs' },
    { content: 'W', isNumeric: true, tooltip: 'Wickets' },
    { content: '4s', isNumeric: true, tooltip: 'Fours' },
    { content: '6s', isNumeric: true, tooltip: 'Sixes' },
    { content: 'Econ', isNumeric: true },
    { content: 'XW', isNumeric: true, tooltip: 'Expected Wickets' },
    ...(showImpact
      ? [
          {
            content: 'IMP',
            isNumeric: true,
            tooltip: 'Impact'
          }
        ]
      : []),
    {
      content: 'M',
      isNumeric: true,
      tooltip: 'Matches',
      style: { borderLeft: '1px', borderLeftColor: 'grey.100' }
    },
    { content: 'W', isNumeric: true, tooltip: 'Wickets' },
    { content: 'Ave', isNumeric: true, tooltip: 'Average' },
    {
      content: 'BBI',
      isNumeric: true,
      tooltip: 'Best Bowling in Innings',
      style: { borderRight: '1px', borderRightColor: 'grey.100' }
    }
  ];
};

const BowlerTableRow = ({ bowler, scorecardData }) => {
  const {
    player_id,
    overs,
    bowling_type,
    conceded,
    wickets,
    fours_conceded,
    sixes_conceded,
    bowling_economy_rate,
    xW_diff
  } = bowler;
  const bowlerName = get(scorecardData, `players[${player_id}].name`);
  const bowlerCareer = get(scorecardData, `players[${player_id}].career`) || {};
  const bowlerImpact = get(scorecardData, `impact[${player_id}].bowling_impact`);
  const bowlerHandedness = checkBowlerHandedness(bowling_type);
  const { matches, wickets: careerWickets, bowling_average, bbi } = bowlerCareer;

  return (
    <Tr>
      <Td>
        <Link to={`/player/${player_id}`}>{bowlerName}</Link>
      </Td>
      <Td>
        <Box
          as="span"
          color="white"
          fontSize="xs"
          p="0.5"
          fontWeight="bold"
          rounded="sm"
          bg={
            bowlerHandedness === 'right'
              ? 'cricket.RH'
              : bowlerHandedness === 'left'
              ? 'cricket.LH'
              : undefined
          }
        >
          {bowling_type}
        </Box>
      </Td>
      <Td isNumeric>{overs}</Td>
      <Td isNumeric>{conceded}</Td>
      <Td isNumeric>{wickets}</Td>
      <Td isNumeric>{fours_conceded}</Td>
      <Td isNumeric>{sixes_conceded}</Td>
      <Td isNumeric>{bowling_economy_rate}</Td>
      <Td isNumeric>{xW_diff}</Td>
      {bowlerImpact && (
        <Td isNumeric color={bowlerImpact > 0 ? 'positive' : 'negative'}>
          {bowlerImpact}
        </Td>
      )}
      <Td isNumeric borderLeft="1px" borderLeftColor="grey.100">
        {matches}
      </Td>
      <Td isNumeric>{careerWickets}</Td>
      <Td isNumeric>{bowling_average}</Td>
      <Td isNumeric>{bbi}</Td>
    </Tr>
  );
};

const LiveBowlerTable = ({ liveBowler, scorecardData }) => {
  const showImpact = getBowlerImpactAvailable(liveBowler, scorecardData);
  return (
    <Table size="sm" w="min-content" variant="striped" mt="5" whiteSpace="nowrap">
      <TableHeader columns={getColumns(showImpact)} />
      <Tbody>
        {liveBowler.map((bowler) => (
          <BowlerTableRow key={bowler.player_id} bowler={bowler} scorecardData={scorecardData} />
        ))}
      </Tbody>
    </Table>
  );
};

export default LiveBowlerTable;
