import { Box, Flex, Text} from '@chakra-ui/react';
import { InfoOutline48 } from '_icons/outline/48';
import Header from '../../_components/header/Header';

const NoData = ({header}) => {
  return (<>
    <Header
      title={header}
    />
    <Flex
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Box py={10}>
        <InfoOutline48 color="primary.tint.80" mb={1} />
        <Text>No data available for this match</Text>
      </Box>
    </Flex>
  </>);
};

export default NoData;