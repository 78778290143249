import { Box, Flex, Icon, Avatar } from '@chakra-ui/react';
import { BiBriefcase } from 'react-icons/bi';
import { MdInfoOutline } from 'react-icons/md';
import CricketBatBall from '_icons/icons/CricketBatBall';
import RouterLink from '_shared/components/Utils/RouterLink';
const HomePageHeader = ({ teamData, userData }) => {
  const { description, full_name, company_name } = userData;
  const { logo_url, name, id: homeTeamId } = teamData;
  const headerExpanded = false;
  return (
    <Box
      h={headerExpanded ? '271px' : '80px'}
      bg="primary.shade.70"
      zIndex="1"
      transitionProperty="height"
      transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
      transitionDuration="500ms"
    >
      <Flex justify="space-between" py={4} px={5} gap="5">
        <Flex flexGrow={1} direction="column" gap="10px">
          <Flex h="48px" alignItems="center">
            <Flex flexGrow={1} alignItems="center" gap="4" color="white.100">
              <Avatar size="md" src={logo_url ? logo_url : null} bg="white.100" />
              <Box color="white" fontSize="lg" mr={5}>
                {full_name}
              </Box>
              <Flex alignItems="center" gap="1">
                <Icon as={CricketBatBall} boxSize="5" />
                <RouterLink to={`/team/${homeTeamId}`}>{name}</RouterLink>
              </Flex>
              <Flex alignItems="center" gap="1">
                <Icon as={BiBriefcase} boxSize="5" />
                <Box>{company_name ? company_name : 'Guest'}</Box>
              </Flex>
              <Flex alignItems="center" gap="1">
                <Icon as={MdInfoOutline} boxSize="5" />
                <Box>{description}</Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default HomePageHeader;
