import { Box, Flex, Select, Show, Center, Button } from '@chakra-ui/react';
import { useState } from 'react';

import { MATCH_VIDEO_ENABLED, MATCH_VIDEO_ID } from '_shared/globalState/atoms';
import { useRecoilValue, useRecoilState } from 'recoil';
import ButtonSelector from '_shared/components/ButtonSelector';
import BBBComms from './BBBComms';
import OversBBB from './OversBBB';
import { Player, BigPlayButton, ControlBar } from 'video-react';
import AddUserFavourites from '_shared/components/AddUserFavourites';
import 'video-react/dist/video-react.css';
import 'panels/PanelTypes/VideoPanel/Video.css';
import { handlePermissions } from '_shared/utils/utilFunctions';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
// import { panelData } from './panelData';

const BBBOversComms = ({ panelData, pageData }) => {
  const [tab, setTab] = useState('over');
  const { team1_id, team1_abbreviation, team2_id, team2_abbreviation } = pageData.match_information;
  const [vidExpanded, setVidExpanded] = useRecoilState(MATCH_VIDEO_ENABLED);
  const videoId = useRecoilValue(MATCH_VIDEO_ID);
  const { bbb: balls } = panelData.data;
  const teams = {};
  const { data: userInfo } = useUserDetails();
  const { permissions } = userInfo || {};
  const videoAllowed = handlePermissions(8, permissions);
  teams[team1_id] = team1_abbreviation;
  teams[team2_id] = team2_abbreviation;
  const innings = [
    {
      innings: balls[0].innings_number,
      batting_team_id: balls[0].batting_team_id,
      bowling_team_id: balls[0].bowling_team_id
    }
  ];
  let overs = {};
  let entire_innings = [];
  const inningsBalls = {};
  const videos = {};
  let currInns = balls[0].innings_number;

  const [inningsSelect, setInningsSelected] = useState(currInns);
  balls.forEach((el) => {
    if (el?.video?.length > 0) {
      videos[el.ball_id] = el.video[0].video_url;
    }

    if (currInns !== el.innings_number) {
      innings.push({
        innings: el.innings_number,
        batting_team_id: el.batting_team_id,
        bowling_team_id: el.bowling_team_id
      });
      inningsBalls[currInns] = { overs, entire_innings };
      currInns = el.innings_number;
      overs = {};
      entire_innings = [];
    }
    entire_innings.push(el);
    const currOver = parseInt(el.over_number);
    if (overs.hasOwnProperty(currOver)) {
      overs[currOver].push(el);
    } else {
      overs[currOver] = [el];
    }
  });
  inningsBalls[balls[balls.length - 1].innings_number] = { overs, entire_innings };

  const inningsLabels = [];
  innings.forEach((el) => {
    let label = teams[el.batting_team_id];
    if (innings.length > 2) {
      if (el.innings > 2) {
        label += ` 2nd innings`;
      } else {
        label += ` 1st innings`;
      }
    } else {
      label += ' innings';
    }
    inningsLabels.push({ label, value: el.innings });
  });

  const { players } = pageData;

  return (
    <Box>
      <Flex px="2" py="1" gap="1">
        <Show below="sm">
          <Select
            value={tab}
            onChange={(e) => setTab(e.target.value)}
            maxW="300px"
            bg="white"
            size="md"
            fontSize="14px"
          >
            <option value="over">Overs</option>
            <option value="comms">Text Commentary</option>
          </Select>
          <Select
            value={inningsSelect}
            onChange={(e) => setInningsSelected(e.target.value)}
            maxW="300px"
            bg="white"
            size="md"
            fontSize="14px"
            justifyContent={'flex-end'}
          >
            {inningsLabels.map((el) => {
              return (
                <option value={el.value} key={`inn-${el.value}`}>
                  {el.label}
                </option>
              );
            })}
          </Select>
        </Show>
        <Show above="sm">
          <ButtonSelector
            handleChange={setTab}
            value={tab}
            options={[
              { value: 'over', label: 'Overs' },
              { value: 'comms', label: 'Text Commentary' }
            ]}
            buttonMinWidth="120px"
          />
          <Select
            value={inningsSelect}
            onChange={(e) => setInningsSelected(e.target.value)}
            maxW="300px"
            bg="white"
            size="md"
            fontSize="14px"
          >
            {inningsLabels.map((el) => {
              return (
                <option value={el.value} key={`inn-${el.value}`}>
                  {el.label}
                </option>
              );
            })}
          </Select>
        </Show>
      </Flex>
      <Flex>
        <Box px="2" pt="4" pb="0" w={vidExpanded ? '58%' : '100%'}>
          {tab === 'over' ? (
            <OversBBB
              oversData={inningsBalls[inningsSelect].overs}
              players={players}
              current_over={Math.max(...Object.keys(inningsBalls[inningsSelect].overs))}
              videos={videos}
              videoAllowed={videoAllowed}
            />
          ) : tab === 'comms' ? (
            <BBBComms
              balls={inningsBalls[inningsSelect].entire_innings}
              videos={videos}
              playerObj={panelData.data?.players}
              videoAllowed={videoAllowed}
            />
          ) : null}
        </Box>
        {videoAllowed && vidExpanded && (
          <Box w="40%" p={1} position="fixed" right="15px" top="45px">
            {videoId ? (
              <Player
                src={videos[videoId]}
                width={'97%'}
                height={'95%'}
                playsInline={true}
                autoPlay={true}
              >
                <BigPlayButton position="center" />
                <ControlBar autoHide={true} className="control-bar-panel" autoHideTime={1000} />
              </Player>
            ) : (
              <Center>Sorry, no video available for this ball</Center>
            )}
            {vidExpanded && videoId ? (
              <Flex w="100%" justifyContent={'flex-end'}>
                <Button onClick={() => setVidExpanded(false)} mt={2}>
                  Close
                </Button>
                <Box pt={4} ml={3} mr={2}>
                  <AddUserFavourites favType={'video'} favId={videoId} lightBackground={true} />
                </Box>
              </Flex>
            ) : null}
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default BBBOversComms;
