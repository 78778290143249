import { Box, Button, Flex, Grid, useBreakpointValue } from '@chakra-ui/react';
import { useState } from 'react';
import MultiSelect from './MultiSelect';
import DateRange from './DateRange';
import RangeFilter from './RangeFilter';
import InputCheckboxGroup from './InputCheckboxGroup';
import SelectBox from './SelectBox';
import FilterAccordionGrid from './FilterAccordionGrid';
import { ArrowRightOutline24, CloseOutline24, PlusOutline24 } from '_icons/outline/24';

const getElement = (props, type) => {
  if (type === 'multiselect' || type === 'checkbox') {
    return <MultiSelect {...props} />;
  }
  if (type === 'date_range') {
    return <DateRange {...props} />;
  }
  if (type === 'range') {
    return <RangeFilter {...props} />;
  }
  if (type === 'checkbox') {
    return <InputCheckboxGroup {...props} />;
  }
  if (type === 'select') {
    return <SelectBox {...props} />;
  }
  return null;
};

const VideoFiltersLayout = ({ handleSubmit, setFieldValue, folding, filters, initialValues }) => {
  const columnCount = useBreakpointValue({ base: null, sm: 1, lg: 2, xl: 3 });
  const [expandedFilter, setExpandedFilter] = useState(null);
  const [isFolded, setIsFolded] = useState(true);

  const basicFilters = [];
  const accordianFilters = [];

  filters.forEach((filterData, idx) => {
    const { api_tag } = filterData;
    const isAccordion = !folding || idx > 5;
    const elementProps = {
      filterData,
      setFieldValue,
      initialValues,
      key: `${api_tag}-search-filter`,
      isExpanded: expandedFilter === api_tag,
      setIsExpanded: setExpandedFilter,
      isAccordion
    };
    const element = getElement(elementProps, filterData.type);
    if (folding && idx < 6) {
      basicFilters.push(element);
    } else {
      accordianFilters.push(element);
    }
  });
  return (
    <Box>
      {folding ? (
        <Grid templateColumns={`repeat(${columnCount}, 1fr)`} columnGap="8" rowGap="5" mb="10">
          {basicFilters}
        </Grid>
      ) : null}
      {folding ? (
        <Flex justify="space-between" pb={isFolded ? '0' : '10'}>
          <Button
            variant={isFolded ? 'secondary' : 'tertiary'}
            h="44px"
            fontSize="md"
            fontWeight="bold"
            display="flex"
            gap="2"
            onClick={() => setIsFolded((prevState) => !prevState)}
          >
            {isFolded ? <PlusOutline24 /> : <CloseOutline24 />}
            Advanced Filters
          </Button>
          <Button
            w={210}
            h="44px"
            fontSize="md"
            fontWeight="bold"
            onClick={() => {
              handleSubmit();
            }}
            display="flex"
            gap="2"
          >
            <Box>View results</Box>
            <ArrowRightOutline24 />
          </Button>
        </Flex>
      ) : null}
      <FilterAccordionGrid
        accordianFilters={accordianFilters}
        columnCount={columnCount}
        isFolded={folding && isFolded}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
  //   return (
  //     <Flex direction="column" gap="10">
  //       <FilterAccordionGrid accordianFilters={accordianFilters} columnCount={columnCount} />
  //       <Flex justify="flex-end">
  //         <Button
  //           w={210}
  //           h="44px"
  //           onClick={() => {
  //             handleSubmit();
  //           }}
  //           display="flex"
  //           gap="2"
  //         >
  //           <Box>View results</Box>
  //           <ArrowRightOutline24 />
  //         </Button>
  //       </Flex>
  //     </Flex>
  // <Grid templateColumns={`repeat(${columnCount}, 1fr)`}>
  //   {basicFilters}
  //   {accordianFilters}
  // </Grid>
  //   );
};

export default VideoFiltersLayout;
