import {
  Box,
  Flex,
  Button,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  Center,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner
} from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-location';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineTrophy } from 'react-icons/ai';
import {
  MdNavigateNext,
  MdOutlineFilterList,
  MdOutlineSportsCricket,
  MdPeopleOutline,
  MdPersonOutline,
  MdSearch,
  MdInfoOutline
} from 'react-icons/md';
import { getPlayerInfo } from '_shared/dataFetching/apiService';
import ButtonSelector from '_shared/components/ButtonSelector';
import useIntersectionObserver from '_shared/hooks/useIntersectionObserver';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import useSearch from '_shared/hooks/useSearch';
import useWindowDimensions from '_shared/hooks/useWindowDimensions';
import { searchSuccess } from '_shared/tracking/trackingLogs';

const dataTypes = ['player', 'match', 'comp', 'team'];
const getType = (item) => {
  const splitArray = item.url.split('/');
  let type;
  splitArray.forEach((el) => {
    if (dataTypes.includes(el)) {
      type = el;
    }
  });
  return type;
};

const icons = {
  comp: AiOutlineTrophy,
  player: MdPersonOutline,
  match: MdOutlineSportsCricket,
  team: MdPeopleOutline
};

const filterTypes = [
  { value: 'all', label: 'All' },
  { value: 'player', label: 'Player' },
  { value: 'match', label: 'Match' },
  { value: 'comp', label: 'Comp' },
  { value: 'team', label: 'Team' },
  { value: 'ground', label: 'Ground' }
];
const PlayerInfoSearch = ({ player_id, isOpen, handleClose }) => {
  const paramaters = ['span', 'nationality', 'dob', 'pob'];
  const { isLoading, error, data } = useQueryFetch(
    ['player_info', player_id],
    () => getPlayerInfo(player_id),
    {
      enabled: isOpen
    }
  );

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xs" scrollBehavior="inside" h="150px">
      <ModalOverlay />
      <ModalContent maxH="260px" maxW="360px">
        {isLoading && <Spinner size="sm" color="primary.tint.60" />}
        {error && <Box>Unable to fetch search results</Box>}
        {!isLoading && !data && <ModalHeader>No further player information available</ModalHeader>}
        {data && data.full_name && (
          <>
            <ModalHeader px="4" py="2">
              <Box>{data.full_name}</Box>
            </ModalHeader>

            <ModalBody
              px="3"
              overflow="auto"
              borderTop="1px"
              borderColor="black.8"
              id={`info_list_${player_id}`}
            >
              {paramaters.map((el) => {
                if (data.hasOwnProperty(el) && data[el]) {
                  return (
                    <Flex p={1} gap={1}>
                      <Box fontWeight="bold" flexGrow={1}>
                        {el[0].toUpperCase()}
                        {el.slice(1)}
                      </Box>
                      <Box>{data[el]}</Box>
                    </Flex>
                  );
                }
                return null;
              })}
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

const SearchItem = ({ item, handleClick, focused, onHover, idx }) => {
  const ref = useRef();
  const entry = useIntersectionObserver(ref, {
    threshold: 1,
    root: document.getElementById('search_list')
  });
  const isVisible = !!entry?.isIntersecting;
  const [playerInfoModalOpen, setPlayerInfoModalOpen] = useState(false);
  useEffect(() => {
    if (focused && !isVisible) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }
  }, [focused, isVisible]);

  const type = getType(item);

  return (
    <>
      <ListItem width="100%" key={item.url} id={`search_result_${idx}`} ref={ref}>
        <Flex alignItems="center" onMouseEnter={onHover} mt={2}>
          <Flex
            rounded="md"
            bg={focused ? 'primary.tint.80' : 'black.8'}
            _hover={{ cursor: 'pointer' }}
            px="4"
            py="2"
            onClick={handleClick}
            h="14"
            display="flex"
            flexGrow="99"
            gap={4}
            alignItems="center"
          >
            {type ? <Icon as={icons[type]} boxSize="6" color="black.32" /> : <Box boxSize="6" />}

            <Box flexGrow={1}>{item.html}</Box>
            <Icon as={MdNavigateNext} boxSize="6" color="black.32" />
          </Flex>

          {type === 'player' ? (
            <Center flexGrow="1">
              <IconButton
                icon={<MdInfoOutline size={24} />}
                boxSize="6"
                variant="ghostDark"
                onClick={() => setPlayerInfoModalOpen((e) => !e)}
              />
            </Center>
          ) : null}
        </Flex>

        <PlayerInfoSearch
          player_id={item.id}
          isOpen={playerInfoModalOpen}
          handleClose={() => setPlayerInfoModalOpen(false)}
        />
      </ListItem>
    </>
  );
};

const SearchModal = () => {
  const navigate = useNavigate();
  const [searchOpen, setSearchOpen] = useState(false);
  const [typeFilter, setTypeFilter] = useState('all');
  const [typeFilterOpen, setTypeFilterOpen] = useState(false);
  const { width } = useWindowDimensions();
  const [focusedItem, setFocusedItem] = useState(0);
  const { searchValue, setSearchValue, isLoading, error, data } = useSearch(typeFilter);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const el = data[focusedItem];
      navigate({ to: el.url });
      setSearchOpen(false);
    }
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
    if (event.key === 'ArrowUp' && focusedItem !== 0) {
      setFocusedItem((p) => p - 1);
    }
    if (event.key === 'ArrowDown' && focusedItem !== data.length - 1) {
      setFocusedItem((p) => p + 1);
    }
  };

  const handleClick = (el) => {
    const { url } = el;
    searchSuccess(searchValue, url);
    navigate({ to: url });
    setSearchOpen(false);
  };

  const filterFunction = (item) => {
    if (typeFilter === 'all') {
      return true;
    }
    let include = false;
    const splitArray = item.url.split('/');
    splitArray.forEach((el) => {
      if (el === typeFilter) {
        include = true;
      }
    });
    return include;
  };

  return (
    <>
      <Button
        variant="primaryDark"
        color="white.100"
        onClick={() => {
          setSearchValue('');
          setSearchOpen(true);
        }}
        leftIcon={width > 700 ? <Icon as={MdSearch} fontSize="20" /> : null}
        mr={1}
      >
        {width > 700 ? (
          <Box ml={1} fontSize="sm">
            Search
          </Box>
        ) : (
          <Icon as={MdSearch} fontSize="20" />
        )}
      </Button>
      <Modal
        isOpen={searchOpen}
        onClose={() => setSearchOpen(false)}
        size="2xl"
        scrollBehavior="inside"
        h="300px"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader px="4" py="2">
            <Box>
              <Flex>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="primary.tint.30"
                    fontSize="24px"
                    children={<Icon as={MdSearch} />}
                  />
                  <Input
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    borderColor="transparent"
                    focusBorderColor="transparent"
                    fontSize="18px"
                  />
                  <InputRightElement
                    children={isLoading && <Spinner size="sm" color="primary.tint.60" />}
                  />
                </InputGroup>
                <IconButton
                  onClick={() => {
                    setTypeFilterOpen((p) => !p);
                  }}
                  isRound
                  size="md"
                  color={typeFilter === 'all' ? 'black.24' : 'primary.tint.30'}
                  variant="ghost"
                  aria-label="filter-results"
                  icon={<Icon boxSize={6} as={MdOutlineFilterList} />}
                />
              </Flex>
              {typeFilterOpen ? (
                <Box mt="2">
                  <ButtonSelector
                    value={typeFilter}
                    handleChange={setTypeFilter}
                    options={filterTypes}
                  />
                </Box>
              ) : null}
            </Box>
          </ModalHeader>
          {searchValue !== '' && data && (
            <ModalBody
              px="3"
              overflow="auto"
              borderTop="1px"
              borderColor="black.8"
              id="search_list"
            >
              <Box>
                {error && <Box>Unable to fetch search results</Box>}
                {data && (
                  <List spacing={1}>
                    {data.filter(filterFunction).map((item, idx) => {
                      return (
                        <SearchItem
                          item={item}
                          handleClick={() => handleClick(item)}
                          onHover={() => setFocusedItem(idx)}
                          focused={focusedItem === idx}
                          idx={idx}
                          key={item.id}
                        />
                      );
                    })}
                  </List>
                )}
              </Box>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default SearchModal;
