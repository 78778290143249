const getPanelStaleTime = (panelTypeId, isLive) => {
  if ([18, 20, 21].includes(panelTypeId)) {
    return 0;
  } else if (isLive) {
    return 0;
  }
  return 3 * 60 * 1000;
};

export default getPanelStaleTime;
