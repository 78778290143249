export const getColor = (ball, type) => {
  if (ball.scoring === 'W') {
    return `ballScore.w.${type}`;
  }
  if (ball.scoring === '0') {
    return `ballscore.nr.${type}`;
  }

  return `ballScore.${ball.scoring}.${type}`;
};
