export const getFilterOptions = ({ data }) => {
  const favouriteTypes = [];
  Object.entries(data).forEach(([key, value]) => {
    if (['comp', 'player', 'team', 'ground'].includes(key) && value.length !== 0) {
      favouriteTypes.push(key);
    }
    if (key === 'match') {
      Object.entries(value).forEach(([matchType, matchTypeArray]) => {
        if (
          matchType === 'complete' &&
          matchTypeArray.length !== 0 &&
          !favouriteTypes.includes('results')
        ) {
          favouriteTypes.push('results');
        }
        if (
          ['live', 'forthcoming'].includes(matchType) &&
          matchTypeArray.length !== 0 &&
          !favouriteTypes.includes('fixtures')
        ) {
          favouriteTypes.push('fixtures');
        }
      });
    }
  });
  return {
    favouriteTypes
  };
};
