import { Box, Flex, Text} from '@chakra-ui/react';
import { CricVizLogo } from '_icons/svgs';
import colors from './Colors';

const Info = () => {
  return (
    <Flex
      justifyContent="center"
    >
      <Box
        maxW="570px"
      >
        <Flex
          justifyContent="center"
          paddingBottom="24px"
        >
          <CricVizLogo />
        </Flex>
        <Text fontWeight="700" paddingBottom="24px">
          The Captaincy Grid indicates which bowling types and individual bowlers are a good match-up to an opposition batter.
        </Text>
        <Text paddingBottom="24px">
          Using ball-by-data and the CricViz Match Impact model, all match-up combinations are shown as good for the bowling side (shown in <Text as="span" color={colors[colors.length - 1]} fontWeight="700">green</Text>) or bad for the bowling side (shown in <Text as="span" color={colors[0]} fontWeight="700">red</Text>).
        </Text>
        <Text>
          Hovering over the grid will reveal the specific ball-by-ball data for that given match-up.
        </Text>
      </Box>
    </Flex>
  );
};
  
export default Info;