import {
  Button,
  Box,
  Flex,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton
} from '@chakra-ui/react';
import { useQueryClient } from 'react-query';

import { deleteVideoPlaylist } from '_shared/dataFetching/apiService';

const DeletePlaylistModal = ({ playlistData, formModalOpen, handleClose }) => {
  const queryClient = useQueryClient();
  const { playlist_name, id } = playlistData;
  const deletePlaylist = () => {
    const payload = { id };
    deleteVideoPlaylist(payload);
    setTimeout(() => queryClient.refetchQueries(['user_playlists']), 1000);
  };
  return (
    <Modal isOpen={formModalOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent maxW="500px">
        <ModalCloseButton />
        <ModalBody mt={2}>
          <Box mt={4} fontWeight="bold">
            Are you sure you want to delete playlist
          </Box>
          <Center fontSize="lg" mt={3} border="1" p={1}>
            {playlist_name}
          </Center>
          <Flex gap={2} pt={3} mt={2}>
            <Button
              onClick={() => {
                deletePlaylist();
                handleClose();
              }}
            >
              Yes
            </Button>
            <Button variant="ghost" onClick={handleClose}>
              No
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeletePlaylistModal;
