export const getInningsData = (scorecardData) => {
  const {
    team1_id,
    team2_id,
    team1_name,
    team2_name,
    team1_logo_url,
    team2_logo_url,
    team1_abbreviation,
    team2_abbreviation
  } = scorecardData.match;
  const inningsData = {
    [team1_id]: {
      id: team1_id,
      emblem: team1_logo_url,
      abbrev: team1_abbreviation,
      name: team1_name,
      innings: []
    },
    [team2_id]: {
      id: team2_id,
      emblem: team2_logo_url,
      abbrev: team2_abbreviation,
      name: team2_name,
      innings: []
    }
  };
  if (scorecardData.innings) {
    scorecardData.innings.forEach((inn) => {
      const batting_team = inn.batting_team_id || inn.team_id;
      if (!batting_team) return;
      if (!inningsData[batting_team]) return;
      const { overs, runs, wickets, run_rate, closure_name, live_status, xR, xW } = inn;
      const innings = {
        overs,
        runs,
        wickets,
        run_rate,
        closure_name,
        live_status,
        xR,
        xW
      };
      inningsData[batting_team].innings.push(innings);
    });
  }
  return inningsData;
};
