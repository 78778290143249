const authServerUrl = process.env.REACT_APP_AUTH_URL;

export const getApiUrl = () => {
  const localApiUrl = 'http://localhost:3000/';

  // we are running tests, so don't set the api url, otherwise MSW won't work
  if (process.env.NODE_ENV === 'test') return undefined;

  // we are running locally, using MSW
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MSW === 'on') {
    return localApiUrl;
  }

  // we are running locally, using the staging API
  if (process.env.NODE_ENV === 'development') {
    return `${authServerUrl}/redirect`;
  }

  // we are running remotely. get the url from env vars
  if (process.env.NODE_ENV === 'production') {
    return `${authServerUrl}/redirect`;
  }

  // return local by default
  return localApiUrl;
};
