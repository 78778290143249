import { useFormikContext } from 'formik';
import { useState, useEffect } from 'react';
import { getQTColumns } from '_shared/dataFetching/apiService';

const useColumnFetch = (enabled) => {
  const [fetchingColumns, setFetchingColumns] = useState(false);
  const [sampleData, setSampleData] = useState({});
  const [dateData, setDateData] = useState(['2000-01-01', '2023-01-01']);
  const { values, errors, setFieldValue } = useFormikContext();
  const urlValue = values?.qt_api;
  const urlValid = !errors?.qt_api;

  useEffect(() => {
    if (enabled) {
      const timeoutId = setTimeout(() => {
        const fetch = async () => {
          setFetchingColumns(true);
          try {
            const data = await getQTColumns(urlValue);
            setSampleData(data.sample);
            setDateData(data.date_range);
            setFieldValue('columnData', data.columns, false);
          } catch (err) {
            setFieldValue('columnData', [], false);
          }
          setFetchingColumns(false);
        };
        if (urlValid && urlValue !== '') {
          fetch();
        } else {
          setFieldValue('columnData', [], false);
        }
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [enabled, urlValue, urlValid, setFieldValue]);

  return { fetchingColumns, sampleData, dateData };
};

export default useColumnFetch;
