import { Box } from '@chakra-ui/react';

const Bar = ({ value, color }) => {
  if (value === undefined || value === null) return null;
  return (
    <Box w="100%" bg="black.3" h="2" rounded="full">
      <Box w={`${value}%`} bg={color} h="100%" rounded="full" />
    </Box>
  );
};

export default Bar;
