import { Navigate } from '@tanstack/react-location';
import FetchComponent from 'pages/_shared/components/FetchComponent';
import BetaSwitch from './BetaSwitch';
import PageContainer from 'pages/_shared/components/PageContainer';

export const matchRoutes = {
  path: 'match',
  children: [
    { path: '/', element: <Navigate to="/matches" /> },
    {
      path: ':objectId',
      element: (
        <BetaSwitch beta={<PageContainer type="match" key="match" />}>
          <FetchComponent type="match" />
        </BetaSwitch>
      )
    }
  ]
};
