import { forwardRef } from 'react';
import { Box } from '@chakra-ui/react';
import WidgetComponent from './WidgetComponent';

const ScorecardWidget = forwardRef(({ panelData }, ref) => {
  const { variable_id } = panelData;

  const widgetId = `scorecard_${variable_id}`;

  const widgetProps = {
    id: widgetId,
    type: 'scorecard',
    include: 'advanced,partnerships',
    team_color: 'true',
    player_names: 'full',
    match: variable_id,
    live_update: 'true',
    ref
  };

  return (
    <Box h="100%" margin={'auto'}>
      <WidgetComponent widgetParams={widgetProps} />
    </Box>
  );
});
export default ScorecardWidget;
