const checkCompType = (objectData) => {
  const { completed_matches } = objectData;
  if (!completed_matches) {
    return 'forthcoming';
  }
  const compMatches = parseInt(completed_matches);
  if (compMatches > 0) {
    return 'comp';
  }
  return 'forthcoming';
};

export default checkCompType;
