import { createIcon } from '@chakra-ui/icons';

const CheckboxUnchecked = createIcon({
  displayName: 'CheckboxUnchecked',
  viewBox: '0 0 24 24',
  defaultProps: { width: '24px', height: '24px', fill: 'none' },
  path: <rect x="1" y="1" width="22" height="22" rx="3" stroke="currentColor" stroke-width="2" />
});

export default CheckboxUnchecked;
