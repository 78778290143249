export const battingHeadersOrder = [
  {
    content: 'span'
  },
  {
    content: 'mat',
    isNumeric: true
  },
  {
    content: 'Inns',
    isNumeric: true
  },
  {
    content: 'NO',
    isNumeric: true
  },
  {
    content: 'runs',
    isNumeric: true
  },
  {
    content: 'hs',
    isNumeric: true
  },
  {
    content: 'avg',
    isNumeric: true
  },
  {
    content: 'bf',
    isNumeric: true
  },
  {
    content: 'SR',
    isNumeric: true
  },
  {
    content: 'DisR',
    isNumeric: true
  },
  {
    content: '0s',
    isNumeric: true
  },
  {
    content: '50s',
    isNumeric: true
  },
  {
    content: '100s',
    isNumeric: true
  },
  {
    content: 'dots_faced',
    isNumeric: true
  },
  {
    content: '4s',
    isNumeric: true
  },
  {
    content: '6s',
    isNumeric: true
  },
  {
    content: 'Dot%',
    isNumeric: true
  },
  {
    content: 'Bound%',
    isNumeric: true
  }
];
export const bowlingHeadersOrder = [
  {
    content: 'span'
  },
  {
    content: 'Mat',
    isNumeric: true
  },
  {
    content: 'Inns',
    isNumeric: true
  },
  {
    content: 'Balls',
    isNumeric: true
  },
  {
    content: 'Runs',
    isNumeric: true
  },
  {
    content: 'Wkts',
    isNumeric: true
  },
  {
    content: 'bbi',
    isNumeric: true
  },
  {
    content: 'bbm',
    isNumeric: true
  },
  {
    content: 'avg',
    isNumeric: true
  },
  {
    content: 'eR',
    isNumeric: true
  },
  {
    content: 'sR',
    isNumeric: true
  },
  {
    content: '5W',
    isNumeric: true
  },
  {
    content: '10W',
    isNumeric: true
  },
  {
    content: 'Dot%',
    isNumeric: true
  },
  {
    content: 'Bound%',
    isNumeric: true
  }
];
