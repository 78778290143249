import { createIcon } from '@chakra-ui/icons';

const History = createIcon({
  displayName: 'History',
  viewBox: '0 0 20 20',
  defaultProps: { width: '20px', height: '20px' },
  path: (
    <path
      d="M9.98399 16.5833C8.15172 16.5833 6.59644 15.9442 5.31813 14.6659C4.03983 13.3875 3.40335 11.8322 3.40869 10H4.47596C4.49199 11.5085 5.03553 12.8023 6.10657 13.8814C7.17762 14.9605 8.4701 15.5 9.98399 15.5C11.4979 15.5 12.793 14.9618 13.8694 13.8854C14.9458 12.809 15.484 11.5139 15.484 10C15.484 8.48611 14.9458 7.19097 13.8694 6.11458C12.793 5.03819 11.4979 4.5 9.98399 4.5C9.17096 4.5 8.41823 4.65774 7.7258 4.97323C7.03338 5.28872 6.43515 5.72285 5.93111 6.27562H8.04809V7.35894H3.81738V3.12821H4.90067V5.85258C5.50751 5.11007 6.24789 4.51846 7.12182 4.07775C7.99575 3.63704 8.94981 3.41669 9.98399 3.41669C10.8974 3.41669 11.7533 3.5894 12.5517 3.93481C13.35 4.28021 14.0461 4.7499 14.6401 5.34387C15.2341 5.93786 15.7038 6.63401 16.0492 7.43233C16.3946 8.23065 16.5673 9.08654 16.5673 10C16.5673 10.9135 16.3946 11.7693 16.0492 12.5677C15.7038 13.366 15.2341 14.0621 14.6401 14.6561C14.0461 15.2501 13.35 15.7198 12.5517 16.0652C11.7533 16.4106 10.8974 16.5833 9.98399 16.5833ZM12.2356 12.6891L9.46636 9.91988V6H10.5497V9.45513L13.0096 11.9151L12.2356 12.6891Z"
      fill="currentColor"
    />
  )
});

export default History;
