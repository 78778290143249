import { Button, Flex, Select, Box } from '@chakra-ui/react';
import FormInput from 'Forms/_shared/FormElements/FormInput';
import { useState } from 'react';
import { getPanelNameVariables } from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import { transformPanelText } from '../utils';

const DynamicNameVariables = ({ pageType, objectId, values, setFieldValue }) => {
  const [selectedDynamicVar, setSelectedDynamicVar] = useState('');
  const handleAddText = () => {
    setFieldValue('panel_name', `${values.panel_name}{{${selectedDynamicVar}}}`);
  };
  const { data: dynamicPanelNames = {} } = useQueryFetch([pageType, objectId], () =>
    getPanelNameVariables(pageType, objectId)
  );

  return (
    <Box mt="3">
      <FormInput name="panel_name" label="Panel Name" />
      <Box
        mt={2}
        color="grey.800"
        px="2"
        pt="2"
        h="10"
        fontWeight="bold"
        borderBottom="1px"
        borderColor="black.8"
        boxShadow="0px 2px 6px rgba(4, 9, 33, 0.1), 0px 2px 0px rgba(4, 9, 33, 0.05), 0px 0px 2px rgba(4, 9, 33, 0.03)"
      >
        {transformPanelText(values.panel_name, dynamicPanelNames, true)}
      </Box>
      <Box fontSize="14px">Preview</Box>
      {Object.keys(dynamicPanelNames).length > 0 && (
        <Flex mt={2}>
          <Select
            onChange={(e) => setSelectedDynamicVar(e.target.value)}
            placeholder="Select to add to panel name..."
          >
            {Object.keys(dynamicPanelNames).map((item) => {
              return (
                <option key={item} value={item}>
                  {dynamicPanelNames[item]} <Box as="span">({item})</Box>
                </option>
              );
            })}
          </Select>
          <Button onClick={handleAddText}>Add +</Button>
        </Flex>
      )}
    </Box>
  );
};

export default DynamicNameVariables;
