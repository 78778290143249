import { Box, Flex, Button, Input, Grid, IconButton, Tooltip } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-location';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import { getUserVideoPlaylists } from '_shared/dataFetching/apiService';
import { useState } from 'react';
import NewPlaylistModal from './NewPlaylistModal';
import usePageTitle from '_shared/hooks/usePageTitle';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import { handlePermissions } from '_shared/utils/utilFunctions';
import FullScreenAuthError from '_shared/errors/FullScreenAuthError';
import StickyScrollContainer from 'pages/_shared/components/StickyScroll/StickyScrollContainer';
import StickyScrollWrapper from 'pages/_shared/components/StickyScroll/StickyScrollWrapper';
import PlaylistCard from './PlaylistCard/PlaylistCard';
import {
  ListBulletOutline24,
  SearchOutline24,
  ArrowDownOutline24,
  ArrowUpOutline24,
  PlusOutline24
} from '_icons/outline/24';
import { InfoOutline48 } from '_icons/outline/48';

function compareArray(a, b, reverseSort) {
  if (Date.parse(a.modified) < Date.parse(b.modified)) {
    return reverseSort ? -1 : 1;
  }
  if (Date.parse(a.modified) > Date.parse(b.modified)) {
    return reverseSort ? 1 : -1;
  }
  return 0;
}

const VideoPlaylistHome = () => {
  const { data: userPlaylists = [] } = useQueryFetch(['user_playlists'], () =>
    getUserVideoPlaylists()
  );
  usePageTitle('Video Playlists');
  const navigate = useNavigate();
  const { data: userData = {} } = useUserDetails();
  const { permissions } = userData;
  const VCPerm = handlePermissions(10, permissions);
  const [newModalOpen, setNewModalOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [reverseSort, setReverseSort] = useState(false);

  if (!VCPerm) {
    return <FullScreenAuthError />;
  }
  const subHeaderHeight = 64;

  const filteredPlaylists = userPlaylists.filter((playlist) => {
    return playlist.playlist_name?.toLowerCase().includes(searchFilter.toLowerCase());
  });
  const sortedPlaylists = filteredPlaylists.sort((a, b) => compareArray(a, b, reverseSort));
  return (
    <StickyScrollContainer bg="black.3">
      <StickyScrollWrapper headerSize={subHeaderHeight}>
        <Flex align="center" height={`${subHeaderHeight}px`} bg="primary.shade.70" px="5">
          <Flex gap="2">
            <Tooltip label="Video search home">
              <IconButton
                icon={<SearchOutline24 />}
                variant="ghostDark"
                onClick={() => {
                  navigate({ to: `/video_search/` });
                }}
              />
            </Tooltip>
            <IconButton
              icon={<ListBulletOutline24 />}
              variant="ghostDark"
              color="primary.tint.80"
              bg="white.20"
            />
          </Flex>
          <Box flexGrow="1">Playlists</Box>
          <Flex gap="2">
            <IconButton
              icon={reverseSort ? <ArrowUpOutline24 /> : <ArrowDownOutline24 />}
              variant="ghostDark"
              onClick={() => setReverseSort((P) => !P)}
            />
          </Flex>
        </Flex>
        <Flex py="2" px="4" gap="2" minH="10" boxShadow="md">
          <Input
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
            placeholder="Search playlists"
            h="40px"
          />
          <Button
            variant="primary"
            onClick={() => setNewModalOpen(true)}
            leftIcon={<PlusOutline24 />}
            px="5"
          >
            New playlist
          </Button>
        </Flex>
      </StickyScrollWrapper>
      {sortedPlaylists.length !== 0 ? (
        <Grid templateColumns="repeat( auto-fill, minmax(250px, 1fr) )" gap="4" my="4" mx="6">
          {sortedPlaylists.map((el) => {
            return <PlaylistCard key={el.id} playlistData={el} />;
          })}
        </Grid>
      ) : (
        <Flex direction="column" alignItems="center" gap="5" mt="10">
          <InfoOutline48 color="primary.tint.80" />
          <Box mb="2" color="black.60" textAlign="center">
            No playlist data to display
          </Box>
        </Flex>
      )}
      <NewPlaylistModal formModalOpen={newModalOpen} handleClose={() => setNewModalOpen(false)} />
    </StickyScrollContainer>
  );
};

export default VideoPlaylistHome;
