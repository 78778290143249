import { Box, Flex, Tooltip } from '@chakra-ui/react';

import ButtonSelector from '_shared/components/ButtonSelector';

const videoSpeeds = [0.25, 0.5, 0.75, 1];
const videoSpeedLabels = videoSpeeds.map((el) => {
  return { value: el, label: `${el}x` };
});

const VideoEditingSpeedComponent = ({
  changeSpeed,

  editItem,
  editAtt,
  setEditAtt,
  speedVal
}) => {
  // const [speedVal, setSpeedVal] = useState(videoRef.play);
  const speedChange = (e) => {
    changeSpeed(parseFloat(e));
    // setSpeedVal(parseFloat(e));
    setEditAtt({ ...editAtt, speed: parseFloat(e) });
    editItem({ ...editAtt, speed: parseFloat(e) });
  };
  return (
    <Flex direction="column" gap="1">
      <Tooltip label="Video speed">
        <Box>
          <ButtonSelector
            handleChange={speedChange}
            value={speedVal}
            options={videoSpeedLabels}
            fontSize="sm"
            buttonMinWidth="60px"
            color="primary.main"
          />
        </Box>
      </Tooltip>
    </Flex>
  );
};

export default VideoEditingSpeedComponent;
