import { FormControl, FormErrorMessage, FormLabel, VStack } from '@chakra-ui/react';
import { Field } from 'formik';
import { Select, chakraComponents } from 'chakra-react-select';
import { ChevronDownOutline24 } from '_icons/outline/24';

const FormReactSelect = (props) => {
  const { name } = props;
  return (
    <Field name={name}>{(fieldProps) => <SelectComponent {...fieldProps} {...props} />}</Field>
  );
};

const components = {
  DropdownIndicator: (props) => (
    <chakraComponents.DropdownIndicator {...props}>
      <ChevronDownOutline24 />
    </chakraComponents.DropdownIndicator>
  )
};

const SelectComponent = ({
  field,
  form,
  name,
  label,
  placeholder,
  isDisabled,
  options,
  onChangeCustom,
  menuPlacement
}) => {
  const { setFieldValue } = form;
  const { value } = field;
  const handleChange = (value) => {
    setFieldValue(name, value);
  };

  return (
    <FormControl isInvalid={form.errors[name] && form.touched[name]} mt="3">
      <VStack alignItems="start">
        <FormLabel>{label}</FormLabel>
        <Select
          value={value}
          label={label}
          placeholder={placeholder}
          options={options}
          size="sm"
          isDisabled={isDisabled}
          useBasicStyles
          onChange={onChangeCustom || handleChange}
          chakraStyles={{
            container: (prev) => ({
              ...prev,
              width: '100%'
            }),
            menu: (prev) => ({
              ...prev,
              zIndex: 5
            })
          }}
          components={components}
          // menuPortalTarget={document.body}
          // styles={{
          //   menuPortal: (provided) => ({ ...provided, zIndex: 1500 })
          // }}
          menuPlacement={menuPlacement}
        />
      </VStack>
      <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
    </FormControl>
  );
};

export default FormReactSelect;
