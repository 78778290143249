import { Flex } from '@chakra-ui/react';
import SubHeaderInfoItem from 'pages/_shared/components/SubHeaderInfoItem';

const DimensionsInfo = ({ groundInfo }) => {
  const { dimensions_unit, dimensions_width, dimensions_length } = groundInfo;
  if (!dimensions_unit || !dimensions_width || !dimensions_length) return null;
  return (
    <SubHeaderInfoItem
      header="LENGTH X WIDTH"
      detail={`${dimensions_length}${dimensions_unit} x ${dimensions_width}${dimensions_unit}`}
    />
  );
};

const GroundDetails = ({ groundInfo }) => {
  const {
    year_founded,
    capacity,
    north_end,
    south_end,
    surface_area,
    circumference,
    wicket_angle
  } = groundInfo;

  return (
    <Flex direction="column" gap="10px">
      <Flex color="white.100" justify="space-between" gap="10px">
        <SubHeaderInfoItem header="YEAR FOUNDED" detail={year_founded} />
        <SubHeaderInfoItem header="CAPACITY" detail={parseInt(capacity)?.toLocaleString()} />
        <SubHeaderInfoItem header="NORTH END" detail={north_end} />
        <SubHeaderInfoItem header="SOUTH END" detail={south_end} />
      </Flex>
      <Flex color="white.100" justify="space-between" gap="10px">
        <DimensionsInfo groundInfo={groundInfo} />
        <SubHeaderInfoItem
          header="SURFACE AREA"
          detail={`${parseInt(surface_area)?.toLocaleString()}m2`}
        />
        <SubHeaderInfoItem header="CIRCUMFERENCE" detail={`${parseInt(circumference)}m`} />
        <SubHeaderInfoItem header="WICKET ANGLE" detail={wicket_angle} />
      </Flex>
    </Flex>
  );
};

export default GroundDetails;
