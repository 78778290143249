import { Box, Flex } from '@chakra-ui/react';

const FilterTag = ({ value, group, filterDef, border }) => {
  const elements = [];
  const { label: groupLabel } = group || {};
  const { label } = filterDef || {};
  if (groupLabel && groupLabel !== label) {
    elements.push(
      <Box key="group_label" fontWeight="700" whiteSpace="nowrap">
        {group.label}
      </Box>
    );
    elements.push(
      <Box key=">" fontWeight="700">
        {'>'}
      </Box>
    );
  }
  if (label) {
    elements.push(
      <Box key="label" fontWeight="700" whiteSpace="nowrap">
        {filterDef.label}:
      </Box>
    );
  }
  if (value) {
    if (filterDef?.options) {
      const values = [];
      const addOption = (v) => {
        const option = filterDef.options.find((el) => {
          return el.value === v;
        });
        if (option) {
          values.push(option.label);
        }
      };
      if (Array.isArray(value)) {
        value.forEach((el) => {
          addOption(el);
        });
      } else {
        addOption(value);
      }
      elements.push(<Box key="value">{values.join(', ')}</Box>);
    } else {
      elements.push(<Box key="value">{value}</Box>);
    }
  }
  const borderStyle = border
    ? {
        bg: 'white.100',
        color: 'black.76',
        borderRadius: 'md',
        border: '1px',
        borderColor: 'black.8'
      }
    : {};

  return (
    <Flex p="2" gap="1" fontSize="xs" {...borderStyle} userSelect="none">
      {elements}
    </Flex>
  );
};

export default FilterTag;
