import { Box } from '@chakra-ui/react';

const OptionStyle = ({ children }) => {
  return (
    <Box
      color="#5f6173"
      bg="black.3"
      borderRadius="md"
      border="1px solid transparent"
      _hover={{ borderColor: '#c8c8ce', color: 'text.primary', bg: 'white' }}
      _active={{ borderColor: '#ededee', color: 'text.primary', bg: 'white' }}
    >
      {children}
    </Box>
  );
};

export default OptionStyle;
