import { createIcon } from '@chakra-ui/icons';

const Error = createIcon({
  displayName: 'Error',
  viewBox: '0 0 25 25',
  defaultProps: { width: '24px', height: '24px' },
  path: [
    <path d="M12.8574 17.5C13.1408 17.5 13.3783 17.4042 13.5699 17.2125C13.7616 17.0208 13.8574 16.7833 13.8574 16.5C13.8574 16.2167 13.7616 15.9792 13.5699 15.7875C13.3783 15.5958 13.1408 15.5 12.8574 15.5C12.5741 15.5 12.3366 15.5958 12.1449 15.7875C11.9533 15.9792 11.8574 16.2167 11.8574 16.5C11.8574 16.7833 11.9533 17.0208 12.1449 17.2125C12.3366 17.4042 12.5741 17.5 12.8574 17.5ZM12.8574 13.5C13.1408 13.5 13.3783 13.4042 13.5699 13.2125C13.7616 13.0208 13.8574 12.7833 13.8574 12.5V8.5C13.8574 8.21667 13.7616 7.97917 13.5699 7.7875C13.3783 7.59583 13.1408 7.5 12.8574 7.5C12.5741 7.5 12.3366 7.59583 12.1449 7.7875C11.9533 7.97917 11.8574 8.21667 11.8574 8.5V12.5C11.8574 12.7833 11.9533 13.0208 12.1449 13.2125C12.3366 13.4042 12.5741 13.5 12.8574 13.5ZM12.8574 22.5C11.4741 22.5 10.1741 22.2375 8.95742 21.7125C7.74076 21.1875 6.68242 20.475 5.78242 19.575C4.88242 18.675 4.16992 17.6167 3.64492 16.4C3.11992 15.1833 2.85742 13.8833 2.85742 12.5C2.85742 11.1167 3.11992 9.81667 3.64492 8.6C4.16992 7.38333 4.88242 6.325 5.78242 5.425C6.68242 4.525 7.74076 3.8125 8.95742 3.2875C10.1741 2.7625 11.4741 2.5 12.8574 2.5C14.2408 2.5 15.5408 2.7625 16.7574 3.2875C17.9741 3.8125 19.0324 4.525 19.9324 5.425C20.8324 6.325 21.5449 7.38333 22.0699 8.6C22.5949 9.81667 22.8574 11.1167 22.8574 12.5C22.8574 13.8833 22.5949 15.1833 22.0699 16.4C21.5449 17.6167 20.8324 18.675 19.9324 19.575C19.0324 20.475 17.9741 21.1875 16.7574 21.7125C15.5408 22.2375 14.2408 22.5 12.8574 22.5ZM12.8574 20.5C15.0908 20.5 16.9824 19.725 18.5324 18.175C20.0824 16.625 20.8574 14.7333 20.8574 12.5C20.8574 10.2667 20.0824 8.375 18.5324 6.825C16.9824 5.275 15.0908 4.5 12.8574 4.5C10.6241 4.5 8.73242 5.275 7.18242 6.825C5.63242 8.375 4.85742 10.2667 4.85742 12.5C4.85742 14.7333 5.63242 16.625 7.18242 18.175C8.73242 19.725 10.6241 20.5 12.8574 20.5Z" fill="currentColor"/>
  ]
});

export default Error;
