import { Box } from '@chakra-ui/react';
import { Player, BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css';

const VideoPlayer = ({ videoRef, videoUrl, onVideoEnded }) => {
  return (
    <Box sx={{
      '& .video-react': {
        fontFamily: 'inherit'
      }
    }} position="relative">
      <Player
        ref={(player) => (videoRef.current = player)}
        src={videoUrl}
        controls
        autoPlay
        muted
        onEnded={onVideoEnded}
      >
        <BigPlayButton position="center" />
      </Player>
    </Box>
  );
};

export default VideoPlayer;


