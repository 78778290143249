import { Input, Button, Flex } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { addRemovePanelCategory } from '_shared/dataFetching/apiService';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

const AddNewCategory = ({ pageType, classId }) => {
  const queryClient = useQueryClient();
  const [val, setVal] = useState('');
  const { setFieldValue } = useFormikContext();

  return (
    <Flex gap="1">
      <Input
        type="text"
        placeholder="Add new panel category"
        value={val}
        onInput={(e) => setVal(e.target.value)}
      />

      <Button
        w="20%"
        onClick={async () => {
          const res = await addRemovePanelCategory('add', pageType, classId, val);
          if (res.status === 200) {
            console.log('POST SUCCESS');
            queryClient.refetchQueries(['categories', pageType]);
            const { data } = res;
            const objKey = Object.keys(data)[0];
            setFieldValue('category_type_id', objKey);
          } else {
            console.log('POST ERROR');
          }
        }}
      >
        Add +
      </Button>
    </Flex>
  );
};

export default AddNewCategory;
