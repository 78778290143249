import { CircularProgress, CircularProgressLabel, Box, Flex } from '@chakra-ui/react';
import getStatsColor from './getStatsColor';

const CircleItem = ({ data }) => {
  const value = data?.values?.primary?.display_value || 0;
  const label = data?.values?.primary?.label || '';

  return (
    <Flex justify="center" align="center" flexGrow="1">
      <CircularProgress
        value={value}
        color={getStatsColor(value)}
        trackColor="black.3"
        size="120px"
        capIsRound
        thickness="8px"
      >
        <CircularProgressLabel>
          <Box fontSize="sm" lineHeight={1} mb={2}>{label}</Box>
          <Box fontSize="lg" lineHeight={1} fontWeight="bold" color={getStatsColor(value)}>
            {value}%
          </Box>
        </CircularProgressLabel>
      </CircularProgress>
    </Flex>
  );
};

export default CircleItem;
