import { Box } from '@chakra-ui/react';

import SquadPlayers from './SquadPlayers';

const TeamPageSquadPanel = ({ panelData }) => {
  return (
    <Box direction="column" h="100%">
      <SquadPlayers panelData={panelData} isMultiSelect={true} />
    </Box>
  );
};
export default TeamPageSquadPanel;
