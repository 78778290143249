// import FormSelect from './FormSelect';
import { getClassOptions } from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import FormReactSelect from './FormReactSelect';

const FormClassSelector = ({ name, label, placeholder, onChangeCustom }) => {
  const {
    isLoading,
    error,
    data: classOptions
  } = useQueryFetch(['classOptions'], () => getClassOptions(), {
    select: (data) => {
      return Object.entries(data).map(([classId, { med }]) => {
        return {
          value: classId,
          label: med
        };
      });
    }
  });
  if (isLoading || error) {
    return null;
  }

  return (
    <FormReactSelect
      name={name}
      label={label}
      placeholder={isLoading ? 'loading...' : error ? 'formats unavailable' : placeholder}
      options={classOptions}
      onChangeCustom={onChangeCustom}
    />
  );

  // return (
  //   <FormSelect
  //     name={name}
  //     label={label}
  //     options={classOptions}
  //     onChangeCustom={onChangeCustom}
  //     placeholder={isLoading ? 'loading...' : error ? 'categories unavailable' : placeholder}
  //   />
  // );
};

export default FormClassSelector;
