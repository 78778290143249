import { Link as ChakraLink } from '@chakra-ui/react';
import { Link as LocationLink } from '@tanstack/react-location';

const RouterLink = (props) => {
  return (
    <ChakraLink as={LocationLink} {...props}>
      {props.children}
    </ChakraLink>
  );
};

export default RouterLink;
