import { createIcon } from '@chakra-ui/icons';

const Refresh = createIcon({
  displayName: 'refresh',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M10 16C8.33333 16 6.90633 15.4097 5.719 14.229C4.53167 13.0483 3.95867 11.597 4 9.875L2.812 11.062L1.75 10L4.75 7L7.75 10L6.688 11.062L5.5 9.875C5.472 11.1803 5.90267 12.2777 6.792 13.167C7.68067 14.0557 8.75 14.5 10 14.5C10.3053 14.5 10.597 14.4723 10.875 14.417C11.153 14.361 11.424 14.2777 11.688 14.167L12.792 15.271C12.3753 15.493 11.9343 15.67 11.469 15.802C11.0037 15.934 10.514 16 10 16ZM15.25 13L12.25 10L13.312 8.938L14.5 10.125C14.528 8.81967 14.0973 7.72233 13.208 6.833C12.3193 5.94433 11.25 5.5 10 5.5C9.708 5.5 9.41967 5.52767 9.135 5.583C8.85033 5.639 8.576 5.72233 8.312 5.833L7.208 4.729C7.62467 4.507 8.06933 4.33 8.542 4.198C9.014 4.066 9.5 4 10 4C11.6807 4 13.1113 4.59033 14.292 5.771C15.472 6.95167 16.0413 8.403 16 10.125L17.188 8.938L18.25 10L15.25 13Z"
      fill="currentColor"
    />
  )
});

export default Refresh;
