import { ADVANCED_VISUALISATIONS } from '_shared/globalState/atoms';
import { useRecoilState } from 'recoil';
import FilterGroup from './FilterGroup';

const formElement = [
  {
    form_type: 'multiselect_checkbox',
    label: null,
    name: 'visualisations',
    options: [
      {
        value: 'wagon_wheel',
        label: 'Wagon wheel'
      },
      {
        value: 'beehive',
        label: 'Beehive'
      },
      {
        value: 'pitchmap',
        label: 'Pitchmap'
      }
    ]
  }
];

const Visualisations = () => {
  const [visualisations, setVisualisations] = useRecoilState(ADVANCED_VISUALISATIONS);

  const handleFilterChange = (_, value) => {
    setVisualisations(value || []);
  };

  return (
    <FilterGroup
      isActive={visualisations?.length !== 0}
      label="Visualisations"
      elements={formElement}
      handleFilterChange={handleFilterChange}
      key="visualisations"
      filterValues={{ visualisations }}
    />
  );
};

export default Visualisations;
