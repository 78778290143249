import dayjs from 'dayjs';
import { dateTypeOptions } from './formConfig';

export const checkNoExisitngConfig = (data) => {
  return (
    data.user_class_id !== null && data.user_date_range !== null && data.user_home_team !== null
  );
};

export const generatePayload = (values) => {
  const { class_id, between_dates, team_id, custom_date_start, custom_date_end } = values;
  const payload = {};
  if (team_id) {
    payload.team_id = team_id.value;
  }
  if (between_dates?.value !== 'custom') {
    payload.between_dates = between_dates.value;
  }
  if (between_dates?.value === 'custom') {
    if (custom_date_start === null && custom_date_end === null) {
      payload.between_dates = 'all';
    } else {
      const customStart = custom_date_start ? dayjs(custom_date_start).format('YYYY-MM-DD') : '';
      const customEnd = custom_date_end ? dayjs(custom_date_end).format('YYYY-MM-DD') : '';
      const dateString = `${customStart}~${customEnd}`;
      payload.between_dates = dateString;
    }
  }

  if (class_id) {
    payload.class_id = class_id.value;
  }
  return payload;
};

export const getDateValue = (user_date_range) => {
  const dateValues = {
    between_dates: dateTypeOptions.find((el) => el.value === user_date_range) || {
      label: 'Last 12 months',
      value: 'last_12_months'
    },
    custom_date_start: null,
    custom_date_end: null
  };
  if (dateValues.between_dates.value === 'custom' && user_date_range) {
    const [start, end] = user_date_range.split('~');
    dateValues.custom_date_start = start === '' ? null : new Date(start);
    dateValues.custom_date_end = end === '' ? null : new Date(end);
  }
  return dateValues;
};
