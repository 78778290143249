import { Box } from '@chakra-ui/react';
import { Formik } from 'formik';
import FormLayout from 'Forms/_shared/components/FormLayout';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { updateUserConfig } from '_shared/dataFetching/apiService';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import UserConfigForm from './UserConfigForm';
import { generatePayload, getDateValue } from './utils';

const UserConfig = ({ handleClose }) => {
  const queryClient = useQueryClient();
  const [formSubmitError, setFormSubmitError] = useState(null);
  const { data, isLoading, error } = useUserDetails();

  if (isLoading) return null;
  if (error) return <Box>{error}</Box>;

  const {
    user_class_id,
    user_class_name_medium,
    user_home_team_id,
    user_home_team_name,
    user_date_range
  } = data;
  const dateValues = getDateValue(user_date_range);
  const initialValues = {
    team_id: { value: user_home_team_id, label: user_home_team_name },
    ...dateValues,
    class_id:
      user_class_id && user_class_name_medium
        ? { value: user_class_id?.toString(), label: user_class_name_medium || 'Test' }
        : null
  };

  return (
    <Box>
      <Box fontWeight="700" fontSize="xl" mb="5">
        Set your preferences
      </Box>
      <Box color="black.60">
        Fill out the fields below to tailor Centurion to your desired settings.
      </Box>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          setFormSubmitError(null);
          const errors = {};
          if (!values.team_id) {
            errors.team_id = 'Please select format';
          }
          if (!values.between_dates) {
            errors.between_dates = 'Please default date range';
          }
          if (!values.class_id) {
            errors.class_id = 'Please select home team';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const payload = generatePayload(values);
          const res = await updateUserConfig(payload);
          console.log({ payload, response: res });
          if (res.status === 200) {
            queryClient.refetchQueries(['user']);
            queryClient.refetchQueries(['home']);
            handleClose();
          } else {
            setFormSubmitError(res.data);
          }
          setSubmitting(false);
        }}
      >
        {(props) => (
          <FormLayout buttonText="Save" onCancel={handleClose} {...props}>
            <UserConfigForm {...props} formSubmitError={formSubmitError} />
          </FormLayout>
        )}
      </Formik>
    </Box>
  );
};

export default UserConfig;
