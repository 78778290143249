import { Flex, Box, ListItem, UnorderedList } from '@chakra-ui/react';
import RadarComponent from 'content/_components/radar/RadarComponent';
import usePlayerSkillsSearch from './usePlayerSkillsSearch';
import PlayerSearch from './PlayerSearch';
import { useMatch, useSearch } from '@tanstack/react-location';
import { useState } from 'react';
import Header from '../_components/header/Header';
import HeaderInfo from '../_components/header/HeaderInfo';
import NoDataDisplay from 'panels/Panel/NoDataDisplay';

const PlayerSkillsContent = ({ content, objectVariable }) => {
  const {
    params: { objectId }
  } = useMatch();
  const { playerData, setSelectedValues, ...searchValues } = usePlayerSkillsSearch(
    objectVariable,
    objectId
  );
  const search = useSearch();
  const { tab } = search;
  const { key, header } = content;
  const [infoOpen, setInfoOpen] = useState(false);

  const handleClick = (value) => {
    setSelectedValues((p) => {
      return [...p, value];
    });
  };

  const handleKeyClick = (value) => {
    setSelectedValues((prevState) => prevState.filter((id) => id !== value));
  };

  const noData = content?.data?.[0]?.data?.length === 0;

  const displayData = [...(content?.data || []), ...(playerData || [])];

  return (
    <Box>
      <Header
        title={header}
        showInfoButton={!noData}
        infoOpen={infoOpen}
        setInfoOpen={setInfoOpen}
      />
      {noData ? (
        <NoDataDisplay message="No player data available" />
      ) : (
        <Box position="relative">
          <PlayerSearch
            {...searchValues}
            handleClick={handleClick}
            disabled={playerData?.length >= 2}
          />
          <RadarComponent
            data={displayData}
            handleKeyClick={handleKeyClick}
            disableClickFirstItem
          />
          <HeaderInfo infoOpen={infoOpen}>
            <Info key={`${key}_info`} tab={tab} objectVariable={objectVariable} />
          </HeaderInfo>
        </Box>
      )}
    </Box>
  );
};

const Info = ({ tab, objectVariable }) => {
  let message = null;

  if (tab === 'profile') {
    if (objectVariable === 'batter') {
      message = (
        <Box>
          <Box>
            Player Skills is our unique Data Science Model which ranks batters out of 100 across key
            performance metrics.
          </Box>
          <br />
          <Box>This advanced tool provides insights into:</Box>
          <br />
          <Flex justifyContent={'center'}>
            <Box textAlign={'left'}>
              <UnorderedList>
                <ListItem>
                  <b>V Spin</b> : Performance against spin bowling
                </ListItem>
                <ListItem>
                  <b>V Pace</b> : Performance against pace bowling
                </ListItem>
                <ListItem>
                  <b>Power</b> : Batting strength and hitting power
                </ListItem>
                <ListItem>
                  <b>Powerplay</b> : Effectiveness during the powerplay overs
                </ListItem>
                <ListItem>
                  <b>Middle</b> : Consistency and scoring in the middle overs
                </ListItem>
                <ListItem>
                  <b>Death</b> : Performance in the final overs
                </ListItem>
                <ListItem>
                  <b>Scoring Rate</b> : Overall scoring efficiency
                </ListItem>
                <ListItem>
                  <b>Wicket Retention</b> : Ability to avoid getting out
                </ListItem>
              </UnorderedList>
            </Box>
          </Flex>
          <br />
          <Box>
            Additionally, our model allows you to compare up to three players side-by-side, offering
            a comprehensive analysis of their skills and performance.
          </Box>
        </Box>
      );
    } else if (objectVariable === 'bowler') {
      message = (
        <Box>
          <Box>
            Player Skills is our unique Data Science Model which ranks bowlers out of 100 across key
            performance metrics.
          </Box>
          <br />
          <Box>This advanced tool provides insights into:</Box>
          <br />
          <Flex justifyContent={'center'}>
            <Box textAlign={'left'}>
              <UnorderedList>
                <ListItem>
                  <b>V RHB</b> : Performance against Right Hand batters
                </ListItem>
                <ListItem>
                  <b>V LHB</b> : Performance against Left Hand batters
                </ListItem>
                <ListItem>
                  <b>Powerplay</b> : Effectiveness during the powerplay overs
                </ListItem>
                <ListItem>
                  <b>Middle</b> : Effectiveness in the middle overs
                </ListItem>
                <ListItem>
                  <b>Death</b> : Effectiveness in the final overs
                </ListItem>
                <ListItem>
                  <b>Minimise Runs</b> : Ability to bowl economically
                </ListItem>
                <ListItem>
                  <b>Wicket Threat</b> : Potency of taking wickets
                </ListItem>
              </UnorderedList>
            </Box>
          </Flex>
          <br />
          <Box>
            Additionally, our model allows you to compare up to three players side-by-side, offering
            a comprehensive analysis of their skills and performance.
          </Box>
        </Box>
      );
    }
  }

  return <Box>{message}</Box>;
};

export default PlayerSkillsContent;
