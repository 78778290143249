import { BETA_SELECT } from '_shared/globalState/atoms';
import { useRecoilValue } from 'recoil';

const BetaSwitch = ({ children, beta }) => {
  const betaSelect = useRecoilValue(BETA_SELECT);
  if (betaSelect && beta) return beta;
  return children;
};

export default BetaSwitch;
