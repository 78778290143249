import { Tab, Tabs, TabList, TabPanels, TabPanel, Center, Spinner, Box } from '@chakra-ui/react';
import { getObjectInformation } from '_shared/dataFetching/apiService';
import PlayerHomePageLists from './PlayerHomePageLists';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';

const PlayerHomePage = () => {
  const pageType = 'recent_player_performances';
  const objectType = '';
  const {
    isLoading: objectIsLoading,
    error: objectError,
    data: objectData,
    isFetching: objectIsFetching
  } = useQueryFetch([pageType, objectType], () => getObjectInformation(pageType, objectType));
  if (objectIsLoading) {
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    );
  }
  if (objectError && !objectIsFetching) {
    return <Box>{`Unable to retrieve player data`}</Box>;
  }
  if (objectData) {
    const { class_ids, player_imgs, standard } = objectData;
    return (
      <Tabs>
        <TabList variant="enclosed" h="full">
          {Object.keys(class_ids)
            .filter((el) => standard.batting[el].length > 0)
            .map((el) => {
              return <Tab key={`${el}-batting`}>{class_ids[el].med}</Tab>;
            })}
        </TabList>
        <TabPanels>
          {Object.keys(class_ids)
            .filter((el) => standard.batting[el].length > 0)
            .map((el) => {
              return (
                <TabPanel key={`${el}-bowling`}>
                  <PlayerHomePageLists
                    classIds={class_ids}
                    playerImgs={player_imgs}
                    standard={standard}
                    selectedClass={el}
                  />
                </TabPanel>
              );
            })}
        </TabPanels>
      </Tabs>
    );
  }
};

export default PlayerHomePage;
