import React from 'react';
import { Table, Tbody, Tr, Td, Box } from '@chakra-ui/react';
import { Link } from '@tanstack/react-location';
import get from 'lodash.get';
import { getBatterImpactAvailable, partnershipString } from './utils';
import TableHeader from '../_shared/TableHeader';

const getColumns = (showImpact) => {
  return [
    { content: 'Batter' },
    { content: '' },
    { content: 'R', isNumeric: true, tooltip: 'Runs' },
    { content: 'B', isNumeric: true, tooltip: 'Balls' },
    { content: '4s', isNumeric: true, tooltip: 'Fours' },
    { content: '6s', isNumeric: true, tooltip: 'Sixes' },
    { content: 'SR', isNumeric: true, tooltip: 'Strike Rate' },
    { content: 'XR', isNumeric: true, tooltip: 'Expected Runs' },
    { content: 'XFS', isNumeric: true, tooltip: 'Expected False Shots' },
    ...(showImpact
      ? [
          {
            content: 'IMP',
            isNumeric: true,
            tooltip: 'Impact'
          }
        ]
      : []),
    {
      content: 'M',
      isNumeric: true,
      tooltip: 'Matches',
      style: { borderLeft: '1px', borderLeftColor: 'grey.100' }
    },
    { content: 'R', isNumeric: true, tooltip: 'Runs' },
    { content: 'HS', isNumeric: true, tooltip: 'High Score' },
    {
      content: 'Ave',
      isNumeric: true,
      tooltip: 'Average',
      style: { borderRight: '1px', borderRightColor: 'grey.100' }
    }
  ];
};

const BatterTableRow = ({ batter, scorecardData }) => {
  const { player_id, batting_hand, runs, balls_faced, fours, sixes, xR_diff } = batter;
  const batterName = get(scorecardData, `players[${player_id}].name`);
  const batterCareer = get(scorecardData, `players[${player_id}].career`) || {};
  const batterImpact = get(scorecardData, `impact[${player_id}].batting_impact`);
  const { matches, runs: careerRuns, high_score, batting_average } = batterCareer;
  return (
    <Tr>
      <Td>
        <Link to={`/player/${player_id}`}>{batterName}</Link>
      </Td>
      <Td>
        <Box
          as="span"
          color="white"
          fontSize="xs"
          p="0.5"
          rounded="sm"
          fontWeight="bold"
          bg={
            batting_hand === 'RHB'
              ? 'cricket.RH'
              : batting_hand === 'LHB'
              ? 'cricket.LH'
              : undefined
          }
        >
          {batting_hand}
        </Box>
      </Td>
      <Td isNumeric>{runs}</Td>
      <Td isNumeric>{balls_faced}</Td>
      <Td isNumeric>{fours}</Td>
      <Td isNumeric>{sixes}</Td>
      <Td isNumeric>
        {runs > 0 && balls_faced > 0 ? (100 * (runs / balls_faced)).toFixed(2) : null}
      </Td>
      <Td isNumeric color={xR_diff > 0 ? 'positive' : 'negative'}>
        {xR_diff}
      </Td>
      <Td isNumeric></Td>
      {batterImpact && (
        <Td isNumeric color={batterImpact > 0 ? 'positive' : 'negative'}>
          {batterImpact}
        </Td>
      )}
      <Td isNumeric borderLeft="1px" borderLeftColor="grey.100">
        {matches}
      </Td>
      <Td isNumeric>{careerRuns}</Td>
      <Td isNumeric>{high_score}</Td>
      <Td isNumeric borderRight="1px" borderRightColor="grey.100">
        {batting_average}
      </Td>
    </Tr>
  );
};

const PartnershipTableRow = ({ partnership }) => {
  const {
    fow_wickets,
    partnership_runs,
    partnership_balls,
    partnership_fours,
    partnership_sixes,
    xr_diff
  } = partnership;
  return (
    <Tr>
      <Td colSpan={2}>Partnership ({partnershipString(fow_wickets)})</Td>
      <Td isNumeric>{partnership_runs}</Td>
      <Td isNumeric>{partnership_balls ? partnership_balls : null}</Td>
      <Td isNumeric>{partnership_fours ? partnership_fours : null}</Td>
      <Td isNumeric>{partnership_sixes ? partnership_sixes : null}</Td>
      <Td isNumeric>
        {partnership_runs > 0 && partnership_balls > 0
          ? (100 * (partnership_runs / partnership_balls)).toFixed(2)
          : null}
      </Td>
      <Td isNumeric color={xr_diff > 0 ? 'positive' : 'negative'}>
        {xr_diff}
      </Td>
      <Td isNumeric></Td>
      <Td isNumeric></Td>
    </Tr>
  );
};

const LiveBatterTable = ({ liveBatters, livePartnership, scorecardData }) => {
  const showImpact = getBatterImpactAvailable(liveBatters, scorecardData);
  return (
    <Table size="sm" w="min-content" variant="striped" whiteSpace="nowrap">
      <TableHeader columns={getColumns(showImpact)} />
      <Tbody>
        {liveBatters.map((batter) => (
          <BatterTableRow key={batter.player_id} batter={batter} scorecardData={scorecardData} />
        ))}
        {livePartnership.map((partnership, idx) => (
          <PartnershipTableRow key={idx} partnership={partnership} />
        ))}
      </Tbody>
    </Table>
  );
};

export default LiveBatterTable;
