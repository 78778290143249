import { Box, Collapse, Flex } from '@chakra-ui/react';
import { ChevronDownOutline24 } from '_icons/outline/24';

const FilterLabel = ({
  name,
  label,
  isActive,
  isExpanded,
  setIsExpanded,
  isAccordion,
  children
}) => {
  const handleClick = () => {
    setIsExpanded((p) => {
      if (p === name) {
        return null;
      }
      return name;
    });
  };

  if (isAccordion) {
    return (
      <Box
        bg={isExpanded || isActive ? 'white.100' : 'black.3'}
        borderRadius="md"
        border="1px"
        borderColor={isExpanded || isActive ? 'black.8' : 'transparent'}
        _hover={{ borderColor: 'black.24', bg: 'white.100' }}
      >
        <Flex
          px="4"
          py="3"
          aling="center"
          justify="space-between"
          cursor="pointer"
          onClick={handleClick}
        >
          <Flex align="center" gap="1" fontSize="sm" fontWeight="700">
            <Box>{label}</Box>
            {isActive ? <Box color="primary.main">•</Box> : null}
          </Flex>
          <ChevronDownOutline24 />
        </Flex>
        <Collapse in={isExpanded}>
          <Box px="4" py="3" my="1px">
            {children}
          </Box>
        </Collapse>
      </Box>
    );
  }
  return (
    <Flex direction="column" gap="3">
      <Box fontSize="sm" fontWeight="700">
        {label}
      </Box>
      <Box>{children}</Box>
    </Flex>
  );
};

export default FilterLabel;
