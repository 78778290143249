import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { useState } from 'react';
import Header from '../_components/header/Header';
import HeaderInfo from '../_components/header/HeaderInfo';
import getContentConfig from '../contentConfig/getContentConfig';
import ButtonSelector from '_shared/components/ButtonSelector';
import RankingTable from './RankingTable';
import RankingLeader from './RankingLeader';
import { InfoOutline48 } from '_icons/outline/48';

const RankingContent = ({ content }) => {
  const { data, key, header, filters } = content;
  const { table_data = [], table_header = [] } = data;
  const defaultPrimaryValue = filters?.primary?.[0]?.value;
  const [infoOpen, setInfoOpen] = useState(false);
  const [primaryFilter, setPrimaryFilter] = useState(defaultPrimaryValue);

  const rankingInfoText = getContentConfig(key, 'rankingInfoText');

  const rankingItems = table_data
    .filter((el) => {
      return checkFilter(el, primaryFilter);
    })
    .map((el) => {
      const { player_id, team_id, team_name, name, logo_url, player_img } = el;
      const newValue = { ...el };
      newValue.name = {};
      if (name) newValue.name.primary = name;
      if (team_name) newValue.name.primary = team_name;
      if (player_id) newValue.id = player_id;
      if (team_id) newValue.id = team_id || team_id;
      if (logo_url) newValue.img = logo_url;
      if (player_img) newValue.img = player_img;
      return newValue;
    });

  const rankingLeader = rankingItems.slice(0, 1)[0];
  const rankingTableItems = rankingItems.slice(1);

  return (
    <Box mb="1">
      <Header
        title={header}
        showInfoButton={!!rankingInfoText}
        infoOpen={infoOpen}
        setInfoOpen={setInfoOpen}
      />
      <Box position="relative">
        <Box px="4" minH="60px">
          {filters?.primary && filters.primary.length !== 0 && (
            <Box flexGrow={filters.primary.length} pb={4}>
              <ButtonSelector
                fullWidth
                value={primaryFilter}
                handleChange={setPrimaryFilter}
                options={filters.primary}
              />
            </Box>
          )}
        </Box>
        <Grid gridTemplateColumns={rankingItems.length === 0 ? '1fr' : '1fr 1fr'}>
          {rankingItems.length === 0 ? (
            <Flex alignItems="center" justifyContent="center" textAlign="center">
              <Box py={10}>
                <InfoOutline48 color="primary.tint.80" mb={1} />
                <Text>No data available</Text>
              </Box>
            </Flex>
          ) : (
            <>
              <Flex borderTop="1px" borderRight="1px" borderColor="black.8">
                <RankingLeader data={rankingLeader} tableHeader={table_header} />
              </Flex>
              <Box>
                <RankingTable tableData={rankingTableItems} tableHeader={table_header} />
              </Box>
            </>
          )}
        </Grid>
        <HeaderInfo infoOpen={infoOpen}>
          {rankingInfoText && <Text key={`${key}_info`}>{rankingInfoText}</Text>}
        </HeaderInfo>
      </Box>
    </Box>
  );
};

export default RankingContent;

function checkFilter(el, filterValue) {
  return !el.filters || el.filters.primary === filterValue;
}
