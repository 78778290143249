import { Box, Flex, IconButton, Text, Button } from '@chakra-ui/react';
import ChevronLeft from '_icons/outline/24/ChevronLeft';
import ChevronRight from '_icons/outline/24/ChevronRight';

const VideoHeader = ({ vidInd, setVidInd, data, videoRef, videoSearchUrl/* , navigate*/ }) => {

  const multipleVideos = data?.results?.length > 1;
  const searchJustify = multipleVideos ? 'space-between' : 'center';

  return (
    <Flex direction="column">
      {multipleVideos && 
        <Flex direction="column" alignItems="center" justifyContent="center" height="40px" borderBottom="1px" borderBottomColor="black.3">
          <Text fontSize="12px" color="black.76" userSelect="none">
            Video {vidInd + 1} of {data?.results?.length}
          </Text>
        </Flex>
      }
      {(multipleVideos || videoSearchUrl) && 
        <Flex padding={4} gap={3} justifyContent={searchJustify} alignItems="center">
          <Box>
            {videoSearchUrl &&
              <Button
                variant={'tertiary'}
                h="44px"
                fontSize="sm"
                fontWeight="bold"
                // onClick={() => navigate({ to: videoSearchUrl })}
                onClick={() => {
                  videoRef?.current?.actions?.pause();
                  window.open(videoSearchUrl, '_blank');
                }}
              >
                View in Video Centre
              </Button>
            }
          </Box>
          {multipleVideos &&
            <Box>
              <IconButton
                icon={<ChevronLeft />}
                onClick={() => setVidInd(vidInd > 0 ? vidInd - 1 : data?.results?.length - 1)}
                variant="ghost"
                m={1}
                w="44px"
                h="44px"
                disabled={vidInd === 0}
                cursor="pointer"
              />
              <IconButton
                icon={<ChevronRight />}
                onClick={() => setVidInd(vidInd < data?.results?.length - 1 ? vidInd + 1 : 0)}
                variant="ghost"
                m={1}
                w="44px"
                h="44px"
                disabled={vidInd === data?.results?.length - 1}
                cursor="pointer"
              />
            </Box>
          }
        </Flex>
      }
    </Flex>
  );
};

export default VideoHeader;


