import career_statistics_table from './career_statistics_table';
import player_skills_player_skills from './player_skills_player_skills';
import form_last_10_innings_general_stats from './form_last_10_innings_general_stats';
import last_10_innings_table from './last_10_innings_table';

const configDefs = {
  career_statistics_table,
  player_skills_player_skills,
  form_last_10_innings_general_stats,
  form_last_10_innings_bar: form_last_10_innings_general_stats,
  last_10_innings_table
};

export default configDefs;
