import { createIcon } from '@chakra-ui/icons';

const Loop = createIcon({
  displayName: 'Loop',
  viewBox: '0 0 24 24',
  defaultProps: { width: '24px', height: '24px' },
  path: (
    <path
      d="M6 12.05C6 12.8 6.14167 13.5292 6.425 14.2375C6.70833 14.9458 7.15 15.6 7.75 16.2L8 16.45V15C8 14.7167 8.09583 14.4792 8.2875 14.2875C8.47917 14.0958 8.71667 14 9 14C9.28333 14 9.52083 14.0958 9.7125 14.2875C9.90417 14.4792 10 14.7167 10 15V19C10 19.2833 9.90417 19.5208 9.7125 19.7125C9.52083 19.9042 9.28333 20 9 20H5C4.71667 20 4.47917 19.9042 4.2875 19.7125C4.09583 19.5208 4 19.2833 4 19C4 18.7167 4.09583 18.4792 4.2875 18.2875C4.47917 18.0958 4.71667 18 5 18H6.75L6.35 17.65C5.48333 16.8833 4.875 16.0083 4.525 15.025C4.175 14.0417 4 13.05 4 12.05C4 10.4833 4.4 9.0625 5.2 7.7875C6 6.5125 7.075 5.53333 8.425 4.85C8.65833 4.71667 8.90417 4.70833 9.1625 4.825C9.42083 4.94167 9.59167 5.13333 9.675 5.4C9.75833 5.65 9.75417 5.9 9.6625 6.15C9.57083 6.4 9.40833 6.59167 9.175 6.725C8.20833 7.25833 7.4375 7.99583 6.8625 8.9375C6.2875 9.87917 6 10.9167 6 12.05ZM18 11.95C18 11.2 17.8583 10.4708 17.575 9.7625C17.2917 9.05417 16.85 8.4 16.25 7.8L16 7.55V9C16 9.28333 15.9042 9.52083 15.7125 9.7125C15.5208 9.90417 15.2833 10 15 10C14.7167 10 14.4792 9.90417 14.2875 9.7125C14.0958 9.52083 14 9.28333 14 9V5C14 4.71667 14.0958 4.47917 14.2875 4.2875C14.4792 4.09583 14.7167 4 15 4H19C19.2833 4 19.5208 4.09583 19.7125 4.2875C19.9042 4.47917 20 4.71667 20 5C20 5.28333 19.9042 5.52083 19.7125 5.7125C19.5208 5.90417 19.2833 6 19 6H17.25L17.65 6.35C18.4667 7.16667 19.0625 8.05417 19.4375 9.0125C19.8125 9.97083 20 10.95 20 11.95C20 13.5167 19.6 14.9375 18.8 16.2125C18 17.4875 16.925 18.4667 15.575 19.15C15.3417 19.2833 15.0958 19.2917 14.8375 19.175C14.5792 19.0583 14.4083 18.8667 14.325 18.6C14.2417 18.35 14.2458 18.1 14.3375 17.85C14.4292 17.6 14.5917 17.4083 14.825 17.275C15.7917 16.7417 16.5625 16.0042 17.1375 15.0625C17.7125 14.1208 18 13.0833 18 11.95Z"
      fill="currentColor"
    />
  )
});

export default Loop;
