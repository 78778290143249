import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

// format string as per dayjs docs
// https://day.js.org/docs/en/display/format

export const parseDateTimeUTC = (dateTime, format) => {
  return dayjs.utc(dateTime).format(format);
};

export const parseDateTimeLocal = (dateTime, format) => {
  return dayjs.utc(dateTime).local().format(format);
};

export const getLocalTimeZone = () => {
  return dayjs.tz.guess();
};
