import bowlerTypes from '_shared/definitions/bowlerTypes';

export const checkBowlerHandedness = (bowlerType) => {
  if (bowlerTypes.right.fast.includes(bowlerType) || bowlerTypes.right.spin.includes(bowlerType)) {
    return 'right';
  }
  if (bowlerTypes.left.fast.includes(bowlerType) || bowlerTypes.left.spin.includes(bowlerType)) {
    return 'left';
  }
};

export const getUserInitials = (user_info) => {
  let initials = '';
  if (user_info.full_name) {
    const full_name = user_info.full_name.trim();
    const nameArray = full_name.split(' ');
    if (nameArray.length > 0) {
      initials += nameArray[0][0].toUpperCase();
    }
    if (nameArray.length > 1) {
      initials += nameArray[nameArray.length - 1][0].toUpperCase();
    }
  }
  return initials;
};

export const getFavouritesIds = (userFavourites, favType) => {
  const ids = [];
  let favList = [];
  if (userFavourites.hasOwnProperty(favType)) {
    favList = userFavourites[favType];
  }
  favList.forEach((el) => {
    ids.push(el.id.toString());
  });
  return ids;
};

export const organiseFavourites = (favIdxList, initialIdxList, data) => {
  const favIds = [];
  favIdxList.forEach((listIdx) => {
    if (!initialIdxList.includes(listIdx)) {
      favIds.push(data[listIdx].ball_id);
    }
  });
  return favIds;
};

export const getClassIdPanels = (panelList) => {
  const returnArr = {};
  if (panelList.length === 0) {
    return {};
  }
  panelList.forEach((el) => {
    if (el.class_id && !returnArr[el.class_id] && el.edit_classes && el.edit_classes[el.class_id]) {
      returnArr[el.class_id] = el.edit_classes[el.class_id].medium_name;
    }
  });
  if (Object.keys(returnArr).length > 1) {
    return returnArr;
  }
  return {};
};

export const createUUID = (
  charSet = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']
) => {
  let uuid = '';
  while (uuid.length < 13) {
    const rand = Math.floor(Math.random() * charSet.length);
    uuid += charSet[rand];
  }
  return uuid;
};

export const getDateRange = (minDate, maxDate) => {
  if (!minDate && !maxDate) {
    return null;
  } else if (minDate && !maxDate) {
    return `${minDate.toISOString().split('T')[0]}~`;
  } else if (maxDate && !minDate) {
    return `~${maxDate.toISOString().split('T')[0]}`;
  } else if (minDate > maxDate) {
    return `${maxDate.toISOString().split('T')[0]}~${minDate.toISOString().split('T')[0]}`;
  }
  return `${minDate.toISOString().split('T')[0]}~${maxDate.toISOString().split('T')[0]}`;
};

export const manageCategoryOptions = (optionsData) => {
  const vals = Object.keys(optionsData);
  if (vals.length === 0) {
    return [];
  }
  const returnArr = [];
  vals.forEach((objectKey) => {
    returnArr.push({ label: optionsData[objectKey].category_name, value: objectKey });
  });

  return returnArr;
};

export const fileDownload = (data, type, fileName = 'data') => {
  const blob = new Blob([data], { type });
  const href = URL.createObjectURL(blob);
  // create "a" HTLM element with href to file
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const downloadData = (fetchedData) => {
  const { data, meta, panel_name_variables, api_reference, panel_name } = fetchedData;
  const possibleNames = panel_name_variables ? Object.values(panel_name_variables) : [];
  let varNameInPanel = false;
  for (let k = 0; k < possibleNames.length; k += 1) {
    if (panel_name.includes(possibleNames[k])) {
      varNameInPanel = true;
    }
  }
  const fileName = varNameInPanel ? panel_name : `${document.title} - ${panel_name}`;
  // create file in browser
  if (meta && data.length > 0) {
    const objKeys = Object.keys(data[0]);
    const metaKeys = Object.keys(meta);
    for (let k = 0; k < Object.keys(metaKeys).length; k += 1) {
      if (!objKeys.includes(metaKeys[k])) {
        delete meta[metaKeys[k]];
      }
    }
  }
  const json = JSON.stringify({ data, meta, api_reference, panel_name }, null, 2);
  fileDownload(json, 'application/json', `${fileName}.json`);
  return true;
};

export const downloadVideoPlaylistData = (fetchedData, data) => {
  const { playlistName, user, video_resolution } = data;
  const json = JSON.stringify(
    { username: user, playlist: playlistName, resolution: video_resolution, videos: fetchedData },
    null,
    2
  );
  const blob = new Blob([json], { type: 'application/json' });
  const href = URL.createObjectURL(blob);
  // create "a" HTLM element with href to file
  const link = document.createElement('a');
  link.href = href;
  link.download = `${playlistName}.json`;
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
  return true;
};

export const convert64ToJSON = (val) => {
  const decoded = atob(val);
  const jsonObj = JSON.parse(decoded);
  return jsonObj;
};

export const convertJSONTo64 = (val) => {
  const stringObj = JSON.stringify(val);
  const base64Obj = btoa(stringObj);
  return base64Obj;
};

export const VideoResultsConvertScoring = (scoringVal) => {
  if (String(scoringVal).includes('W') || String(scoringVal).includes('w')) {
    return 'wickets';
  } else if (String(scoringVal).includes('&#8226') || scoringVal === 0) {
    return 'dots';
  } else if ([1, 2, 3].includes(parseInt(scoringVal))) {
    return 'twosthrees';
  } else if ([parseInt(scoringVal) < 6]) {
    return 'fours';
  }

  return 'sixes';
};

export const setTopScroll = (idx) => {
  const gridItem = document.getElementById(`video-result-${idx}`);
  const topPos = gridItem.offsetTop;
  document.getElementById('scrolling_div').scrollTop = topPos - 120;
};

export const stringifyJson = (jsonObj) => {
  const stringObj = JSON.stringify(jsonObj);
  const base64Obj = btoa(stringObj);
  return base64Obj;
};
export const returnFilters = (jsonObj, rawFilters) => {
  const filters = {};
  rawFilters.forEach((el) => {
    filters[el.api_tag] = el.options;
  });

  const returnF = {};
  for (let k = 0; k < Object.keys(jsonObj).length; k += 1) {
    const key = Object.keys(jsonObj)[k];
    const val = jsonObj[Object.keys(jsonObj)[k]];
    if (Array.isArray(val)) {
      returnF[key] = filters[key].filter((innerEl) => val.includes(innerEl.value));
    } else if (typeof val === 'object') {
      returnF[key] = val;
    } else if (val.includes('~')) {
      const min = val.split(`~`)[0];
      const max = val.split(`~`)[1];
      returnF[key] = { min, max };
    } else {
      returnF[key] = val;
    }
  }
  return returnF;
};

export const handlePermissions = (permId, permList) => {
  const permsAvailable = [];
  permList.forEach((el) => {
    permsAvailable.push(el.permission_id);
  });
  if (permsAvailable.includes(1) || permsAvailable.includes(permId)) {
    return true;
  }
  return false;
};

const buildCSVString = (downloadData) => {
  const { data_columns, data, meta } = downloadData;
  if (!data_columns || !data) return;
  const csvRows = [];
  const headerValues = [];
  data_columns.forEach((col) => {
    if (meta?.[col]?.heading) {
      headerValues.push(meta?.[col]?.heading);
    } else {
      headerValues.push(col);
    }
  });
  csvRows.push(headerValues.join(','));
  data.forEach((row) => {
    const rowValues = [];
    data_columns.forEach((col) => {
      if (row?.[col]) {
        rowValues.push(row[col]);
      } else {
        rowValues.push('');
      }
    });
    csvRows.push(rowValues.join(','));
  });
  return csvRows.join('\n');
};

export const downloadCSV = (panelData) => {
  const { panel_name } = panelData;
  const csvString = buildCSVString(panelData);
  fileDownload(csvString, 'text/csv', `${panel_name}.csv` || 'download.csv');
};
