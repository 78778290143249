import { Box, Flex, Spinner, Tooltip, IconButton } from '@chakra-ui/react';
import { useState } from 'react';
import { getPlaylistData } from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import { useMatch, useNavigate } from '@tanstack/react-location';
import VideoPlaylistContainer from './VideoPlaylistContainer';

import { handlePermissions } from '_shared/utils/utilFunctions';
import FullScreenAuthError from '_shared/errors/FullScreenAuthError';
import VideoCentreError from '_shared/errors/VideoCentreError';

import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import { ArrowLeftOutline24, CheckboxUncheckedOutline24, SearchOutline24 } from '_icons/outline/24';
import { CheckmarkFilled24 } from '_icons/filled/24';

const VideoPlaylistViewer = () => {
  const { data: userData = {} } = useUserDetails();
  const { permissions } = userData;
  const VCPerm = handlePermissions(10, permissions);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const {
    params: { playlistId }
  } = useMatch();
  const navigate = useNavigate();

  const {
    isLoading: objectIsLoading,
    // error: objectError,
    refetch,
    data: objectData
    // isFetching: objectIsFetchingdata
  } = useQueryFetch([`playlist_${playlistId}`], () => getPlaylistData(playlistId), {
    manual: true,
    enabled: true
  });

  if (!VCPerm) {
    return <FullScreenAuthError />;
  }

  const allSelected = selectedVideos.length === objectData?.listings?.length;
  const selectAllVids = () => {
    const returnArr = [];
    objectData?.listings?.forEach((el) => {
      returnArr.push(parseInt(el.ball_id));
    });
    setSelectedVideos(returnArr);
  };
  const deselectAllVids = () => {
    setSelectedVideos([]);
  };

  return (
    <>
      <Flex flex="0 0 64px" bg="primary.shade.70" px="5" align="center" justify="space-between">
        <Flex gap="2">
          <Tooltip label="Back to playlists">
            <IconButton
              icon={<ArrowLeftOutline24 />}
              variant="ghostDark"
              onClick={() => navigate({ to: `/video_playlist` })}
            />
          </Tooltip>
          <Tooltip label="Video search home">
            <IconButton
              icon={<SearchOutline24 />}
              variant="ghostDark"
              onClick={() => navigate({ to: `/video_search/` })}
            />
          </Tooltip>
        </Flex>
        <Flex color="white" fontSize="md" fontWeight="700">
          {objectData?.playlist_data?.playlist_name}
        </Flex>
        <Flex>
          <Box w="40px" />
          {objectData && objectData?.listings?.length !== 0 ? (
            <Tooltip label={allSelected ? 'Deselect all' : 'Select all'}>
              {allSelected ? (
                <IconButton
                  icon={<CheckmarkFilled24 />}
                  variant="ghostDark"
                  color="primary.main"
                  _hover={{ color: 'primary.main' }}
                  onClick={() => {
                    deselectAllVids();
                  }}
                />
              ) : (
                <IconButton
                  icon={<CheckboxUncheckedOutline24 />}
                  variant="ghostDark"
                  onClick={() => {
                    selectAllVids();
                  }}
                />
              )}
            </Tooltip>
          ) : (
            <Box w="40px" />
          )}
        </Flex>
      </Flex>
      {objectIsLoading && (
        <Flex align="center" justify="center" flexGrow="1">
          <Spinner />
        </Flex>
      )}
      {objectData && objectData?.error ? (
        <VideoCentreError errorMessage={objectData?.error} pageType="playlist" />
      ) : (
        objectData &&
        objectData?.listings?.length !== 0 && (
          <VideoPlaylistContainer
            selectedVideos={selectedVideos}
            setSelectedVideos={setSelectedVideos}
            objectData={objectData}
            playlistId={playlistId}
            refetch={refetch}
          />
        )
      )}
    </>
  );
};

export default VideoPlaylistViewer;
