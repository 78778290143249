export const mapOptions = (option) => {
  const { value, label } = option;
  return { value, label };
};

export const groupSelectOptions = (options) => {
  const optArray = [];
  options.forEach((el) => {
    if (!el.optgroup) {
      optArray.push(mapOptions(el));
    } else {
      const optElement = optArray.findIndex((opt) => {
        return opt.label === el.optgroup && opt.options && Array.isArray(opt.options);
      });
      if (optElement === -1) {
        optArray.push({
          label: el.optgroup,
          options: [mapOptions(el)]
        });
      } else {
        optArray[optElement].options.push(mapOptions(el));
      }
    }
  });
  return optArray;
};
