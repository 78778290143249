import { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Center, Spinner, Flex } from '@chakra-ui/react';
import useVideoModal from 'modals/hooks/useVideoModal';
import { getVideoResultsUsingLink } from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import { stringifyJson } from '_shared/utils/utilFunctions';
import { useNavigate } from '@tanstack/react-location';

import VideoPlayer from './VideoPlayer/VideoPlayer';
import VideoNavigator from './VideoNavigator/VideoNavigator';

const getVideoSearchUrl = (urlString) => {
  if (urlString) {
    const urlStringParts = urlString?.split('?');
    if (urlStringParts?.length > 1) {
      const typeId = urlStringParts[0] ?? null;
      const typeIdMatches = typeId?.match(/\/(.*)\/(.*)/);
      if (typeIdMatches?.length > 1) {
        const type = typeIdMatches?.[1];
        const id = typeIdMatches?.[2];
        if (type && id) {
          const queryString = urlStringParts[1] ?? null;
          const urlParams = new URLSearchParams(queryString);
          const keys = urlParams.keys();
          const params = {};
          keys.forEach((key) => {
            const totalParams = urlParams.getAll(key);
            params[key] = totalParams?.length > 1 ? urlParams.getAll(key) : urlParams.get(key);
          });
          const { class_id, ...otherParams } = params;
          if (class_id) {
            const queryUrl = stringifyJson(otherParams);
            return `/video_search/results/${class_id}/${type}/${id}?q=${queryUrl}`;
          }
        }
      }
    }
  }

  return null;
};

export const VideoModal = () => {
  const { hideVideoModal, videoModalShown, videoModalContent, videoModalDirect } = useVideoModal();
  const videoRef = useRef(null);
  const [vidInd, setVidInd] = useState(0);
  const navigate = useNavigate();

  // VideoModal supports two modes depending on the 'videoModalContent' and 'videoModalDirect' passed:
  //   1. If 'videoModalDirect' => assumes its to launch the video directly within it.
  //      (Just plays single video, with no link to video center page & no navigation controls.)
  //   2. If not 'videoModalDirect' => assumes it has to first do a content fetch.
  //      (Supports playing of multiple videos, with navigation controls if there are multiple videos.)

  const contentFetch = useQueryFetch(
    ['videos', videoModalContent],
    () => getVideoResultsUsingLink(videoModalContent),
    {
      enabled: videoModalShown && !videoModalDirect
    }
  );
  const currentVideoUrl = videoModalDirect
    ? videoModalContent
    : contentFetch?.data?.results[vidInd]?.video_url;
  const hasVideoToPlay = videoModalDirect || contentFetch?.data?.results?.[0]?.video_url;
  const showVideoNavigator = videoModalDirect || !!contentFetch?.data?.results?.length;
  const videoSearchUrl = videoModalDirect ? null : getVideoSearchUrl(videoModalContent);

  const nextVideo = useCallback(
    () => setVidInd(vidInd < contentFetch?.data?.results?.length - 1 ? vidInd + 1 : 0),
    [contentFetch?.data?.results?.length, vidInd]
  );

  const onKeyDown = useCallback(
    (event) => {
      switch (event.key) {
        case 'Escape': {
          hideVideoModal();
          setVidInd(0);
          break;
        }
        default: {
          break;
        }
      }
    },
    [hideVideoModal, setVidInd]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);

    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [onKeyDown]);

  if (contentFetch?.isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (contentFetch?.isError) {
    return <Box>Error fetching data</Box>;
  }

  const zIndexFrontOfUi = 9999;

  return (
    <>
      {videoModalShown && hasVideoToPlay && (
        <>
          <Flex
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="100%"
            zIndex={zIndexFrontOfUi}
            bg="rgba(0, 0, 0, 0.75)"
            onMouseUp={() => {
              hideVideoModal();
              setVidInd(0);
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Box
              bg="#fff"
              width="100%"
              maxW="900px"
              zIndex={zIndexFrontOfUi + 1}
              onMouseUp={(e) => e.stopPropagation()}
              boxShadow="xl"
              borderRadius="10px"
              overflow="hidden"
            >
              <VideoPlayer
                videoRef={videoRef}
                videoUrl={currentVideoUrl}
                onVideoEnded={nextVideo}
              />
              {showVideoNavigator && (
                <VideoNavigator
                  vidInd={vidInd}
                  setVidInd={setVidInd}
                  data={contentFetch?.data}
                  navigate={navigate}
                  videoRef={videoRef}
                  videoSearchUrl={videoSearchUrl}
                />
              )}
            </Box>
          </Flex>
        </>
      )}
    </>
  );
};
