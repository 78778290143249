import { Box, Flex, Tooltip } from '@chakra-ui/react';
import TeamImage from '../TeamImage';

const xRxWCheck = (xR, xW) => {
  if (!xR && !xW) return false;
  if (xR === '0' && xW === '0') return false;
  return true;
};

const InningsScore = ({ data }) => {
  const { runs, overs, wickets, closure_name, xR, xW } = data;
  if (!overs || overs === '0.0' || overs === 0) {
    return 'Yet to bat';
  }
  return (
    <Flex direction="column">
      <Flex alignItems="center" gap="2">
        <Box fontWeight="700" fontSize="16px" color="black.76">
          {runs}
          {closure_name !== 'all out' && wickets !== 10 ? `/${wickets}` : null}
          {closure_name === 'declared' ? 'd' : null}
        </Box>
        <Box fontSize="14px" color="black.60">{`(${overs} ov)`}</Box>
      </Flex>
      {xRxWCheck(xR, xW) ? (
        <Tooltip label="Expected Runs/Expected Wickets">
          <Box fontSize="14px" color="black.60">
            x{xR}/{xW}
          </Box>
        </Tooltip>
      ) : null}
    </Flex>
  );
};

const TeamScore = ({ inningsData }) => {
  if (!inningsData) return null;
  const inningsScore = () => {
    if (!inningsData.innings || inningsData.innings.length === 0) {
      return 'Yet to bat';
    }
    if (inningsData.innings.length === 1) {
      return <InningsScore data={inningsData.innings[0]} />;
    }
    return inningsData.innings.map((inn, idx) => {
      return <InningsScore key={idx} data={inn} />;
    });
  };

  return (
    <Flex gap="2" flex="1" align="center">
      <TeamImage size={60} team={{ logo: inningsData.emblem, id: inningsData.id }} />
      <Flex direction="column" minWidth="200px" mt={'auto'} mb={'auto'}>
        <Box fontWeight="700" fontSize="16px" color="black.100">
          {inningsData.name}
        </Box>
        <Flex gap="2">{inningsScore()}</Flex>
      </Flex>
    </Flex>
  );
};

export default TeamScore;
