import { Avatar, Box, Flex } from '@chakra-ui/react';
import { getNameString } from './utils';
import StatsLeader from '_icons/icons/StatsLeader';
import RouterLink from '_shared/components/Utils/RouterLink';
import useVideoModal from 'modals/hooks/useVideoModal';
import TeamImage from '_shared/components/TeamImage';

export default function RankingLeader({ data, tableHeader }) {
  const elements = [];
  if (data) {
    tableHeader.forEach((el) => {
      if (el.key === 'name') {
        const { name, img, object_link } = data;
        const nameString = getNameString(name);
        elements.push(<LeaderName name={nameString} img={img} link={object_link} key="name" />);
        return;
      }
      if (el.key === 'team') {
        const { team, team_logo, team_flag, team_link } = data;
        elements.push(
          <TeamName
            team={{ logo: team_logo, flag: team_flag, id: team, name: team, link: team_link }}
            key="team"
          />
        );
        return;
      }
      const { label, key, video_link } = el;
      const videoUrl = data[video_link];

      const value = data[key];
      elements.push(<RankingValue label={label} value={value} videoUrl={videoUrl} key={label} />);
    });
  }
  return (
    <Flex w="100%" direction="column" p="4" gap="4">
      <Box flex="0 0 40px">
        <StatsLeader color="primary.main" boxSize="36px" />
      </Box>
      <Flex direction="column" gap="2" flexGrow="1">
        {elements}
      </Flex>
      <Box flex="0 0 40px" />
    </Flex>
  );
}

function LeaderName({ name, img, link }) {
  return (
    <Flex direction="column" alignItems="center" gap="2">
      <Avatar size="xl" src={img ? img : null} />
      <Box fontWeight="700" fontSize="lg" textAlign="center">
        <RouterLink fontSize="20px" to={link}>
          {name}
        </RouterLink>
      </Box>
    </Flex>
  );
}

function RankingValue({ label, value, videoUrl }) {
  const { showVideoModal } = useVideoModal();

  return (
    <Flex direction="column" alignItems="center">
      <Box fontSize="sm">{label}</Box>
      <Box
        fontSize="xl"
        fontWeight="700"
        lineHeight="1"
        position="relative"
        _hover={{ textDecoration: videoUrl && 'underline', cursor: videoUrl && 'pointer' }}
        onClick={() => showVideoModal(videoUrl)}
      >
        {value}
      </Box>
    </Flex>
  );
}

function TeamName({ team }) {
  const { name, link } = team;
  return (
    <RouterLink to={link}>
      <Flex direction="column" alignItems="center" justifyContent="center">
        <TeamImage team={team} size={40} type="circle" />
        <Box>{name}</Box>
      </Flex>
    </RouterLink>
  );
}
