const fetchDataColsRegex = (regex_str) => {
  if (typeof regex_str !== 'string') {
    return [];
  }
  const regExp = /{{(.*?)}}/g;
  const arr = [...regex_str.matchAll(regExp)];
  const mapArray = arr.map((el) => el[1]);
  return [...new Set(mapArray)];
};

export default fetchDataColsRegex;
