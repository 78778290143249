import { useRecoilState, useSetRecoilState } from 'recoil';
import { MATCH_VIDEO_ENABLED, MATCH_VIDEO_ID } from '_shared/globalState/atoms';

const { Box, Flex, Circle } = require('@chakra-ui/react');

const getBowlerInfo = (data, players) => {
  const bowlerId = data[0].bowling_player_id;
  return players[bowlerId];
};

const getColor = (ball, type) => {
  const runs = parseInt(ball.runs);
  if (ball.dismissal_name !== '') {
    return `ballScore.w.${type}`;
  }
  if (runs === 0) {
    return `ballScore.nr.${type}`;
  }
  if ([1, 2, 3].includes(runs)) {
    return `ballScore.r.${type}`;
  }
  return `ballScore.${ball.runs}.${type}`;
};

const getRunsValue = (ball) => {
  const runs = parseInt(ball.runs);
  if (ball.dismissal_name !== '') {
    return 'W';
  }
  return runs;
};

const Over = ({ over, players, videos, videoAllowed }) => {
  const [count, data] = over;
  const bowler = getBowlerInfo(data, players);
  const setVidExpanded = useSetRecoilState(MATCH_VIDEO_ENABLED);
  const [vidId, setVideoId] = useRecoilState(MATCH_VIDEO_ID);
  const handleClick = (ball_id) => {
    if (videoAllowed && Object.keys(videos).includes(ball_id)) {
      setVidExpanded(true);
      setVideoId(ball_id);
    }
  };

  return (
    <Box minW="220px" mb="2" mx="1" p="2" pt="1" rounded="md" bg="black.3">
      <Box mb="1">
        <Box as="span" fontSize="sm" fontWeight="700">
          Over {count}
        </Box>
        <Box as="span" fontSize="sm" pl="2">
          {bowler ? bowler.short_name : 'unknown'}
        </Box>
      </Box>
      <Flex>
        {data
          .map((ball) => {
            const ball_id = ball?.ball_id?.toString();
            const isSelected = ball_id === vidId;
            return (
              <Circle
                key={ball_id}
                boxSize={'6'}
                fontSize="sm"
                fontWeight="600"
                bg={getColor(ball, 'bg')}
                color={getColor(ball, 'color')}
                mr="2"
                onClick={() => handleClick(ball_id)}
                _hover={
                  videoAllowed && Object.keys(videos).includes(ball_id)
                    ? { cursor: 'pointer' }
                    : null
                }
                p={3}
                outline={isSelected ? '1px solid #404456' : null}
                border="1px"
                borderColor={isSelected ? '#404456' : getColor(ball, 'border')}
              >
                {getRunsValue(ball)}
                {parseInt(ball.noballs) !== 0 && (
                  <Box as="sup" fontSize="10px">
                    nb
                  </Box>
                )}
                {parseInt(ball.wides) !== 0 && (
                  <Box as="sup" fontSize="10px">
                    wd
                  </Box>
                )}
                {parseInt(ball.legbyes) !== 0 && (
                  <Box as="sup" fontSize="10px">
                    lb
                  </Box>
                )}
                {parseInt(ball.byes) !== 0 && (
                  <Box as="sup" fontSize="10px">
                    b
                  </Box>
                )}
                {parseInt(ball.penalties) !== 0 && ball.penalties !== null && (
                  <Box as="sup" fontSize="10px">
                    {ball.penalties !== ball.runs ? ball.penalties : null}p
                  </Box>
                )}
              </Circle>
            );
          })
          .reverse()}
      </Flex>
    </Box>
  );
};

const OversBBB = ({ oversData, players, videos, videoAllowed }) => {
  // const setVidExpanded = useSetRecoilState(MATCH_VIDEO_ENABLED);
  // const setVideoId = useSetRecoilState(MATCH_VIDEO_ID);
  if (!oversData) {
    return null;
  }
  const overs = [];

  Object.entries(oversData).forEach((over) => {
    overs.unshift(
      <Over
        key={over[0]}
        over={over}
        players={players}
        // setVidExpanded={setVidExpanded}
        // setVideoId={setVideoId}
        videos={videos}
        videoAllowed={videoAllowed}
      />
    );
  });
  const columns = overs.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 3);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return (
    <Box
      overflowX="auto"
      visibility="hidden"
      _hover={{ visibility: 'visible' }}
      _focusVisible={{ visibility: 'visible' }}
    >
      <Flex visibility="visible">
        {columns.map((col, idx) => {
          return <Box key={idx}>{col}</Box>;
        })}
      </Flex>
    </Box>
  );
};

export default OversBBB;
