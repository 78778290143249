import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer
} from 'recharts';
import capitalise from '_shared/utils/capitalise';
import useGraphPlotKey from '../hooks/useGraphPlotKey';
import getColourPalette from './utils/chartColours';

const PanelBarChart = ({ panelData, isVertical, layoutInfo }) => {
  const graphKey = useGraphPlotKey(panelData, layoutInfo);
  const { data: plottingData, data_columns: dataColumns } = panelData;
  const colours = getColourPalette(dataColumns.y.length);
  let min = 0;
  let max = 0;
  const { x } = dataColumns;
  const yList = dataColumns.y;
  const data = [];
  let yVal;
  for (let i = 0; i < plottingData.length; i += 1) {
    const xval = plottingData[i][x];
    const rowData = { name: xval };
    for (let j = 0; j < yList.length; j += 1) {
      yVal = parseFloat(plottingData[i][yList[j]]);
      rowData[yList[j]] = yVal;
      if (yVal < min) {
        min = yVal;
      }
      if (yVal > max) {
        max = yVal;
      }
    }
    data.push(rowData);
  }
  return (
    <ResponsiveContainer width="100%" height="100%" key={graphKey}>
      <BarChart data={data} vertical={isVertical}>
        <XAxis dataKey="name" />
        <YAxis domain={[parseInt(min), parseInt(max)]} />
        <Tooltip />
        <CartesianGrid />
        <Legend />
        {yList.map((columnName, idx) => {
          return (
            <Bar name={capitalise(columnName)} key={idx} dataKey={columnName} fill={colours[idx]} />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PanelBarChart;
