import trackingEnabled from './trackingEnabled';

const initializeTracking = (userData = {}) => {
  if (trackingEnabled()) {
    const { username, full_name, company_name, company_id } = userData;
    if (username) {
      window.pendo.initialize({
        visitor: {
          id: username,
          name: full_name
        },
        account: {
          id: company_id,
          accountName: company_name
        }
      });
    }
  }
};

export default initializeTracking;
