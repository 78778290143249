import { Box, Flex, Circle } from '@chakra-ui/react';
import { CheckmarkFilled24 } from '_icons/filled/24';
import { CalendarOutline20, LocationOutline20 } from '_icons/outline/20';
import { CheckboxUncheckedOutline24 } from '_icons/outline/24';

import { getColor } from 'pages/videoSearch/components/VideoSearchUtils';

const VideoScoreComponent = ({ el, checkBox, isChecked, setSelectedVideos }) => {
  return (
    <>
      <Flex
        fontSize="xs"
        color="black.60"
        borderBottom="1px"
        borderBottomColor="black.8"
        align="center"
        h="10"
        px="4"
      >
        <Flex flexGrow="1" align="center" gap="2">
          <Flex gap="1" align="center">
            <CalendarOutline20 />
            <Box>{el.start_date || el.match_date}</Box>
          </Flex>
          <Flex gap="1" align="center">
            <LocationOutline20 />
            <Box>{el.ground_name}</Box>
          </Flex>
        </Flex>
        {checkBox && (
          <Box cursor="pointer">
            {isChecked ? (
              <CheckmarkFilled24
                color="primary.main"
                onMouseDown={(e) => {
                  e.stopPropagation();
                  setSelectedVideos((prev) => prev.filter((innerEl) => innerEl !== el.ball_id));
                }}
              />
            ) : (
              <CheckboxUncheckedOutline24
                color="black.8"
                onMouseDown={(e) => {
                  e.stopPropagation();
                  setSelectedVideos((prev) => [...prev, el.ball_id]);
                }}
              />
            )}
          </Box>
        )}
      </Flex>
      <Flex align="center" px="4" gap="2">
        <Circle
          key={el?.ball_id}
          bg={getColor(el.scoring, 'bg')}
          color={getColor(el.scoring, 'color')}
          borderColor={getColor(el.scoring, 'border')}
          border="1px"
          boxSize="36px"
          fontSize="md"
          fontWeight="600"
          cursor="pointer"
        >
          {el.scoring}
        </Circle>
        <Flex direction="column">
          <Box fontSize="md" fontWeight="700">
            {el.batting_team || el.batting_team_abbr} v {el.bowling_team || el.bowling_team_abbr}
          </Box>
          <Flex fontSize="sm" gap="2">
            <Box fontWeight="700">{el.score || el.team_score}</Box>
            <Box color="black.60">({el.overs || el.team_overs})</Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default VideoScoreComponent;
