import { Flex } from '@chakra-ui/react';
import SubHeaderInfoItem from 'pages/_shared/components/SubHeaderInfoItem';

const PlayerDetails = ({ playerInfo }) => {
  const { date_of_birth, current_age, pob, player_bat_string, player_bowl_string, playing_span } =
    playerInfo;
  return (
    <>
      <Flex color="white.100" justify="space-between" gap="10px">
        <SubHeaderInfoItem header="BORN" detail={date_of_birth} />
        <SubHeaderInfoItem header="AGE" detail={current_age} />
        <SubHeaderInfoItem header="PLACE OF BIRTH" detail={pob} />
      </Flex>
      <Flex color="white.100" justify="space-between" gap="10px">
        <SubHeaderInfoItem header="PLAYING SPAN" detail={playing_span} />
        <SubHeaderInfoItem header="BATTING STYLE" detail={player_bat_string} />
        <SubHeaderInfoItem header="BOWLING STYLE" detail={player_bowl_string} />
      </Flex>
    </>
  );
};

export default PlayerDetails;
