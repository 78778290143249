import { Button, Box, Flex, Tooltip } from '@chakra-ui/react';
import EditScrubComponent from './EditScrubComponent';
import { CircleCheckmarkFilled24, PauseFilled24, PlayFilled24 } from '_icons/filled/24';
import { ScissorsOutline24 } from '_icons/outline/24';
const EditVideo = ({
  el,
  editItem,
  videoRef,
  // autoPlay,
  // setAutoPlay,
  editAtt,
  // setEditAtt,
  changeInput,
  handleInputChangeEnd,
  currentTime,
  isHidden
}) => {
  if (videoRef?.current) {
    const playerState = videoRef.current.manager?.store?.getState();
    return (
      <Flex
        w="100%"
        bg="#000000B2"
        mt={-3}
        gap="2"
        align="center"
        px="2"
        opacity={isHidden ? '0' : '1'}
        transition="opacity 0.3s"
      >
        <Button
          variant="ghostDark"
          // bg="#000000B2"
          boxSize="10"
          minW="0"
          _hover={{ bg: 'white.10' }}
          onClick={() => {
            videoRef.current.actions.togglePlay();
          }}
        >
          {playerState.player.paused ? <PlayFilled24 /> : <PauseFilled24 />}
        </Button>
        <Box flex="1">
          <EditScrubComponent
            videoRef={videoRef}
            editAtt={editAtt}
            changeInput={changeInput}
            handleInputChangeEnd={handleInputChangeEnd}
            el={el}
            currentTime={currentTime}
          />
        </Box>
        <Box>
          {el?.video_start === editAtt?.video_start && el?.video_end === editAtt?.video_end ? (
            <Flex boxSize="10" justify="center" align="center">
              <CircleCheckmarkFilled24 color="positive.main" />
            </Flex>
          ) : (
            <Tooltip label={'Save clipping'}>
              <Button
                variant="ghostDark"
                // bg="#000000B2"
                boxSize="10"
                minW="0"
                _hover={{ bg: 'white.10' }}
                onClick={() => editItem(editAtt)}
              >
                <ScissorsOutline24 />
              </Button>
            </Tooltip>
          )}
        </Box>

        {/*  <Box ml={1} mt={0}>
          <Button
            variant="ghostDark"
            onClick={() => {
              console.log('click');
              videoRef.current.video.toggleFullscreen();
            }}
          >
            <Icon as={MdOutlineZoomOutMap} fontSize="lg" />
          </Button>
        </Box> */}
      </Flex>
    );
  }
};
export default EditVideo;
