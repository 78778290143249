import isEqual from 'lodash.isequal';
import { parseSelectValue } from '../_shared/utils';

export const editFormInitialValues = (panelData, queryOptions) => {
  const initialValues = {
    class: panelData.class_id,
    panel_id: panelData.panel_id,
    variable_id: panelData.variable_id
  };
  if (queryOptions) {
    const initFormOpts = panelData.query_form?.form_opts;
    const form_opts = {};
    Object.entries(queryOptions).forEach(([key, value]) => {
      if (value.form === 'range') {
        if (initFormOpts[key]) {
          form_opts[key] = initFormOpts[key];
          return;
        }
        form_opts[key] = value.value.join('~');
        return;
      }
      if (key === 'groupby') {
        const { group_by } = panelData.query_form?.form_opts;
        if (
          !group_by ||
          group_by.length === 0 ||
          (group_by.length === 1 && group_by[0] === 'default')
        ) {
          initialValues.group_by = [];
        } else {
          const { option } = value;
          const parsedValues = parseSelectValue(group_by, option);
          initialValues.group_by = parsedValues;
        }
        return;
      }
      if (['select', 'search'].includes(value.form)) {
        if (initFormOpts[key]) {
          const { option } = value;
          const parsedValues = parseSelectValue(initFormOpts[key], option);
          form_opts[key] = parsedValues;
          return;
        }
        form_opts[key] = [];
        return;
      }
      if (
        ['radio', 'select_single'].includes(value.form) ||
        [
          'diy_operator',
          'diy_select1',
          'diy_select2',
          'having_cmp',
          'having_select',
          'orderby',
          'orderbyad'
        ].includes(key)
      ) {
        if (initFormOpts[key]) {
          form_opts[key] = initFormOpts[key];
          return;
        }
        const selected = value.option.find((el) => el.selected);
        form_opts[key] = selected?.value || '';
        return;
      }
      if (value.form === 'checkbox' || key === 'selectopt') {
        const checkboxValues = {};
        value.option.forEach((el) => {
          checkboxValues[el.value] = el.selected;
        });
        form_opts[key] = checkboxValues;
        return;
      }
      if (['having_value', 'size'].includes(key)) {
        if (initFormOpts[key]) {
          form_opts[key] = initFormOpts[key];
          return;
        }
        form_opts[key] = 0;
        return;
      }
      if (key === 'menu') {
        return;
      }
      console.log(`field: ${key} could not be initialised`);
    });
    if (Object.keys(form_opts).length !== 0) {
      initialValues.form_opts = form_opts;
    }
  }
  return initialValues;
};

export const generateEditQTQueryPayload = (values, initialValues, queryOptions, panelData) => {
  const payload = {};
  Object.entries(values).forEach(([key, value]) => {
    if (key === 'form_opts') {
      const initialOptions = initialValues.form_opts;
      const changedOptions = panelData.query_form.form_opts || {};
      delete changedOptions.group_by;
      Object.entries(value).forEach(([optName, optValue]) => {
        if (!isEqual(initialOptions[optName], optValue)) {
          if (queryOptions[optName].form === 'checkbox' || optName === 'selectopt') {
            const newArrayValue = Object.entries(optValue)
              .filter((o) => o[1])
              .map((o) => o[0]);
            changedOptions[optName] = newArrayValue;
          } else if (['select', 'search'].includes(queryOptions[optName].form)) {
            changedOptions[optName] = optValue.map((v) => v.value);
          } else {
            changedOptions[optName] = optValue;
          }
        }
      });
      payload.form_opts = changedOptions;
    } else if (key === 'group_by') {
      if (value.length === 0) {
        payload.group_by = ['default'];
      } else {
        payload.group_by = value.map((el) => el.value);
      }
    } else if (!isEqual(initialValues[key], value)) {
      payload[key] = value;
    }
  });
  payload.panel_id = values.panel_id;
  payload.variable_id = values.variable_id;
  payload.class = values.class;
  return payload;
};
