import { Box, Grid } from '@chakra-ui/react';
import TeamPerformance from './TeamPerformance';
import Header from 'content/_components/header/Header';

const RecentPerformance = ({ content }) => {
  const { data, header } = content;
  const gridRowCount =
    Math.max(data?.[0]?.results?.length || 0, data?.[1]?.results?.length || 0) + 1;
  return (
    <Box>
      <Header title={header} />
      <Grid
        gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
        columnGap="3"
        rowGap="2"
        pt="2"
        px="5"
        pb="7"
      >
        {data.map((el, idx) => {
          return (
            <TeamPerformance
              dataItem={el}
              key={`${idx}-team-performance`}
              gridRowCount={gridRowCount}
            />
          );
        })}
      </Grid>
    </Box>
  );
};
export default RecentPerformance;
