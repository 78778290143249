import { Box } from '@chakra-ui/react';

const PanelText = ({ panelData }) => {
  const { data = [] } = panelData;
  return (
    <Box mb="2">
      {data.map((opt, idx) => {
        return (
          <Box
            key={idx}
            py="3"
            px="4"
            color="black.100"
            borderBottom="1px"
            borderBottomColor="black.8"
            fontSize="sm"
          >
            {opt.text_line}
          </Box>
        );
      })}
    </Box>
  );
};

export default PanelText;
