import competition_ladder_comp_table from './competition_ladder_comp_table';
import matches_match_list from './matches_match_list';
import squads_comp_squads from './squads_comp_squads';

const configDefs = {
  competition_ladder_comp_table,
  matches_match_list,
  squads_comp_squads
};

export default configDefs;
