import { Box, Flex, Grid } from '@chakra-ui/react';

const getScoreString = (runs, wickets, overs) => {
  if (overs === '0.0') return 'Yet to bat';
  let scoreString = '';
  if (runs) scoreString += runs;
  if (wickets !== 10) {
    scoreString += `/${wickets}`;
  }
  return scoreString;
};

const Score = ({ xScoreAvailable, type, size, inningsData = {} }) => {
  const { runs, wickets, overs, xR, xW } = inningsData;
  const scoreString = getScoreString(runs, wickets, overs);
  const reversed = type === 'home' && size !== 'small';
  const inningsStarted = overs !== '0.0';
  return (
    <Grid gridTemplateRows="subgrid">
      <Flex alignItems="baseline" gap="1.5" direction={reversed ? 'row-reverse' : 'row'}>
        {inningsStarted ? (
          <>
            <Box fontSize={size === 'small' ? 'lg' : 'xl'} fontWeight="700" lineHeight="1.2">
              {scoreString}
            </Box>
            <Box lineHeight="1" color="white.56" fontSize="xs">{`(${overs} ov)`}</Box>
          </>
        ) : (
          <Box fontSize={size === 'small' ? 'sm' : 'lg'} color="white.56" lineHeight="1.3">
            Yet to bat
          </Box>
        )}
      </Flex>
      {xScoreAvailable && (
        <Box
          fontSize="xs"
          fontWeight="700"
          color="white.56"
          textAlign={reversed ? 'right' : 'left'}
          minH="18px"
        >
          {xR && xW ? `x${xR}/${xW}` : ''}
        </Box>
      )}
    </Grid>
  );
};

export default Score;
