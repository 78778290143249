import { Flex } from '@chakra-ui/react';
import ChakraReactDatepicker from 'pages/_shared/components/InputComponents/ChakraReactDatepicker.js';
import { useState } from 'react';
import FilterLabel from './FilterLabel';
import capitalise from '_shared/utils/capitalise';

const DateRange = ({
  filterData,
  setFieldValue,
  initialValues,
  isAccordion,
  isExpanded,
  setIsExpanded
}) => {
  const { options, api_tag, label } = filterData;
  const initialMinValue = initialValues[api_tag]?.min ? initialValues[api_tag]?.min : options.min;
  const initialMaxValue = initialValues[api_tag]?.max ? initialValues[api_tag]?.max : options.max;
  const [minDate, setMinDate] = useState(initialMinValue);
  const [maxDate, setMaxDate] = useState(initialMaxValue);
  const setDate = (value, type) => {
    if (!value) {
      if (type === 'min') {
        setMinDate(null);
        if (maxDate) {
          setFieldValue(api_tag, `~${maxDate}`);
        } else {
          setFieldValue(api_tag, null);
        }
      } else {
        setMaxDate(null);
        if (minDate) {
          setFieldValue(api_tag, `${minDate}~`);
        } else {
          setFieldValue(api_tag, null);
        }
      }
    } else if (type === 'min') {
      setMinDate(value.toISOString().split('T')[0]);
      if (maxDate) {
        setFieldValue(api_tag, `${value.toISOString().split('T')[0]}~${maxDate}`);
      } else {
        setFieldValue(api_tag, `${value.toISOString().split('T')[0]}~`);
      }
    } else {
      setMaxDate(value.toISOString().split('T')[0]);
      if (minDate) {
        setFieldValue(api_tag, `${minDate}~${value.toISOString().split('T')[0]}`);
      } else {
        setFieldValue(api_tag, `~${value.toISOString().split('T')[0]}`);
      }
    }
  };
  const isActive = minDate !== initialMinValue || maxDate !== initialMaxValue;

  return (
    <FilterLabel
      name={api_tag}
      label={capitalise(label)}
      isActive={isActive}
      isAccordion={isAccordion}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <Flex gap="2">
        <ChakraReactDatepicker
          selectedDate={minDate ? new Date(minDate) : null}
          minDate={minDate ? new Date(minDate) : null}
          onChange={(v) => setDate(v, 'min')}
          dateFormat="dd-MM-yyyy"
          fontSize={'sm'}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          popperPlacement="bottom-end"
        />
        <ChakraReactDatepicker
          selectedDate={maxDate ? new Date(maxDate) : null}
          maxDate={maxDate ? new Date(maxDate) : null}
          onChange={(v) => setDate(v, 'max')}
          fontSize={'sm'}
          dateFormat="dd-MM-yyyy"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          popperPlacement="bottom-end"
        />
      </Flex>
    </FilterLabel>
  );
};

export default DateRange;
