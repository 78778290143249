import { SimpleGrid } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { ADVANCED_VISUALISATIONS } from '_shared/globalState/atoms';
import ContentComponent from 'content/ContentComponent';

const ContentGrid = ({ content, objectVariable, objectId, video }) => {
  // minH="100%" templateRows='repeat(auto-fit, 500px)'
  const visualisations = useRecoilValue(ADVANCED_VISUALISATIONS);
  if (!content) return null;
  return (
    <SimpleGrid templateColumns="repeat(12, 1fr)" spacing="5" p="5">
      {content
        .filter(({ content_type }) => {
          if (!['pitchmap', 'wagon_wheel', 'beehive'].includes(content_type)) {
            return true;
          }
          if (visualisations && visualisations.includes(content_type)) return true;
          return false;
        })
        .map((el, idx) => {
          return (
            <ContentComponent
              contentData={el}
              key={idx}
              objectVariable={objectVariable}
              objectId={objectId}
              video={video}
            />
          );
        })}
    </SimpleGrid>
  );
};

export default ContentGrid;
