import axios from 'axios';
import {
  getLocalStorageAuthTokens,
  setLocalStorageAuthTokens,
  removeLocalStorageAuthTokens
} from '_shared/utils/localStorage';

const authServerUrl = process.env.REACT_APP_AUTH_URL;

let refreshInProgress = false;

export const validateCredentialsToken = async ({ credentials }) => {
  const formData = new URLSearchParams();
  formData.append('grant_type', 'authorisation');
  formData.append('user_name', credentials.userName);
  formData.append('password', credentials.password);
  const url = `${authServerUrl}/auth/token?group=centurion`;
  const res = await axios({
    method: 'post',
    url,
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
  const { data } = res;
  return data;
};
export const refreshUserTokens = async () => {
  // this is needed because the refresh cycle takes a little time to complete, so if another call was made during this time and failed,
  // it would be refreshing twice simultaneously
  if (refreshInProgress) return 'refreshInProgress';
  refreshInProgress = true;
  const tokenData = getLocalStorageAuthTokens();
  const formData = new URLSearchParams();
  if (!tokenData.refreshToken) return 'No refesh token available';
  formData.append('grant_type', 'refresh');
  formData.append('user_name', tokenData.userName);
  formData.append('refresh_token', tokenData.refreshToken);
  const url = `${authServerUrl}/auth/token`;
  const res = await fetch(url, {
    method: 'POST',
    body: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
  if (res.status === 200) {
    const data = await res.json();
    setLocalStorageAuthTokens(data?.access_token, data?.refresh_token);
  }
  refreshInProgress = false;
  return res;
};
export const logout = async () => {
  const tokenData = getLocalStorageAuthTokens();
  removeLocalStorageAuthTokens();
  const formData = new URLSearchParams();
  formData.append('access_token', tokenData.accessToken);
  const url = `${authServerUrl}/auth/logout`;
  const res = await fetch(url, {
    method: 'POST',
    body: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${tokenData.accessToken}`
    }
  });
  const { data } = res;
  window.location.href = '/login';
  return data;
};
