import { Flex, Box } from '@chakra-ui/react';
import GridCardLink from '_shared/components/DesignSystem/GridCardLink';

function getColor(resultShort) {
  let color = 'text.tertiary';
  let bg = 'surface.tertiary';
  if (resultShort === 'W') {
    color = 'text.positive';
    bg = 'surface.positive-x-light';
  } else if (resultShort === 'L') {
    color = 'text.negative';
    bg = 'surface.negative-x-light';
  }
  return { color, bg };
}

const ResultShort = ({ resultShort }) => {
  const { color, bg } = getColor(resultShort);
  const size = '48px';
  return (
    <Flex
      mt="1.5"
      flexGrow="0"
      color={color}
      bg={bg}
      borderColor={color}
      borderWidth={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={size}
      height={size}
      borderRadius="50%"
      fontSize="16px"
      fontWeight="700"
    >
      {resultShort}
    </Flex>
  );
};

const ResultRow = ({ resultItem }) => {
  const { result_short, opposition, match_link, match_date, comp_name, result } = resultItem;
  const { color } = getColor(result_short);
  return (
    <GridCardLink to={match_link}>
      <Flex fontSize={14} h="100%" alignItems="start" gap="3" px="1">
        <ResultShort resultShort={result_short} />
        <Box flex="1">
          <Box>v {opposition}</Box>
          <Box color={color} fontWeight={700}>
            {result}
          </Box>
          <Box fontSize={12}>
            {comp_name}, {match_date}
          </Box>
        </Box>
      </Flex>
    </GridCardLink>
  );
};

const TeamResults = ({ results }) => {
  return results.map((el) => {
    return <ResultRow resultItem={el} key={el.match_link} />;
  });
};
export default TeamResults;
