import CompLeagueTable from './CompLeagueTable';

const checkFixtures = (matches = {}) => {
  if (matches.completed?.length || matches.forthcoming?.length || matches.live?.length) {
    return true;
  }
  return false;
};

const CompLadderFixturesPanel = ({ panelData }) => {
  const { data } = panelData;
  const { matches, league_table } = data;
  return (
    <CompLeagueTable data={league_table} matches={checkFixtures(matches) ? matches : undefined} />
  );
};

export default CompLadderFixturesPanel;
