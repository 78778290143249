import { Box, Flex } from '@chakra-ui/react';

const RecentPeriods = ({ recentData }) => {
  return (
    <Flex w="100%" gap="2">
      {recentData.map(({ header, rpo, score }, idx) => {
        return (
          <Box key={`rp_${idx}`} bg="white.10" flex="1" borderRadius="4" py="2" px="3" lineHeight="1.3">
            <Box fontSize="sm" color="white.100">
              {header}
            </Box>
            <Box fontSize="lg" color="white.100" fontWeight="700">
              {score}
            </Box>
            <Box fontSize="sm" color="white.56">
              {rpo}
            </Box>
          </Box>
        );
      })}
    </Flex>
  );
};

export default RecentPeriods;
