import { Flex, FormControl, FormErrorMessage, FormLabel, Radio, RadioGroup } from '@chakra-ui/react';
import { Field } from 'formik';

const FormRadioButtons = ({ name, options, label, isDisabled }) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const { onChange, ...rest } = field;
        return (
          <FormControl isInvalid={form.errors[name] && form.touched[name]} mt="3">
            <Flex justify="space-between">
              <FormLabel>{label}</FormLabel>
              <RadioGroup {...rest} display="flex" flexWrap="wrap" gridColumnGap={2} isDisabled={isDisabled}>
                {options.map((opt) => (
                  <Radio onChange={onChange} value={opt.value} key={opt.value}>
                    {opt.label}
                  </Radio>
                ))}
              </RadioGroup>
            </Flex>
            <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default FormRadioButtons;
