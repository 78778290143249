import { Flex, Box, Icon, Button, Tooltip } from '@chakra-ui/react';
import {
  MdSearch,
  MdCheckBoxOutlineBlank,
  MdCheckBox,
  MdTune,
  MdList,
  MdArrowBack,
  MdArrowDownward,
  MdArrowUpward
} from 'react-icons/md';

import { useNavigate } from '@tanstack/react-location';
import SearchbarFilters from './SearchbarFilters';
import { selectedButton } from './VideoSearchUtils';
import { useState } from 'react';

const ResultsHeader = ({
  changeSearch,
  setChangeSearch,
  class_info,
  objectType,
  object_info,
  results,
  objectId,
  setFiltersOpen,
  selectAllVids,
  deselectAllVids,
  setViewType,
  videoPage,
  reversed,
  setReversed
}) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const iconSize = '24px';

  return (
    <Box bg="primary.shade.70" p="2">
      <Flex gap={1} borderRadius="sm" alignItems="center" flexWrap="wrap">

        <Box marginRight="auto">
          <Flex>

            <Tooltip label={videoPage === 'standard' ? 'Back to results' : 'Refine your search'}>
              <Button
                mb="auto"
                mt="auto"
                variant={'ghostDark'}
                onClick={() => {
                  if (videoPage === 'standard') {
                    setViewType(videoPage);
                  } else {
                    navigate({
                      to: `${window.location.pathname.replace('/results/', '/filters/')}${
                        window.location.search
                      }`
                    });
                  }
                }}
              >
                <Icon as={MdArrowBack} fontSize={iconSize} />
              </Button>
            </Tooltip>

            {!changeSearch ? (
              <Tooltip label="Change search criteria">
                <Button
                  mb="auto"
                  mt="auto"
                  type="a"
                  variant={'ghostDark'}
                  onClick={() => {
                    // setChangeSearch(true);

                    navigate({
                      to: `/video_search`
                    });
                  }}
                >
                  <Icon as={MdSearch} fontSize={iconSize} />
                </Button>
              </Tooltip>
            ) : null}
            <Tooltip label="Go to playlists">
              <Button
                mb="auto"
                mt="auto"
                type="a"
                variant={'ghostDark'}
                onClick={() => {
                  navigate({ to: `/video_playlist` });
                }}
              >
                <Icon as={MdList} fontSize={iconSize} />
              </Button>
            </Tooltip>
          </Flex>
        </Box>

        <Box m="auto" alignItems="center" order={[1, 1, 1, 0]}>
        {changeSearch ? (
          <SearchbarFilters
            classId={{ value: class_info.id, label: class_info.short_name }}
            criteria={{
              label: `${objectType[0].toUpperCase()}${objectType.slice(1)}`,
              value: objectType
            }}
            keyWord={{ label: object_info.header, value: objectId }}
            setChangeSearch={setChangeSearch}
          />
        ) : (
          <Box m="auto">
            <Flex alignItems="center" whiteSpace="nowrap" flexWrap="wrap" justifyContent="center">
              <Box fontSize="lg" fontWeight="bold" color="white" marginLeft="10px" marginRight="10px">
                {results.length} videos
              </Box>
              <Flex ml={3} alignItems="center" flexWrap="wrap" columnGap="4" justifyContent="center">
                {selectedButton('Format', class_info.short_name, 'dark')}
                {selectedButton(
                  'Criteria',
                  `${objectType[0].toUpperCase()}${objectType.slice(1)}`,
                  'dark'
                )}
                <Tooltip label={`Go to ${object_info.header} in Centurion`}>
                  <Box
                    as="span"
                    _hover={{ cursor: 'pointer' }}
                    onClick={() =>
                      navigate({
                        to: `/${
                          ['batter', 'bowler'].includes(objectType) ? 'player' : objectType
                        }/${objectId}`
                      })
                    }
                  >
                    {selectedButton('Keyword', object_info.header, 'dark')}
                  </Box>
                </Tooltip>

                {/* CODE USED TO SHOW FILTERS SELECTED
              {params ? filtersConversion(params, filters, 'dark') : null} 
              */}
              </Flex>
            </Flex>
          </Box>
        )}
        </Box>

        <Box marginLeft="auto">
          <Flex>
            <Tooltip label="Change search filters">
              <Button
                type={'button'}
                variant={'ghostDark'}
                onClick={() => {
                  setFiltersOpen(true);
                }}
              >
                <Icon as={MdTune} fontSize={iconSize} />
              </Button>
            </Tooltip>
            <Tooltip label={!reversed ? 'Order (reverse)' : 'Order (standard)'}>
              <Button
                type={'button'}
                variant={'ghostDark'}
                onClick={() => {
                  // setFiltersOpen(true);
                  setReversed((rev) => !rev);
                }}
              >
                <Icon as={reversed ? MdArrowUpward : MdArrowDownward} fontSize={iconSize} />
              </Button>
            </Tooltip>
            <Tooltip label={isChecked ? 'Deselect all' : 'Select all'}>
              <Button
                type={'button'}
                variant={'ghostDark'}
                onClick={() => {
                  setIsChecked((prev) => !prev);
                  if (!isChecked) {
                    selectAllVids();
                  } else {
                    deselectAllVids();
                  }

                  // setFiltersOpen(true);
                }}
              >
                <Icon as={isChecked ? MdCheckBox : MdCheckBoxOutlineBlank} fontSize={iconSize} />
              </Button>
            </Tooltip>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default ResultsHeader;
