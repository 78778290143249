import PlayerFilterHeader from 'pages/player/components/PlayerFilterHeader';
import FilterLayout from './FilterComponents/FilterLayout';
import MatchFilterHeader from 'pages/match/components/MatchFilterHeader';
import CompFilterHeader from 'pages/comp/components/CompFilterHeader';
import TeamFilterHeader from 'pages/team/components/TeamFilterHeader';
import GroundFilterHeader from 'pages/ground/components/GroundFilterHeader';

const PageFilterHeader = ({ objectData, type }) => {
  return (
    <FilterLayout px="5">
      {type === 'player' && <PlayerFilterHeader objectData={objectData} />}
      {type === 'match' && <MatchFilterHeader objectData={objectData} />}
      {type === 'comp' && <CompFilterHeader objectData={objectData} />}
      {type === 'team' && <TeamFilterHeader objectData={objectData} />}
      {type === 'ground' && <GroundFilterHeader objectData={objectData} />}
    </FilterLayout>
  );
};

export default PageFilterHeader;
