import { useFormikContext } from 'formik';
import ReactJson from 'react-json-view';
import isEqual from 'lodash.isequal';

const JsonTreeView = () => {
  const { setFieldValue, values } = useFormikContext();
  const { apiData, nested_dict } = values;
  return (
    <ReactJson
      src={apiData}
      collapsed={true}
      onSelect={(select) => {
        if (!isEqual(nested_dict, select.namespace)) {
          setFieldValue('columnSelection', [], false);
          setFieldValue('data_value', '', false);
        }
        setFieldValue('nested_dict', select.namespace, false);
      }}
      enableClipboard={false}
    />
  );
};

export default JsonTreeView;
