import { Navigate } from '@tanstack/react-location';
import FetchComponent from 'pages/_shared/components/FetchComponent';
import BetaSwitch from './BetaSwitch';
import PageContainer from 'pages/_shared/components/PageContainer';

export const groundRoutes = {
  path: 'ground',
  children: [
    { path: '/', element: <Navigate to="/home" /> },
    {
      path: ':objectId',
      element: (
        <BetaSwitch
          beta={
            process.env.REACT_APP_ENV !== 'prod' && <PageContainer type="ground" key="ground" />
          }
        >
          <FetchComponent type="ground" />
        </BetaSwitch>
      )
    }
  ]
};
