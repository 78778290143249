import { useState } from 'react';
import { Box, Icon, Tooltip } from '@chakra-ui/react';
import { getUserFavourites, postFavourites } from '_shared/dataFetching/apiService';
import { MdStarBorder, MdStar } from 'react-icons/md';
import { useQuery, useQueryClient } from 'react-query';
import cloneDeep from 'lodash.clonedeep';
// import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';

const FavIcon = ({ iconType, color, cursor, handleClick }) => {
  const icon = iconType === 'filled' ? MdStar : MdStarBorder;
  const tooltipText = iconType === 'filled' ? 'Remove favourite' : 'Add favourite';

  return (
    <Tooltip label={tooltipText}>
      <Box boxSize={6} data-testid={iconType}>
        <Icon
          as={icon}
          w={6}
          h={6}
          _hover={{ color: 'favourite' }}
          color={color}
          cursor={cursor}
          onClick={handleClick}
        />
      </Box>
    </Tooltip>
  );
};

const AddUserFavourites = ({ favType, favId, lightBackground }) => {
  const queryClient = useQueryClient();
  const [isSaving, setIsSaving] = useState(false);
  const { isLoading, error, data } = useQuery(
    ['favourites', favType],
    () => getUserFavourites(favType),
    {
      staleTime: 10 * 60 * 1000,
      enabled: Boolean(favType && favId)
    }
  );

  const outLineColor = lightBackground ? 'black.24' : 'white.32';

  if (!favType || !favId) {
    return null;
  }

  if (isLoading || error || isSaving) {
    return <FavIcon iconType="border" color={outLineColor} cursor={isSaving && 'pointer'} />;
  }

  const isFavourite = data?.some((el) => {
    if (favType === 'panel' && favId === `${el.panel_id}-${el.class_id}-${el.variable_id}`) {
      return true;
    }
    if ((el.id === parseInt(favId) || el.id === favId.toString()) && el.variable_type === favType) {
      return true;
    }
    return false;
  });

  const handleClick = async (e) => {
    e.stopPropagation();
    setIsSaving(true);
    const action = isFavourite ? 'delete' : 'add';
    const res = await postFavourites(favId, favType, action);
    if (res.status === 200) {
      queryClient.setQueryData(['favourites', favType], (favs) => {
        if (action === 'add') {
          const newFavs = cloneDeep(favs);
          newFavs.push({
            id: favId,
            url: `/${favType}/${favId}`,
            variable_type: favType
          });
          return newFavs;
        } else if (action === 'delete') {
          if (favType !== 'panel') {
            return favs.filter(
              (el) =>
                el.id !== parseInt(favId) &&
                el.id !== favId.toString() &&
                el.variable_type === favType
            );
          }

          return favs.filter((el) => favId !== el.id);
        }
        return favs;
      });
    } else {
      console.log('POST ERROR');
    }
    setIsSaving(false);
  };

  return isFavourite ? (
    <FavIcon iconType="filled" color="favourite" cursor="pointer" handleClick={handleClick} />
  ) : (
    <FavIcon iconType="border" color={outLineColor} cursor="pointer" handleClick={handleClick} />
  );
};

export default AddUserFavourites;
