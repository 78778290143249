export const getNameString = (name) => {
  let nameString = '';
  if (typeof name === 'string') {
    nameString += name;
  } else if (name?.primary) {
    nameString += name.primary;
  } else if (name?.secondary) {
    nameString += name.primary;
  }
  return nameString;
};
