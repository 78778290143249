const checkRangeFilter = (filterValue = '~', value = '~') => {
  const [start1Str, end1Str] = filterValue.split('~');
  const [start2Str, end2Str] = value.split('~');

  const start1 = start1Str ? parseInt(start1Str) : 0;
  const end1 = end1Str ? parseInt(end1Str) : 999;
  const start2 = start2Str ? parseInt(start2Str) : 0;
  const end2 = end2Str ? parseInt(end2Str) : 999;

  return start1 <= end2 && start2 <= end1;
};

export default checkRangeFilter;
