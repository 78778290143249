import { createIcon } from '@chakra-ui/icons';

const CircleCheckmark = createIcon({
  displayName: 'CircleCheckmark',
  viewBox: '0 0 24 24',
  defaultProps: { width: '24px', height: '24px' },
  path: [
    <circle cx="12" cy="12" r="10" fill="currentColor" />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0585 8.04897C18.323 8.32676 18.3121 8.76633 18.0344 9.03077L10.0097 16.6697C9.87263 16.8001 9.68829 16.869 9.49929 16.8604C9.31028 16.8518 9.13297 16.7664 9.00834 16.6241L5.92192 13.0984C5.6693 12.8099 5.69844 12.3711 5.98702 12.1185C6.2756 11.8659 6.71433 11.895 6.96695 12.1836L9.57647 15.1645L17.0767 8.02479C17.3545 7.76035 17.7941 7.77118 18.0585 8.04897Z"
      fill="white"
    />
  ]
});

export default CircleCheckmark;
