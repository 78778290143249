import { Box, Flex } from '@chakra-ui/react';

const ScoreTypeComponent = ({ score, bg, color, text }) => {
  return (
    <Flex
      h="78px"
      rounded="md"
      border="1px"
      borderColor={color}
      color={color}
      bg={bg}
      direction="column"
      alignItems="center"
      justifyContent="center"
      w="120px"
    >
      <Box color="black" fontSize="sm">
        {text}
      </Box>
      <Box fontWeight="700" fontSize="lg">
        {score}
      </Box>
    </Flex>
  );
};

export default ScoreTypeComponent;
