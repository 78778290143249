import { Box, Flex, IconButton, Button, Show, Hide } from '@chakra-ui/react';
import { useRouter, useNavigate } from '@tanstack/react-location';
import { AiOutlineEye } from 'react-icons/ai';
import { FiPlus } from 'react-icons/fi';
import { MdMenu } from 'react-icons/md';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import SearchModal from 'search/SearchModal';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import {
  PANEL_SETTINGS_AVAILABLE,
  PANEL_SETTINGS_OPEN,
  HIDE_SHOW_OPEN,
  GLOBAL_NAV_OPEN,
  BETA_SELECT
} from '_shared/globalState/atoms';
import UserMenu from './UserMenu';
import { handlePermissions } from '_shared/utils/utilFunctions';

const useBeta = (currentPath) => {
  const [betaSelect, setBetaSelect] = useRecoilState(BETA_SELECT);
  const playerPage = currentPath?.includes('/player/');
  const matchPage = currentPath?.includes('/match/');
  const compPage = currentPath?.includes('/comp/');
  const teamPage = currentPath?.includes('/team/');
  const groundPage = currentPath?.includes('/ground/');
  const showButton =
    playerPage ||
    matchPage ||
    (compPage && process.env.REACT_APP_ENV !== 'prod') ||
    (teamPage && process.env.REACT_APP_ENV !== 'prod') ||
    (groundPage && process.env.REACT_APP_ENV !== 'prod');
  const buttonLabel = !betaSelect ? 'Try our new beta experience' : 'Switch to default view';
  const buttonLabelSmall = !betaSelect ? 'Try Beta' : 'Quit Beta';
  const showChip = showButton && betaSelect;

  const onClickSwitch = () => {
    setBetaSelect((p) => !p);
  };

  return {
    showButton,
    buttonLabel,
    buttonLabelSmall,
    showChip,
    onClickSwitch
  };
};

const NavBar = () => {
  const router = useRouter();
  const navigate = useNavigate();
  const currentPath = router.state.location.href;
  const beta = useBeta(currentPath, navigate);

  const setGlobalNavOpen = useSetRecoilState(GLOBAL_NAV_OPEN);
  const setShowHideOpen = useSetRecoilState(HIDE_SHOW_OPEN);
  const setPanelSettingsOpen = useSetRecoilState(PANEL_SETTINGS_OPEN);
  const panelSettingsAvailable = useRecoilValue(PANEL_SETTINGS_AVAILABLE);
  const { data: userInfo } = useUserDetails();
  const { permissions } = userInfo || {};
  const AddPanelPerm = handlePermissions(2, permissions);
  const iconSize = 24;
  const buttonSize = 44;

  return (
    <Box bg="black.100" px={4} position="relative">
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        <Flex alignItems="center" gap="4">
          <IconButton
            icon={<MdMenu size={iconSize} />}
            variant="ghostDark"
            fontSize={buttonSize}
            onClick={() => setGlobalNavOpen(true)}
          />
          {beta.showChip && (
            <Box
              color="white.100"
              bg="#943DFF"
              px={2}
              py={1.5}
              borderRadius="full"
              fontSize="12px"
              fontWeight="700"
              lineHeight="1"
              userSelect="none"
              display={{ base: 'none', md: 'initial' }}
            >
              Beta
            </Box>
          )}
        </Flex>
        {beta.showButton && (
          <Box
            position="absolute"
            left={{ base: '120px', md: '50%' }}
            top="50%"
            transform="translate(-50%, -50%)"
          >
            <Button
              variant="secondaryDark"
              fontSize="14px"
              fontWeight="700"
              onClick={beta.onClickSwitch}
            >
              <Show above="md">{beta.buttonLabel}</Show>
              <Hide above="md">{beta.buttonLabelSmall}</Hide>
            </Button>
          </Box>
        )}
        <Flex alignItems={'center'} gap="4">
          {panelSettingsAvailable && (
            <IconButton
              icon={<AiOutlineEye size={iconSize} />}
              variant="ghostDark"
              onClick={() => setShowHideOpen(true)}
              fontSize={buttonSize}
            />
          )}
          {panelSettingsAvailable && AddPanelPerm && (
            <IconButton
              icon={<FiPlus size={iconSize} />}
              variant="ghostDark"
              onClick={() => setPanelSettingsOpen(true)}
              fontSize={buttonSize}
            />
          )}
          {!window.location.href.includes('video') ? <SearchModal /> : null}

          <UserMenu />
        </Flex>
      </Flex>
    </Box>
  );
};

export default NavBar;
