import { Box, Flex } from '@chakra-ui/react';
import PercentageComponent from './PercentageComponent';
import ScoreTypeComponent from './ScoreTypeComponent';

const BattingFirst = ({ batting_first, panelWidth }) => {
  return (
    <Box flex="1">
      <Flex fontWeight="700" fontSize="md" mb="2">
        Batting first predictions
      </Flex>
      <Flex gap="3" justifyContent="space-evenly" direction="row">
        <PercentageComponent
          percentage={batting_first.winning_percentage}
          panelWidth={panelWidth}
        />
        <Flex alignItems="center" justifyContent="space-around" gap="2">
          <ScoreTypeComponent
            score={batting_first.winning_team_average_score || 'N/A'}
            text="Winning score"
            bg="positive.tint.90"
            color="positive.shade.30"
          />
          <ScoreTypeComponent
            score={batting_first.losing_team_average_score || 'N/A'}
            text="Losing score"
            bg="negative.tint.90"
            color="negative.shade.10"
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default BattingFirst;
