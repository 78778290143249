import BulletList from '_icons/icons/BulletList';
// import CompetitionStar from '_icons/icons/CompetitionStar';
import CricketBall from '_icons/icons/CricketBall';
import Home from '_icons/icons/Home';
import Questions from '_icons/icons/Questions';
import Search from '_icons/icons/Search';
import VideoPlay from '_icons/icons/VideoPlay';

const menuDef = [
  {
    icon: <Home boxSize="6" />,
    text: 'Home',
    location: 'home'
  },
  {
    icon: <CricketBall boxSize="6" />,
    text: 'Matches',
    location: 'matches'
  },
  // {
  //   icon: <CompetitionStar boxSize="6" />,
  //   text: 'Competitions',
  //   location: 'competitions'
  // },
  {
    icon: <VideoPlay boxSize="6" />,
    text: 'Video Centre',
    subMenuDef: [
      {
        icon: <Search boxSize="6" />,
        text: 'Video Search',
        location: 'video_search'
      },
      {
        icon: <BulletList boxSize="6" />,
        text: 'Playlists',
        location: 'video_playlist'
      }
    ]
  },
  {
    icon: <Questions boxSize="6" />,
    text: 'FAQ',
    location: 'faq'
  }
];

export default menuDef;
