import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

const datesAreEqual = (date1, date2) => {
  if (date1 === null && date2 === null) return true;
  if (date1 && date1.isValid() && date2 && date2.isValid() && date1.isSame(date2, 'day')) {
    return true;
  }
  return false;
};

export const checkMinDate = (user_date_min, page_date_min) => {
  const userMinDate = user_date_min !== null ? dayjs(user_date_min) : null;
  const pageMinDate = page_date_min !== null ? dayjs(page_date_min) : null;
  if (datesAreEqual(userMinDate, pageMinDate)) {
    return { changed: false, date: user_date_min || '' };
  }
  if (pageMinDate === null) {
    return { changed: true, date: '' };
  }
  return { changed: true, date: pageMinDate.format('YYYY-MM-DD') };
};

export const checkMaxDate = (user_date_max, page_date_max) => {
  const userMaxDate = user_date_max !== null ? dayjs(user_date_max) : null;
  const pageMaxDate = page_date_max !== null ? dayjs(page_date_max) : null;
  if (datesAreEqual(userMaxDate, pageMaxDate)) {
    return { changed: false, date: user_date_max || '' };
  }
  if (pageMaxDate === null) {
    return { changed: true, date: '' };
  }
  if (pageMaxDate.isSameOrAfter(dayjs(), 'day')) {
    return { changed: !!user_date_max, date: '' };
  }
  return { changed: true, date: pageMaxDate.format('YYYY-MM-DD') };
};

const checkMinMaxDates = ({ user_date_min, user_date_max, page_date_min, page_date_max }) => {
  if (page_date_min === undefined && page_date_max === undefined) return null;
  const min = checkMinDate(user_date_min, page_date_min);
  const max = checkMaxDate(user_date_max, page_date_max);

  if (!min.changed && !max.changed) {
    return null;
  }
  if (min.date === '' && max.date === '') {
    return 'all';
  }
  return `${min.date}~${max.date}`;
};

export default checkMinMaxDates;
