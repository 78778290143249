import { createIcon } from '@chakra-ui/icons';

const Calendar = createIcon({
  displayName: 'Calendar',
  viewBox: '0 0 20 20',
  defaultProps: { width: '20px', height: '20xp' },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2V4H13V2H14V4H15C16.1046 4 17 4.89543 17 6V16C17 17.1046 16.1046 18 15 18H5C3.89543 18 3 17.1046 3 16V6C3 4.89543 3.89543 4 5 4H6V2H7ZM5 5C4.44772 5 4 5.44772 4 6V8H16V6C16 5.44772 15.5523 5 15 5H5ZM4 16V9H16V16C16 16.5523 15.5523 17 15 17H5C4.44772 17 4 16.5523 4 16ZM10 13C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11C9.44772 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13ZM8 12C8 12.5523 7.55228 13 7 13C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11C7.55228 11 8 11.4477 8 12ZM13 13C13.5523 13 14 12.5523 14 12C14 11.4477 13.5523 11 13 11C12.4477 11 12 11.4477 12 12C12 12.5523 12.4477 13 13 13Z"
      fill="currentColor"
    />
  )
});

export default Calendar;
