import { Box, Flex, Icon } from '@chakra-ui/react';
import AddUserFavourites from '_shared/components/AddUserFavourites';
import getHeaderType from './getHeaderTypes';

const FixtureStatusHeader = ({ matchData, favouriteAvailable }) => {
  const { id, match_status, match_situation_str, result_str } = matchData;

  const { bgColor, color, icon, border } = getHeaderType(matchData);

  return (
    <Flex
      px="3"
      py="2"
      gap="2"
      alignItems="center"
      bg={bgColor}
      color={color}
      borderBottom={border ? '1px' : undefined}
      borderColor={border ? 'black.8' : undefined}
    >
      <Icon as={icon} />
      <Box fontSize="14px" fontWeight="700" flexGrow="1">
        {match_status === 'live' && match_situation_str}
        {match_status === 'complete' && result_str}
      </Box>
      {favouriteAvailable ? <AddUserFavourites favType="match" favId={id} lightBackground /> : null}
    </Flex>
  );
};

export default FixtureStatusHeader;
