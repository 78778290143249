import { MdClose, MdSearch } from 'react-icons/md';
import { getVideoObject } from '_shared/dataFetching/apiService';
import { useNavigate } from '@tanstack/react-location';
import { Flex, Box, Icon, Button } from '@chakra-ui/react';
import FormAsyncSelect from 'Forms/_shared/FormElements/FormAsyncSelect';
import FormClassSelector from 'Forms/_shared/FormElements/FormClassSelector';
import FormReactSelect from 'Forms/_shared/FormElements/FormReactSelect';
import { Formik } from 'formik';

const object_type_labels = () => {
  return [
    { label: 'Batter', value: 'batter' },
    { label: 'Bowler', value: 'bowler' },
    { label: 'Match', value: 'match' },
    { label: 'Competition', value: 'comp' }
  ];
};
const SearchbarFilters = ({ classId, criteria, keyWord, setChangeSearch, q }) => {
  const navigate = useNavigate();
  const fetchObjectOptions = async (values, inputValue) => {
    if (!inputValue || !values.class_id?.value || !values.object_type?.value) return null;
    const data = await getVideoObject(
      values.object_type?.value,
      values.class_id?.value,
      inputValue
    );

    if (data?.results) {
      return data.results.map(({ id, text }) => {
        return {
          value: id,
          label: text
        };
      });
    }
    return null;
  };

  return (
    <Formik
      initialValues={{
        class_id: classId,
        object_type: criteria,
        object_id: keyWord,
        query_string: q
      }}
      validate={(values) => {
        const errors = {};
        if (!values.object_type) {
          errors.object_type = 'Please select object';
        }
        if (!values.object_id) {
          errors.object_id = 'Please select a keyword';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        let qUrl = '';
        const params = new URLSearchParams(window.location.search);
        if (params.has('q')) {
          qUrl += `?q=${params.get('q')}`;
        }
        if (values.object_type && values.object_id) {
          navigate({
            to: `/video_search/filters/${values.class_id.value}/${values.object_type.value}/${values.object_id.value}${qUrl}`
          });
        }
        setSubmitting(false);
      }}
    >
      {(props) => (
        <>
          <Box m="auto" w="70%">
            <Flex>
              <FormClassSelector
                label={
                  <Box fontWeight="bold" fontSize="sm" color="white">
                    Select Format
                  </Box>
                }
                name="class_id"
                placeholder="Select format"
                onChangeCustom={(e) => {
                  props.setFieldValue('class_id', e);
                  props.setFieldValue('object_id', null);
                }}
              />

              <FormReactSelect
                name="object_type"
                label={
                  <Box fontWeight="bold" fontSize="sm" color="white">
                    Search Criteria
                  </Box>
                }
                placeholder="Select search criteria"
                options={object_type_labels()}
                onChangeCustom={(e) => {
                  props.setFieldValue('object_type', e);
                  props.setFieldValue('object_id', null);
                }}
              />
              <FormAsyncSelect
                name="object_id"
                label={
                  <Box fontWeight="bold" fontSize="sm" color="white">
                    Keyword
                  </Box>
                }
                placeholder={
                  !props.values.object_type
                    ? 'Search for batters, bowlers, competitions, matches'
                    : `Search for videos by ${props.values.object_type.label.toLowerCase()}`
                }
                fetchOptionsFunction={(e) => fetchObjectOptions(props.values, e)}
                isClearable
                isDisabled={!props.values.object_type}
              />
              <Box mt={'auto'} ml={5}>
                <Button
                  size="md"
                  onClick={() => {
                    props.submitAction = 'primary';
                    props.handleSubmit();
                  }}
                  isDisabled={
                    !props.values.object_type && !props.values.object_id && !props.values.class_id
                  }
                >
                  <Icon as={MdSearch} fontSize="lg" />
                </Button>
              </Box>
            </Flex>
          </Box>
          <Box ml={2} mr="auto">
            <Button
              fontSize="md"
              variant={'ghost'}
              ml={2}
              type="button"
              onClick={() => {
                setChangeSearch(false);
              }}
            >
              <Icon as={MdClose} fontSize="lg" color="white" />
            </Button>
          </Box>
        </>
      )}
    </Formik>
  );
};

export default SearchbarFilters;
