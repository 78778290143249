import { Box, chakra } from '@chakra-ui/react';
import FormInput from 'Forms/_shared/FormElements/FormInput';
import FormSelect from 'Forms/_shared/FormElements/FormSelect';
import Columns from 'Forms/PanelForms/_shared/FormComponents/Columns';
import PanelType from 'Forms/PanelForms/_shared/FormComponents/PanelType';
import StaffFields from 'Forms/PanelForms/_shared/FormComponents/StaffFields';
import useColumnFetch from '../hooks/useColumnFetch';
import { getSearchParamsFromUrl } from '_shared/utils/searchParamsUtils';
import { transformPanelText, classIdLabel } from '../utils';
import FormCategorySelector from 'Forms/_shared/FormElements/FormCategorySelector';
import DynamicNameVariables from '../FormComponents/DynamicNameVariables';

const QTPanelForm = ({
  editMode,
  handleChange,
  setFieldValue,
  values,
  is_staff,
  classListOptions,
  setFieldTouched,
  videoOptionAvailable,
  dataRow,
  pageType,
  objectId
}) => {
  const { sampleData } = useColumnFetch(!editMode);
  const { columnData, classId } = values;
  return (
    <>
      {!editMode && (
        <Box mt="3">
          <FormInput
            name="qt_api"
            label="API URL"
            placeholder="Enter API url"
            onChangeCustom={(e) => {
              const searchParams = getSearchParamsFromUrl(e.target.value);
              if (searchParams.class) {
                const listOption = classListOptions.find((el) => el.value === searchParams.class);
                if (listOption) {
                  setFieldValue('classId', searchParams.class, false);
                }
              }
              handleChange(e);
              setFieldTouched('qt_api', true, false);
            }}
          />
        </Box>
      )}
      {values.dynamic_variable && Array.isArray(values.dynamic_variable) ? (
        <Box direction="row" mt="3" color={'red'}>
          The following variables will change on a page-to-page basis (
          {values.dynamic_variable.map((val, idx) => {
            return (
              <chakra.span key={`${val}-${idx}`} ml={idx !== 0 ? 1 : null}>
                {val}
                {['home', 'away'].includes(val) ? ' team' : null}
                {idx !== values.dynamic_variable.length - 1 ? ',' : null}
              </chakra.span>
            );
          })}
          )
        </Box>
      ) : null}
      {is_staff === 1 && <StaffFields />}
      {editMode && classListOptions.length !== 0 && (
        <Box mt="3">
          <FormSelect
            name="classId"
            label="Class"
            options={classListOptions}
            placeholder="Select class"
            isHorizontal
          />
        </Box>
      )}
      {!editMode && classId ? (
        <Box mt="3">Class: {classIdLabel(classListOptions, classId)}</Box>
      ) : null}
      <Box pt={2} pb={2}>
        <FormCategorySelector
          name="category_type_id"
          label="Category"
          pageType={pageType}
          value={values.category_type_id}
          classId={values.classId}
        />
      </Box>
      <Box mt="3">
        <PanelType values={values} videoOptionAvailable={videoOptionAvailable} />
      </Box>
      <DynamicNameVariables
        pageType={pageType}
        objectId={objectId}
        values={values}
        setFieldValue={setFieldValue}
      />
      {values.panel_type_id !== '4' && (
        <Box my="3">
          <Columns panel_type_id={values.panel_type_id} columnData={columnData} />
        </Box>
      )}
      {values.panel_type_id === '1' ? (
        <Box
          mt={2}
          p={2}
          mb={5}
          w="100%"
          textAlign="center"
          variant="simple"
          className="table-tiny"
          size="sm"
          background="white"
          border="1px"
          borderColor="cricket.50"
          boxShadow="lg"
        >
          {transformPanelText(values.data_value, !editMode ? sampleData : dataRow, false)}
        </Box>
      ) : null}
    </>
  );
};

export default QTPanelForm;
