import BarItem from './BarItem';
import CircleItem from './CircleItem';

const StatsItem = ({ data }) => {
  if (data.type === 'bar') return <BarItem data={data} />;
  if (data.type === 'circle') return <CircleItem data={data} />;
  return null;
};

export default StatsItem;
