import { Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

const HeaderCell = ({ params }) => {
  const { content, tooltip, isNumeric, style } = params;
  if (tooltip) {
    return (
      <Th isNumeric={isNumeric} {...style}>
        <Tooltip label={tooltip} placement="top">
          {content}
        </Tooltip>
      </Th>
    );
  }
  return (
    <Th isNumeric={isNumeric} {...style}>
      {content}
    </Th>
  );
};

const TableHeader = ({ columns, bg }) => {
  return (
    <Thead bg={bg}>
      <Tr>
        {columns.map((el, index) => {
          return <HeaderCell params={el} key={index} />;
        })}
      </Tr>
    </Thead>
  );
};

export default TableHeader;
