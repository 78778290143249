import { useEffect, useState } from 'react';

const useGraphPlotKey = (panelData, layoutInfo) => {
  const [graphKey, setGraphKey] = useState('1');
  const { panel_id } = panelData;
  useEffect(() => {
    const { width = 4, height = 10 } = layoutInfo;
    setGraphKey(`${panel_id}_W${width}_h${height}`);
  }, [layoutInfo, panel_id]);
  return graphKey;
};

export default useGraphPlotKey;
