import { Flex } from '@chakra-ui/react';

import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';

import FormClassSelector from 'Forms/_shared/FormElements/FormClassSelector';
import FormReactSelect from 'Forms/_shared/FormElements/FormReactSelect';
import { getTeamsClass } from '_shared/dataFetching/apiService';
import { dateTypeOptions } from './formConfig';
import FormChakraDatePicker from 'Forms/_shared/FormElements/FormChakraDatePicker';

const UserConfigForm = ({ values, setFieldValue }) => {
  const var_class_id = values?.class_id?.value;
  const { data: teamListData = [] } = useQueryFetch(
    [var_class_id],
    () => getTeamsClass(var_class_id),
    {
      select: (d) => {
        return d.results.map(({ id, text }) => {
          return {
            value: id,
            label: text
          };
        });
      },
      enabled: !!var_class_id
    }
  );

  return (
    <Flex direction="column" gap="4" pt={4} pb={12}>
      <FormClassSelector
        name="class_id"
        label="Default format"
        placeholder="Select format"
        onChangeCustom={(e) => {
          setFieldValue('team_id', null, false);
          setFieldValue('class_id', e);
        }}
      />
      <FormReactSelect
        name="between_dates"
        label="Default date range"
        placeholder="Select range"
        options={dateTypeOptions}
      />
      {values.between_dates.value === 'custom' ? (
        <Flex gap="2">
          <FormChakraDatePicker name="custom_date_start" />
          <FormChakraDatePicker name="custom_date_end" />
        </Flex>
      ) : null}
      <FormReactSelect
        name="team_id"
        label="Home team"
        placeholder="Search"
        options={teamListData}
        isClearable
        isDisabled={!values.class_id}
        menuPlacement="top"
      />
    </Flex>
  );
};

export default UserConfigForm;
