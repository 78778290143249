import { Grid, Flex, List } from '@chakra-ui/react';
import { useState } from 'react';
import FlexHeaderScroll from '_shared/components/LayoutComponents/FlexHeaderScroll';
import ButtonSelector from '_shared/components/ButtonSelector';
import FixtureComponent from '_shared/components/FixtureComponent/FixtureComponent';
import CompFavListItem from './CompFavListItem';
import PlayerFavListItem from './PlayerFavListItem';
import TeamFavListItem from './TeamFavListItem';
import { getFilterOptions } from './utils';
import NoDataDisplay from 'panels/Panel/NoDataDisplay';
import GroundFavListItem from './GroundFavListItem';

const UserFavouritesPanel = ({ panelData }) => {
  const { data } = panelData;
  const { favouriteTypes } = getFilterOptions(panelData);
  const [favouriteType, setFavouriteType] = useState(
    favouriteTypes.length > 0 ? favouriteTypes[0] : null
  );

  const getDisplay = () => {
    if (favouriteType === 'comp' && data?.comp) {
      return (
        <Grid m="2" templateColumns="repeat(auto-fill,minmax(250px, 1fr))" gap="2">
          {data.comp.map((val) => {
            return <CompFavListItem item={val} />;
          })}
        </Grid>
      );
    }
    if (favouriteType === 'player' && data?.player) {
      return (
        <Grid m="2" templateColumns="repeat(auto-fill,minmax(300px, 1fr))" gap="2">
          {data.player.map((val) => {
            return <PlayerFavListItem item={val} />;
          })}
        </Grid>
      );
    }
    if (favouriteType === 'team' && data?.team) {
      return (
        <Grid m="2" templateColumns="repeat(auto-fill,minmax(250px, 1fr))" gap="2">
          {data.team.map((val) => {
            return <TeamFavListItem item={val} />;
          })}
        </Grid>
      );
    }
    if (favouriteType === 'fixtures') {
      return (
        <List m="4" spacing="2">
          {data?.match?.live
            ? data.match.live.map((val) => {
                return <FixtureComponent matchData={val} />;
              })
            : null}
          {data?.match?.forthcoming
            ? data.match.forthcoming.map((val) => {
                return <FixtureComponent matchData={val} />;
              })
            : null}
        </List>
      );
    }
    if (favouriteType === 'results') {
      return (
        <List m="4" spacing="2">
          {data?.match?.complete
            ? data.match.complete.map((val) => {
                return <FixtureComponent matchData={val} />;
              })
            : null}
        </List>
      );
    }
    if (favouriteType === 'ground' && data?.ground) {
      return (
        <Grid m="2" templateColumns="repeat(auto-fill,minmax(250px, 1fr))" gap="2">
          {data.ground.map((val) => {
            return <GroundFavListItem item={val} />;
          })}
        </Grid>
      );
    }
    return null;
  };

  if (favouriteTypes.length === 0) {
    return (
      <NoDataDisplay message="You don't have any favourite pages yet. Click the 'star' icon on any Player, Team, Match or Competition page for a shortcut to appear here." />
    );
  }

  return (
    <FlexHeaderScroll
      header={
        favouriteTypes.length !== 0 ? (
          <Flex px="2" py="2" gap="1">
            <ButtonSelector
              handleChange={setFavouriteType}
              value={favouriteType}
              options={favouriteTypes}
              // buttonMinWidth="100px"
            />
          </Flex>
        ) : null
      }
      content={getDisplay()}
    />
  );
};

export default UserFavouritesPanel;
