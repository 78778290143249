import { createIcon } from '@chakra-ui/icons';

const Download = createIcon({
  displayName: 'Download',
  viewBox: '0 0 24 24',
  defaultProps: { width: '24px', height: '24px' },
  path: [
    <path
      d="M16.2929 9.29289L13 12.5858L13 3C13 2.44771 12.5523 2 12 2C11.4477 2 11 2.44771 11 3L11 12.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L17.7071 10.7071C18.0976 10.3166 18.0976 9.68342 17.7071 9.29289C17.3166 8.90237 16.6834 8.90237 16.2929 9.29289Z"
      fill="currentColor"
    />,
    <path
      d="M6 16C6 15.4477 5.55228 15 5 15C4.44772 15 4 15.4477 4 16V19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V16C20 15.4477 19.5523 15 19 15C18.4477 15 18 15.4477 18 16V19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V16Z"
      fill="currentColor"
    />
  ]
});

export default Download;
