import { Box, Flex, Avatar } from '@chakra-ui/react';
import capitalise from '_shared/utils/capitalise';
import GridCardLink from '_shared/components/DesignSystem/GridCardLink';

const PlayerCard = ({ item }) => {
  const { id, known_as, player_img, player_role_short } = item;
  const name = known_as.split(' ');
  const firstName = name.length > 1 ? name[0] : '';
  const lastName = name.length > 1 ? name.slice(1).join(' ') : name.length === 1 ? name[0] : '';
  return (
    <GridCardLink to={`/player/${id}`}>
      <Flex direction="column" alignItems="center" gap="1">
        <Avatar size="lg" src={player_img ? player_img : null} />
        <Box>
          <Box fontSize="xs" textAlign="center" w="100%">
            {firstName}
          </Box>
          <Box fontSize="sm" fontWeight="700" textAlign="center" w="100%" lineHeight="1.2">
            {lastName}
          </Box>
        </Box>
        <Box fontSize="xs" color="black.60" textAlign="center">
          {capitalise(player_role_short)}
        </Box>
      </Flex>
    </GridCardLink>
  );
};

export default PlayerCard;
