import { Box, Flex, Show } from '@chakra-ui/react';
import { useState } from 'react';
import Header from 'content/_components/header/Header';
import ButtonSelector from '_shared/components/ButtonSelector';
import ReactTable from '_shared/components/ReactTable/ReactTable';
import TeamImage from '_shared/components/TeamImage';
import RouterLink from '_shared/components/Utils/RouterLink';

const CompTable = ({ content }) => {
  const { data, header, filters } = content;
  const tableData = data?.data;
  const { primary } = filters;
  const hasGroupStages = primary.length > 0;
  const [group, setGroup] = useState(hasGroupStages ? primary[0].value : null);
  const filteredData = tableData.filter((el) => {
    if (!hasGroupStages) return true;
    return el.filter.primary === group;
  });
  const columns = createColumns(data?.header);
  return (
    <Box height="100%">
      <Header title={header} />
      {hasGroupStages && (
        <Box mx={4} mb={4}>
          <ButtonSelector
            options={primary}
            value={group}
            handleChange={setGroup}
            fullWidth
            color="primary.main"
          />
        </Box>
      )}
      <Box>
        <ReactTable columns={columns} data={filteredData} size="md" rowHeight="72px" />
      </Box>
    </Box>
  );
};
export default CompTable;

function createColumns(tableColumnsData = []) {
  const columns = [
    {
      label: '',
      accessor: 'team',
      tipText: '',
      Cell(props) {
        const rowData = props.cell?.row?.original || {};
        const { position, abbreviation, logo_url, short_name, team_id } = rowData;
        return (
          <Flex gap={3} alignItems="center">
            <Box fontWeight="bold" w="20px" fontSize="sm">
              {position}
            </Box>
            <Box>
              <TeamImage
                team={{ logo: logo_url, name: short_name, id: team_id }}
                size={42}
                type="circle"
              />
            </Box>
            <RouterLink fontWeight="bold" fontSize="16" to={`/team/${team_id}`}>
              <Show below="sm">{abbreviation}</Show>
              <Show above="sm">{short_name}</Show>
            </RouterLink>
          </Flex>
        );
      }
    }
  ];
  tableColumnsData.forEach((el) => {
    if (el.label) {
      const colValue = {
        Header: el.label,
        accessor: el.key,
        tipText: el.tooltip || '',
        isNumeric: true,
        bold: !!el.bold,
        fontSize: 'sm'
      };
      columns.push(colValue);
    }
  });
  return columns;
}
