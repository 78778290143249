import { useState } from 'react';
import { playerSearch } from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import useDebounce from './useDebounce';

const useSearch = (type, isDisabled) => {
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = useDebounce(searchValue, 500);
  const { data: userData = {} } = useUserDetails();

  const { data, isLoading, error } = useQueryFetch(
    ['search', debouncedValue, type],
    () => playerSearch(debouncedValue,type),
    {
      enabled: !isDisabled || userData.user_class_id
    }
  );

  const filterFunction = (item) => {
    if (!type || type === 'all') {
      return true;
    }
    let include = false;
    const splitArray = item.url.split('/');
    splitArray.forEach((el) => {
      if (el === type) {
        include = true;
      }
    });
    return include;
  };

  const filteredData = data ? data.filter(filterFunction) : [];

  return { searchValue, setSearchValue, data: filteredData, isLoading, error };
};

export default useSearch;
