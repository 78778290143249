const getInitDataSet = (color) => {
  return {
    data: [],
    backgroundColor: `${color}33`,
    borderColor: `${color}`,
    pointBorderColor: '#FFFFFF',
    pointBackgroundColor: `${color}`
  };
};

const parseData = (dataSets = [], legendColors = []) => {
  const parsedData = {
    labels: [],
    datasets: []
  };
  dataSets.forEach((el, idx) => {
    const dataset = getInitDataSet(legendColors[idx]);
    el?.data?.forEach((item) => {
      dataset.data.push(item?.value);
      if (idx === 0) {
        parsedData.labels.push(item?.label?.split(' '));
      }
    });
    parsedData.datasets.push(dataset);
  });
  return parsedData;
};

export default parseData;
