import { Box, Button, Flex, Select } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import FormTextarea from 'Forms/_shared/FormElements/FormTextarea';

const ColumnText = ({ columnData }) => {
  const [value, setValue] = useState('');
  const { setFieldValue, values } = useFormikContext();
  const handleAddText = () => {
    setFieldValue('data_value', `${values.data_value}{{${value}}}`);
  };
  return (
    <Box>
      <FormTextarea name="data_value" label="Text" />
      <Flex>
        <Select value={value} onChange={(e) => setValue(e.target.value)} placeholder="Select column to add...">
          {columnData.map((item) => {
            return (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </Select>
        <Button onClick={handleAddText}>Add +</Button>
      </Flex>
    </Box>
  );
};

export default ColumnText;
