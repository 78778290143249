import { FormControl, FormErrorMessage, FormLabel, Textarea } from '@chakra-ui/react';
import { Field } from 'formik';

const FormTextarea = ({ name, label }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[name] && form.touched[name]}>
          <FormLabel>{label}</FormLabel>
          <Textarea {...field} size="sm" />
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default FormTextarea;
