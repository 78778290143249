const team = [
  {
    value: 'summary',
    label: 'Summary'
  },
  {
    value: 'playerstats',
    label: 'Player statistics',
    labelSmall: 'Player stats'
  },
  {
    value: 'teamstats',
    label: 'Team statistics',
    labelSmall: 'Team stats'
  },
  {
    value: 'advanced',
    label: 'Advanced analysis',
    labelSmall: 'Advanced'
  }
];

export default team;
