import axios from 'axios';
import { getLocalStorageAuthTokens } from '_shared/utils/localStorage';
import { createUUID } from '_shared/utils/utilFunctions';
import { refreshUserTokens } from './authService';
import { getApiUrl } from './urlConfig';
import checkIncludeUUID from './checkIncludeUUID';
import QueryString from 'qs';

export const fetchClient = axios.create({
  baseURL: getApiUrl(),
  withCredentials: true,
  params: {},
  paramsSerializer(params) {
    return QueryString.stringify(params, { indices: false });
  }
});

fetchClient.interceptors.request.use((config) => {
  if (checkIncludeUUID(config)) {
    config.params.cveasbp = createUUID();
  }
  const cvapiDev = config?.url && config?.url.includes('http://127.0.0.1:');
  const token = getLocalStorageAuthTokens();
  if (token.accessToken && !cvapiDev) {
    config.headers.Authorization = `Bearer ${token.accessToken}`;
  }
  config.headers['http.X-Varnish-Pass'] = 'false';
  if (cvapiDev) {
    config.baseURL = '';
  }
  return config;
});

// code adapted from TennisViz example - Moin
fetchClient.interceptors.response.use(
  (response) => response,
  async function (error) {
    // this gets called if the API request has returned any kind of error
    const originalRequest = error.config;
    if ([401, 422].includes(error.response.status) && !originalRequest._retry) {
      // access token is invalid, lets try and refresh the token
      originalRequest._retry = true;
      const res = await refreshUserTokens();
      if (res.status !== 200) {
        if (res !== 'refreshInProgress') {
          // the refresh process has failed, so log the user out
          // reject with error so reactQuery can handle logout
          return Promise.reject(error);
        }
        // another refresh is currently in progress, reject as error so reactQuery can try again
        return Promise.reject('Token refresh currently in progress');
      }
      // refresh has returned 200, so succeeded. New tokens are in local storage, so try the request again
      return fetchClient(originalRequest);
    }
    // we either: errored, successfully refreshed token, tried again with the new tokens, but the request has failed again. So lets error out.
    // or the error isn't a 401, in which case error out
    return Promise.reject(error);
  }
);
