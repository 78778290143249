import { Box, Flex, Center, Spinner, Tooltip, IconButton } from '@chakra-ui/react';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import { useMatch, useNavigate, useSearch } from '@tanstack/react-location';
import { getVideoFilters } from '_shared/dataFetching/apiService';
import VideoFiltersContainer from 'pages/videoSearch/components/VideoFilters/VideoFiltersContainer';
import { convert64ToJSON } from '_shared/utils/utilFunctions.js';
// import { selectedButton } from './VideoSearchUtils';
import { handlePermissions } from '_shared/utils/utilFunctions';
import FullScreenAuthError from '_shared/errors/FullScreenAuthError';
import { ArrowLeftOutline24, ListBulletOutline24 } from '_icons/outline/24';

const VideoFiltersPage = () => {
  const {
    params: { classId, objectId, objectType }
  } = useMatch();
  const search = useSearch();
  const navigate = useNavigate();
  const { data: userData = {} } = useUserDetails();
  const { q } = search;
  const params = q ? convert64ToJSON(q) : {};
  const { permissions } = userData;
  const VCPerm = handlePermissions(10, permissions);
  const {
    isLoading: objectIsLoading,
    error: objectError,
    data: objectData,
    isFetching: objectIsFetching
  } = useQueryFetch(
    [objectType, objectId, classId],
    () => getVideoFilters(objectType, objectId, classId),
    {
      staleTime: 30 * 1000
    }
  );
  if (!VCPerm) {
    return <FullScreenAuthError />;
  }
  if (objectIsLoading) {
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    );
  }
  if (objectError && !objectIsFetching) {
    return (
      <Box>{`Unable to retrieve video filters for ${objectType} ${objectId}: ${objectError.message}`}</Box>
    );
  }

  const displayContent = () => {
    if (objectError && !objectIsFetching) {
      return (
        <Box>{`Unable to retrieve video filters for ${objectType} ${objectId}: ${objectError.message}`}</Box>
      );
    }
    if (objectIsLoading) {
      return (
        <Flex flexGrow="1" align="center" justify="center">
          <Spinner />
        </Flex>
      );
    }
    if (objectData) {
      const { filters, object_info, class_info } = objectData;
      return (
        <Flex justify="center">
          <Box
            m="10"
            maxW="1400px"
            bg="white"
            borderRadius="10px"
            boxShadow="md"
            p="10"
            flexGrow="1"
          >
            <VideoFiltersContainer
              objectData={objectData}
              presetFilters={params}
              filters={filters}
              classId={classId}
              objectType={objectType}
              objectId={objectId}
              folding={true}
              object_info={object_info}
              class_info={class_info}
            />
          </Box>
        </Flex>
      );
    }
  };

  return (
    <>
      <Flex flex="0 0 64px" bg="primary.shade.70" px="5" align="center" justify="space-between">
        <Flex gap="2">
          <Tooltip label="Back to search home">
            <IconButton
              icon={<ArrowLeftOutline24 />}
              variant="ghostDark"
              onClick={() => navigate({ to: `/video_search/` })}
            />
          </Tooltip>
          <Tooltip label="Video playlists">
            <IconButton
              icon={<ListBulletOutline24 />}
              variant="ghostDark"
              onClick={() => navigate({ to: `/video_search/` })}
            />
          </Tooltip>
        </Flex>
        <Flex color="white" fontSize="md" fontWeight="700">
          {objectData?.playlist_data?.playlist_name}
        </Flex>
      </Flex>
      <Flex direction="column" flexGrow="1" overflowY="scroll">
        {displayContent()}
      </Flex>
    </>
  );

  // if (objectData) {
  //   const { filters, object_info, class_info } = objectData;

  //   return (
  //     <Box overflowY="scroll">
  //       <Box
  //         maxW="1,286px"
  //         margin="auto"
  //         bg="white"
  //         borderRadius="10px"
  //         boxShadow="md"
  //         p="10"
  //         flexGrow="1 "
  //         color="black.100"
  //         m={20}
  //         minW="70%"
  //       >
  //         <Box fontSize="lg" fontWeight={'bold'} marginBottom={'10px'}>
  //           Refine your search
  //         </Box>
  //         <Flex fontSize="sm" gap={3}>
  //           {selectedButton('Format', class_info.short_name, 'white')}
  //           {selectedButton(
  //             'Criteria',
  //             `${objectType[0].toUpperCase()}${objectType.slice(1)}`,
  //             'white'
  //           )}
  //           {selectedButton('Keyword', object_info.header, 'white')}
  //         </Flex>

  //         <VideoFiltersContainer
  //           presetFilters={params}
  //           filters={filters}
  //           classId={classId}
  //           objectType={objectType}
  //           objectId={objectId}
  //           folding={true}
  //         />
  //       </Box>
  //     </Box>
  //   );
  // }
};

export default VideoFiltersPage;
