import { Select } from '@chakra-ui/react';

const SelectFilter = ({ value, options, handleChange }) => {
  return (
    <Select value={value} onChange={(e) => handleChange(e.target.value)} bg="white">
      {options.map((item) => {
        const { value, label } = item;
        return (
          <option value={value} key={value}>
            {label}
          </option>
        );
      })}
    </Select>
  );
};

export default SelectFilter;
