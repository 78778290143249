import { Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';
import { bannerAlertType } from '_shared/constants/alertTypes';

const ErrorMessage = (props) => {
  const { title, description } = props;
  return (
    <Alert status={bannerAlertType.ERROR} variant="left-accent">
      <AlertIcon />
      {title && <AlertTitle mr={2}>{title}</AlertTitle>}
      <AlertDescription>{description}</AlertDescription>
    </Alert>
  );
};

export default ErrorMessage;
