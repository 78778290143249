import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  List,
  ListItem,
  Center
} from '@chakra-ui/react';
import AddUserFavourites from '_shared/components/AddUserFavourites';
import { getColor } from './utils';

const VideoListItem = ({ item, handleClick, idx, selectedVideo }) => {
  const {
    ball_id,
    overs_unique,
    scoring,
    opposition,
    opposition_abbreviation,
    team_abbreviation,
    team_score,
    text_commentary,
    url,
    batting_balls_faced,
    batting_runs
  } = item;
  return (
    <ListItem
      key={url}
      bg="black.3"
      rounded="md"
      border="1px"
      borderColor={selectedVideo === idx ? 'primary.main' : 'white'}
      outline={selectedVideo === idx && 'solid'}
      outlineColor="primary.main"
      _hover={{
        bg: 'white.100',
        borderColor: selectedVideo === idx ? 'primary.main' : 'black.24',
        boxShadow: 'small'
      }}
      cursor="pointer"
      id={`search_result_${idx}`}
      p="4"
      onClick={handleClick}
    >
      <Flex flexGrow="1" justifyContent="space-between" alignItems="center" gap="6px">
        <Flex gap="10px" fontSize="14px" fontWeight="700">
          <Box>{overs_unique}</Box>
          <Center
            w="5"
            h="5"
            fontSize="14px"
            bg={getColor(item, 'bg')}
            color={getColor(item, 'color')}
            p={1}
          >
            {scoring}
          </Center>
          {team_abbreviation && opposition_abbreviation ? (
            <Box>
              {team_abbreviation} v {opposition_abbreviation}
            </Box>
          ) : (
            <Box>v {opposition}</Box>
          )}
          <Box>{team_score}</Box>
          <Box flex="2">
            R {batting_runs}({batting_balls_faced})
          </Box>
        </Flex>
        <AddUserFavourites favType="video" favId={ball_id} lightBackground />
      </Flex>
      <Box fontSize="sm">{text_commentary}</Box>
    </ListItem>
  );
};

const VideoListModal = ({ isOpen, onClose, panel_name, data, handleClick, selectedVideo }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md" fontWeight="700" alignItems="center" p="3">
          {panel_name}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflow="auto" borderTop="1px" borderColor="black.8" id="video_list" px="3">
          {data && (
            <List spacing={2}>
              {data.map((item, idx) => {
                return (
                  <VideoListItem
                    key={item.ball_id}
                    item={item}
                    selectedVideo={selectedVideo}
                    handleClick={() => handleClick(idx)}
                    idx={idx}
                  />
                );
              })}
            </List>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default VideoListModal;
