import { useState } from 'react';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/form-control';
import { Box, Button, Flex, Image, Input } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { validateCredentialsToken } from '_shared/dataFetching/authService';
import { setLocalStorageAuthTokens } from '_shared/utils/localStorage';
import ErrorMessage from '_shared/components/Utils/ErrorMessage';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { getUserInfo } from '_shared/dataFetching/apiService';
const formSubmitErrorMessage = 'Your user name and password were not recognised, please try again';

const LoginForm = () => {
  const { r: redirectUrl } = useSearch();
  const navigate = useNavigate();
  const [formSubmitError, setFormSubmitError] = useState(null);
  const [showForm, setShowForm] = useState(false);

  useQuery(['user'], () => getUserInfo(), {
    retry: false,
    onError: () => setShowForm(true),
    onSuccess: () => {
      navigate({ to: '/home' });
    }
  });

  const mutation = useMutation(
    (credentials) =>
      validateCredentialsToken({
        credentials
      }),
    {
      onSuccess: (data, variables) => {
        if (data?.access_token) {
          setLocalStorageAuthTokens(data?.access_token, data?.refresh_token, variables.userName);
          if (!redirectUrl) {
            navigate({ to: '/home' });
            return;
          }
          const redirect = atob(redirectUrl);
          if (redirect !== '/login') {
            navigate({ to: redirect });
            return;
          }
          navigate({ to: '/home' });
        } else {
          setFormSubmitError(formSubmitErrorMessage);
        }
      },
      onError: () => {
        setFormSubmitError(formSubmitErrorMessage);
      }
    }
  );

  return (
    <Box
      backgroundSize="cover"
      backgroundPosition="center"
      bgImage="https://cv-qtp-images.s3.eu-west-2.amazonaws.com/UK_kiaoval_sunset.jpg"
      h="100vh"
      w="100vw"
    >
      {showForm ? (
        <Flex
          justifyContent={{ base: 'center', lg: 'flex-end' }}
          alignItems="center"
          h="100%"
          mx={{ base: '0px', lg: '200px' }}
        >
          <Box w="360px" bg="white" rounded="10px" p="8">
            <Image
              src="https://cv-qtp-images.s3.eu-west-2.amazonaws.com/cricviz-logo.png"
              mb="10"
            />
            <Box fontSize="4xl" mb="4">
              Sign in
            </Box>
            <Formik
              initialValues={{ userName: '', password: '' }}
              validate={(values) => {
                setFormSubmitError(null);
                const errors = {};
                if (!values.userName) {
                  errors.userName = 'Please enter your user name';
                }
                if (!values.password) {
                  errors.password = 'Please enter your password';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                mutation.mutate(values);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, values, errors, touched, handleChange, handleBlur }) => (
                <Form>
                  <Flex direction="column" gap="20px">
                    <FormControl isInvalid={errors.userName && touched.userName}>
                      <FormLabel
                        htmlFor="userName"
                        fontSize="md"
                        fontWeight="700"
                        color="black.76"
                        mb="1"
                      >
                        Username
                      </FormLabel>
                      <Input
                        id="userName"
                        name="userName"
                        placeholder="Username"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.userName}
                      />
                      <FormErrorMessage>{errors.userName}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.password && touched.password}>
                      <FormLabel
                        htmlFor="password"
                        fontSize="md"
                        fontWeight="700"
                        color="black.76"
                        mb="1"
                      >
                        Password
                      </FormLabel>
                      <Input
                        id="password"
                        name="password"
                        placeholder="Password"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                      <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>
                    {formSubmitError && <ErrorMessage description={formSubmitError} />}
                    <Button
                      mt="4"
                      spinnerPlacement="start"
                      type="submit"
                      disabled={isSubmitting}
                      h="44px"
                      rounded="8px"
                    >
                      Sign in
                    </Button>
                  </Flex>
                </Form>
              )}
            </Formik>
          </Box>
        </Flex>
      ) : null}
    </Box>
  );
};

export default LoginForm;
