const config = {
  default: {
    panelColSpan: { base: 12, md: 6 }
  },
  classId: {
    1: {
      panelColSpan: 12
    }
  }
};

export default config;
