import { Box, Flex, Icon, Avatar, Image } from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useRecoilState } from 'recoil';
import AddUserFavourites from '_shared/components/AddUserFavourites';
// import CommentThread from 'pages/_shared/components/CommentThread/CommentThread';
import { HEADER_EXPANDED } from '_shared/globalState/atoms';
import TeamDetails from './TeamDetails';

const TeamSubHeader = ({ data }) => {
  const [headerExpanded, setHeaderExpanded] = useRecoilState(HEADER_EXPANDED);
  const expanded = headerExpanded.team || false;
  const { team_info } = data;
  return (
    <Box
      h={expanded ? '271px' : '80px'}
      bg="primary.shade.70"
      transitionProperty="height"
      transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
      transitionDuration="500ms"
    >
      <Flex justify="space-between" py={4} px={5} gap="5">
        <Box
          boxSize={expanded ? '48' : '12'}
          mx={expanded ? '2' : '0'}
          my={expanded ? '5' : '0'}
          transitionProperty="height width"
          transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
          transitionDuration="500ms"
        >
          <Avatar
            size="full"
            bg="white"
            src={
              team_info.logo_thumb_url
                ? team_info.logo_thumb_url
                : team_info.logo_url
                ? team_info.logo_url
                : null
            }
          />
        </Box>
        <Flex flexGrow={1} direction="column" gap="10px">
          <Flex h="48px" alignItems="center">
            <Flex flexGrow={1} alignItems="center" gap="3">
              <Box color="white" fontSize={20} fontWeight="700">
                {team_info.team_short_name}
              </Box>
              {team_info.country_id !== team_info.team_id ? (
                <Box>
                  <Image
                    ml={2}
                    display="inline-block"
                    w={10}
                    src={
                      team_info.flag_thumb_url
                        ? team_info.flag_thumb_url
                        : team_info.flag_url
                        ? team_info.flag_url
                        : null
                    }
                  />
                </Box>
              ) : null}
            </Flex>
            {/* <Flex gap="3">
              <CommentThread pageType="team" pageId={team_info.team_id} />
            </Flex> */}
            <Flex gap="3">
              <AddUserFavourites favType="team" favId={team_info.team_id} />
              <Icon
                cursor="pointer"
                boxSize={6}
                color="white.100"
                as={expanded ? FiChevronUp : FiChevronDown}
                onClick={() =>
                  setHeaderExpanded((p) => {
                    const currentValue = p.team || false;
                    return { ...p, team: !currentValue };
                  })
                }
              />
            </Flex>
          </Flex>
          {expanded ? <TeamDetails teamInfo={team_info} /> : null}
        </Flex>
      </Flex>
    </Box>
  );
};

export default TeamSubHeader;
