/* eslint-disable no-restricted-syntax */
const organiseSearchParams = (urlParams) => {
  if (!urlParams) {
    return 'match';
  }
  const dynamicVariableOpts = ['comp', 'event', 'country', 'team'];
  const returnArr = [];
  dynamicVariableOpts.forEach((el) => {
    if (!urlParams[el]) {
      return;
    }
    if (el !== 'team') {
      returnArr.push(el);
      return;
    }
    if (Array.isArray(urlParams[el])) {
      returnArr.push('home');
      returnArr.push('away');
    } else {
      returnArr.push('home');
    }
  });
  if (returnArr.length > 0) {
    return returnArr;
  }
  return 'match';
};

export default organiseSearchParams;
