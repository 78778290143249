import { useState, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Player, ControlBar } from 'video-react';

import VideoEditorBall from './VideoEditorBall';
import EditVideo from './EditVideo';

const VideoEditorContainer = ({
  el,
  lastVid,
  autoPlay,
  vidInd,
  editItem,
  setAutoPlay,
  videoRef,
  editAtt,
  setEditAtt,
  changeVideo,
  removeItem,
  listLength,
  changeSpeed,
  changeSound,
  vidList,
  speedVal,
  selectedVideos,
  setSelectedVideos
}) => {
  const [playerHover, setPlayerHover] = useState(false);
  const changeInput = (e) => {
    videoRef.current.actions.seek(e[1]);
    setEditAtt({ ...editAtt, video_start: e[0], video_end: e[2] });
  };
  const [currentTime, setCurrentTime] = useState(0);
  const handleInputChangeEnd = (e) => {
    const videoEl = videoRef?.current?.video?.video;
    if (videoEl.currentTime >= e[2]) {
      videoEl.pause();
    }

    if (videoRef.current.currentTime !== e[1]) {
      videoRef.current.actions.seek(e[1]);
    }
    setEditAtt({ ...editAtt, video_start: e[0], video_end: e[2] });
  };

  useEffect(() => {
    // ensures container does not load without video element being attached to ref

    if (!videoRef.current) {
      return;
    }
    // ensures that video can't go full screen

    videoRef.current.actions.toggleFullscreen = () => {};
    videoRef.current.video.video.playbackRate = editAtt?.speed ? editAtt?.speed : 1;
    videoRef.current.muted = !editAtt?.sound;
    videoRef.current.actions.handleTimeUpdate = (event) => {
      const { currentTime } = event;
      setCurrentTime(currentTime);
      const { video_end, video_start } = el;
      const editAttEnd = editAtt.video_end;

      if (video_end) {
        if (currentTime > video_end) {
          if (autoPlay) {
            if (lastVid) {
              changeVideo(0);
            } else {
              changeVideo(vidInd + 1);
            }
          } else {
            videoRef.current.actions.seek(video_start, 'seconds');
            videoRef.current.actions.play();
          }
        }
      } else {
        if (editAttEnd) {
          if (currentTime > editAttEnd) {
            if (autoPlay) {
              if (lastVid) {
                changeVideo(0);
              } else {
                changeVideo(vidInd + 1);
              }
            } else {
              videoRef.current.actions.seek(video_start, 'seconds');
              videoRef.current.actions.play();
            }
          }
        }
      }
    };
  }, [videoRef, el, autoPlay, lastVid, vidInd, editAtt, changeVideo, setCurrentTime]);
  return (
    <Flex direction="column" borderRadius="md" overflow="hidden">
      <Box
        flex="1"
        sx={{
          '& .video-control-bar': {
            bg: 'none'
          }
        }}
        onMouseOver={() => setPlayerHover(true)}
        onMouseLeave={() => setPlayerHover(false)}
      >
        <Player
          ref={(player) => (videoRef.current = player)}
          preload="auto"
          controls={false}
          playsInline={true}
          autoPlay={true}
          fluid={false}
          width={'100%'}
          height={'100%'}
          // muted={true}
          className="video-player-panel"
          startTime={el?.video_start}
          endTime={el?.video_end}
          src={el?.video_url}
          onEnded={() => {
            if (autoPlay) {
              if (lastVid) {
                changeVideo(0);
              } else {
                changeVideo(vidInd + 1);
              }
            } else {
              videoRef.current.actions.seek(el?.video_start, 'seconds');
              videoRef.current.actions.play();
            }
          }}
        >
          <ControlBar autoHide disableDefaultControls={true} className="video-control-bar">
            <EditVideo
              el={el}
              editItem={editItem}
              videoRef={videoRef}
              setAutoPlay={setAutoPlay}
              editAtt={editAtt}
              setEditAtt={setEditAtt}
              changeInput={changeInput}
              handleInputChangeEnd={handleInputChangeEnd}
              currentTime={currentTime}
              isHidden={!playerHover}
            />
          </ControlBar>
        </Player>
      </Box>
      <Flex direction="column">
        <VideoEditorBall
          el={el}
          ballId={el.ball_id}
          videoInd={vidInd}
          removeItem={removeItem}
          listLength={listLength}
          autoPlay={autoPlay}
          setAutoPlay={setAutoPlay}
          sound={editAtt.sound}
          changeSound={changeSound}
          speedVal={speedVal}
          changeSpeed={changeSpeed}
          changeVideo={changeVideo}
          vidList={vidList}
          editItem={editItem}
          setEditAtt={setEditAtt}
          editAtt={editAtt}
          isChecked={selectedVideos.includes(el.ball_id)}
          selectedVideos={selectedVideos}
          setSelectedVideos={setSelectedVideos}
        />
      </Flex>
    </Flex>
  );
};

export default VideoEditorContainer;
