import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Center,
  Button,
  Box,
  Flex
} from '@chakra-ui/react';

import { useQueryClient } from 'react-query';
import {
  getUserFeedbackNotifications,
  postUserFeedbackNotifications
} from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import { useEffect, useState } from 'react';

const UserFeedbackMessage = ({ surveyLevel, form_url, handleClose }) => {
  const queryClient = useQueryClient();
  const postPayload = { notify_on: 1, survey_level: surveyLevel + 1 };
  const removeNotificationsPayload = { notify_on: 0, survey_level: surveyLevel };

  const removeNotifications = async (removeForm) => {
    const res = await postUserFeedbackNotifications(removeForm);
    if (res.status === 200) {
      queryClient.setQueryData(['user_feedback', res.data], (data) => {
        return data;
      });
    } else {
      console.log('POST ERROR');
    }
    handleClose();
  };
  const surveyForm = async (form_url, postPayload) => {
    const res = await postUserFeedbackNotifications(postPayload);
    if (res.status === 200) {
      queryClient.setQueryData(['user_feedback', res.data], (data) => {
        return data;
      });
    } else {
      console.log('POST ERROR');
    }
    window.open(form_url, '_blank');
    handleClose();
  };

  const addButton = (
    <Button fontSize="md" onClick={() => surveyForm(form_url, postPayload)}>
      Yes, I’d like to help
    </Button>
  );
  const removeButton = (
    <Button
      variant="link"
      fontSize="sm"
      color="primary.main"
      fontWeight="400"
      onClick={() => removeNotifications(removeNotificationsPayload)}
    >
      No, don’t show this again
    </Button>
  );
  if (surveyLevel === 0) {
    return (
      <Flex h="260px" margin="auto" my="6" px="3" direction="column">
        <Center fontWeight="700" fontSize="lg" mt="8" mb="4">
          Welcome to Centurion Beta v1
        </Center>
        <Box fontSize="sm" textAlign="center">
          Could you spare 2 minutes to answer a few questions that will help us build a product to
          suit your needs?
        </Box>
        <Center mt="8">{addButton}</Center>
        <Center mt="4">{removeButton}</Center>
      </Flex>
    );
  } else if (surveyLevel === 1) {
    return null;
    // return (
    //   <Flex direction="column" my="4">
    //     <Box m={3} pt={2} textAlign="justify">
    //       Thank you for using Centurion. The following survey is about your overall experience using the
    //       tool - based around the design, speed and usability of the current version. You will also
    //       be given the chance to provide any input as to what you would like to add to the tool, and
    //       what you think this might need to help to improve Centurion. This feedback will be used to help
    //       the development/design team improve the product prior to launch to external customers.
    //       This survey will take approximately 5 minutes to complete
    //     </Box>
    //     <Center pt={3} pb={3}>
    //       {addButton}
    //     </Center>
    //     <Center pt={3} pb={3}>
    //       {removeButton}
    //     </Center>
    //   </Flex>
    // );
  }
  // user shouldn't ever get to this stage based on isOpen clause
  return (
    <Box>Thank you for submitting feedback - this information will help to improve this tool.</Box>
  );
};

const UserFeedbackModal = () => {
  const [formModalOpen, setFormModalOpen] = useState(false);
  const { data: userFeedback } = useQueryFetch(['user_feedback'], () =>
    getUserFeedbackNotifications()
  );
  const { survey_level, user_form, allow_notifications } = userFeedback || {};

  useEffect(() => {
    if (
      allow_notifications &&
      survey_level === 0
      // || (survey_level === 1 && parseInt(total_views) > 150)
    ) {
      setFormModalOpen(true);
    } else {
      setFormModalOpen(false);
    }
  }, [allow_notifications, survey_level]);

  const handleClose = () => {
    setFormModalOpen(false);
  };

  return (
    <Modal isOpen={formModalOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent maxW="500px">
        <ModalCloseButton />
        <ModalBody>
          <UserFeedbackMessage
            surveyLevel={survey_level}
            form_url={user_form}
            handleClose={handleClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UserFeedbackModal;
