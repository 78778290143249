import { Center, Flex, Image } from '@chakra-ui/react';
import { Outlet } from '@tanstack/react-location';
import NavBar from './NavBar/NavBar';
import UserFeedbackModal from './UserFeedbackModal';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import UserConfigModal from 'Forms/UserConfig/UserConfigModal';
import MenuDrawer from './MenuDrawer/MenuDrawer';
import { VideoModal } from 'modals/VideoModal/VideoModal';
import { useEffect } from 'react';
import initializeTracking from '_shared/tracking/initializeTracking';

let trackingInit = false;

const MainContainer = () => {
  const { data, isLoading } = useUserDetails({ retry: false });

  useEffect(() => {
    if (trackingInit === false && data) {
      initializeTracking(data);
      trackingInit = true;
    }
  }, [data]);

  return (
    <>
      <Flex h="100vh" bg={isLoading ? 'white.100' : 'background'} direction="column">
        {isLoading ? (
          <Center h="100%" w="100%">
            <Image src="https://cv-qtp-images.s3.eu-west-2.amazonaws.com/cricviz-logo.png" />
          </Center>
        ) : null}
        {data ? (
          <>
            <MenuDrawer />
            <NavBar />
            <UserFeedbackModal />
            <UserConfigModal />
            <Outlet />
          </>
        ) : null}
      </Flex>
      <VideoModal />
    </>
  );
};

export default MainContainer;
