import checkEnabled from './checkEnabled/checkEnabled';

const validateFilters = (filterValues, filterDefs) => {
  const newFilterValues = {};
  Object.entries(filterValues).forEach(([key, value]) => {
    const filterDef = filterDefs.find((el) => {
      return el.name === key;
    });
    if (filterDef && checkEnabled(filterValues, filterDef.enabled)) {
      const { options } = filterDef;
      if (options) {
        if (Array.isArray(value)) {
          const newValues = [];
          value.forEach((el) => {
            const option = options.find((opt) => {
              return opt.value === el;
            });
            if (option && checkEnabled(filterValues, option.enabled)) {
              newValues.push(el);
            }
          });
          if (newValues.length !== 0) {
            newFilterValues[key] = newValues;
          }
        } else {
          const option = options.find((opt) => {
            return opt.value === value;
          });
          if (option && checkEnabled(filterValues, option.enabled)) {
            newFilterValues[key] = value;
          }
        }
      } else {
        newFilterValues[key] = value;
      }
    }
  });
  return newFilterValues;
};

export default validateFilters;
