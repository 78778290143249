import { Flex, useToken } from '@chakra-ui/react';
import TeamResult from './TeamResult';
import MultiSegmentCircularProgress from './MultiSegmentCircularProgress';

const HeadToHeadDisplay = ({ data }) => {
  const { teams, results } = data;
  const { team1, team2, total } = results;
  const [t1, t2, dcolor] = useToken('colors', [
    'teams.team-1a',
    'teams.team-2a',
    'teams.team-draw'
  ]);

  if (teams && teams.length > 1) {
    return (
      <Flex justifyContent="center" alignItems="center" my="5">
        <Flex gap="6" alignItems="center">
          <TeamResult team={teams[0]} win_count={team1} color={t1} />
          <Flex direction="column" gap="4">
            <MultiSegmentCircularProgress
              segments={[
                { value: (100 * team1) / total, color: t1 },
                { value: (100 * team2) / total, color: t2 },
                { value: (100 * (total - team1 - team2)) / total, color: dcolor }
              ]}
              played={total}
            />
            <TeamResult win_count={total - team1 - team2} color={dcolor} />
          </Flex>
          <TeamResult team={teams[1]} win_count={team2} color={t2} />
        </Flex>
      </Flex>
    );
  }
};

export default HeadToHeadDisplay;
