import { Box, Flex, Grid } from '@chakra-ui/react';
import AddUserFavourites from '_shared/components/AddUserFavourites';
import { useEffect, useRef } from 'react';
import autoAnimate from '@formkit/auto-animate';

const GroundV2SubHeader = ({ data, size }) => {
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  return (
    <Box bg="primary.shade.70" ref={parent}>
      <Flex py={4} px={5} alignItems="center">
        <Flex
          flexGrow={1}
          color="white"
          fontSize={20}
          h="48px"
          fontWeight="700"
          alignItems="center"
        >
          <Box>{data.main_heading}</Box>
        </Flex>
        <AddUserFavourites favType={'player'} favId={data.id} />
      </Flex>
      {size === 'large' && (
        <Grid
          gridTemplateColumns={{ base: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' }}
          gap="2"
          px="4"
          pb="4"
        >
          {data?.info?.map(({ label, value }) => {
            return (
              <Box bg="white.10" flex="1" borderRadius="4" py="2" px="3" lineHeight="1.3">
                <Box fontSize="xs" color="white.56">
                  {label}
                </Box>
                <Box fontSize="md" color="white.100" fontWeight="700">
                  {value}
                </Box>
              </Box>
            );
          })}
        </Grid>
      )}
    </Box>
  );
};

export default GroundV2SubHeader;
