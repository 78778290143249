import {
  Button,
  Box,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton
} from '@chakra-ui/react';
import { useState } from 'react';

import { newVideoPlaylist } from '_shared/dataFetching/apiService';
import { useQueryClient } from 'react-query';

const NewPlaylistModal = ({ formModalOpen, handleClose }) => {
  const queryClient = useQueryClient();
  const [playlistName, setPlaylistName] = useState('');
  return (
    <Modal isOpen={formModalOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent maxW="500px">
        <ModalCloseButton />
        <ModalBody mt={5} mb={5}>
          <Box fontWeight={'bold'} fontSize="lg" mb={4}>
            Create new playlist
          </Box>
          <Input
            type="text"
            name="playlist_name"
            placeholder="Enter playlist name"
            value={playlistName}
            onInput={(e) => setPlaylistName(e.target.value)}
          />
          <Box mt={2}>
            <Button
              w="100%"
              onClick={() => {
                newVideoPlaylist({ playlist_name: playlistName });
                setTimeout(() => queryClient.refetchQueries(['user_playlists']), 1000);
                setPlaylistName('');
                handleClose();
              }}
            >
              Save
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NewPlaylistModal;
