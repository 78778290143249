const chartColours = {
  1: ['#558eff'],
  2: ['#558eff', '#052566'],
  3: ['#558eff', '#3256af', '#052566'],
  4: ['#558eff', '#3e68c9', '#254596', '#052566'],
  5: ['#558eff', '#4471d6', '#3256af', '#1e3d8a', '#052566'],
  6: ['#558eff', '#4777de', '#3961bf', '#2a4ca0', '#193882', '#052566'],
  7: ['#558eff', '#4a7be4', '#3e68c9', '#3256af', '#254596', '#16357e', '#052566'],
  8: ['#558eff', '#4b7ee8', '#416ed1', '#375eba', '#2c4fa4', '#21408f', '#14327a', '#052566']
};

const getColourPalette = (arrLength) => {
  if (arrLength === 0) {
    return [];
  }
  if (arrLength <= 8) {
    const value = chartColours[arrLength];
    return value.reverse();
  }
  const colors = [];
  for (let i = 0; i < arrLength; i += 1) {
    const index = i % 8;
    colors.push(chartColours[8][index]);
  }
  return colors;
};

export default getColourPalette;
