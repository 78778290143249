import { Select, chakraComponents } from 'chakra-react-select';
import { ChevronDownOutline24 } from '_icons/outline/24';

const components = {
  DropdownIndicator: (props) => (
    <chakraComponents.DropdownIndicator {...props}>
      <ChevronDownOutline24 color="black.60" />
    </chakraComponents.DropdownIndicator>
  ),
  IndicatorSeparator: () => null,
  ClearIndicator: () => null
};

const buildSelectedValues = (options = [], valueArray = []) => {
  return valueArray.map((value) => {
    const option = options.find((el) => {
      return el.value === value;
    });
    return { value, label: option?.label || '' };
  });
};

const FilterMultiSelect = ({ options, label, handleFilterChange, name, filterValues }) => {
  const selectedValues = buildSelectedValues(options, filterValues[name]);

  const handleClick = (selectValues) => {
    const newValues = selectValues.map(({ value }) => value);
    handleFilterChange(name, newValues);
  };

  return (
    <Select
      value={selectedValues}
      placeholder={`Select ${label}`}
      options={options}
      isMulti
      size="sm"
      useBasicStyles
      onChange={handleClick}
      chakraStyles={{
        container: (prev) => ({
          ...prev,
          minHeight: '44px',
          zIndex: '1',
          width: '100%'
        }),
        menu: (prev) => ({
          ...prev,
          zIndex: 1400
        }),
        placeholder: (defaultStyles) => {
          return {
            ...defaultStyles,
            color: 'black.100'
          };
        }
      }}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 1400 })
      }}
      components={components}
      menuPortalTarget={document.body}
    />
  );
};

export default FilterMultiSelect;
