import checkDateFilter from './checkDateFilter';
import checkRangeFilter from './checkRangeFilter';

const checkEnabled = (filterValues, enabledLogic) => {
  try {
    if (!enabledLogic || Object.keys(enabledLogic).length === 0) return true;
    const { name, operator = '===', value } = enabledLogic;
    switch (operator) {
      case '===':
        if (Array.isArray(filterValues[name])) {
          const enabled = filterValues[name].some((arrValue) => arrValue === value);
          return enabled;
        }
        return filterValues[name] === value;
      case '!==':
        if (Array.isArray(filterValues[name])) {
          const enabled = filterValues[name].every((arrValue) => arrValue !== value);
          return enabled;
        }
        return filterValues[name] !== value;
      case '>':
        return filterValues[name] > value;
      case '<':
        return filterValues[name] < value;
      case '>=':
        return filterValues[name] >= value;
      case '<=':
        return filterValues[name] <= value;
      case 'isUndefined':
        return filterValues[name] === undefined;
      case 'isDefined':
        return filterValues[name] !== undefined;
      case 'AND':
        const andEnabled = value.every((el) => {
          const enabled = checkEnabled(filterValues, el);
          return enabled;
        });
        return andEnabled;
      case 'OR':
        const orEnabled = value.some((el) => {
          const enabled = checkEnabled(filterValues, el);
          return enabled;
        });
        return orEnabled;
      case 'date':
        const dateCheck = checkDateFilter(filterValues[name], value);
        return dateCheck;
      case 'range':
        const rangeCheck = checkRangeFilter(filterValues[name], value);
        return rangeCheck;
      default:
        return false;
    }
  } catch {
    console.log('enabled logic error');
    return false;
  }
};

export default checkEnabled;
