import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { Field } from 'formik';

const FormInput = ({ name, label, placeholder, onChangeCustom }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[name] && form.touched[name]}>
          <FormLabel>{label}</FormLabel>
          <Input
            {...field}
            h="44px"
            borderRadius={'5px'}
            size="sm"
            type="text"
            onChange={onChangeCustom || field.onChange}
            placeholder={placeholder}
          />
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default FormInput;
