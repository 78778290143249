import { useState } from 'react';
import { Flex, Box, Button } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import AddToPlaylistModal from 'pages/videoSearch/components/AddToPlaylistModal';
import { CloseOutline24, PlusOutline24 } from '_icons/outline/24';

const RemoveOrAddToOtherPlaylist = ({
  vidList,
  vidLength,
  setSelectedVideos,
  vidDict,
  removeItem
}) => {
  const [formModalOpen, setFormModalOpen] = useState(false);

  return (
    <>
      <AnimatePresence>
        {vidLength !== 0 ? (
          <motion.div
            key="snack-bar"
            initial={{ y: 64 }}
            animate={{ y: 0 }}
            exit={{ y: 64 }}
            transition={{
              y: { duration: 0.5, ease: 'easeOut' }
            }}
          >
            <Box
              position="fixed"
              bottom="0"
              height="64px"
              bg="white.100"
              borderTopRadius="md"
              overflow="hidden"
              w="100%"
              boxShadow="0px -2px 4px 0px #00000005"
            >
              <Flex p={3} justify="space-between">
                <Box>
                  <Button
                    mr={7}
                    fontSize="sm"
                    variant="tertiary"
                    type="button"
                    onClick={() => {
                      setSelectedVideos([]);
                    }}
                    fontWeight="bold"
                  >
                    Deselect
                  </Button>
                </Box>
                <Flex>
                  <Button
                    mr={7}
                    fontSize="sm"
                    variant="secondary"
                    type="button"
                    fontWeight="bold"
                    onClick={() => {
                      removeItem();
                    }}
                    display="flex"
                    gap="2"
                  >
                    <CloseOutline24 />
                    Remove from playlist
                  </Button>
                  <Button
                    fontSize="sm"
                    type="submit"
                    variant={'secondary'}
                    fontWeight="bold"
                    onClick={() => {
                      setFormModalOpen(true);
                    }}
                    display="flex"
                    gap="2"
                  >
                    <PlusOutline24 />
                    Add to another playlist
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </motion.div>
        ) : null}
      </AnimatePresence>
      <AddToPlaylistModal
        vidList={vidList}
        vidDict={vidDict}
        vidLength={vidLength}
        formModalOpen={formModalOpen}
        setFormModalOpen={setFormModalOpen}
        setSelectedVideos={setSelectedVideos}
      />
    </>
  );
};

export default RemoveOrAddToOtherPlaylist;
