import { useState, useEffect } from 'react';
import { getQTOptions } from '_shared/dataFetching/apiService';
import { editFormInitialValues } from '../utils';

const useEditFormInitialValues = (panelData) => {
  const [initialValues, setInitialValues] = useState(null);
  const [queryOptions, setQueryOptions] = useState(null);
  const { api_reference } = panelData;

  useEffect(() => {
    const fetch = async () => {
      const url = new URL(api_reference);
      url.searchParams.delete('size');
      try {
        const data = await getQTOptions(url.href);

        const init = editFormInitialValues(panelData, data);

        setQueryOptions(data);
        setInitialValues(init);
      } catch (err) {
        const init = editFormInitialValues(panelData);
        setInitialValues(init);
      }
    };
    if (api_reference && initialValues === null) {
      fetch();
    }
  }, [api_reference, initialValues, panelData]);

  return { initialValues, queryOptions };
};

export default useEditFormInitialValues;
