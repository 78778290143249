import { useEffect, useState } from 'react';

function getTabs(data) {
  const returnArr = [];
  data.forEach((el) => {
    if (el.object_type && !returnArr.includes(el.object_type)) {
      returnArr.push(el.object_type);
    }
  });
  return returnArr.sort();
}

const useRecentlyViewedFilters = (panelData) => {
  const { recently_viewed, most_viewed } = panelData?.data;
  const [listType, setListType] = useState('recent');
  const [typeOptions, setTypeOptions] = useState([]);
  const [viewType, setViewType] = useState('');

  useEffect(() => {
    if (listType === 'recent') {
      setTypeOptions(getTabs(recently_viewed));
    }
    if (listType === 'most') {
      setTypeOptions(getTabs(most_viewed));
    }
  }, [recently_viewed, most_viewed, listType]);

  useEffect(() => {
    if (!typeOptions.includes(viewType)) {
      setViewType(typeOptions[0]);
    }
  }, [typeOptions, viewType]);

  const data =
    listType === 'recent'
      ? recently_viewed.filter((el) => {
          return el.object_type === viewType;
        })
      : listType === 'most'
      ? most_viewed.filter((el) => {
          return el.object_type === viewType;
        })
      : [];

  return { listType, setListType, viewType, setViewType, typeOptions, data };
};

export default useRecentlyViewedFilters;
