import { Box, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import ButtonSelector from '_shared/components/ButtonSelector';
import ScoreRange from './ScoreRange';

const fullOptions = [
  { value: '0', label: '1st' },
  { value: '1', label: '2nd' },
  { value: '2', label: '3rd' },
  { value: '3', label: '4th' }
];

const InningsAverage = ({ innData = [] }) => {
  const [innings, setInnings] = useState('0');
  const options = fullOptions.slice(0, innData.length);

  return (
    <Box flex="2">
      <Flex alignItems="center" justifyContent="space-between" mb="4">
        <Box fontWeight="700" fontSize="md">
          Historical innings breakdowns
        </Box>
        <ButtonSelector options={options} handleChange={(v) => setInnings(v)} value={innings} />
      </Flex>
      {innData && innData[innings] ? <ScoreRange data={innData[innings]} /> : null}
    </Box>
  );
};

export default InningsAverage;
