import { Select } from '@chakra-ui/react';

const PageSelectFilter = ({ options, value, setValue, allOption }) => {
  if (options.length === 0) {
    return null;
  }
  return (
    <Select value={value} onChange={(e) => setValue(e.target.value)} w="250px" bg="white">
      {allOption ? <option value="all">{allOption}</option> : null}
      {options.map((item) => {
        const { value, label } = item;
        return <option value={value}>{label}</option>;
      })}
    </Select>
  );
};

export default PageSelectFilter;
