import { Box, Flex } from '@chakra-ui/react';
import Bar from './Bar';
import getStatsColor from './getStatsColor';

const BarItem = ({ data }) => {
  const { primary = {}, secondary = {} } = data?.values || {};

  return (
    <Flex gridColumn="span 2 / span 2" justify="center" align="center" minW="240px" flexGrow="2">
      <Flex direction="column" w="100%" gap="2">
        <Box fontSize="sm" color="black.100" lineHeight={1}>
          {primary.label}
        </Box>
        <Box fontSize="lg" fontWeight="bold" color={getStatsColor(primary.graph_value)} lineHeight={1}>
          {primary.display_value ?? '\u00A0'}
        </Box>
        <Flex direction="column" gap="1">
          <Bar value={primary.graph_value} color={getStatsColor(primary.graph_value)} />
          <Bar value={secondary.graph_value} color="black.32" />
        </Flex>
        {!!secondary.display_value && (
          <Box fontSize="xs" color="black.60" fontWeight="bold" lineHeight={1}>
            {secondary.label} {secondary.display_value}
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default BarItem;
