import NoDataDisplay from 'panels/Panel/NoDataDisplay';
import SquadPlayers from './SquadPlayers';

const TeamSquads = ({ panelData, classId }) => {
  const { class_id_list, squad_data } = panelData.data;
  if (
    class_id_list &&
    Object.keys(class_id_list).length !== 0 &&
    squad_data &&
    Object.keys(squad_data).length !== 0
  ) {
    const teams = {};
    Object.entries(class_id_list).forEach(([classId, label]) => {
      teams[classId] = {
        name: label
      };
    });
    return <SquadPlayers panelData={{ data: { teams, squads: squad_data } }} isMultiSelect />;
  }

  if (squad_data && squad_data[classId] && squad_data[classId].length !== 0) {
    const squadArray = squad_data[classId];
    return <SquadPlayers panelData={{ data: squadArray }} />;
  }
  return <NoDataDisplay message="No squad data available" />;
};

export default TeamSquads;
