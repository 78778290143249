import { Navigate } from '@tanstack/react-location';
import FetchComponent from 'pages/_shared/components/FetchComponent';
import BetaSwitch from './BetaSwitch';
import PageContainer from 'pages/_shared/components/PageContainer';

export const teamRoutes = {
  path: 'team',
  children: [
    { path: '/', element: <Navigate to="/home" /> },
    {
      path: ':objectId',
      element: (
        <BetaSwitch
          beta={process.env.REACT_APP_ENV !== 'prod' && <PageContainer type="team" key="team" />}
        >
          <FetchComponent type="team" />
        </BetaSwitch>
      )
    }
  ]
};
