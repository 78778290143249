import { Box, Button, Collapse, Flex, Grid } from '@chakra-ui/react';
import { ArrowRightOutline24 } from '_icons/outline/24';

const FilterAccordionGrid = ({ accordianFilters, columnCount, isFolded, handleSubmit }) => {
  if (!columnCount) return null;
  const columns = [];
  accordianFilters.forEach((filterElement, idx) => {
    const columnNumber = idx % columnCount;
    if (!columns[columnNumber]) {
      columns[columnNumber] = [];
    }
    columns[columnNumber].push(filterElement);
  });
  return (
    <Collapse in={!isFolded}>
      <Grid templateColumns={`repeat(${columnCount}, 1fr)`} gap="8">
        {columns.map((filters) => {
          return (
            <Flex direction="column" gap="5">
              {filters}
            </Flex>
          );
        })}
      </Grid>
      <Flex justify="flex-end" mt="10">
        <Button
          w={210}
          h="44px"
          fontSize="md"
          fontWeight="bold"
          onClick={() => {
            handleSubmit();
          }}
          display="flex"
          gap="2"
        >
          <Box>View results</Box>
          <ArrowRightOutline24 />
        </Button>
      </Flex>
    </Collapse>
  );
};

export default FilterAccordionGrid;
