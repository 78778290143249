import isEqual from 'lodash.isequal';
import { createColumnValue, getDataColumns } from '../_shared/utils';

const parseColumnsToFormValues = (data_columns) => {
  const columnSelection = [];
  let x_column;
  if (Array.isArray(data_columns)) {
    x_column = data_columns[0] || '';
    data_columns.forEach((col) => {
      columnSelection.push(createColumnValue(col));
    });
  } else if (typeof data_columns === 'object') {
    if (data_columns.x) {
      x_column = data_columns.x;
    }
    if (data_columns.y) {
      if (Array.isArray(data_columns.y)) {
        data_columns.y.forEach((col) => {
          columnSelection.push(createColumnValue(col));
        });
      } else {
        columnSelection.push(createColumnValue(data_columns.y));
      }
    }
  } else {
    columnSelection.push(createColumnValue(data_columns));
  }
  return { x_column, columnSelection };
};

export const editPanelInitialValues = (panelData) => {
  const { x_column, columnSelection } = parseColumnsToFormValues(panelData.data_columns);
  const initialValues = {
    columnData: panelData.all_columns,
    classId: panelData.class_id,
    data_value: panelData.data_value,
    panel_name: panelData.panel_name,
    category_type_id: panelData.category_type_id,
    panel_type_id: `${panelData.panel_type_id}`,
    is_live: Boolean(panelData.is_live),
    is_public: Boolean(panelData.is_public),
    panel_id: panelData.panel_id,
    variable_id: panelData.variable_id,
    columnSelection,
    x_column,
    data_columns: panelData.data_columns
  };
  if (panelData.data_type === 'qt') {
    initialValues.qt_api = panelData.api_reference;
  }
  if (panelData.data_type === 'ext') {
    initialValues.ext_api = panelData.api_reference;
    initialValues.nested_dict = panelData.nested_dict || [];
  }
  return initialValues;
};

export const generateEditPanelPayload = (values, initialValues) => {
  const payload = {};
  Object.entries(values).forEach(([key, value]) => {
    if (
      [
        'columnData',
        'apiData',
        'columnSelection',
        'x_column',
        'min_date',
        'max_date',
        'classId'
      ].includes(key)
    ) {
      return;
    }
    if (key === 'data_columns') {
      const newColumns = getDataColumns(values);
      if (!isEqual(initialValues[key], newColumns)) {
        payload.data_columns = newColumns;
      }
    } else if (!isEqual(initialValues[key], value)) {
      if (key === 'is_live' || key === 'is_public') {
        payload[key] = value ? 1 : 0;
      } else {
        payload[key] = value;
      }
    }
  });
  payload.panel_id = values.panel_id;
  payload.variable_id = values.variable_id;
  payload.class = values.classId;
  // if (values.classId !== 1001 && (values.min_date || values.max_date)) {
  //   payload.between_dates = getDateRange(values.min_date, values.max_date);
  // }
  return payload;
};
