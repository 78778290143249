import {
  Flex,
  Select,
  Switch,
  Center,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Checkbox,
  Button
} from '@chakra-ui/react';
import { Player, BigPlayButton, ControlBar } from 'video-react';
import { useState, useEffect, useRef } from 'react';
import VideoCard from './VideoCard';
import { postFavourites } from '_shared/dataFetching/apiService';
import { organiseFavourites } from '_shared/utils/utilFunctions';
import 'panels/PanelTypes/VideoPanel/Video.css';

const VideoCentreContainer = ({ rawData }) => {
  const { user_favourites, others_favourites } = rawData;
  const videoLibraries = { user: user_favourites, others: others_favourites };
  const [vidIndex, setVidIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [delFavList, setDelFavList] = useState([]);
  const [delOn, setDelOn] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [data, setData] = useState(videoLibraries.user);
  const videoRef = useRef();
  const vidIndexLogic = (forward) => {
    if (forward) {
      if (vidIndex === data.length - 1) {
        setVidIndex(0);
      } else {
        setVidIndex((prev) => prev + 1);
      }
    } else {
      if (vidIndex === 0) {
        setVidIndex(data.length - 1);
      } else {
        setVidIndex((prev) => prev - 1);
      }
    }
  };
  const autoPlayChange = () => {
    setAutoPlay((prevAutoPlay) => !prevAutoPlay);
  };
  const setDeleteOn = () => {
    setDelOn((prevDel) => !prevDel);
  };
  const repeatChange = () => {
    setRepeat((prevAutoPlay) => !prevAutoPlay);
  };
  useEffect(() => {
    videoRef.current.playbackRate = playbackRate;
  }, [playbackRate]);

  const setPlayBack = (e) => {
    setPlaybackRate(e);
  };

  return (
    <Flex h="98%" w="98%" m={2}>
      <Flex w="65%" h="85%" direction="column" m={2}>
        <Player
          ref={videoRef}
          src={data.length > 0 ? data[vidIndex].video_url : null}
          fluid={false}
          width={'100%'}
          height={'70%'}
          playsInline={true}
          autoPlay={autoPlay}
          muted={true}
          className="video-player-panel"
          onEnded={() => {
            setPlayBack(1);
            if (!repeat) {
              vidIndexLogic(true);
            } else {
              videoRef.current.play();
            }
          }}
        >
          <BigPlayButton position="center" />
          <ControlBar autoHide={true} className="control-bar-panel" autoHideTime={1000} />
        </Player>
        <Flex direction="column" w="100%">
          <Flex direction="row">
            <Center w="12%" pt={2} fontWeight="bold">
              Video Library
            </Center>
            <Flex w="15%" pt={3} ml={1} mr={2}>
              <Select
                onChange={(e) => setData(videoLibraries[e.target.value])}
                bg={'white'}
                border={'2px solid black'}
              >
                <option value="user">Favourites</option>
                <option value="others">Other</option>
              </Select>
            </Flex>
          </Flex>
          <Flex direction="row" pt={4}>
            <Center w="12%" ml={2} fontWeight="bold">
              Autoplay
            </Center>
            <Flex w="15%" pt={2} ml={1} mr={2}>
              <Switch id="autoplay" onChange={() => autoPlayChange()} />
            </Flex>
          </Flex>
          <Flex direction="row" pt={4}>
            <Center w="12%" ml={2} fontWeight="bold">
              Repeat
            </Center>
            <Flex w="15%" pt={2} ml={1} mr={2}>
              <Switch id="repeat" onChange={() => repeatChange()} />
            </Flex>
          </Flex>
          <Flex direction="row" pt={10}>
            <Center w="12%" ml={2} fontWeight="bold">
              Speed
            </Center>
            <Flex w="15%" pt={1} ml={1} mr={2}>
              <Slider
                aria-label="slider-ex-1"
                defaultValue={1}
                min={0.1}
                max={4}
                step={0.1}
                onChange={(val) => setPlayBack(val)}
              >
                <SliderMark
                  value={playbackRate}
                  textAlign="center"
                  bg="blue.500"
                  color="white"
                  mt="-10"
                  ml="-5"
                  w="12"
                >
                  {playbackRate}x
                </SliderMark>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex w="35%" h="100%" direction="column" overflowY="scroll">
        <Flex direction="row">
          <Center w="12%" ml={2} fontWeight="bold">
            Delete
          </Center>
          <Flex w="10%" pt={1} ml={1} mt={!delOn ? 0 : 2}>
            <Switch id="delete-favs" onChange={() => setDeleteOn()} />
          </Flex>
          {delOn ? (
            <Flex direction="row" w="78%">
              <Center ml={1} mr={2} fontWeight="bold">
                {!allSelected ? `Select all` : `Deselect All`}
              </Center>
              <Flex pt={1} ml={1} mr={2} direction="row">
                <Checkbox
                  isChecked={allSelected}
                  onChange={() => {
                    if (!allSelected) {
                      setDelFavList([...Array(data.length).keys()]);
                    } else {
                      setDelFavList([]);
                    }
                    setAllSelected((selection) => !selection);
                  }}
                />
              </Flex>
              <Flex pt={1} ml={1} mr={1} direction="row">
                <Button
                  variant={'ghost'}
                  colorScheme={'cricket'}
                  size={'md'}
                  ml={3}
                  onClick={async () => {
                    const submitFavList = organiseFavourites(delFavList, [], data);
                    const res = await postFavourites(submitFavList, 'video', 'delete');
                    if (res.status === 200) {
                      console.log('POST SUCCESS');
                    } else {
                      console.log('POST ERROR');
                    }
                    window.location.reload(false);
                  }}
                >
                  Delete
                </Button>
              </Flex>
            </Flex>
          ) : null}
        </Flex>
        {data.map((val, idx) => {
          return (
            <Flex
              key={val.ball_id}
              direction="column"
              border={
                (!delOn && idx === vidIndex) || (delOn && delFavList.includes(idx))
                  ? '3px solid red'
                  : '1px solid black'
              }
              _hover={{ bg: 'cricketSecondary.dark', cursor: 'pointer' }}
              p={5}
              mt={2}
              mb={2}
              borderRadius={4}
              onClick={() => {
                if (!delOn) {
                  setVidIndex(idx);
                } else {
                  if (delFavList.includes(idx)) {
                    setDelFavList((current) =>
                      current.filter((lsItem) => {
                        return lsItem !== idx;
                      })
                    );
                  } else {
                    setDelFavList([...delFavList, idx]);
                  }
                }
              }}
            >
              <VideoCard videoData={val} />
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default VideoCentreContainer;
