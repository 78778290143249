import { Box, GridItem } from '@chakra-ui/react';
import TeamHeader from 'content/_components/TeamHeader';
import TeamResults from './TeamResults';

const TeamPerformance = ({ dataItem, gridRowCount }) => {
  const { results, logo, name, id } = dataItem;

  return (
    <GridItem display="grid" gridTemplateRows="subgrid" rowSpan={gridRowCount}>
      <Box pb="1">
        <TeamHeader img={logo} teamName={name} id={id} />
      </Box>
      <TeamResults results={results} />
    </GridItem>
  );
};
export default TeamPerformance;
