import { Box, Flex } from '@chakra-ui/react';
import LiveIcon from '_icons/icons/LiveIcon';

const ResultString = ({ data }) => {
  const matchStatus = data?.status?.match_status;
  return (
    <Flex alignItems="center" gap="2" w="100%" justifyContent="center">
      {matchStatus === 'live' && (
        <Flex gap="1">
          <LiveIcon color="positive.tint.10" />
          <Flex
            bg="positive.tint.10"
            fontSize="xs"
            rounded="sm"
            lineHeight="1"
            py="1px"
            px="0.5"
            fontWeight="700"
            justifyContent="center"
            alignItems="center"
          >
            Live
          </Flex>
        </Flex>
      )}
      <Box color="positive.tint.10" fontWeight="600" fontSize="sm">
        {data?.status?.string}
      </Box>
    </Flex>
  );
};

export default ResultString;
