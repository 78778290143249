import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useRecoilValue } from 'recoil';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import { PAGE_FILTER_DATES } from '_shared/globalState/atoms';
import checkMinMaxDates from './checkMinMaxDates';

dayjs.extend(isSameOrAfter);

const usePageFilterDates = () => {
  const pageFilterDates = useRecoilValue(PAGE_FILTER_DATES);
  const { data: userInfo = {} } = useUserDetails();
  const { user_date_min, user_date_max } = userInfo || {};
  const { min: page_date_min, max: page_date_max } = pageFilterDates || {};
  return checkMinMaxDates({
    user_date_min,
    user_date_max,
    page_date_min,
    page_date_max
  });
};

export default usePageFilterDates;
