import { Grid, GridItem, Flex, Box, Text } from '@chakra-ui/react';
import { InfoOutline48 } from '_icons/outline/48';
import StatsItem from './StatsItem';
import { useState } from 'react';
import ButtonSelector from '_shared/components/ButtonSelector';
import SelectFilter from './SelectFilter';
import PercentilesInfo from '../_components/info/PercentilesInfo';

import Header from '../_components/header/Header';
import HeaderInfo from '../_components/header/HeaderInfo';

import { useSearch } from '@tanstack/react-location';

const StatsContent = ({ content, objectVariable }) => {
  const { data, filters } = content;
  const defaultPrimaryValue = filters?.primary?.[0]?.value;
  const defaultSecondaryValue = filters?.secondary?.[0]?.value;
  const [primaryFilter, setPrimaryFilter] = useState(defaultPrimaryValue);
  const [secondaryFilter, setSecondaryFilter] = useState(defaultSecondaryValue);

  const search = useSearch();
  const { tab } = search;
  const [infoOpen, setInfoOpen] = useState(false);
  const { header, key } = content;

  const renderItems = () => {
    let items = [];
    let barGroup = [];
    data.forEach((item, idx) => {
      if (item.filters?.primary && item.filters.primary !== primaryFilter) return;
      if (item.filters?.secondary && item.filters.secondary !== secondaryFilter) return;
      const key = `${item.values?.primary?.key} + ${idx}`;
      const itemComponent = <StatsItem data={item} key={key} />;
      if (item.type !== 'bar' || item.values?.secondary?.display_value) {
        if (barGroup[0]) {
          items = items.concat(barGroup);
          barGroup = [];
        }
        items.push(itemComponent);
      } else {
        barGroup.push(itemComponent);
        if (barGroup.length === 2) {
          items.push(
            <GridItem
              colSpan="2"
              direction="column"
              key={`bar_group_${idx}`}
              display="flex"
              flexDirection="column"
              rowGap="4"
              gridColumnEnd={-1}
            >
              {barGroup}
            </GridItem>
          );
          barGroup = [];
        }
      }
    });

    if (!items?.length) {
      return (
        <Flex
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Box py={10}>
            <InfoOutline48 color="primary.tint.80" mb={1} />
            <Text>No data available for this player</Text>
          </Box>
        </Flex>);
    }

    return items;
  };

  return (
    <Flex direction="column" userSelect="none" h="100%">
      <Header
        title={header}
        showInfoButton
        infoOpen={infoOpen}
        setInfoOpen={setInfoOpen}
      />
      <Box position="relative" flex="1">
        <Flex px="4" gap="4">
          {filters?.primary && filters.primary.length !== 0 && (
            <Box flexGrow={filters.primary.length} pb={4}>
              <ButtonSelector
                fullWidth
                value={primaryFilter}
                handleChange={setPrimaryFilter}
                options={filters.primary}
              />
            </Box>
          )}
          {filters?.secondary && filters.secondary.length !== 0 && (
            <Box flexGrow="1" pb={4}>
              <SelectFilter
                value={secondaryFilter}
                handleChange={setSecondaryFilter}
                options={filters.secondary}
              />
            </Box>
          )}
        </Flex>
        <Grid
          ml="4"
          mr="4"
          mt="0"
          mb="6"
          templateColumns="repeat(auto-fit,minmax(120px, 1fr))"
          // templateColumns={{base: "repeat(auto-fit,minmax(120px, 1fr))", md: "repeat(auto-fit,minmax(320px, 1fr))"}}
          gap="4"
          gridAutoFlow="dense"
        >
          {renderItems()}
        </Grid>
        <HeaderInfo infoOpen={infoOpen}>
          <Info content={content} key={`${key}_info`} tab={tab} objectVariable={objectVariable} />
        </HeaderInfo>
      </Box>
    </Flex>
  );
};

const Info = ({ content, tab, objectVariable }) => {
  let line1;
  let line2;
  let line3;

  if (tab === 'profile') {
    if (objectVariable === 'batter') {
      line1 = "Our Player Form Analysis Panel uses a data science model to evaluate a batter's performance in the last 10 innings when compared to his entire career for the chosen format.";
      line2 = "The color-coded bars indicate the batter's percentile rank compared to all batters in the format:";
    } else if (objectVariable === 'bowler') {
      line1="Our Player Form Analysis Panel uses a data science model to evaluate a bowler's performance in the last 10 innings when compared to his entire career.";
      line2="The color-coded bars indicate the bowler's percentile rank compared to all bowlers in the format:";
    }
  } else if (tab === 'headtohead') {
    switch (content?.key) {
      case "vs_spin_direction_general_stats":
        {
          line1="Understand a batter's proficiency against different spin directions with our intuitive Spin Direction Panel.";
          line2="The color-coded bars indicate the batter's percentile rank compared to all other same-handed batters in the format:";
          break;
        }
      case "vs_spin_general_stats":
        {
          line1="Understand a batter's proficiency against different speed variations of spin.";
          line2="The color-coded bars indicate the batter's percentile rank compared to all batters in the format:";
          break;
        }
      case "vs_pace_general_stats":
        {
          line1="Understand a batter's proficiency against different speed of Pace bowlers.";
          line2="The color-coded bars indicate the batter's percentile rank compared to all batters in the format:";
          break;
        }
      case "innings_progression___balls_faced_general_stats":
        {
          line1="Understand a batter's proficiency at different stages of their innings.";
          line2="The color-coded bars indicate the batter's percentile rank compared to all batters in the format:";
          break;
        }
      case "phase_breakdown_general_stats":
        {
          line1="Understand a batter's proficiency at different stages of their team's innings.";
          line2="The color-coded bars indicate the batter's percentile rank compared to all batters in the format:";
          break;
        }
      case "speed_usage_general_stats":
        {
          line1="Understand a pace bowler's proficiency across the innings.";
          line2="The color-coded bars indicate the bowler's percentile rank compared to all pace bowlers in the format:";
          line3="The panel also shows the pace bowler's slower ball delivery %.";
          break;
        }
      case "bowler_utilisation_general_stats":
        {
          line1="Understand where a bowler has bowled across the different phases.";
          line2="The color-coded bars indicate the bowler's percentile rank compared to all bowlers of the same type in the format:";
          break;
        }
      case "length__economy___strike_rate_general_stats":
        {
          line1="Understand a pace bowler's proficiency for different length groupings across the different phases.";
          line2="The color-coded bars indicate the bowler's percentile rank compared to all pace bowlers in the format:";
          break;
        }
      case "death_bowling_approach_general_stats":
        {
          line1="Understand a pace bowler's proficiency for pace on vs. pace off at the death.";
          line2="The color-coded bars indicate the bowler's percentile rank compared to all pace bowlers in the format:";
          break;
        }
      case "vs_right_handers_general_stats":
        {
          line1="Understand a spin bowler's proficiency for the different spin variations against right handed batters.";
          line2="The color-coded bars indicate the bowler's percentile rank compared to all spin bowlers in the format:";
          line3="The panel also shows the % of balls bowled with the selected variation.";
          break;
        }
      case "vs_left_handers_general_stats":
        {
          line1="Understand a spin bowler's proficiency for the different spin variations against left handed batters.";
          line2="The color-coded bars indicate the bowler's percentile rank compared to all spin bowlers in the format:";
          line3="The panel also shows the % of balls bowled with the selected variation.";
          break;
        }
      case "length_economy___strike_rate_general_stats":
        {
          line1="Understand a spin bowler's proficiency for different length groupings.";
          line2="The color-coded bars indicate the bowler's percentile rank compared to all spin bowlers in the format:";
          line3="The panel also shows the % of balls bowled with the selected length.";
          break;
        }
      case "speeds_general_stats":
        {
          line1="Understand a spin bowler's proficiency for different speed groupings.";
          line2="The color-coded bars indicate the bowler's percentile rank compared to all spin bowlers in the format:";
          line3="The panel also shows the % of balls bowled with the selected speed.";
          break;
        }
      case "swing_direction_general_stats":
        {
          if (objectVariable === 'batter') {
            line1="Understand a batter's proficiency against different swing directions with our intuitive Swing Direction Panel.";
            line2="The color-coded bars indicate the batter's percentile rank compared to all other same-handed batters in the format:";
          } else if (objectVariable === 'bowler') {
            line1="Understand a bowler's proficiency in different swing directions against different batter's hand with our intuitive Swing Direction Panel.";
            line2="The color-coded bars indicate the bowler's percentile rank compared to all other bowlers of the similar type in the format:";
          }
          break;
        }
      case "seam_direction_general_stats":
        {
          if (objectVariable === 'batter') {
            line1="Understand a batter's proficiency against different swing directions with our intuitive Swing Direction Panel.";
            line2="Understand a batter's proficiency against different seam directions with our intuitive Seam Direction Panel.";
          } else if (objectVariable === 'bowler') {
            line1="Understand a bowler's proficiency in different seam directions against different batter's hand with our intuitive Seam Direction Panel.";
            line2="The color-coded bars indicate the bowler's percentile rank compared to all other bowlers of the similar type in the format:";
          }
          break;
        }
      case "vs_ball_age_general_stats":
        {
          line1="Understand a batter's proficiency against New and Old ball";
          line2="The color-coded bars indicate the batter's percentile rank compared to all other same-handed batters in the format:";
          break;
        }

      case "ball_age_general_stats":
        {
          line1="Understand a bowler's proficiency in New and Old ball usage";
          line2="The color-coded bars indicate the bowler's percentile rank compared to all other bowlers of the similar type  in the format:";
          break;
        }
      case "spin_direction_general_stats":
        {
          line1="Understand a bowler's proficiency in different spin directions against different batter's hand with our intuitive Spin Direction Panel.";
          line2="The color-coded bars indicate the bowler's percentile rank compared to all other bowlers of the similar type in the format:";
          break;
        }
      default: {
        break;
      }
    }
  }

  let message = null;

  if (line1 || line2 || line3) {
    message = (
      <PercentilesInfo
        line1={line1}
        line2={line2}
        line3={line3}
      >
      </PercentilesInfo>);
  }

  return message;
};

export default StatsContent;
