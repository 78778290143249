import { Flex } from '@chakra-ui/react';
import TeamImage from '_shared/components/TeamImage';
import RouterLink from '_shared/components/Utils/RouterLink';

const Team = ({ team, reversed, size }) => {
  return (
    <Flex
      color="white.100"
      fontSize="lg"
      alignItems="center"
      gap="2"
      direction={reversed ? 'row-reverse' : 'row'}
      flex={size === 'large' && '1'}
    >
      <TeamImage team={team} type="circle" size={48} padding={8} />
      <RouterLink to={`/team/${team.id}`}>{team.name}</RouterLink>
    </Flex>
  );
};

const PreMatchTeams = ({ data, size }) => {
  const teams = data?.teams || [];
  const [homeTeam, awayTeam] = teams;
  const reversed = size !== 'small';
  return (
    <Flex gap="2" w="100%" justifyContent={size === 'small' ? 'start' : 'center'} pb="2">
      <Team team={homeTeam} reversed={reversed} size={size} />
      <Team team={awayTeam} size={size} />
    </Flex>
  );
};

export default PreMatchTeams;
