import summary from './summary';
import insights from './insights';
import squads from './squads';

const configDefs = {
  ...summary,
  ...insights,
  ...squads
};

export default configDefs;
