const panelFetchErrorMessage = (type, name) => {
  if (type === 'player') {
    return `Panel data unavailable for ${type}${name ? ' ' : ''}${
      name || ''
    }, try selecting a different format`;
  }
  return `Panel data unavailable for ${type} ${name || ''}`;
};

export default panelFetchErrorMessage;
